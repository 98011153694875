import React, { useEffect } from "react";
import { useStyles } from "../styles";
import { Box, createTheme, ThemeProvider, Typography,CircularProgress, } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import LinearProgressWithLabel from "@material-ui/core/LinearProgress";
import ErrorMessage from "../../Validation/ErrorMessage";


function AddressProofFile({description,setFile,handleUploadButton,upload,handleSubmit,document}) {
    const classes = useStyles();
    const theme = createTheme({
      overrides: {
        bar: {
          background: "#48D4A5",
          height: "100px"
        }
      }
    });
  
    const [progress, setProgress] = React.useState(0);
    const [uploadStep, setUploadStep] = React.useState(1);
    const [fileTypeError,setFileTypeError]=React.useState(false)
    const [fileSizeError,setFileSizeError]=React.useState(false)
  
    //uploadCounter(uploadStep);

   

    useEffect(()=>{
      if(document){
        setUploadStep(3)
      }
    },[document])
  
    const onDrop = (acceptedFiles) => {
      if(!acceptedFiles[0].name.match(/\.(jpg|png)$/)){
        setFileTypeError(true)
      }else{
        setFileTypeError(false)
      if(acceptedFiles[0].size<5242880){
        setFile(acceptedFiles)
      setUploadStep(3);
      setFileSizeError(false)
      }else{
        setFileSizeError(true)
      }
    }
      
    };
  
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop, multiple: false, 
    accept:'image/*'
    
      
    });
  
    const files = acceptedFiles.map((file) => (
      <Box key={file.path}>{file.name}</Box>
    ));

    
  
    const handleCloseFile=()=>{
      console.log('asdfas')
      setUploadStep(1)
      handleUploadButton()
    }
  
    const UploadStepTwo = (props) => {
     // setLoading(true)
      React.useEffect(() => {
        const timer = setInterval(() => {
          setProgress((oldProgress) => {
            if (oldProgress === 100) {
              //setUploadStep(3);
              //
              clearInterval(timer);
              //handleSubmit()
              return 100
              
             
            }else{
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 100);
            }
          });
        }, 50);
  
        return () => {
          clearInterval(timer);
        };
      }, []);
  
      
  
      const size = acceptedFiles.map((file, progress,i) => (
        <Box key={i}>{Number(file.size / 1024).toFixed(2)}kb</Box>
      ));
      return (
        <React.Fragment>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            className={classes.file}
            p={2}
            px={2}
          >
            <Box
              width="350px"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                width="100px"
              >
                <img src="/images/file.svg" height={35} width={35} />
                <Box
                  fontSize={14}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mt={1}
                >
                   {acceptedFiles.length>0 ? files :  <Box >{document.split('\\').pop().split('/').pop()}</Box>}
                </Box>
              </Box>
              <img src="/images/close.svg" height={20} width={20} onClick={handleCloseFile}/>
            </Box>
            <Box width="100%" my={1}>
              <ThemeProvider theme={theme}>
                <LinearProgressWithLabel
                  variant="determinate"
                 
                  value={progress}
                  //className={classes.progress}
                  classes={{ barColorPrimary: classes.barColorPrimary}}
                  {...props}
                />
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  my={1}
                >
                  {size}
                  {Math.floor(progress)}%
                </Box>
  
                <Box />
              </ThemeProvider>
            </Box>
          </Box>
          
        </React.Fragment>
      );
    };
  
    useEffect(()=>{
      console.log(upload)
        if(upload){
            setUploadStep(2)
        }
    },[upload])
  
    return (
      <React.Fragment>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          width="100%"
        >
          {uploadStep === 1 && (
            <React.Fragment>
              <Box className={classes.fileUpload}>
                <Box
                  {...getRootProps({ className: "dropzone" })}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  px={5}
                  py={2}
                >
                  <input {...getInputProps()} />
                  <img src="/images/upload.svg" height={80} width={80} onClick={handleCloseFile}/>
                  <Box mt={1} fontSize={14}>
                    {description}
                  </Box>
                  <Box mt={1} fontSize={12}>
                  {`Format: .png/.jpg, Max Size:5MB`}
                  </Box>
                  
                </Box>
                
              </Box>
              {fileSizeError && <ErrorMessage message="Maximun file size is 5MB"/>}
              {fileTypeError && <ErrorMessage message={`File type must be .jpg/.png`}/>}
              </React.Fragment>
              ) }
              
             
              {uploadStep === 3 ? (
            <React.Fragment>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                width="350px"
                mb={4}
              >
                <Box
                  className={classes.file}
                  p={2}
                  px={2}
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Box mr={2}>
                      <img src="/images/file.svg" height={35} width={35} />
                    </Box>
  
                    <Box
                      fontSize={14}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {acceptedFiles.length>0 ? files :  <Box >{document.split('\\').pop().split('/').pop()}</Box>}
                     
                    </Box>
                  </Box>
                  <img src="/images/close.svg" height={20} width={20} onClick={handleCloseFile} />
                </Box>
              </Box>
              
            </React.Fragment>
          ) : (
            ""
          )}
     {uploadStep === 2 ? <UploadStepTwo /> : ""}
          
          
  
         
        </Box>
      </React.Fragment>
    )
}

export default AddressProofFile