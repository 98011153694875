import React from "react";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import moment from "moment";
import localeString from "../../../hooks/LocaleString";

const OrderList = ({ orderData }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      borderBottom: "1px solid #DDDDDD",
      //borderRadius: "5px",
    },
    header: {
      fontSize: "0.85rem",
    },
    greyText: {
      fontSize: "0.85rem",
      color: "#C0C0C0",
    },
    successText: {
      color: "#6FDDB8",
      fontSize: "0.8rem",
      textAlign: "end",
      fontWeight: 500,
      paddingRight: "10px",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    failedText: {
      color: "#F27E45",
      fontSize: "0.8rem",
      textAlign: "end",
      fontWeight: 500,
      paddingRight: "10px",
    },
    pendingText: {
      color: "#F2AF1A",
      fontSize: "0.8rem",
      textAlign: "end",
      fontWeight: 500,
      paddingRight: "10px",
    },
  }));
  const classes = useStyles();



  return (
    <div>
      <Box className={classes.root} p={2}>
        <Grid container>
          <Grid item xs={9} md={7} lg={7}>
            <Box display="flex" alignItems="center">
              <img src={orderData.logo_url} height="auto" width="40px" style={{ borderRadius: '10px', }} />
              <Box pl={2} display="flex" alignItems="start" flexDirection={'column'}>
                <Typography className={classes.header} style={{ fontWeight: '600' }}>
                  {orderData?.fund_name
                    ? orderData?.fund_name
                    : orderData?.fundName}
                </Typography>
                <Typography className={classes.header}>
                  <span style={{ fontWeight: '300' }}>Scheme Id:</span>{" "}
                  {orderData?.scheme}
                </Typography>
              </Box>
              {/* <Box className={classes.type} style={{color:orderTypeColor[ele?.orderType],background:`${orderTypeColor[ele?.orderType]}80`}}>{orderTypeObj[ele?.orderType]}</Box> */}
            </Box>
          </Grid>

          <Grid item xs={2} lg={3}alignItems="center" display="flex">

            <Box display="flex" alignItems="center" justifyContent={'center'} flexDirection={'row'}>
              <Box px={1}>
                <span className={classes.header} style={{ fontWeight: '300', fontSize: '0.7rem' }}>Amount:</span>{" "}
                <Typography className={classes.header}>
                  <span className={classes.rupees}>₹</span>{" "}
                  {orderData?.amount && localeString(orderData?.amount)}
                </Typography>
              </Box>
              <Box px={1}>
                <span className={classes.header} style={{ fontWeight: '300', fontSize: '0.7rem' }}>Units:</span>{" "}
                <Typography className={classes.header}>
                  {orderData?.allotted_units ? orderData?.allotted_units : '--'}
                </Typography>
              </Box>
              </Box>
          </Grid>

          <Grid item xs={3} lg={2}>
            <Box display="flex" justifyContent="end" alignItems="end">
              <Typography className={classes.greyText}>
              {orderData?.created_at ?
                moment(orderData?.created_at).format("DD MMM YYYY") : `${orderData?.installment_day} ${orderData?.frequency}` }
              </Typography>
              <ChevronRightIcon style={{ cursor: "pointer" }} />
            </Box>
            <Typography
              className={
                orderData?.state == "successful"
                  ? classes.successText
                  : orderData?.status == "pending"
                    ? classes.pendingText
                    : classes.failedText
              }
            >
              {orderData?.state}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default OrderList;
