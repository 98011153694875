import React, { useState } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Grid,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { GET_SUGGESTED_FUND } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import { useNavigate } from "react-router-dom";
import Strategy from "./Strategy";
import ExpiryBanner from "./ExpiryBanner";
import AssistedOrderSummary from "./AssistedOrderSummary";
import { useSelector } from "react-redux";
import moment from "moment";
import { namespace } from "../../../environment";

const InvestDetails = () => {
  const useStyles = makeStyles({
    header: {
      fontSize: "1.1rem",
      color: "#0E76BC",
      fontWeight: 500,
    },
    inputBox: {
      background: "#E6E6E6",
      padding: "0.7rem 0.95rem",
      borderRadius: "10px",
      border: "none",
      // width: "90%",
      fontFamily: "inherit",
      marginBottom: "15px",
    },
    label: {
      fontSize: "0.8rem",
      marginBottom: "5px",
      fontWeight: 600,
    },
    select: {
      fontSize: "0.85rem",
      color: "black",
      padding: "3px 0px",
      borderRadius: "10px",
      background: "#E6E6E6",

      //minWidth:"20rem",
      "& .MuiSelect-select": {
        paddingTop: "10px ",
        paddingBottom: "10px",
        paddingRight: "40px",
        paddingLeft: "10px",
        borderRadius: "5px",
        "&:focus": {
          backgroundColor: "#E6E6E6",
        },
      },
      "& .MuiSvgIcon-root": {
        color: "black",
      },
      "& .MuiMenuItem-root": {
        fontSize: "0.85rem !important",
      },
    },
    menu: {
      boxShadow: "none",
      border: "1px solid #DDDDDD",
    },
    menuItem: {
      fontSize: "0.85rem !important",
    },
    containerBox: {
      width: "50%",
    },
    primaryButton: {
      color: "white",
      backgroundColor: "#0E76BC",
      border: "none",
      borderRadius: "39px",
      fontSize: "15px",
      fontWeight: "500",
      padding: "10px 4rem",
      fontFamily: "inherit",
      cursor: "pointer",
    },
    rupees: {
      position: "absolute",
      marginTop: "36px",
      marginLeft: "10px",
      fontFamily: "Open Sans",
    },
    image: {
      height: "250px",
      width: "500px",
    },
    "@media (max-width: 600px)": {
      image: {
        height: "250px",
        width: "300px",
      },
      containerBox: {
        width: "80%",
      },
    },
  });
  const classes = useStyles();

  const [amount, setAmount] = useState("");
  const [year, setYear] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [yearError, setYearError] = useState(false);
  const [suggestedFund, setSuggestedFund] = useState([]);
  const [strategy, setStrategy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resubscribe, setResubscribe] = useState(false);
  const [error, setError] = useState(false);

  const { userStore } = useSelector((state) => state);

  const navigate = useNavigate();

  const handle_amount = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
    if (e.target.value < 500000) {
      setAmountError("Minimum amount is 5,00,000");
    } else if (e.target.value >= 10000000) {
      setAmountError("Personal Assistance to be provided. Contact the support");
    } else {
      setAmountError(false);
    }
    setAmount(e.target.value);
  };

  const handle_years = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").toLowerCase();
    if (e.target.value == "") {
      setYearError("Please enter Year");
    } else {
      setYearError(false);
    }
    setYear(e.target.value);
  };

  const handle_submit = async () => {
    setError(false);
    if (!amount) {
      setAmountError("Please enter Amount");
    } else if (!amountError && !yearError) {
      setLoading(true);
      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          amount: amount,
          tenure: year,
          userId: localStorage.getItem("user_id"),
          suggestCount: 1,
        },
      };

      try {
        const response = await httpInstance(
          `${GET_SUGGESTED_FUND}`,
          httpConfig
        );
        setSuggestedFund(response.data?.data);
        setStrategy(true);
        setTimeout(() => {
          navigate(
            `${namespace}/dashboard/mutualfund/assisted-services/suggested-funds`,
            {
              state: {
                fundsData: response.data?.data,
                totalAmount: amount,
                tenure: year,
              },
            }
          );
        }, "5000");
      } catch (error) {
        setLoading(false);
        setError(error.response?.data?.message);
      }
    }
  };

  //-----------------handle resubscribe------------------------------//
  const handle_resubscribe = () => {
    setResubscribe(true);
  };
  return (
    <div>
      {!strategy ? (
        resubscribe ? (
          <AssistedOrderSummary />
        ) : (
          <>
            {moment(
              userStore?.user?.assistedServiceUserDetailsId?.expiresOn
            ).diff(moment(), "days") < 16 && (
              <ExpiryBanner handle_resubscribe={handle_resubscribe} />
            )}
            <Box px={{ xs: 1.5, md: 13 }} py={6}>
              <Grid container>
                <Grid item xs={12} md={5}>
                  <Typography className={classes.header}>
                    Enter these Details to help us plan and analyze best
                    Strategy for you
                  </Typography>
                  <Box
                    mt={5}
                    display="flex"
                    flexDirection="column"
                    className={classes.containerBox}
                  >
                    <Typography className={classes.label}>
                      Enter the Amount
                    </Typography>
                    <p className={classes.rupees}>₹</p>
                    <input
                      className={classes.inputBox}
                      placeholder="Enter Amount"
                      onChange={handle_amount}
                      required
                      style={{ paddingLeft: "23px" }}
                    />
                    {amountError && <ErrorMessage message={amountError} />}
                    <Typography className={classes.label}>
                      Enter the Investment Horizon
                    </Typography>
                    <input
                      className={classes.inputBox}
                      placeholder="Enter the number of Years"
                      onChange={handle_years}
                      required
                    />
                    {yearError && <ErrorMessage message={yearError} />}
                    {/* <FormControl >
                        <Select
                        //value={type}
                        // onChange={handleChange}
                        className={classes.select}
                        disableUnderline
                        IconComponent={KeyboardArrowDownIcon}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                            style: {
                            maxHeight: 350,                
                            },
                            anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                            },
                            transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                            },
                            getContentAnchorEl: null,
                            classes: { paper: classes.menu}
                        }}
                        >  
                       
                        <MenuItem value={10} className={classes.menuItem}>10 Years</MenuItem>
                        <MenuItem value={5} className={classes.menuItem}>5 Years</MenuItem>
                        <MenuItem value={3} className={classes.menuItem}>3 Years</MenuItem>
                        <MenuItem value={2} className={classes.menuItem}>1 Year</MenuItem>
                        </Select>  
                     </FormControl>  */}
                  </Box>
                </Grid>

                <Grid item xs={12} md={7}>
                  <Box display="flex" justifyContent="end">
                    <img
                      src="/images/assitedInvest.svg"
                      className={classes.image}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box display="flex" justifyContent="center" mt={5}>
              <button className={classes.primaryButton} onClick={handle_submit}>
                {loading ? (
                  <CircularProgress size="1rem" color="inherit" />
                ) : (
                  " Next"
                )}
              </button>
            </Box>
            <Box display="flex" justifyContent="center">
              {error && <ErrorMessage message={error} />}
            </Box>
          </>
        )
      ) : (
        <Box px={{ md: 13 }} py={6}>
          <Strategy />
        </Box>
      )}
    </div>
  );
};

export default InvestDetails;
