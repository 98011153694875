import React, { useState, useEffect } from "react";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TransHistory from "./TransHistory";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LocaleString from "../../../../hooks/LocaleString";
import { SEARCH_BY_ISIN, GET_TRANS_HISTORY } from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import { namespace } from "../../../../environment";

const Index = () => {
  const useStyles = makeStyles({
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },
    rootBox: {
      border: "1px solid #DDDDDD",
      borderRadius: "5px",
    },

    box1: {
      borderBottom: "1px solid #DDDDDD",
      cursor: "pointer",
    },
    title: {
      fontSize: "1rem",
      fontWeight: 500,
      color: "#0E76BC",
      //padding:"0px 10px"
    },

    grid: {
      display: "flex",
      justifyContent: "end",
    },

    girdEndTextgrey: {
      display: "flex",
      justifyContent: "end",
      fontSize: "0.8rem",
      color: "#C0C0C0",
    },
    girdEndText: {
      display: "flex",
      justifyContent: "end",
      fontSize: "0.8rem",
    },

    textGrey: {
      fontSize: "0.8rem",
      color: "#C0C0C0",
    },
    text: {
      fontSize: "0.8rem",
    },
    greyTitle: {
      color: "#C0C0C0",
      fontWeight: 600,
    },

    subTitle: {
      fontWeight: 600,
    },
    textBlue: {
      color: "#0E76BC",
      fontWeight: 600,
    },
    rupees: {
      fontFamily: "Open Sans ",
    },
  });
  const classes = useStyles();

  const [transData, setTransData] = useState([]);

  const { state } = useLocation();
  const { schemeData, fundData } = state;
  // console.log(state)

  const navigate = useNavigate();

  const get_all_transaction = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { isin: schemeData?.isin, folio: schemeData?.folioNumber },
    };

    try {
      const response = await httpInstance(`${GET_TRANS_HISTORY}`, httpConfig);
      setTransData(response.data?.data);
    } catch {}
  };

  useEffect(() => {
    get_all_transaction();
  }, []);

  const navigate_fund = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { isin: schemeData?.isin },
    };

    try {
      const response = await httpInstance(`${SEARCH_BY_ISIN}`, httpConfig);
      navigate(
        `${namespace}/mutualfund/scheme-code/${response.data?.data[0]?.scheme_code}`
      );
    } catch {}
  };

  return (
    <div>
      <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3" }}
      >
        <Link
          to={`${namespace}/myportfolio?tab=mutualfund`}
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            Mutual Funds
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography className={classes.subTabtext}>
            {schemeData?.schemes?.[0]?.name}
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="flex-start"
        px={{ xs: 1, md: 18 }}
        mt={2}
        className={classes.backButton}
      >
        <Link to={`${namespace}/myportfolio?tab=mutualfund`}>
          <img src="/images/backIcon.svg" height="20px" width="20px" />
        </Link>
      </Box>

      {/* card */}
      <Box px={{ xs: 1, md: 20 }} mt={4}>
        <Box p={3} className={classes.rootBox}>
          <Link
            to={`${namespace}/mutualfund/scheme-code/${schemeData?.schemes?.[0]?.plan_id}`}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.box1}
              pb={2}
            >
              <Box display="flex">
                {/* <img src="/images/fundimg.svg" height="30px" width="30px"/> */}
                <Typography className={classes.title}>
                  {" "}
                  {schemeData?.schemes?.[0]?.name}
                </Typography>
              </Box>

              <ChevronRightIcon />
            </Box>
          </Link>
          <Box pt={2}>
            <Grid container>
              <Grid item xs={6} container>
                <Grid item xs={12} md={3} className={classes.textGrey}>
                  Current NAV
                </Grid>
                <Grid item xs={4} className={classes.text}>
                  {schemeData?.schemes?.[0]?.nav?.value}
                </Grid>
              </Grid>
              <Grid item xs={6} container className={classes.grid}>
                <Grid item xs={12} md={8} className={classes.girdEndTextgrey}>
                  Folio No.
                </Grid>
                <Grid item xs={4} className={classes.girdEndText}>
                  {schemeData?.folio_number}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box pt={2}>
            <Grid container>
              <Grid item xs={6} container>
                <Grid item xs={12} md={3} className={classes.textGrey}>
                  Pur NAV
                </Grid>
                <Grid item xs={4} className={classes.text}>
                  {transData?.avgNav}
                </Grid>
              </Grid>
              <Grid item xs={6} container className={classes.grid}>
                <Grid item xs={12} md={8} className={classes.girdEndTextgrey}>
                  Balanced Units
                </Grid>
                <Grid item xs={4} className={classes.girdEndText}>
                  {schemeData?.schemes?.[0]?.holdings?.units}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {/* <Box display="flex" justifyContent={{md:"space-between"}} pt={2}>
              <Grid container>
              <Grid item xs={4} md={4}>
                      <p className={classes.textGrey}>Current NAV</p>
                      <p className={classes.textGrey}>Pur NAV</p>
                  </Grid>
                  <Grid item xs={6}>
                  <p className={classes.text}>{schemeData?.nav}</p>
                      <p className={classes.text}>180.9</p>
                  </Grid>

              </Grid>
              <Grid container className={classes.grid}>
              <Grid item xs={6}>
                      <p className={classes.girdEndTextgrey}>Folio No.</p>
                      <p className={classes.girdEndTextgrey}>Balanced Units</p>
                  </Grid>
                  <Grid item xs={4}>
                  <p className={classes.girdEndText}>{schemeData?.folioNumber}</p>
                      <p className={classes.girdEndText}>{schemeData?.holdingUnits}</p>
                  </Grid>

              </Grid>
            </Box> */}
        </Box>
        <Box p={2} mt={3}>
          <Grid container>
            <Grid item xs={4} md={6} className={classes.greyTitle}>
              Invested
            </Grid>
            <Grid item xs={4} md={3} className={classes.greyTitle}>
              Current
            </Grid>
            <Grid item xs={4} md={3}>
              <Box
                display="flex"
                justifyContent="end"
                className={classes.greyTitle}
              >
                CAGR
              </Box>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "1rem" }}>
            <Grid item xs={4} md={6} className={classes.subTitle}>
              <span className={classes.rupees}>₹</span>
              {LocaleString(schemeData?.schemes?.[0]?.invested_value.amount)}
            </Grid>
            <Grid item xs={4} md={3} className={classes.subTitle}>
              <span className={classes.rupees}>₹</span>
              {LocaleString(schemeData?.schemes?.[0]?.market_value.amount)}
            </Grid>
            <Grid item xs={4} md={3}>
              <Box
                display="flex"
                justifyContent="end"
                className={classes.textBlue}
              >
                {transData?.cagr}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <TransHistory transData={transData} />
      </Box>
    </div>
  );
};

export default Index;
