import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {  Select, MenuItem, InputLabel, FormControl, ListItemText, Checkbox as MUICheckbox } from '@mui/material';

import { Checkbox, FormGroup, FormControlLabel, Typography } from '@material-ui/core';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { asset_Sub_Category } from '../../../components/Data/assetSubCatogory'
import { GET_ALL_AMC_CATEGORY_LIST } from '../../../utils/endpoints';
import httpInstance from "../../../utils/instance";

const riskType = [
  // { value: "Aggressive", name: "Aggressive" },
  // { value: "Moderately Aggressive", name: "Moderately Aggressive" },
  // { value: "Conservative", name: "Conservative" },
  // { value: "Moderately Conservative", name: "Moderately Conservative" },
  // { value: "Balanced", name: "Balanced" },
  { value: "high_returns", name: "High Returns" },
  { value: "top_rated", name: "Top Rated" },
  { value: "tax_saver", name: "Tax Saver" },
  { value: "sip_100", name: "Sip 100" },
  { value: "index_fund", name: "Index Fund" },
  { value: "new_fund", name: "New Fund" },
  { value: "sip_500", name: "Sip 500" },
  { value: "beat_fd", name: "Beat FD Funds" },
  { value: "gold_funds", name: "Gold Funds" },
  { value: "global_funds", name: "Global Funds" },
  { value: "sectoral_funds", name: "Sectoral Funds" },
  { value: "liquid_funds", name: "Liquid Funds" },
  { value: "govt_funds", name: "Govt Funds" },
]


const pickReturns = [
  { value: "year10", name: "10 years" },
  { value: "year7", name: "7 years" },
  { value: "year5", name: "5 years" },
  { value: "year3", name: "3 years" },
  { value: "year2", name: "2 years" },
  { value: "year1", name: "1 year" },
  { value: "month6", name: "6 months" },
  { value: "month3", name: "3 months" },
  { value: "month1", name: "1 month" },
]


const allAmcList = [
  { amcName: "Trending", amcCode: "top_rated", categoryId: 0 },
  { amcName: "Fucused Fund", amcCode: "sectoral_fund", categoryId: 107 },
  { amcName: "Save Tax", amcCode: "tax_saver", categoryId: null },
  { amcName: "Multi Cap", amcCode: "null", categoryId: 145 },
  { amcName: "Balanced Fund", amcCode: "null", categoryId: 137 },
  { amcName: "Liquid Fund", amcCode: "liquid_funds", categoryId: 125 },
  { amcName: "New Fund Offering", amcCode: "new_fund", categoryId: null },

]


const useStyles = makeStyles((theme) => ({
  root: {
    "& p": {
      margin: "4px"
    },
    width: '100%',
    "& .MuiAccordion-root:before": {
      height: "0px",
      top: -4
    },
    "& .MuiAccordion-rounded:first-child ": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px"
    },
    "& .MuiAccordion-rounded:last-child ": {
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px"
    },
    "& .MuiFormControlLabel-root": {
      padding: "5px 0px"
    }
  },
  heading: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  filterStyle: {
    justifyContent: "flex-start",
    alignItems: "top"
  },

  checkBox: {
    fontSize: "1rem",
    "& .Muip-body1": {
      fontSize: "0.85rem"
    },
    "& .MuiCheckbox-colorPrimary.Mui-checked": {
      color: "#0E76BC",
    },
  },
  accordion: {
    borderRadius: "10px",
    // position: 'absolute',
    // zIndex: 1000,
  },
  accordionExpanded: {
    position: 'absolute',
    zIndex: 10000,
    width: '100%',
    marginTop: '10px',
    // backgroundColor: theme.palette.background.paper,
  },

  reset: {
    fontSize: "0.9rem",
    color: "#0E76BC",
    textAlign: "end",
    cursor: "pointer"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const RatingImage = ({ rating }) => {
  return (
    [...Array(rating)].map((img, i) => {
      return <img key={i} src="/images/starRating.svg" />
    })
  )
}

export default function SimpleAccordion({ allAmc, amc_filter, risk_filter, subAsset_filter, rating_filter, reset_filter, type }) {
  const classes = useStyles();

  const [checkedAmc, setCheckedAmc] = useState([])
  const [checkedRisk, setCheckedRisk] = useState([])
  const [checkedAsset, setCheckedAsset] = useState([])
  const [checkRate, setCheckRate] = useState([])
  const [amcCategoryList, setAmcCategoryList] = useState([])


  //getting all the amc category list in the place 

  const get_all_amc_category = async () => {
    const httpConfig = {
      method: "GET",
      // tokenType: "user",
      test: 'test',
      // headers: {
      //   "Content-Type": "application/json",
      // },
    };

    try {

      const response = await httpInstance(`${GET_ALL_AMC_CATEGORY_LIST}`, httpConfig);

      console.log(response, 'getSpecificAMC')
      setAmcCategoryList(response?.data?.data)
    } catch { }

  };





  useEffect(() => {
    get_all_amc_category();
  }, []);







  const handle_amc = (e) => {
    const { value, checked } = e.target
    let amcArry
    // if(checked){
    //   amcArry=[...checkedAmc,value]
    // }else{
    //   amcArry=checkedAmc.filter((e)=>e!=value)
    // }
    if (checked) {
      amcArry = [value]
    } else {
      amcArry = checkedAmc.filter((e) => e != value)
    }
    setCheckedAmc(amcArry)
    console.log(amcArry[0], "AMC")
    amc_filter(amcArry.toString())
  }

  //---------------------------risk profile-----------------------------------------------//
  const handle_riskProfile = (e) => {
    const { value, checked } = e.target
    console.log(value)
    let riskArry
    // if(checked){
    //   riskArry=[...checkedRisk,value]
    // }else{
    //   riskArry=checkedRisk.filter((e)=>e!=value)
    // }
    // console.log(riskArry)
    // setCheckedRisk(riskArry)
    if (checked) {
      riskArry = [value]
    } else {
      riskArry = checkedAsset.filter((e) => e != value)
    }
    setCheckedRisk(riskArry);
    risk_filter(riskArry.toString())
  }

  const handle_sub_asset = (e) => {
    const { value, checked } = e.target
    // console.log(value, 'Sub Asset') 
    let assetkArry
    // if(checked){
    //   assetkArry=[...checkedAsset,value]
    // }else{
    //   assetkArry=checkedAsset.filter((e)=>e!=value)
    // }
    // setCheckedAsset(assetkArry)
    // setCheckedAsset([assetkArry])
    if (checked) {
      assetkArry = [value]
    } else {
      assetkArry = checkedAsset.filter((e) => e != value)
    }
    // console.log(assetkArry)
    setCheckedAsset(assetkArry)
    subAsset_filter(assetkArry?.toString())
  }

  const handle_rating = (e) => {
    const { value, checked } = e.target
    let ratingArry
    // if(checked){
    //   ratingArry=[...checkRate,value]
    // }else{
    //   ratingArry=checkRate?.filter((e)=>e!=value)

    // }
    // setCheckRate(ratingArry)
    if (checked) {
      ratingArry = [value]
    } else {
      ratingArry = checkedAsset.filter((e) => e != value)
    }
    setCheckRate(ratingArry)
    rating_filter(ratingArry)

  }



  //------------------handle reset--------------------------------------------//
  const handle_reset = () => {
    setCheckedAmc([])
    setCheckedRisk([])
    setCheckedAsset([])
    setCheckRate([])
    reset_filter()
  }


  const renderMultiSelect = (label, options, checkedValues, handleChange, disabled = false) => (
    <FormControl className={classes.formControl}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={checkedValues}
        onChange={handleChange}
        renderValue={(selected) => selected.join(', ')}
        disabled={disabled}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            <MUICheckbox checked={checkedValues.includes(option.value)} />
            <ListItemText primary={option.name || option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
    <div className={classes.filterStyle}>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleAccordionChange('panel1')}
        className={`${classes.accordion} ${expanded === 'panel1' ? classes.accordionExpanded : ''}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.heading}
        >
          Pick Returns
        </AccordionSummary>
        <SimpleBar style={{ maxHeight: 300 }}>
          <AccordionDetails className={classes.checkBox}>
            <FormGroup>
              {pickReturns?.map((returns, i) => (
                <FormControlLabel
                  key={i}
                  control={<Checkbox color="primary" value={returns.value} checked={checkedAmc.includes(returns.value)} onChange={handle_amc} />}
                  label={returns.name}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </SimpleBar>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel2'}
        onChange={handleAccordionChange('panel2')}
        className={`${classes.accordion} ${expanded === 'panel2' ? classes.accordionExpanded : ''}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.heading}
        >
          Pick Rating
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {[...Array(5)].map((_, i) => {
              let ratingCount = 5 - i;
              return (
                <FormControlLabel
                  key={i}
                  control={<Checkbox color="primary" value={5 - i} checked={checkRate?.includes((5 - i).toString())} onChange={handle_rating} />}
                  label={<RatingImage rating={ratingCount} />}
                />
              );
            })}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel3'}
        onChange={handleAccordionChange('panel3')}
        className={`${classes.accordion} ${expanded === 'panel3' ? classes.accordionExpanded : ''}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          className={classes.heading}
        >
          Pick Risk
        </AccordionSummary>
        <SimpleBar style={{ maxHeight: 300 }}>
          <AccordionDetails className={classes.checkBox}>
            <FormGroup>
              {riskType?.map((risk, i) => (
                <FormControlLabel
                  key={i}
                  control={<Checkbox color="primary" value={risk.value} checked={checkedRisk.includes(risk.value)} onChange={handle_riskProfile} />}
                  label={risk.name}
                  disabled={type === "All Fund" ? false : true}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </SimpleBar>
      </Accordion>

      <Accordion
        expanded={expanded === 'panel4'}
        onChange={handleAccordionChange('panel4')}
        className={`${classes.accordion} ${expanded === 'panel4' ? classes.accordionExpanded : ''}`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
          className={classes.heading}
        >
          Pick Asset Sub Categories
        </AccordionSummary>
        <SimpleBar style={{ maxHeight: 200 }}>
          <AccordionDetails className={classes.checkBox}>
            <FormGroup>
              {amcCategoryList?.map((ele, i) => (
                <FormControlLabel
                  key={i}
                  control={<Checkbox color="primary" value={ele.id} checked={JSON.stringify(checkedAsset).includes(ele.id)} onChange={handle_sub_asset} />}
                  label={ele.categoryName}
                  disabled={type === "All Fund" ? false : true}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </SimpleBar>
      </Accordion>

      <Typography className={classes.reset} onClick={handle_reset}>Reset</Typography>
    </div>
  </div>
    // <div className={classes.root}>
    //   {/* <Typography className={classes.reset} onClick={handle_reset}>Reset</Typography> */}
    //   <div className={classes.filterStyle} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    //     <Accordion variant='outlined' className={classes.accordion}>
    //       <AccordionSummary
    //         expandIcon={<ExpandMoreIcon />}
    //         aria-controls="panel1a-content"
    //         id="panel1a-header"
    //         className={classes.heading}
    //       >
    //         Pick Returns
    //       </AccordionSummary>
    //       <SimpleBar style={{ maxHeight: 300 }}>
    //         <AccordionDetails className={classes.checkBox}>

    //           <FormGroup>
    //             {/* {allAmcList?.map((amc,i)=>{
    //           return(
    //             <FormControlLabel 
    //             key={i}
    //             control={<Checkbox color="primary" value={amc.amcCode} checked={checkedAmc.includes(amc.amcCode)} onChange={handle_amc}/>}  
    //             label={amc.amcName} />
    //           )
    //         })} */}
    //             {pickReturns?.map((returns, i) => {
    //               return (
    //                 <FormControlLabel
    //                   key={i}
    //                   control={<Checkbox color="primary" value={returns.value} checked={checkedAmc.includes(returns.value)} onChange={handle_amc} />}
    //                   label={returns.name} />
    //               )
    //             })}
    //           </FormGroup>


    //         </AccordionDetails>
    //       </SimpleBar>
    //     </Accordion>
    //     <Accordion variant='outlined' className={classes.accordion}>
    //       <AccordionSummary
    //         expandIcon={<ExpandMoreIcon />}
    //         aria-controls="panel2a-content"
    //         id="panel2a-header"
    //         className={classes.heading}
    //       >
    //         Pick Rating
    //       </AccordionSummary>
    //       <AccordionDetails>
    //         <FormGroup>
    //           {[...Array(5)].map((rating, i) => {
    //             let ratingCount = 5 - i
    //             return (
    //               <FormControlLabel
    //                 key={i}
    //                 control={<Checkbox color="primary" value={5 - i} checked={checkRate?.includes((5 - i).toString())} onChange={handle_rating} />}
    //                 label={<RatingImage rating={ratingCount} />} />
    //             )
    //           })}

    //         </FormGroup>
    //       </AccordionDetails>
    //     </Accordion>
    //     <Accordion variant='outlined' className={classes.accordion}>
    //       <AccordionSummary
    //         expandIcon={<ExpandMoreIcon />}
    //         aria-controls="panel3a-content"
    //         id="panel3a-header"
    //         className={classes.heading}

    //       >
    //         Pick Risk
    //       </AccordionSummary>
    //       <SimpleBar style={{ maxHeight: 300 }}>
    //         <AccordionDetails className={classes.checkBox}>
    //           <FormGroup>
    //             {riskType?.map((risk, i) => {
    //               return (
    //                 <FormControlLabel
    //                   key={i}
    //                   control={<Checkbox color="primary" value={risk.value} checked={checkedRisk.includes(risk.value)} onChange={handle_riskProfile} />}
    //                   label={risk.name}
    //                   disabled={type === "All Fund" ? false : true} />
    //               )
    //             })}

    //           </FormGroup>
    //         </AccordionDetails>
    //       </SimpleBar>
    //     </Accordion>
    //     <Accordion variant='outlined' className={classes.accordion}>
    //       <AccordionSummary
    //         expandIcon={<ExpandMoreIcon />}
    //         aria-controls="panel3a-content"
    //         id="panel3a-header"
    //         className={classes.heading}
    //       >
    //         Pick Asset Sub Categories
    //       </AccordionSummary>
    //       <SimpleBar style={{ maxHeight: 200 }}>
    //         <AccordionDetails className={classes.checkBox}>
    //           <FormGroup>
    //             {amcCategoryList?.map((ele, i) => {
    //               return (
    //                 <FormControlLabel
    //                   key={i}
    //                   control={<Checkbox color="primary" value={ele.id} checked={JSON.stringify(checkedAsset).includes(ele.id)} onChange={handle_sub_asset} />}
    //                   label={ele.categoryName}
    //                   disabled={type === "All Fund" ? false : true} />
    //               )
    //             })}

    //           </FormGroup>


    //         </AccordionDetails>
    //       </SimpleBar>
    //     </Accordion>
    //     <Typography className={classes.reset} onClick={handle_reset}>Reset</Typography>
    //   </div>
    // </div>
  //   <div className={classes.root}>
  //   <div className={classes.filterStyle}>
  //     {renderMultiSelect("Pick Returns", pickReturns, checkedAmc, handle_amc)}
  //     {renderMultiSelect("Pick Rating", [...Array(5)].map((_, i) => ({
  //       value: (5 - i).toString(),
  //       label: <RatingImage rating={5 - i} />
  //     })), checkRate, handle_rating)}
  //     {renderMultiSelect("Pick Risk", riskType, checkedRisk, handle_riskProfile, type !== "All Fund")}
  //     {renderMultiSelect("Pick Asset Sub Categories", amcCategoryList, checkedAsset, handle_sub_asset, type !== "All Fund")}
  //     <Typography className={classes.reset} onClick={handle_reset}>Reset</Typography>
  //   </div>
  // </div>
  );
}
