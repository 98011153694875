import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const Cancellation_Policys = () => {
  const useStyles = makeStyles({
    header: {
      fontSize: "1.8rem",
      fontWeight: 600,
      color: "#272727",
    },
    subTitle: {
      fontSize: "1.3rem",
      fontWeight: 500,
    },
    text: {
      fontSize: "0.9rem",
    },
  });
  const classes = useStyles();
  return (
    <div>
      <Box px={7} p={2} mt={4}>
        <Typography className={classes.header}>Cancellation Policy / Refund Policy</Typography>

        <Box mt={4}>
          <Typography className={classes.subTitle}>Cancellation Policy:</Typography>
        </Box>
        <Box mt={4}>
          {/* <h6 className={classes.text} style={{ marginBottom: '0px' }}>
            BETWEEN
          </h6> */}
          <p className={classes.text}>
            Transaction once completed cannot be canceled since units will be allotted by the respective asset management company and you still wish to withdraw the money, units will have to be redeemed from the Mutual Funds.
          </p>
          <p className={classes.text}> For payments via transfer no cancellation will be allowed.

          </p>
        </Box >
        <Box mt={4}>
          <Typography className={classes.subTitle}>Refund Policy :</Typography>
        </Box>
        <Box mt={4} mb={4}>
          {/* <h6 className={classes.text} style={{ marginBottom: '0px' }}>
            BETWEEN
          </h6> */}
          <p className={classes.text}>
          In case of successful debit of money but failure of transaction or rejection of investment transactions, money will be refunded within 6- 7 working days to your registered bank account which was used for the respective transactions.          
          </p>
        </Box >
       
      </Box>
    </div>
  );
};

export default Cancellation_Policys;
