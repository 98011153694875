import React from 'react'
// import Header from '../../components/header/Header'
import Header from "../../components/Header";
import Footer from '../../components/Footer'
import Privacy_Policys from '../../containers/About/Privacy_Policys'
import { isMobile } from "react-device-detect";
import FooterStatic from '../../components/FooterStatic';

const Privacy_Policy = () => {
  return (
    <div>
        {!isMobile && <Header/>}
        <Privacy_Policys/>
        { !isMobile &&  <FooterStatic/>}
    </div>
  )
}

export default Privacy_Policy