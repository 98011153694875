import React, { useEffect, useState } from "react";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from '@mui/icons-material/Close';
import { GET_MY_INVESTMENT, GET_PENDING_INVESTMENT_RESUME_INVESTMENT, POST_PENDING_INVESTMENT_DEACTIVATE_BASKET_ITEMS } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import moment from "moment";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../../environment";
import ReactLoading from "react-loading";
import ConfirmDialog from "./ConfirmDialog";

const ContuinueInvestmentJourney = () => {
    const useStyles = makeStyles({
        root: {
            border: "1px solid #DDDDDD",
            borderRadius: "5px",
        },

        greyText: {
            color: "#C0C0C0",
        },

        title: {
            fontSize: "1.1rem",
            fontWeight: 500,
        },
        titleNoData: {
            fontSize: "1.0rem",
            fontWeight: 500,
            textAlign: 'center'
        },
        buttonBox: {
            background: "#EAEAEA",
            borderRadius: "8px",
            padding: "2px 5px",
        },
        primaryButton: {
            padding: "0.7rem 3rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.9rem",
            fontFamily: "Poppins",
            fontWeight: 600,
            borderRadius: "5px",
            border: "none",
            outline: "none",
        },
        button: {
            background: "#EAEAEA",
            padding: "0.7rem 3rem",
            fontSize: "0.9rem",
            fontFamily: "Poppins",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
            outline: "none",
        },
        rupees: {
            fontFamily: "Open Sans",
        },
        type: {
            fontSize: "0.7rem",
            borderRadius: "30px",
            //background:"#FFD7C4",
            //color:"#F27E45",
            width: "fit-content",
            padding: "3px 20px",
            margin: "0px 15px",
            height: "fit-content",
        },

        "@media (max-width:600px)": {
            primaryButton: {
                padding: "0.7rem 1rem",
            },
            button: {
                padding: "0.7rem 1rem",
            },
            type: {
                margin: "10px 0px",
            },
        },
    });
    const classes = useStyles();

    const [contuinueInvestmentJourney, setContuinueInvestmentJourney] = useState([]);
    const [inactiveInvest, setInactiveInvest] = useState([]);
    const [value, setValue] = useState("active");
    const [loading, setLoading] = useState(false)

    const orderTypeObj = {
        2: "SIP",
        5: "STP",
        6: "SWP",
    };
    const orderTypeColor = {
        2: "#F27E45",
        5: "#4579F5",
        6: "#CB5D38",
    };

    const get_investment = async () => {
        const userId = localStorage.getItem("user_id");
        setLoading(true)
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            //   params: { userId: userId, isActive: true },
        };

        const httpConfig1 = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            //   params: { userId: userId, isActive: false },
        };

        try {
            const response = await httpInstance(`${GET_PENDING_INVESTMENT_RESUME_INVESTMENT}`, httpConfig)
            // console.log(response)

            setContuinueInvestmentJourney(response.data?.data);
            //   console.log(response?.data?.data[0]?.transaction_basket_items[0])

            // const inactive= await httpInstance(`${GET_INACTIVE_SIP}`,httpConfig)
            // setInactiveSip(inactive.data?.data)
            setLoading(false)

        } catch {
            setLoading(false)

        }
    };

    useEffect(() => {
        get_investment();
    }, []);

    const [showModal, setShowModal] = useState(false)
    const [removeResumeDataFromThreeDaysList, setRemoveResumeDataFromThreeDaysList] = useState()
    //handle close of the waiting continue investment 
    const handleCloseFile =(data)=>{
        setShowModal(true)
        setRemoveResumeDataFromThreeDaysList(data)
    }

    const handleRemoveContinueInvestementPart = async() => {
        // setLoading(true)
        const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
              params: { transaction_basket_item_id: removeResumeDataFromThreeDaysList.id },
        };

        try {
            const response = await httpInstance(`${POST_PENDING_INVESTMENT_DEACTIVATE_BASKET_ITEMS}`, httpConfig)
            console.log(response)
            get_investment()
            setRemoveResumeDataFromThreeDaysList()
            setShowModal(false)
           
            // setLoading(false)

        } catch {
            // setLoading(false)

        }
    }



    const handle_change = () => {
        // handle_primary(id)
        handleRemoveContinueInvestementPart()
      }

    return (
        <div>
            {!loading ? (
                <>
                    <Box mt={5}>
                        <Typography className={classes.title}>
                            Continue Investment
                        </Typography>
                    </Box>
                    {/* {(contuinueInvestmentJourney?.length > 0 || inactiveInvest?.length > 0) && (
        <>
          <Box mt={5}>
            <Typography className={classes.title}>
              My Systematic Investments
            </Typography>
          </Box>

          <Box display="flex" justifyContent="center" my={3}>
            <Box className={classes.buttonBox}>
              <button
                className={
                  value === "active" ? classes.primaryButton : classes.button
                }
                onClick={() => setValue("active")}
              >
                Active
              </button>
              <button
                className={
                  value === "inactive" ? classes.primaryButton : classes.button
                }
                onClick={() => setValue("inactive")}
              >
                Inactive
              </button>
            </Box>
          </Box>
        </>
      )} */}
                    {contuinueInvestmentJourney.length !== 0 ? (
                        <>
                            {(value == "active" ? contuinueInvestmentJourney : inactiveInvest)?.map((ele, i) => {
                                return (
                                    <>
                                        <Box key={i} className={classes.root} mt={3} p={2}>
                                            <Grid container display="flex" alignItems="center">
                                                <Grid item xs={3} md={1}>
                                                    <img src={`${BASE_URL}${ele?.transaction_basket_items[0]?.amc_logo_url}`} height="auto" width="50px" />
                                                </Grid>
                                                <Grid item xs={9} md={6}>
                                                    <Box display={{ sm: "flex" }} alignItems="center">
                                                        <Box display="flex" alignItems="center" flexDirection={'column'}>
                                                            <Typography>{ele?.transaction_basket_items[0]?.fund_detail?.name} </Typography>
                                                            <Typography>{ele?.transaction_basket_items[0]?.fund_detail?.plan_type} </Typography>
                                                        </Box>
                                                        <Box
                                                            className={classes.type}
                                                            style={{
                                                                color: ele?.transaction_basket_items[0]?.transaction_type == 'lumpsum' ? '#fff' : '#fff',
                                                                background: ele?.transaction_basket_items[0]?.transaction_type == 'lumpsum' ? '#F27E45' : '#4579F5',
                                                            }}
                                                        >
                                                            {ele?.transaction_basket_items[0]?.transaction_type.replace(/_/g, " ")}
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6} md={3}>
                                                    <Box display="flex" justifyContent="start" pl={2}>
                                                        {/* {value === "active" && ( */}
                                                        <Typography className={classes.greyText}>
                                                            {`Initiated ${ele?.transaction_basket_items[0]?.transaction_type.replace(/_/g, " ")} paused on`}{" "}
                                                            {moment(ele?.created_at).format("MMM DD YYYY")}
                                                        </Typography>
                                                        {/* )} */}
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6} md={2}>
                                                    <Box display="flex" justifyContent="space-around">
                                                        <Typography>
                                                            <span className={classes.rupees}>₹</span>{" "}
                                                            {ele?.transaction_basket_items[0]?.amount?.toLocaleString("en-IN")}
                                                        </Typography>
                                                        <Link
                                                            to={`/mutualfund/scheme-code/${ele?.transaction_basket_items[0]?.fund_plan_id}`}
                                                            state={{ fundData: ele, status: value }}
                                                            style={{ color: "inherit" }}
                                                        >
                                                            <ChevronRightIcon style={{ cursor: "pointer" }} />
                                                            
                                                        </Link>
                                                        <CloseIcon style={{cursor: "pointer",}}  onClick={()=>handleCloseFile(ele)} />
                                                         
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                );
                            })}
                        </>
                    ) : (
                        <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                            <Typography className={classes.titleNoData} display="flex" justifyContent="center" alignItems="center" >
                                No Investments
                            </Typography>
                            <img src="/images/finance-animate.svg" width={"15%"} height={'auto'} />
                        </Box>
                    )}
                </>
            ) : (
                <Box display="flex" justifyContent="center" pt={3}>
                    <ReactLoading
                        type="spinningBubbles"
                        color="#0E76BC"
                        height={"4%"}
                        width={"4%"}
                    />
                </Box>
            )
            }
                    <ConfirmDialog show={showModal} handleClose={() => setShowModal(false)} changePrimary={handle_change} />

        </div >
    );
};

export default ContuinueInvestmentJourney;
