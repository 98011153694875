import React, { useState, useEffect } from 'react'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CircularProgress
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, Controller } from "react-hook-form";
import ErrorMessage from '../../../components/Validation/ErrorMessage';
import {
  GET_DETAILS_BY_IFSC,
  GET_ACCOUNT_TYPES,
  // ADD_PROFILE_BANK,
  ADD_ADDITIONAL_BANK,
  UPDATE_INVESTOR_ACCOUNT,
  GET_BANK_DETAILS,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import AccountVerify from './AccountVerify';
import { useSelector } from 'react-redux'

const AddBankForm = ({ dialog, closeDialog, get_all_bank }) => {
  const useStyles = makeStyles((theme) => ({
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
    },

    title: {
      fontSize: "1.1rem",
      fontWeight: 500,
      textAlign: "center",
      fontFamily: "inherit"
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "center",
      alignItems: " center",
      textAlign: "center",
      padding: "5px",
    },
    dialogPaper: {
      maxWidth: "23rem",
      justifyContent: "center",
    },
    image: {
      textAlign: "center",
    },
    text: {
      fontSize: "0.8rem",
      textAlign: "center",
      fontFamily: "inherit",
      marginTop: "10px"
    },
    primaryButton: {
      padding: "0.8rem 5rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",

    },
    dialogContent: {
      padding: "8px 30px",
    },

    inputBox: {
      background: "#E6E6E6",
      padding: "0.6rem 0.6rem",
      borderRadius: "10px",
      border: "none",
      fontFamily: "inherit",
      width: "90%",
      marginBottom: "10px"
    },

    textarea: {
      background: "#E6E6E6",
      padding: "0.6rem 0.6rem",
      borderRadius: "10px",
      border: "none",
      fontFamily: "inherit",
      width: "90%",
      resize: "none",
      height: "4rem"

    },
    rootSelect: {
      "& .MuiFormControl-fullWidth": {
        width: "96%"
      }
    },
    select: {

      borderRadius: "10px",
      background: "#E6E6E6",
      marginTop: "2px",
      marginBottom: "10px",
      "& .MuiSelect-select": {
        // paddingRight:"40px",
        // minWidth:"14rem",
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1.5rem"
      },
      "& .MuiSelect-icon": {
        top: 4,

      },
      "& .MuiInputBase-input": {
        padding: "10px 15px"
      },
      "& .MuiSelect-selectMenu": {
        fontSize: "0.83rem",
        fontWeight: 400,
        color: "black"
      },
      "& .MuiMenuItem-root": {
        fontSize: "0.83rem"
      },
      line: {
        border: "1px solid red"
      }
    },

    menuItem: {
      "& .MuiMenuItem-root": {
        fontSize: "0.9rem"
      }
    }
  }));

  const classes = useStyles();




  const [accountTypes, setAccountType] = useState([]);
  const [verify, setVerify] = useState(false)
  const [ifscDetail, setIfscDetail] = useState()
  const [IfscError, setIfscError] = useState(false)
  const [typeAccount, setTypeAcount] = useState()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { userStore } = useSelector((state) => state)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm({});


  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };


    try {
      const response = await httpInstance(`${GET_ACCOUNT_TYPES}`, httpConfig);
      setAccountType(response.data.data);
      //   const bankData=await httpInstance(`${GET_BANK_DETAILS}`,httpConfig1)
      //    resetData(bankData.data?.data[0])

    } catch { }
  }, []);

  const get_bank_data = async (ifsc) => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ifsc: ifsc },
    };

    try {
      const response = await httpInstance(
        `${GET_DETAILS_BY_IFSC}`,
        httpConfig
      );
      setIfscDetail(response.data?.data);
      reset({
        bankName: response.data?.data?.bank_name,
        // branchName: response.data?.data?.branch_name,
        // accountType:2
      });

      setIfscError(false);
    } catch (error) {
      setIfscError(true);
    }
  }



  const handleIfsc = (e) => {
    if (e.target.value.length === 11) {
      get_bank_data(e.target.value.toUpperCase())
    }
  }

  const handle_submit = async (data) => {
    setLoading(true)
    const formData = {
      user_id: userStore.user.id,
      account_holder_name: data.accountHolderName,
      account_number: data.accountNumber,
      bank_name: data.bankName,
      // branchName:data.branchName,
      ifsc_code: data.ifscode.toUpperCase(),
      // accountType:data.accountType
    }
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData
    };
    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },

    };
    try {
      const response = await httpInstance(`${ADD_ADDITIONAL_BANK}`, httpConfig)
      setError('')
      // console.log(response)
      reset({
        accountHolderName: '',
        accountNumber: '',
        bankName: '',
        ifscode: '',
      })
      setLoading(false)
      // await httpInstance(`${UPDATE_INVESTOR_ACCOUNT}`,httpConfig1)
      get_all_bank()
      closeDialog()

    }
    catch (error) {
      console.log(error.response.data)
      setError(error.response.data?.error)
      setLoading(false)
    }

  }
  //----------------------account typr set---------------------------------------------------//
  const handleAccountType = (e) => {
    setTypeAcount(e.target.value)
  }

  return (
    <div>
      <Dialog
        open={dialog}
        // onClose={closeDialog}
        classes={{ paper: classes.dialogPaper }}
      >
        <Box display="flex" className={classes.closeIcon}>
          {" "}
          <CloseIcon onClick={() => {
            closeDialog();
              reset({
                accountHolderName: '',
                accountNumber: '',
                bankName: '',
                ifscode: '',
              })
          }}
            style={{ cursor: "pointer" }} />
        </Box>

        <DialogTitle
          classes={{ paper: classes.dialogPaper, root: classes.dialogTitle }}
        >
          <Typography className={classes.title}>{verify ? "Bank Account Verification" : "Add New Bank"}</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>

          <form onSubmit={handleSubmit(handle_submit)}>
            <label className={classes.text}>IFSC Number</label>
            <input
              type="text"
              style={{ textTransform: 'uppercase' }}
              className={classes.inputBox}
              {...register("ifscode", {
                required: true,
                maxLength: 18,
                minLength: 9,
                onChange: (e) => handleIfsc(e)
              })}
            />
            {errors.ifscode ? <ErrorMessage message="Enter IFSC Code" /> : IfscError && <ErrorMessage message="Invalid IFSC Code" />}
            <label className={classes.text}>Account Holder Name</label>
            <input
              className={classes.inputBox}
              {...register("accountHolderName", {
                required: true,
                pattern: /^(?=(?:^\w))([A-Za-z. ]+)$/

              })}
            />
            {errors.accountHolderName && errors.accountHolderName.type == "required" && <ErrorMessage message="Enter Account Holder Name" />}
            {errors.accountHolderName && errors.accountHolderName.type == "pattern" && <ErrorMessage message="Enter Valid Name" />}

            <label className={classes.text}>Account Number</label>
            <input
              type="text"
              className={classes.inputBox}
              {...register("accountNumber", {
                required: true,
                pattern: /^(?=(?:^\w))([0-9]+)$/,
                maxLength: 18,
                minLength: 9
              })}
            />
            {errors.accountNumber && <ErrorMessage message="Enter Account Number" />}



            {/*<label className={classes.text}>Account Type</label>
               <Box width={"100%"} className={classes.rootSelect}>
                <FormControl fullWidth>
                <Controller
                      control={control}
                      name={`accountType`}
                      rules={{ required: true }}
                      render={({ field }) => (
                  <Select
                  {...field}
                  className={classes.select}
                  defaultValue={typeAccount}
                    disableUnderline
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{
                      style: {
                        maxHeight: 250,
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    
                      {...register('accountType',{onChange:(e)=>handleAccountType(e)})} 
                  >
                    {accountTypes &&
                      accountTypes?.map((ele) => {
                        return (
                          <MenuItem
                            key={ele.id}
                            value={ele.id}
                            style={{fontSize:"0.83rem"}}
                          >
                            {ele.type}
                          </MenuItem>
                        );
                      })}
                  </Select>
                      )}/>
                </FormControl>
              </Box>
              {errors.accountType && <ErrorMessage message="Select Account Type"/>} */}

            <label className={classes.text}>Bank Name</label>
            <input
              type="text"
              className={classes.inputBox}
              disabled
              {...register("bankName")}
            />

            {/* <label className={classes.text}>Branch Name</label>
                <input
                  type="text"
                  className={classes.inputBox}
                  disabled
                  {...register("branchName")}
                />*/}
            {error && <ErrorMessage message={error} />}
            <Box display="flex" justifyContent="center" mt={2}>
              <button
                className={classes.primaryButton}
                type="submit"

              >
                {loading ? <CircularProgress size="1rem" color="inherit" /> : " Add"}



              </button>
            </Box>
          </form>



        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AddBankForm