import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useParams, useLocation } from "react-router-dom";
import { GET_FUND_DETAILS, GET_FUND_FP_DATA, GET_FUND_DETAILS_RETURN_CALCULTAE } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import FundGraph from "./FundGraph";
import RiskAndValidation from "./RiskAndValidation";
import FundPerformance from "./FundPerformance";
import PeerComparison from "./PeerComparison";
import FundHouseDetails from "./FundHouseDetails";
import moment from "moment";
import ReturnCal from "./ReturnCal";
import Holdings from "./Holdings";
import HoldingAnalysis from "./HoldingAnalysis";
import AlternativeFunds from "./AlternativeFunds";
import Mf_Invest from "./Mf_Invest";
import Kyc_status from "../Dashboard/Funds/KycStatusCheck/Kyc_status";
import { namespace, BASE_URL } from "../../environment";

const RatingImage = ({ rating }) => {
  if (rating > 5 || rating < 1) {
    rating = 0;
  }
  // else if (rating == "Not Rated"){
  //   rating = 0;
  // }
  if (rating === "Not Rated") {
    return (
      <div style={{ fontSize: '0.8rem' }}    >
        Not Rated
      </div>
    )
  }

  // Handle case where rating is 0 or -1
  if (rating === 0 || rating === -1) {
    // Render an empty star or another image of your choice
    return [...Array(5)].map((img, i) => {
      return (
        <img
          key={i}
          style={{ marginRight: "4px" }}
          src="/images/starRatingEmpty.svg"
          height="10px"
          width="10px"
        />
      );
    });
    // return (
    //   <img
    //     src="/images/starRatingEmpty.svg"  // Change this to the path of your empty star image
    //     height="10px"
    //     width="10px"
    //   />

    // );
  }

  return [...Array(rating)].map((img, i) => {
    return (
      <img
        key={i}
        style={{ marginRight: "4px" }}
        src="/images/starRating.svg"
        height="10px"
        width="10px"
      />
    );
  });
};

const FundDetail = () => {
  const useStyles = makeStyles((theme) => ({
    root: {},
    mainGrid: {
      padding: "30px 4rem 0px 8rem",
    },
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },
    text: {
      fontSize: "0.8rem",
      fontWeight: 700,
    },
    subText: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
      paddingLeft: '5px',
    },
    text1: {
      fontSize: "0.8rem",
    },

    textBold: {
      fontSize: "0.8rem",
      fontWeight: 700,
      marginBottom: "1rem",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    "@media (max-width: 767px)": {
      mainGrid: {
        padding: "0px 10px 0px 10px",
      },
    },
  }));
  const classes = useStyles();
  const location = useLocation();

  const [fundData, setFundData] = useState(null);
  const [fundDataPerformance, setFundDataPerformance] = useState(null);
  const [fpData, setFpData] = useState();
  const [showBanner, setShowBanner] = useState();
  const [loading, setLoading] = useState(false);
  const [holdingDataOfSectors, setHoldingDataOfSectors] = useState([])
  // const [folioNumber, setFolioNumber]= useState('')

  const { code } = useParams();
  const { state } = useLocation()
  // const {folioNumber}=state
  // console.log(state, 'state')
  // useEffect(() => {
  //   if (state?.folioNumber && fundData) {
  //     console.log('sdfasdfadsfasds')
  //     // setFolioNumber(state?.folioNumber)
  //     setFundData(prevData => ({
  //       ...prevData,
  //       folio_number: state?.folioNumber // Assuming folio_number comes from an input field
  //     }));
  //   }
  // }, [ ]);

  // useEffect(() => {
  //   localStorage.setItem("fund_id", code);
  //   if (location.state?.data) {
  //     setFundData(location.state?.data);
  //   }
  // }, [code]);

  const get_fund_data = async () => {
    const httpConfig = {
      method: "GET",
      // tokenType: "user",
      test: 'test'
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // data: {
      //   scheme_code: code,
      //   plan_name: "",
      //   option_name: "growth",
      //   holding_period: "1M",
      // },
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      // headers: {
      //   "Content-Type": "application/json",
      // },
    };
    try {
      const response = await httpInstance(`${GET_FUND_DETAILS}?fundPlanId=${code}`, httpConfig);
      const updatedFundData = { ...response.data?.data };

      // console.log(type);
      // console.log(selectionOfBank);

      // Make changes to the copied object
      updatedFundData['stateValue'] = state?.fundData ? state?.fundData : null;
      updatedFundData['folio_number'] = state?.folioNumber ? state?.folioNumber : null
      // console.log(updatedSelectionOfBank);

      // Now you can use the updatedSelectionOfBank as needed
      // setSelectedBank(updatedSelectionOfBank);
      // console.log(updatedFundData)
      setFundData(updatedFundData);

      // console.log(response.data.data);
      // const fpres = await httpInstance(`${GET_FUND_FP_DATA}`, {
      //   ...httpConfig1,
      //   params: { isin: response.data?.data?.isin },
      // });
      // setFpData(fpres.data?.data);
    } catch { }
  };

  const get_fund_data_return_calculate = async (sliderValue, typeValue) => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      // test: 'test'
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // data: {
      //   scheme_code: code,
      //   plan_name: "",
      //   option_name: "growth",
      //   holding_period: "1M",
      // },
    };

    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      // headers: {
      //   "Content-Type": "application/json",
      // },
    };
    try {
      const response = await httpInstance(`${GET_FUND_DETAILS_RETURN_CALCULTAE}?plan_id=${code}&amount=${sliderValue}&calculation_type=${typeValue}`, httpConfig);
      // setFundData((prevData) => ({ ...prevData, performance: response.data?.data }));
      setFundDataPerformance(response.data?.data)
      // setFundData( ...fundData, ...response.data?.data);
      // const fpres = await httpInstance(`${GET_FUND_FP_DATA}`, {
      //   ...httpConfig1,
      //   params: { isin: response.data?.data?.isin },
      // });
      // setFpData(fpres.data?.data);
    } catch { }
  };


  const get_fund_fp_data = async () => {
    // console.log(fundData)
    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { isin: fundData?.isinCode },
    };

    try {
      const fpres = await httpInstance(`${GET_FUND_FP_DATA}`, httpConfig1);
      // console.log(fpres?.data?.data.sip_frequency_data?.MONTHLY, 'fadfadfadsfasdfasdfasdf')

      setFpData(fpres.data?.data);
    } catch { }
  };

  useEffect(() => {
    // console.log(location.state, 'location.state');
    // if (!fpData && fundData) {
    get_fund_fp_data();
    // }
  }, [fundData]);


  useEffect(() => {
    // console.log("location.state", location.state)
    // if (!location.state) {
    if (!fundData) {
      get_fund_data();
    }
  }, []);

  useEffect(() => {
    // console.log("location.state", location.state)
    // if (!location.state) {
    get_fund_data_return_calculate(5000, 'sip');
    // }
  }, []);

  const show_banner = () => {
    setShowBanner(true);
  };

  const sliderAmountChangeIntheReturnCalculator = (value, type) => {
    // console.log(data, sdata)
    get_fund_data_return_calculate(value, type)
  }




  return (
    <div className={classes.root}>
      <Box
        display="flex"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3" }}
      >
        <Link
          to={`${namespace}/dashboard/mutualfund/diy`}
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            Find Mutual Funds
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography
            className={classes.subTabtext}
            display="flex"
            alignItems="start"
          >
            {/* {fundData?.scheme_name} */}
            {fundData?.schemeName}
          </Typography>
        </Box>
      </Box>
      {/* kyc banner */}
      {showBanner && <Kyc_status />}
      <Box
        display="flex"
        justifyContent="flex-start"
        px={{ xs: 2, md: 15 }}
        mt={5}
        className={classes.backButton}
      >
        <Link to={`${namespace}/dashboard/mutualfund/diy`}>
          <img src="/images/backIcon.svg" height="auto" width="20px" />
        </Link>
      </Box>

      <Grid container className={classes.mainGrid}>
        <Grid item xs={12} md={8}>
          <Grid container>
            <Grid item xs={2} md={1}>
              <img
                // src={fundData?.img_url} 
                // src={`${BASE_URL}${fundData?.amcLogoUrl}`}
                src={`${BASE_URL}${fundData?.amc?.logoUrl}`}

                height="50px" width="50px" />
            </Grid>
            <Grid item xs={9} >

              {/*  <Typography>{fundData?.scheme_name} </Typography> */}
              <Typography>{fundData?.schemeName} </Typography>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <Typography className={classes.text} >
                  {/*  {fundData?.asset_sub_category} */}
                  {/* {fundData?.primaryCategoryName} */}
                  {fundData?.category?.primaryCategoryName}
                </Typography>
                <Typography className={classes.subText}>&#x2022; {fundData?.colour?.risk} </Typography>
                <Typography className={classes.subText}>&#x2022; {fundData?.category?.categoryName.replace(`${fundData?.category?.primaryCategoryName}:`, "")} </Typography>
                {/* <RatingImage rating={fundData?.vr_rating.length} /> */}
                {/* {fundData.fundRating} */}
              </Box>
              <RatingImage rating={fundData?.fundRating} />


            </Grid>
            <Grid item xs={6} md={2}>
              <Typography style={{ fontSize: "1rem", color: "#6FDDB8" }}>
                {/* {fundData?.performance?.fiveY}% */}
                {/* {fundData?.ret5year ? fundData?.ret5year : 0}% */}
                {fundData?.ret5year ? fundData?.fundRet5year : 0}%
              </Typography>
              <Typography className={classes.text}>Returns( 5yrs )</Typography>
            </Grid>
          </Grid>
          {/* grapg */}
          <FundGraph code={code} />
          <Grid container>
            <Grid item xs={3} className={classes.textBold}>
              {/* Nav : {moment(fundData?.nav_date).format("DD MMM YYYY")}{" "} */}

              Nav : {moment(fundData?.navDate).format("DD MMM YYYY")}{" "}

            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}>₹</span>
              {fundData?.nav}
            </Grid>
            <Grid item xs={3} className={classes.textBold}>
              Rating{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              {fundData?.fundRating == "Not Rated" ||
                fundData?.fundRating == "" ? (
                "Not Rated"
              ) : (
                <RatingImage
                  // rating={fundData?.vr_rating.length}
                  rating={fundData?.fundRating}

                />
              )}
            </Grid>

            <Grid item xs={3} className={classes.textBold}>
              {" "}
              Min. SIP Amount{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}>₹</span>
              {/* {fundData?.min_sip_amount} */}

              {fundData?.minSubsequentSipInvestment}

            </Grid>
            {/*<Grid item xs={3} className={classes.textBold}>
              Fund size{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}>₹</span>
              {fundData?.fund_size.toFixed(2)}Cr.
            </Grid>*/}
            <Grid item xs={3} className={classes.textBold}>
              {" "}
              Min. Initial SIP Amount{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}>₹</span>
              {/* {fundData?.min_initial_investment} */}
              {fundData?.minInitialInvestment}

            </Grid>
            <Grid item xs={3} className={classes.textBold}>
              {" "}
              Expense{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}></span>
              {/* {fundData?.min_initial_investment} */}
              {fundData?.expenseRatio.toFixed(2)}%
            </Grid>
            <Grid item xs={3} className={classes.textBold}>
              {" "}
              Benchmark{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}></span>
              {/* {fundData?.min_initial_investment} */}
              {fundData?.benchmark?.shortName}
            </Grid>
            <Grid item xs={3} className={classes.textBold}>
              {" "}
              Exit Load{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}></span>
              {/* {fundData?.min_initial_investment} */}
              {fundData?.exitLoad}
            </Grid>
            <Grid item xs={3} className={classes.textBold}>
              {" "}
              Lock In{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}></span>
              {/* {fundData?.min_initial_investment} */}
              {fundData?.lockInPeriodDays}
            </Grid>
            <Grid item xs={3} className={classes.textBold}>
              {" "}
              Riskometer{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}></span>
              {/* {fundData?.min_initial_investment} */}
              {fundData?.colour?.colourName}
            </Grid>
            <Grid item xs={3} className={classes.textBold}>
              {" "}
              SID{" "}
            </Grid>
            <Grid item xs={3} className={classes.text1}>
              <span className={classes.rupees}>
                <a href={fundData?.amc.website} target="_blank" rel="noopener noreferrer">
                  Click here
                </a>
              </span>
              {/* {fundData?.min_initial_investment} */}
              {/* {fundData?.amc.website} */}
            </Grid>
          </Grid>

          {/* Return caluculator */}
          <ReturnCal
            // returns={fundData?.performance}
            returns={fundDataPerformance}
            sliderAmountChangeIntheReturnCalculator={sliderAmountChangeIntheReturnCalculator} />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
          className="padding-2rem"
        >
          {!loading && (
            <Mf_Invest
              fundData={fundData}
              fpData={fpData}
              show_banner={show_banner}
              // folioNumber = {folioNumber}
            />
          )}
        </Grid>
      </Grid>
      {/* Holdings */}
      <Holdings schemeCode={code} holdingDataOfSector={(data) => {
        setHoldingDataOfSectors(data)
      }} />

      {/* Risk Validation */}
      <RiskAndValidation fundData={fundData} />

      {/* Fund Performance  */}
      <FundPerformance schemeCode={code} fundData={fundData} />

      {/* Peer  comparison */}
      <PeerComparison schemeCode={code} />

      {/* Funding House Details */}

      <FundHouseDetails schemeCode={code} fundData={fundData} />

      {/* Holding Analysis */}
      <HoldingAnalysis
        exitLoad={fundData?.exitLoad}
        fundSize={fundData?.aum}
        schemeCode={code}
        fundData={fundData}
        holdingDataOfSectors={holdingDataOfSectors}
      />

      {/* Alternative funds */}

      {/* <AlternativeFunds asset_catogory={fundData?.asset_sub_category} /> */}
    </div>
  );
};

export default FundDetail;
