import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid, Slider, CircularProgress, Button } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { POST_GOALS_CALCULATE_EMERGENCY_FUND, POST_GOALS_CALCULATE_PARK_YOUR_SPARE_MONEY } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import { namespace, BASE_URL } from "../../environment";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList

} from "recharts";
import ShowRecommendedFunds from "./ShowRecommendedFunds";
import { useForm } from 'react-hook-form';
import ErrorMessage from "../../components/Validation/ErrorMessage";
import { grey } from "@material-ui/core/colors";
import { BorderTop } from "@mui/icons-material";


const ScrollingNumber = ({ value, time }) => {
    const [displayedValue, setDisplayedValue] = useState(value - 50);

    useEffect(() => {
        const interval = setInterval(() => {
            if (displayedValue < value) {
                setDisplayedValue(prevValue => prevValue + 1);
            } else if (displayedValue > value) {
                setDisplayedValue(prevValue => prevValue - 1);
            }
        }, time); // Adjust speed of animation as needed

        return () => clearInterval(interval);
    }, [displayedValue, value]);

    return <span>{displayedValue}</span>;
};



const ParkYourSpareMoney = () => {
    const useStyles = makeStyles((theme) => ({
        root: {

            '& input[type=number]': {
                '-moz-appearance': 'textfield', /* Firefox */
                '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                    display: 'none',
                },
                '-webkit-appearance': 'none', /* Chrome, Safari, Opera */
                '&[type=number]': {
                    '-moz-appearance': 'textfield', /* Firefox */
                },
                '&::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '&::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '&[type=number]': {
                    '-moz-appearance': 'textfield', /* Firefox */
                },
            },


        },
        mainGrid: {
            padding: "30px 4rem 0px 8rem",
        },
        subTabtext: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
        },
        textUnderline: {
            fontSize: "0.7rem",
            textDecoration: "underline",
            fontFamily: "inherit",
        },
        text: {
            fontSize: "0.8rem",
            fontWeight: 700,
        },
        subText: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
            paddingLeft: '5px',
        },
        text1: {
            fontSize: "0.8rem",
        },
        text2: {
            fontSize: "1.2rem",
            fontFamily: "inherit",
            fontWeight: 500,
            // paddingLeft: '5px', 
        },
        text3: {
            fontSize: "1.2rem",
            fontFamily: "inherit",
            fontWeight: 700,
            // paddingLeft: '5px', 
        },
        text4: {
            fontSize: "1.4rem",
            fontFamily: "inherit",
            fontWeight: 700,
        },
        textBold: {
            fontSize: "0.8rem",
            fontWeight: 700,
            marginBottom: "1rem",
        },

        thumb: {
            background: "#bdbdbd",
            width: "20px",
            height: "20px",
            boxShadow: 'none !important',
            ' &.Mui-focusVisible': {
                boxShadow: 'none !important',
            },
            '&.Mui-active': {
                boxShadow: 'none !important',
            },
            // boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
        },
        mark: {
            background: "black"
        },
        rail: {
            background: "#EBEBEB",
            height: "10px",
            borderRadius: '10px',
        },
        track: {
            background: "#0E76BC",
            height: "10px",
            borderRadius: '10px',
        },
        borderBox: {
            // border: "1px solid #E4E4E4",
            // borderRadius: "5px",
            // padding: "1.5rem",
            // width: "75%",
            '& .MuiSlider-valueLabel': {
                '& > *': {

                    backgroundColor: 'white',
                    color: "black"
                },
            }
        },
        gridBox: {
            cursor: 'pointer',
            borderRadius: '10px',
            transition: '0.2s ease-out',
            padding: '10px 15px',
            // "&:hover": {
            //     backgroundColor: '#0E76BC',
            //     color: '#fff',
            //     boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            // },
        },
        rupees: {
            fontFamily: "Open Sans"
        },
        primaryButton: {
            padding: "0.8rem 5rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            fontFamily: "inherit"

        },
        "@media (max-width: 767px)": {
            mainGrid: {
                padding: "0px 10px 0px 10px",
            },
        },
        rupeesSymbol: {
            position: 'relative',
            left: '9px',
            bottom: '28px',
            marginRight: '5px',
            fontSize: '13px',
            fontFamily: "Open Sans",
            display: 'block',
            height: '0px',
            width: '0px',
        },
        percentageSymbol: {
            position: 'relative',
            left: '98%',
            bottom: '28px',
            marginRight: '5px',
            fontSize: '13px',
            fontFamily: "Open Sans",
            display: 'block',
            height: '0px',
            width: '0px',
        },
        inputBox: {
            background: "#E6E6E6",
            padding: "0.7rem 0.95rem",
            borderRadius: "10px",
            border: "none",
            width: "-webkit-fill-available",
            fontFamily: "inherit",
            paddingLeft: '20px',

            '& input[type=number]': {
                '-moz-appearance': 'textfield', /* Firefox */
                '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                    display: 'none',
                },
                '-webkit-appearance': 'none', /* Chrome, Safari, Opera */
                '&[type=number]': {
                    '-moz-appearance': 'textfield', /* Firefox */
                },
                '&::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '&::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                },
                '&[type=number]': {
                    '-moz-appearance': 'textfield', /* Firefox */
                },
            },
        },
        label: {
            fontSize: "0.7rem",
            fontWeight: 500,
        },
        redumtionFundBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            margin: '7px 7px',
            padding: '7px 7px',
            border: '1px solid #0E76BC',
            borderRadius: '10px',
            // maxHeight: '120px',
            width: '100%',
            height: '100%',

        },
        tabs: {
            display: 'flex',
            position: 'relative',
            overflow: 'hidden',
            border: '1px solid #0E76BC',
            borderRadius: '50px',

        },
        tablink: {
            borderRadius: '10px',
            flex: 1,
            padding: '10px 20px',
            borderRadius: 0,
            '&:not(:last-child)': {
                marginRight: theme.spacing(1),
            },
            position: 'relative',
            zIndex: 1,
            color: '#0E76BC',
            textTransform: 'capitalize',
        },
        active: {
            borderRadius: '00px',
            backgroundColor: '#0E76BC',
            color: theme.palette.secondary.contrastText,
        },
        activeBox: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '50%',
            height: '100%',
            // backgroundColor: theme.palette.secondary.main,
            backgroundColor: '#0E76BC',
            borderRadius: '00px',

            transition: 'left 0.3s ease-in-out',
        },
    }));
    const classes = useStyles();
    const location = useLocation();

    const { register, handleSubmit, formState: { errors } } = useForm();




    const [goalsSelection, setGoalsSelection] = useState(null)
    const [sliderValueOnChangeEnd, setSliderValueOnChangeEnd] = useState(2000)
    const [sliderValueOnChangeEndYear, setSliderValueOnChangeEndYear] = useState(3)
    const [loading, setLoading] = useState(false);
    const [calculatedParkYourSpareMoney, setCalculatedParkYourSpareMoney] = useState(null);
    const [showListChart, setShowListChart] = useState(false);
    const [showRecommendedFundsPage, setShowRecommendedFundsPage] = useState(false)
    const [error, setError] = useState('');
    const [errorsInput, setErrorsInput] = useState('');
    const [reccomndedData, setReccomndedData] = useState(null)
    const [amount, setAmount] = useState()

    const [activeTab, setActiveTab] = useState('monthly');
    const [activeBoxPosition, setActiveBoxPosition] = useState(0);


    const navigate = useNavigate();


    // calculate my Emergency fund post request 
    const CalculateParkYourSpareMoney = async () => {
        // console.log(data)
        setError('')
        setLoading(true)
        const formData = {
            is_monthly: activeTab === 'monthly' ? true : false,
            amount: amount,

        }
        const httpConfig1 = {
            method: "POST",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            // params: { isin: fundData?.isinCode },
            data: formData
        };

        try {
            const response = await httpInstance(`${POST_GOALS_CALCULATE_PARK_YOUR_SPARE_MONEY}`, httpConfig1);
            console.log(response?.data, 'fadfadfadsfasdfasdfasdf')

            setCalculatedParkYourSpareMoney(response?.data);
            setShowListChart(true)
        } catch (error) {
            setError(error.response.data.error)
        } finally {
            setLoading(false)

        }
    }

    // CustomBar Label
    const CustomBarLabel = ({ fill, ...props }) => (
        <g transform={`translate(${props.x},${props.y})`}>
            <text fill={fill} fontSize={10} textAnchor="middle" dominantBaseline="middle" dy={-10} dx={10} >
                ₹{formatNumber(props.value.toFixed(2))}
            </text>
        </g>
    );

    //As the slider change in the area 

    // useEffect(() => {
    //     if (showListChart) {
    //         CalculateParkYourSpareMoney();
    //     }
    // }, [sliderValueOnChangeEnd])

    //See recomendation function call after graph 

    const SeeRecommendedFunds = () => {
        if (calculatedParkYourSpareMoney) {
            setError('')
            setShowRecommendedFundsPage(true)
            // console.log(reccomndedData)
        }
        else {
            setError('Please add amount to proceed!');
        }


    }


    //show lakhs cr valus shorted 
    const formatNumber = (value) => {
        if (value < 1000) {
            return value.toString();
        } else if (value < 100000) {
            return (value / 1000).toFixed(1) + ' ' + 'K';
        } else if (value < 10000000) {
            return (value / 100000).toFixed(1) + ' ' + 'Lakh';
        } else {
            return (value / 10000000).toFixed(1) + ' ' + 'Crore';
        }
        // if (value < 1000) {
        //     return value.toString();
        // } else if (value < 100000) {
        //     console.log(<ScrollingNumber value={value / 1000} time={3} />?.props.children);
        //     return <ScrollingNumber value={value / 1000} time={3} /> + 'K';
        // } else if (value < 10000000) {
        //     return <ScrollingNumber value={value / 100000} time={2} /> + 'Lakh';
        // } else {
        //     return <ScrollingNumber value={value / 10000000} time={1} /> + 'Crore';
        // }
    };

    const onSubmit = (data) => {
        console.log(data); // Here you can handle form submission
    };

    const handleTelChange = (event) => {
        const value = event.target.value;
        // Allow any character to be entered
        event.target.value = value;
    };
    const handleInputChange = (event) => {
        // Remove non-numeric characters
        const newValue = event.target.value.replace(/\D/g, '');
        // Update the input value
        // event.target.value = newValue;
        setAmount(newValue);



    };


    useEffect(() => {
        if (amount >= 100) {
            setErrorsInput('')
            console.log(amount);
            CalculateParkYourSpareMoney();


        } else if (amount < 100) {
            setErrorsInput('Minimum amount is 100')
        }
    }, [amount, activeTab])

    //Move to next page 
    const moveToNextPage = () => {
        setShowListChart(true)
    }


    // handle grid selection based on the mounths valuevation 
    const handleGridSelection = (value, time) => {
        console.log(value, time)
        setReccomndedData({
            value: value, time: time
        })
    }

    //changing the input type
    const tabClickHandler = (tabName, position) => {
        setActiveTab(tabName);
        setActiveBoxPosition(position);
    };

    return (
        <div >
            {!showListChart ? (
                <>
                    < Typography className={classes.text2} style={{ textAlign: 'center', paddingBottom: '10px' }}> Park Your Spare Money </Typography>
                    {/* <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'center' }} className={classes.gridBox}>
                            <Box margin={3} border={'1px solid #f1f1f1'} borderRadius={10} display='flex' justifyContent='center' alignItems='center' flexDirection={'column'} padding={'15px 0px'}>
                                <Box>
                                    < Typography className={classes.text1} style={{ textAlign: 'center', marginTop: '5px' }}>Why settle for less when you can earn more with Insta Redemption funds!</Typography>

                                    <Box display={'flex'} alignItems={'center'} padding={'7px 0px'}>
                                        <img
                                            style={{ padding: '0px 5px' }}
                                            src="/images/checkMarkCircle.svg"
                                            height="20"
                                            width="20"
                                        />
                                        < Typography className={classes.text1} >Simple and Quick Process</Typography>
                                    </Box>
                                    <Box display={'flex'} alignItems={'center'} padding={'7px 0px'}>
                                        <img
                                            style={{ padding: '0px 5px' }}
                                            src="/images/checkMarkCircle.svg"
                                            height="20"
                                            width="20"
                                        />
                                        < Typography className={classes.text1} >Anytime withdrawals (within 30 mins)</Typography>
                                    </Box>
                                    <Box display={'flex'} alignItems={'center'} padding={'7px 0px'}>
                                        <img
                                            style={{ padding: '0px 5px' }}
                                            src="/images/checkMarkCircle.svg"
                                            height="20"
                                            width="20"
                                        />
                                        < Typography className={classes.text1} >Higher returns than savings account</Typography>
                                    </Box>
                                    <Box display={'flex'} alignItems={'center'} padding={'7px 0px'}>
                                        <img
                                            style={{ padding: '0px 5px' }}
                                            src="/images/checkMarkCircle.svg"
                                            height="20"
                                            width="20"
                                        />
                                        < Typography className={classes.text1} >Invest with as low as Rs.500</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} style={{ textAlign: 'center' }} className={classes.gridBox} >
                            <Box margin={3} border={'1px solid #f1f1f1'} borderRadius={10} display='flex' justifyContent='center' alignItems='center' flexDirection={'column'} padding={'15px 10px'}>


                                < Typography className={classes.text2} style={{ textAlign: 'center', marginTop: '5px' }}>How it works</Typography>

                                <Box padding={'7px 0px'}>
                                    < Typography className={classes.text2} >How it compares</Typography>
                                    < Typography className={classes.text1} >Savings account interest rates are at multi-year lows and are expected to decrease further. Meanwhile, the liquid funds category gave an average return of 5.41% over the past year.</Typography>
                                </Box>
                                <Box padding={'7px 0px'}>
                                    < Typography className={classes.text2} >Should you go for it?</Typography>
                                    < Typography className={classes.text1} >Liquid funds have been often suggested as an alternative to savings bank accounts to park extra cash. The instant redemption facility does help in providing better liquidity, but it can’t replace savings bank accounts.</Typography>
                                </Box>
                                <Box padding={'7px 0px'}>
                                    < Typography className={classes.text2} >How much I can withdraw?</Typography>
                                    < Typography className={classes.text1} >Liquid mutual funds with instant redemption facility. Withdraw a maximum of Rs.50,000 or 90% of invested amount within 30 minutes to your bank account.</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid> */}
                    < Typography className={classes.text1} style={{ textAlign: 'center', marginTop: '5px', marginBottom: '10px' }}>Why settle for less when you can earn more with Insta Redemption funds!</Typography>

                    <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>


                        <Grid item xs={12} sm={6} md={4} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Box className={classes.redumtionFundBox} >
                                <img
                                    style={{ padding: '0px 5px' }}
                                    src="/images/checkMarkCircle.svg"
                                    height="25"
                                    width="25"
                                />
                                < Typography className={classes.text1} >Simple and Quick Process</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Box className={classes.redumtionFundBox}>
                                <img
                                    style={{ padding: '0px 5px' }}
                                    src="/images/checkMarkCircle.svg"
                                    height="25"
                                    width="25"
                                />
                                < Typography className={classes.text1} >Anytime withdrawals (within 30 mins)</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Box className={classes.redumtionFundBox} >
                                <img
                                    style={{ padding: '0px 5px' }}
                                    src="/images/checkMarkCircle.svg"
                                    height="25"
                                    width="25"
                                />
                                < Typography className={classes.text1} >Higher returns than savings account</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            <Box className={classes.redumtionFundBox} >
                                <img
                                    style={{ padding: '0px 5px' }}
                                    src="/images/checkMarkCircle.svg"
                                    height="25"
                                    width="25"
                                />
                                < Typography className={classes.text1} >Invest with as low as Rs.500</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    < Typography className={classes.text2} style={{ textAlign: 'center', marginTop: '10px' }}>How it works</Typography>

                    <Box margin={'10px 0px'} border={'1px solid #f1f1f1'} borderRadius={10} display='flex' justifyContent='center' alignItems='start' flexDirection={'column'} padding={'15px 20px'}>



                        <Box padding={'7px 0px'}>
                            < Typography className={classes.text2} >How it compares</Typography>
                            < Typography className={classes.text1} >Savings account interest rates are at multi-year lows and are expected to decrease further. Meanwhile, the liquid funds category gave an average return of 5.41% over the past year.</Typography>
                        </Box>
                        <Box padding={'7px 0px'}>
                            < Typography className={classes.text2} >Should you go for it?</Typography>
                            < Typography className={classes.text1} >Liquid funds have been often suggested as an alternative to savings bank accounts to park extra cash. The instant redemption facility does help in providing better liquidity, but it can’t replace savings bank accounts.</Typography>
                        </Box>
                        <Box padding={'7px 0px'}>
                            < Typography className={classes.text2} >How much I can withdraw?</Typography>
                            < Typography className={classes.text1} >Liquid mutual funds with instant redemption facility. Withdraw a maximum of Rs.50,000 or 90% of invested amount within 30 minutes to your bank account.</Typography>
                        </Box>
                    </Box>
                    <Box mt={3} display='flex' justifyContent='center' alignItems='center'>
                        <button onClick={moveToNextPage} className={classes.primaryButton}>
                            {loading ? (
                                <CircularProgress size="1rem" color="inherit" />
                            ) : (
                                "Continue"
                            )}</button>
                    </Box>
                </>
            ) : (
                <>
                    {showRecommendedFundsPage ? (
                        <ShowRecommendedFunds />

                    ) : (




                        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                            < Typography className={classes.text2} style={{ marginTop: '5px' }}>Don't let spare money idle, invest it today! </Typography>
                            {/* < Typography className={classes.text4} style={{ marginTop: '5px', marginBottom: '15px' }}><span className={classes.rupees}>₹</span> {" "} <ScrollingNumber value={calculatedParkYourSpareMoney?.corpus_amount} time={20} /> </Typography> */}
                            < Typography className={classes.text1} style={{ marginTop: '5px' }}>I want to invest to  </Typography>

                            <div className={classes.tabsFolder} style={{margin: '10px 0px'}}>
                                <div className={classes.tabs}>
                                    <div className={classes.activeBox} style={{ left: `${activeBoxPosition}%` }}></div>
                                    <Button
                                        className={`${classes.tablink} ${activeTab === 'monthly' ? classes.active : ''}`}
                                        onClick={() => tabClickHandler('monthly', 0)}
                                    // onMouseEnter={() => tabHoverHandler('tab1', 0)}
                                    >
                                        Every Monthly
                                    </Button>
                                    <Button
                                        className={`${classes.tablink} ${activeTab === 'oneTime' ? classes.active : ''}`}
                                        onClick={() => tabClickHandler('oneTime', 50)}>
                                        One Time
                                    </Button>
                                </div>
                            </div>

                            <div>
                                {/* <label htmlFor="freshInvestment">Fresh Investment</label> */}
                                <label className={classes.label}>How much you want to invest</label>

                                <input
                                    className={classes.inputBox}
                                    type="text"
                                    placeholder="Expected amount"
                                    // maxLength={3}
                                    // onChange={handleTelChange}
                                    onChange={handleInputChange}
                                    value={amount}
                                    onClick={(e) => !amount ? setErrorsInput('Please enter amount') : ''}
                                />
                                <span className={classes.rupeesSymbol} style={{ marginRight: '5px', }}>₹</span>

                                {errorsInput ? (
                                    <ErrorMessage message={errorsInput} />
                                ) : (
                                    <Box height={20}></Box>
                                )}
                            </div>
                            {calculatedParkYourSpareMoney &&
                                <>

                                    <Grid container>
                                        {/* <Grid item xs={5} sm={3} md={3} lg={3} style={{ textAlign: 'center' }}>
                                            < Typography className={classes.text1} style={{ marginTop: '5px' }}>Duration</Typography>

                                            < Typography className={classes.text3} style={{ marginTop: '5px' }}><ScrollingNumber value={calculatedMyFirstCroreData?.duration} time={50} /> {" "} Years</Typography>

                                        </Grid> */}
                                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'center' }}>
                                            < Typography className={classes.text1} style={{ marginTop: '5px' }}>Invested</Typography>

                                            < Typography className={classes.text3} style={{ marginTop: '5px' }}><span className={classes.rupees}>₹</span>{formatNumber(calculatedParkYourSpareMoney?.total_investment)}  </Typography>
                                            {/* <Typography className={classes.text3} style={{ marginTop: '5px' }}>
                                        <span className={classes.rupees}>₹</span>
                                        {formatNumber(<ScrollingNumber value={calculatedMyFirstCroreData?.invested} time={3} />)}
                                    </Typography> */}
                                            {/* {console.log(<ScrollingNumber value={calculatedMyFirstCroreData?.invested} time={3} />)} */}
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'center' }}>
                                            < Typography className={classes.text1} style={{ marginTop: '5px' }}>Gain</Typography>

                                            < Typography className={classes.text3} style={{ marginTop: '5px' }}><span className={classes.rupees}>₹</span>{formatNumber(calculatedParkYourSpareMoney?.gain?.toFixed(2))} </Typography>

                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'center' }}>
                                            < Typography className={classes.text1} style={{ marginTop: '5px' }}>Maturity Value</Typography>

                                            < Typography className={classes.text3} style={{ marginTop: '5px' }}><span className={classes.rupees}>₹</span>{formatNumber(calculatedParkYourSpareMoney?.total_corpus?.toFixed(2))}</Typography>
                                        </Grid>
                                    </Grid>


                                    <ResponsiveContainer width={'100%'} height={270} >

                                        <BarChart barGap={25}
                                            //  width={900} height={250} 
                                            data={calculatedParkYourSpareMoney?.investment_graph}
                                            margin={{ top: 50, right: 30, left: 20, bottom: 5 }}
                                            // style={{overflow: 'scroll'}}
                                            // padding={{ top: 10, right: 10, left: 10, bottom: 10 }}
                                            barCategoryGap={35} // Adjust the value as needed to control the gap between bars

                                        >
                                            <XAxis dataKey="year" xAxisId={0} allowDuplicatedCategory={false}
                                                //yAxisId="left"
                                                // dataKey="baseValue"
                                                // tick={false}
                                                tick={{ fontSize: 12 }}  // Set the font size for X-axis ticks
                                                label={{ fontSize: 14 }}
                                                axisLine={false} />
                                            <XAxis dataKey="year" xAxisId={1} hide allowDuplicatedCategory={false}
                                                //yAxisId="left"
                                                // dataKey="baseValue"
                                                label={false}
                                                tick={false}
                                                // tick={null}  // Remove X-axis ticks
                                                // label={null} // Remove X-axis label
                                                // scale="auto"
                                                axisLine={false}
                                            />
                                            <YAxis allowDuplicatedCategory={false}
                                                //yAxisId="left"
                                                // dataKey="baseValue"
                                                label={false}
                                                tick={false}
                                                // tick={null}  // Remove X-axis ticks
                                                // label={null} // Remove X-axis label
                                                // scale="auto"
                                                hide
                                                axisLine={false}
                                            />
                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <Tooltip />
                                            {/* <Legend /> */}

                                            <Bar dataKey="total" barSize={30} xAxisId={1} fill="#0E76BC" fillOpacity={1}>
                                                <LabelList dataKey="total" position="top" fill='#0E76BC' content={<CustomBarLabel fill="#ff0000" />} offset={10} />
                                            </Bar>

                                            <Bar dataKey="invested_amount" barSize={30} xAxisId={0} fill="#ccc" />


                                        </BarChart>
                                    </ResponsiveContainer>
                                </>
                            }

                            <Box textAlign={'center'} marginTop={5}>
                                < Typography className={classes.text3} style={{ marginTop: '5px' }}>Maturity Value</Typography>

                                < Typography className={classes.text1} style={{ marginTop: '5px', textAlign: 'center' }}>Assuming return of 6.00%</Typography>

                            </Box>
                            {error && <Box textAlign={'center'}>
                                <ErrorMessage message={error} />
                            </Box>}
                            <Box mt={3} display='flex' justifyContent='center' alignItems='center'>
                                <button onClick={SeeRecommendedFunds} className={classes.primaryButton}>
                                    {loading ? (
                                        <CircularProgress size="1rem" color="inherit" />
                                    ) : (
                                        "See Recommended Funds"
                                    )}</button>
                            </Box>
                        </Box>
                    )}
                </>
            )}

        </div >
    );
};

export default ParkYourSpareMoney;
