import React, { useState, useEffect } from "react";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import httpInstance from "../../../utils/instance";
import {
  GET_ALL_PRODUCT,
  DELIVERY_CHECKOUT,
  GET_GOLD_USER,
} from "../../../utils/endpoints";
import { useNavigate, createSearchParams, Link } from "react-router-dom";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InsufficientBalanceModal from "../Payement/InsufficientBalanceModal";
import { namespace } from "../../../environment";

const GoldCoin = ({ sku, addressId, handleMetal, handleCheckoutData }) => {
  const useStyles = makeStyles((theme) => ({
    text1: {
      fontSize: "0.7rem",
      color: "#646464",
      fontFamily: "inherit",
      marginTop: "1rem",
    },
    text2: {
      fontSize: "0.9rem",
      fontFamily: "inherit",
    },

    primaryButton: {
      padding: "5px 15px",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "1rem",
      fontWeight: 500,
      borderRadius: "5px",
      border: "none",
      marginRight: "10px",
      fontFamily: "Poppins",
      cursor: "pointer",
    },

    inputText: {
      width: "10%",
      border: "none",
      background: "#E4E4E4",
      borderRadius: "5px",
      marginRight: " 10px",
      padding: "0px 20px",
      fontSize: "1rem",
      textAlign: "center",
      fontFamily: "inherit",
    },
    table: {
      width: "30%",
    },

    tableText: {
      fontSize: "0.85rem",
      padding: "10px 0px",
      fontFamily: "inherit",
      fontWeight: 500,
    },

    borderGrid: {
      border: "1px solid #C4C4C4",
      borderRadius: "10px",
      width: "31%",
      padding: "0px 8px",
      marginTop: "1rem",
    },

    borderBox: {
      border: "1px solid #C4C4C4",
      borderRadius: "10px",
      width: "31%",
      // padding:"5px 85px",
      marginTop: "1rem",
      fontSize: "0.85rem",
      fontWeight: 500,
    },
    proceedButton: {
      padding: "0.8rem 5rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      fontFamily: "inherit",
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      width: "100%",
    },

    sliderDots: {
      "& .slick-dots li.slick-active button:before": {
        color: "blue",
      },
      "& .slick-dots li button:before": {
        color: "blue",
      },
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    "@media (max-width:600px)": {
      table: {
        width: "60%",
      },
      borderBox: {
        width: "60%",
      },
      borderGrid: {
        width: "60%",
      },
    },
  }));
  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [allProduct, setAllProduct] = useState([]);
  const [count, setCount] = useState(1);
  const [product, setProduct] = useState();
  const [checkOutData, setCheckoutData] = useState();
  const [transBalance, setTransBalance] = useState();
  const [dialog, setDialog] = useState(false);

  const navigate = useNavigate();

  const handleCloseModal = () => setDialog(false);

  const get_all_product = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_ALL_PRODUCT}`, httpConfig);
      setAllProduct(response.data?.data);
      const user = await httpInstance(`${GET_GOLD_USER}`, httpConfig);
      setTransBalance({
        goldBalance: user.data?.data?.transactionalGoldBalance,
        silverBalance: user.data?.data?.transactionalSilverBalance,
      });
    } catch {}
  };

  const get_checkout = async () => {
    const formData = {
      shippingUserAddressId: addressId.shippingUserAddressId,
      billingUserAddressId: addressId.billingUserAddressId,
      isShippingSameAsBilling: addressId.isShippingSameAsBilling,
      product: [
        {
          sku: sku,
          quantity: count,
        },
      ],
    };
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      const response = await httpInstance(`${DELIVERY_CHECKOUT}`, httpConfig);
      setCheckoutData(response.data?.data);
      handleCheckoutData(response.data?.data);
    } catch {}
  };

  useEffect(() => {
    get_checkout();
  }, [count]);

  useEffect(() => {
    get_all_product();
  }, []);

  useEffect(() => {
    if (allProduct) {
      const arr = allProduct.filter((ele) => {
        return sku === ele.sku;
      });
      setProduct(arr[0]);
    }
  }, [allProduct]);

  const handleAdd = () => {
    setCount(count + 1);
  };

  const handleSub = () => {
    if (count == 0) {
      setCount(0);
    } else {
      setCount(count - 1);
    }
  };

  const handleCount = (e) => {
    setCount(e.target.value);
  };

  const handleOrder = () => {
    if (
      (product.metalType == "gold" &&
        (product.productWeight * count).toFixed(4) >
          transBalance.goldBalance) ||
      (product.metalType == "silver" &&
        (product.productWeight * count).toFixed(4) > transBalance.silverBalance)
    ) {
      setDialog(true);
    } else {
      handleMetal({ type: product?.metalType, quantity: count });
      navigate({
        pathname: `${namespace}/myportfolio/delivery`,
        search: `?${createSearchParams({ tab: "order-summary" })}`,
      });
    }
  };
  return (
    <div>
      <Box px={{ xs: 2, md: 10 }} pt={2}>
        {" "}
        <Link to={`${namespace}/myportfolio/delivery?tab=delivery-address`}>
          <img src="/images/backIcon.svg" height="20px" width="20px" />
        </Link>{" "}
      </Box>
      <Box display="flex" justifyContent="center">
        <Box style={{ width: "150px" }} className={classes.sliderDots}>
          <Slider {...settings}>
            {/* <div >
          <img src={product?.defaultImageUrl} height="150px" width="150px"/>
          </div> */}

            {product?.productImages?.map((ele) => {
              return (
                <div key={ele.id}>
                  <img src={ele.url} height="150px" width="150px" />
                </div>
              );
            })}
          </Slider>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        mt={4}
      >
        <Typography className={classes.text1}>
          SKU - {product?.sku}(In Stock)
        </Typography>
        <Typography className={classes.text2}>{product?.name}</Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <button className={classes.primaryButton} onClick={handleAdd}>
            +
          </button>
          <input
            type="text"
            className={classes.inputText}
            onChange={handleCount}
            value={count}
          />
          <button className={classes.primaryButton} onClick={handleSub}>
            -
          </button>
        </Box>

        <Grid container mt={4} className={classes.table} px={3}>
          <Grid item xs={8} md={10}>
            <Typography className={classes.tableText} pl={3}>
              SKU
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography className={classes.tableText}>
              {product?.sku}
            </Typography>
          </Grid>
        </Grid>

        <Grid container mt={4} className={classes.table} px={3}>
          <Grid item xs={8} md={10}>
            <Typography className={classes.tableText} pl={3}>
              Weight
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography className={classes.tableText}>
              {product?.productWeight?.toFixed(4)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container mt={4} className={classes.table} px={3}>
          <Grid item xs={8} md={10}>
            <Typography className={classes.tableText} pl={3}>
              Purity
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography className={classes.tableText}>
              {product?.purity}
            </Typography>
          </Grid>
        </Grid>
        <Grid container mt={4} className={classes.table} px={3}>
          <Grid item xs={8} md={10}>
            <Typography className={classes.tableText} pl={3}>
              Total Amount(<span className={classes.rupees}>₹</span>)
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography className={classes.tableText}>
              {checkOutData?.totalAmount?.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container mt={4} className={classes.table} px={3}>
          <Grid item xs={8} md={10}>
            <Typography className={classes.tableText} pl={3}>
              Jewellery Metal
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography className={classes.tableText}>
              {product?.metalType}
            </Typography>
          </Grid>
        </Grid>

        <Grid container mt={4} className={classes.borderGrid} px={3}>
          <Grid item xs={8} md={10}>
            <Typography className={classes.tableText} pl={3}>
              Making & Delivery Charges(
              <span className={classes.rupees}>₹</span>)
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography className={classes.tableText}>
              {product?.basePrice?.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>

        <Box className={classes.borderBox}>
          <p
            style={{ margin: "5px", textAlign: "center" }}
          >{`Expected Delivery - ${moment()
            .add(7, "days")
            .format("DD MMM YYYY")}`}</p>
        </Box>
        <Box display="flex" justifyContent="center" mt={5}>
          <button className={classes.proceedButton} onClick={handleOrder}>
            Confirm Order
          </button>
        </Box>
      </Box>

      <InsufficientBalanceModal
        dialog={dialog}
        closeDialog={handleCloseModal}
      />
    </div>
  );
};

export default GoldCoin;
