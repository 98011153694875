// import React, { useEffect, useState } from 'react';
// import { Box, Container, Typography, Grid, Button, makeStyles } from "@material-ui/core";
// import { Link } from "react-router-dom";
// import { namespace } from "../environment";
// import { useSelector } from "react-redux";
// import CountUp from 'react-countup';


// function SeventhSection({ offset, handleShowModal }) {
//   const [refreshToken, setRefreshToken] = useState("");
//   useEffect(() => {
//     setRefreshToken(localStorage.getItem("refresh_token"));
//   }, [localStorage]);
//   // console.log(refreshToken);
//   const useStyles = makeStyles((theme) => ({
//     root: {
//       // backgroundImage: "url('./images/introBG.svg')",
//       background: "#0E76BC",
//       padding: "50px 0rem 55px 0rem",
//       display: "flex",
//       alignItems: "center",
//       justifyContent: "flex-start",
//       flexDirection: "column",

//     },
//     headingOne: {
//       fontSize: "48px",
//       fontWeight: 600,
//       color: "#FFFFFF",
//       marginBottom: "1.2rem",
//       textAlign: "center",
//     },
//     headingOneColor: {
//       color: "#0E76BC",
//     },
//     headingTwo: {
//       fontSize: "16px",
//       fontWeight: 400,
//       textAlign: "start",
//       color: "#FFFFFF",
//       padding: "0px 10px 20px 0px",
//       opacity: 0.8,
//     },
//     headingThree: {
//       fontFamily: 'Roboto',
//       fontSize: "20px",
//       fontWeight: 400,
//       textAlign: "start",
//       color: "#E6F1F8",
//       padding: "0px 0px 5px 0px",
//       opacity: 0.8,
//     },
//     button: {
//       padding: "1rem 4rem",
//       background: "#0E76BC",
//       color: "#FFFFFF",
//       borderRadius: "5px",
//       border: "none",
//       margin: "0 1.8rem 0 0",
//       cursor: "pointer",
//       fontSize: "18px",
//       fontWeight: 600,
//     },

//     boxShadow: {
//       background: '#084670',
//       boxShadow: ' 0px 4px 4px 0px #00000040',
//       minWidth: '110px',
//       borderRadius: '0px 12px 12px 0px',
//       overflow: 'hidden'
//     },
//     boxShadowInvert: {
//       borderRadius: '12px 0px 0px 12px',
//       background: '#084670',
//       boxShadow: ' 0px 4px 4px 0px #00000040',
//       minWidth: '110px',
//       overflow: 'hidden'
//     },

//     boxShadowNumber: {
//       fontFamily: "Poppins",
//       fontSize: '20px',
//       fontWeight: 400,
//       textAlign: 'center',
//       color: "#000000",
//     },
//     // boxShadowData: {
//     //   fontFamily: "Poppins",
//     //   fontSize: '14px',
//     //   fontWeight: 700,
//     //   textAlign: 'center',
//     //   color: "#0E76BC",
//     // },
//     boxShadowSubHeadingOne: {
//       fontFamily: "Poppins",
//       fontSize: '16px',
//       fontWeight: 500,
//       textAlign: 'center',
//       color: "#FFFFFF",
//       padding: '12px 0rem 12px 0rem',
//       marginTop: '-6px'
//     },
//     boxShadowSubBox: {
//       background: '#042338',
//       padding: ' 20px 16px 20px 16px',
//       gap: '10px',
//     },
//     boxShadowSubBoxStartData: {
//       fontFamily: "Poppins",
//       fontSize: '35px',
//       fontWeight: 600,
//       textAlign: 'start',
//       color: "#FFFFFF",
//     },
//     boxShadowSubBoxEndData: {
//       fontFamily: "Poppins",
//       fontSize: '35px',
//       fontWeight: 400,
//       textAlign: 'start',
//       color: "#FFFFFF",
//     },
//     boxShadowSubBoxButton: {
//       fontFamily: "Poppins",
//       fontSize: '18px',
//       fontWeight: 600,
//       textAlign: 'center',
//       color: "#0E76BC",
//       boxShadow: "0px 4px 4px 0px #00000040",
//       backgroundColor: "#FFFFFF",
//       width: '100%',
//       // margin: ' 20px 16px 20px 16px',
//       "&:hover": {
//         backgroundColor: "#0E76BC",
//         color: "#FFFFFF",
//       }
//     },
//     "@media (max-width: 900px)": {
//       root: {
//         padding: "8rem 0rem 1rem 0rem",
//       },
//       headingOne: {
//         fontSize: "1.6rem",
//       },
//       headingTwo: {
//         fontSize: "12px",
//       },
//     },
//   }));
//   const classes = useStyles();

//   return (
//     <div className={classes.root} height="100%">
//       {/* <Container maxWidth="lg"> */}
//       {/* <Box style={{ height: '0px', display: 'flex', justifyContent: 'end', }}>
//           <img src="/images/floatingFiveOne.svg" alt="Section 2" width="153.43px" height='100px' className='img-fluid' style={{ position: 'relative', bottom: '60px', left: '142px' }} />
//         </Box> */}
//       <Box display='flex' justifyContent={'center'} >
//         <Box maxWidth={'900px'}>

//           <Typography variant="h4" component="div" gutterBottom className={classes.headingOne} >
//             Why you should use Customized Investment  Basket?
//           </Typography>
//           <img src="/images/StaticWebDesignIcon copy.svg" alt="Section 2" width="100%" height='30px' className='img-fluid' />

//         </Box>
//       </Box>

//       <Grid container spacing={8} alignItems="center">
//         <Grid item xs={12} md={6} style={{ paddingTop: '40px' }}>
//           <Box className={classes.boxShadow} sx={{ textAlign: 'center' }} style={{ marginBottom: '70px' }}>
//             <Typography variant="h4" component="div" className={classes.boxShadowSubHeadingOne} style={{ backgroundColor: '#76BC0E' }} >
//             </Typography>
//             <Box padding={'31px 27px'} display={'flex'} justifyContent={'end'}>
//               <Box maxWidth={'460px'} margin={'31px '} width={'100%'} display={'flex'} justifyContent={'start'} flexDirection={'column'}>
//                 <img src="/images/menuStaticweb.svg" alt="Section 2" width="91px" height='91px' className='img-fluid' style={{ marginLeft: '-5px' }} />
//                 <Typography variant="h4" component="div" className={classes.boxShadowSubBoxStartData} style={{ paddingTop: '26px' }}>
//                   Regular Funds
//                 </Typography>
//                 <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData}  >
//                   Performance Insights
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//           <Box className={classes.boxShadow} sx={{ textAlign: 'center' }} style={{ marginBottom: '70px' }}>
//             <Typography variant="h4" component="div" className={classes.boxShadowSubHeadingOne} style={{ backgroundColor: '#BC540E' }} >
//             </Typography>
//             <Box padding={'31px 27px'} display={'flex'} justifyContent={'end'}>
//               <Box maxWidth={'460px'} margin={'31px '} width={'100%'} display={'flex'} justifyContent={'start'} flexDirection={'column'}>
//                 <img src="/images/chartStaticweb.svg" alt="Section 2" width="91px" height='91px' className='img-fluid' style={{ marginLeft: '-5px' }} />
//                 <Typography variant="h4" component="div" className={classes.boxShadowSubBoxStartData} style={{ paddingTop: '26px' }}>
//                   Changes in
//                 </Typography>
//                 <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData}  >
//                   Portfolio
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//         </Grid>
//         <Grid item xs={12} md={6} style={{ paddingTop: '40px' }}>
//           <Box className={classes.boxShadowInvert} sx={{ textAlign: 'center' }} style={{ marginTop: '70px' }}>
//             <Typography variant="h4" component="div" className={classes.boxShadowSubHeadingOne} style={{ backgroundColor: '#BC0E76' }} >
//             </Typography>
//             <Box padding={'31px 27px'} display={'flex'} justifyContent={'start'}>
//               <Box maxWidth={'460px'} margin={'31px '} width={'100%'} display={'flex'} justifyContent={'start'} flexDirection={'column'}>
//                 <img src="/images/wallet-checkStaticweb.svg" alt="Section 2" width="91px" height='91px' className='img-fluid' style={{ marginLeft: '-5px' }} />
//                 <Typography variant="h4" component="div" className={classes.boxShadowSubBoxStartData} style={{ paddingTop: '26px' }}>
//                   Quarterly Research
//                 </Typography>
//                 <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData}  >
//                   Report
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//           <Box className={classes.boxShadowInvert} sx={{ textAlign: 'center' }} style={{ marginTop: '70px' }}>
//             <Typography variant="h4" component="div" className={classes.boxShadowSubHeadingOne} style={{ backgroundColor: '#0EBCAB' }} >
//             </Typography>
//             <Box padding={'31px 27px'} display={'flex'} justifyContent={'start'}>
//               <Box maxWidth={'460px'} margin={'31px '} width={'100%'} display={'flex'} justifyContent={'start'} flexDirection={'column'}>
//                 <img src="/images/judgeStaticweb.svg" alt="Section 2" width="91px" height='91px' className='img-fluid' style={{ marginLeft: '-5px' }} />
//                 <Typography variant="h4" component="div" className={classes.boxShadowSubBoxStartData} style={{ paddingTop: '26px' }}>
//                   Profit
//                 </Typography>
//                 <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData}  >
//                   Booking
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//         </Grid>

//       </Grid>
//       {/* <Box style={{ height: '0px', display: 'flex', justifyContent: 'start', }}>
//           <img src="/images/floatingFiveTwo.svg" alt="Section 2" width="153.43px" height='100px' className='img-fluid' style={{ position: 'relative', bottom: '60px', right: '165px' }} />
//         </Box> */}
//       {/* </Container> */}
//     </div>
//   );
// }

// export default SeventhSection;


import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
  IconButton,
  Box,
  Container,
  Grid,
  Button,
} from '@material-ui/core';
// import { ExpandMore as ExpandMoreIcon, Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { namespace } from "../environment";
import { useSelector } from "react-redux";
import CountUp from 'react-countup';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundImage: "url('./images/introBG.svg')",
    background: "#FFFFFF",
    padding: "50px 0rem 55px 0rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    width: '100%',
    '& .MuiAccordion-root': {
      boxShadow: 'none',
      borderBottom: '0.5px solid #ffffff2e',

      '&:before': {
        display: 'none'
        // border: 'none',
        // borderBottom: '0.5px solid #ffffff2e',
      },
    },
    '& .MuiAccordion-expanded': {
      margin: '0 !important',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 'auto',
      '&.Mui-expanded': {
        minHeight: 'auto',
      },
    },
    '& .MuiAccordionDetails-root': {
      padding: theme.spacing(2, 0),
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  // root: {
  //  
  // },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontWeight: 700,

  },
  accordionSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconButton: {
    padding: 0,
  },

  headingOne: {
    fontSize: "48px",
    fontWeight: 600,
    color: "#0E76BC",
    marginBottom: "1.2rem",
    textAlign: "center",
  },
  headingOneColor: {
    color: "#0E76BC",
  },
  headingTwo: {
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "start",
    color: "#FFFFFF",
    padding: "0px 10px 20px 0px",
    opacity: 0.8,
  },
  headingThree: {
    fontFamily: 'Roboto',
    fontSize: "20px",
    fontWeight: 400,
    textAlign: "start",
    color: "#E6F1F8",
    padding: "0px 0px 5px 0px",
    opacity: 0.8,
  },
  button: {
    padding: "1rem 4rem",
    background: "#0E76BC",
    color: "#FFFFFF",
    borderRadius: "5px",
    border: "none",
    margin: "0 1.8rem 0 0",
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: 600,
  },

  boxShadow: {
    background: '#084670',
    boxShadow: ' 0px 4px 4px 0px #00000040',
    minWidth: '110px',
    borderRadius: '0px 12px 12px 0px',
    overflow: 'hidden'
  },
  boxShadowInvert: {
    borderRadius: '12px 0px 0px 12px',
    background: '#084670',
    boxShadow: ' 0px 4px 4px 0px #00000040',
    minWidth: '110px',
    overflow: 'hidden'
  },

  boxShadowNumber: {
    fontFamily: "Poppins",
    fontSize: '20px',
    fontWeight: 400,
    textAlign: 'center',
    color: "#000000",
  },
  // boxShadowData: {
  //   fontFamily: "Poppins",
  //   fontSize: '14px',
  //   fontWeight: 700,
  //   textAlign: 'center',
  //   color: "#0E76BC",
  // },
  boxShadowSubHeadingOne: {
    fontFamily: "Poppins",
    fontSize: '16px',
    fontWeight: 500,
    textAlign: 'center',
    color: "#FFFFFF",
    padding: '12px 0rem 12px 0rem',
    marginTop: '-6px'
  },
  boxShadowSubBox: {
    background: '#042338',
    padding: ' 20px 16px 20px 16px',
    gap: '10px',
  },
  boxShadowSubBoxStartData: {
    fontFamily: "Poppins",
    fontSize: '35px',
    fontWeight: 600,
    textAlign: 'start',
    color: "#FFFFFF",
  },
  boxShadowSubBoxEndData: {
    fontFamily: "Poppins",
    fontSize: '35px',
    fontWeight: 400,
    textAlign: 'start',
    color: "#FFFFFF",
  },
  boxShadowSubBoxButton: {
    fontFamily: "Poppins",
    fontSize: '18px',
    fontWeight: 600,
    textAlign: 'center',
    color: "#0E76BC",
    boxShadow: "0px 4px 4px 0px #00000040",
    backgroundColor: "#FFFFFF",
    width: '100%',
    // margin: ' 20px 16px 20px 16px',
    "&:hover": {
      backgroundColor: "#0E76BC",
      color: "#FFFFFF",
    }
  },
  "@media (max-width: 900px)": {
    root: {
      padding: "8rem 0rem 1rem 0rem",
    },
    headingOne: {
      fontSize: "1.6rem",
    },
    headingTwo: {
      fontSize: "12px",
    },
  },
}));

const CustomAccordion = ({ title, children }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (

    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={
          <IconButton className={classes.iconButton}>
            {expanded ?
              <img src="/images/Frame 48033.svg" alt="remove" width="30px" height='30px' className='img-fluid' />
              :
              <img src="/images/Frame 48032.svg" alt=" add" width="30px" height='30px' className='img-fluid' />
            }
          </IconButton>
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{ minHeight: '20px' }}
      >
        <div className={classes.accordionSummary} >
          <Typography className={classes.heading}>{title}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {children}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};


function SeventhSection({ offset, handleShowModal }) {
  const [refreshToken, setRefreshToken] = useState("");
  useEffect(() => {
    setRefreshToken(localStorage.getItem("refresh_token"));
  }, [localStorage]);
  // console.log(refreshToken);
  const classes = useStyles();

  return (
    <div className={classes.root} height="100%">
      <Container maxWidth="lg">
        <Box display='flex' justifyContent={'center'} >
          <Box maxWidth={'500px'} paddingBottom={'40px'}>

            <Typography variant="h4" component="div" gutterBottom className={classes.headingOne} >
              How do we work
            </Typography>
            <img src="/images/StaticWebDesignIcon.svg" alt="Section 2" width="100%" height='30px' className='img-fluid' />

          </Box>
        </Box>
        <CustomAccordion title="1. Investment:">
          Initiate the investment process by selecting mutual funds based on your financial goals and risk tolerance.
        </CustomAccordion>
        <CustomAccordion title="2. Analysis-Based Fund:">
          Perform a thorough analysis of fund performance, management team, and historical returns to choose the right funds.
        </CustomAccordion>
        <CustomAccordion title="3. Pick Sector:">
          Identify and invest in sectors that show strong potential for growth based on current market trends and economic indicators.
        </CustomAccordion>
        <CustomAccordion title="4. Build Portfolio:">
          Construct a diversified portfolio by allocating investments across different asset classes and sectors to mitigate risk.
        </CustomAccordion>
        <CustomAccordion title="5. Active Management:">
          Regularly review and adjust the portfolio to capitalize on market opportunities and manage risks effectively.
        </CustomAccordion>
        <CustomAccordion title="6. Portfolio Growth:">
          Monitor the portfolio's performance and make strategic changes to ensure steady growth over the investment horizon.
        </CustomAccordion>
        <CustomAccordion title="7. Research Report:">
          Utilize comprehensive research reports to stay informed about market developments and make informed investment decisions.
        </CustomAccordion>
        <CustomAccordion title="8. Profit Booking:">
          Periodically book profits by selling high-performing assets to lock in gains and reinvest in new opportunities.
        </CustomAccordion>
      </Container>
    </div>
  );
};

export default SeventhSection;
