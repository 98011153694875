import React from 'react'
import CapitalGainReport from '../../containers/MyProfile/CapitalGainReport/index'

const Capital_gain_report = () => {
  return (
    <div>
        <CapitalGainReport/>
    </div>
  )
}

export default Capital_gain_report