import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import httpInstance from "../../utils/instance";
import Lumsum from "./InvestType/Lumsum";
import Sip from "./InvestType/Sip";
import {
  CREATE_LUMSUM,
  INITIATE_PAYMENT,
  KYC_DATA,
  GET_LUMSUM_DETAIL,
  KYC_STATUS,
} from "../../utils/endpoints";

const Mf_Invest = ({ fundData, show_banner, fpData }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      border: "1px solid #E4E4E4",
      borderRadius: "12px",
      boxShadow: "0 0 8px -3px #444444",
    },
    box2: {
      borderBottom: "1px solid #E4E4E4",
    },
    box3: {
      padding: "1rem 0px",
      cursor: "pointer",
    },
    bluebox3: {
      padding: "1rem 0px",
      color: "#0E76BC",
      borderBottom: "3px solid #0E76BC",
      cursor: "pointer",
    },
  }));
  const classes = useStyles();
  const [tab, setTab] = useState("sip");
  const [kycStatus, setKycStatus] = useState();
  const [kycData, setKycData] = useState(null)

  const get_kycData = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await httpInstance(`${KYC_STATUS}`, httpConfig);
      setKycData(response?.data?.data)
      setKycStatus(response.data?.data?.fp_kyc_status);
    } catch {
      setKycStatus("empty");
    }
  };

  useEffect(() => {
    get_kycData();
   
  }, []);

  useEffect(()=>{
    if(fundData?.stateValue?.transaction_basket_items[0]?.transaction_type){
      handle_tab(fundData?.stateValue?.transaction_basket_items[0]?.transaction_type)
    }
  },[fundData?.stateValue?.transaction_basket_items[0]?.transaction_type])

  const handle_tab = (data) => {
    setTab(data);
  };

  //-------------------------------handle lumsum invest----------------------------------------------------------//
  // const handle_invest= async()=>{
  //   if(sessionStorage.getItem("mpin")){
  //     if(kycStatus=="successful"){

  //      }else{
  //         show_banner()
  //      }
  //   }else{
  //     navigate("/onBoard")
  //   }
  // }

  // console.log('fpdata', fundData, show_banner, fpData)


  return (
    <div>
      <Box className={classes.root} mt={{ xs: 5, md: 0 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          px={6}
          className={classes.box2}
        >
          <Box
            className={tab == "sip" ? classes.bluebox3 : classes.box3}
            onClick={() => handle_tab("sip")}
          >
            <Typography>Monthly SIP</Typography>
          </Box>
          <Box
            className={tab == "lumpsum" ? classes.bluebox3 : classes.box3}
            onClick={() => handle_tab("lumpsum")}
          >
            <Typography>Lumpsum</Typography>
          </Box>
        </Box>
        {tab == "lumpsum" ? (
          <Lumsum
            fundData={fundData}
            fpData={fpData}
            kycStatus={kycStatus}
            show_banner={show_banner}
            kycData={kycData}
          />
        ) : (
          <Sip
            fundData={fundData}
            fpData={fpData}
            kycStatus={kycStatus}
            show_banner={show_banner}
            kycData={kycData}

          />
        )}
      </Box>
    </div>
  );
};

export default Mf_Invest;
