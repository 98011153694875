import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import {
  GET_TRANS_BY_STATUS,
  GET_DELIVERY_TRANS,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import moment from "moment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useNavigate, createSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { namespace } from "../../../environment";

const LoaderComp = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
        overflow: "hidden",
      }}
    >
      <CircularProgress size="2rem" />
    </div>
  );
};

const ViewAllTransactions = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "2rem 30%",
      //width:"50%",

      "& .MuiPaper-elevation1": {
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;",
      },
      " & .MuiTableCell-root ": {
        padding: "10px",
      },
    },

    subTabroot: {
      borderBottom: "1px solid #E6E3E3",
    },
    subTabtext: {
      fontSize: "0.7rem",
      fontFamily: "inherit",
      fontWeight: 500,
    },
    textUnderline: {
      fontSize: "0.7rem",
      textDecoration: "underline",
      fontFamily: "inherit",
    },

    subTitle: {
      fontSize: "0.85rem",
      fontFamily: "Poppins",
      fontWeight: 500,
      //marginBottom:"10px"
    },
    header: {
      fontSize: "1.2rem",
      fontWeight: 500,
    },

    text: {
      fontSize: "0.65rem",
      fontFamily: "Poppins",
      // marginBottom:"10px",
      fontWeight: 500,
    },
    textgrey: {
      fontSize: "0.65rem",
      fontFamily: "Poppins",
      color: "#C0C0C0",
      //marginBottom:"10px"
    },
    buttonBox: {
      background: "#EAEAEA",
      borderRadius: "8px",
      padding: "2px 5px",
    },
    primaryButton: {
      padding: "0.7rem 3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.9rem",
      fontFamily: "Poppins",
      fontWeight: 600,
      borderRadius: "5px",
      border: "none",
      outline: "none",
    },
    button: {
      background: "#EAEAEA",
      padding: "0.7rem 3rem",
      fontSize: "0.9rem",
      fontFamily: "Poppins",
      borderRadius: "5px",
      border: "none",
      cursor: "pointer",
      outline: "none",
    },

    viewText: {
      textAlign: "center",
      width: "fit-content",
      fontSize: "0.8rem",
      color: "#0E76BC",
      marginTop: "10px",
      fontWeight: 500,
      cursor: "pointer",
    },

    checkBox: {
      "& .MuiCheckbox-colorPrimary.Mui-checked": {
        color: "#0E76BC",
      },
      "& .MuiTypography-body1": {
        fontSize: "0.8rem",
      },
    },
    rupees: {
      fontFamily: "Open Sans",
    },

    "@media(max-width:991px)": {
      root: {
        padding: "2rem 2%",
      },
    },
  }));

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [noMore, setNomore] = useState(true);
  const [value, setValue] = useState("buysell");
  const [failed, setFailed] = useState(false);
  const [buySellList, setBuySellList] = useState([]);
  const [buySellFailed, setBuySellFailed] = useState([]);
  const [deliveryList, setDeliveryList] = useState([]);
  const [failedDelivery, setFailedDelivery] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);

  const navigate = useNavigate();

  const set_page = () => setPage(page + 1);

  const get_buySell_data = async () => {
    const httpConfig2 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        type: "buy/sell",
        status: "SUCCESS",
        limit: 10,
        from: page == 0 ? 0 : page * 10,
      },
    };
    try {
      const response = await httpInstance(
        `${GET_TRANS_BY_STATUS}`,
        httpConfig2
      );
      response.data?.responseCount < 10 && setNomore(false);
      if (response.data?.responseCount == 0) {
        setNomore(false);
      } else {
        setAllTransactions([...allTransactions, ...response.data?.data]);
        set_page();
      }
    } catch {}
  };

  useEffect(() => {
    fetch_data();
  }, [value, failed]);

  //---delivery----------------------------//

  const get_delivery = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { status: "SUCCESS", limit: 10, from: page == 0 ? 0 : page * 10 },
    };

    try {
      const delResponse = await httpInstance(
        `${GET_DELIVERY_TRANS}`,
        httpConfig
      );
      delResponse.data?.responseCount < 10 && setNomore(false);
      if (delResponse.data?.responseCount == 0) {
        setNomore(false);
      } else {
        let delArr = delResponse?.data?.data.map((ele) => {
          return {
            id: ele.id,
            orderType: "delivery",
            metalType: ele.deliveryGoldSilverProduct[0]?.sku?.metalType,
            createdAt: ele.createdAt,
            totalAmount: ele.deliveryGoldSilverProduct[0]?.totalAmount,
            quantity: ele.deliveryGoldSilverProduct[0]?.quantity,
          };
        });
        setAllTransactions([...allTransactions, ...delArr]);
        set_page();
      }
    } catch {}
  };

  //-------------------failed delivery--------------------------------------//

  const get_falied_delivery = async () => {
    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { status: "FAILED", limit: 10, from: page == 0 ? 0 : page * 10 },
    };

    try {
      const delFailResponse = await httpInstance(
        `${GET_DELIVERY_TRANS}`,
        httpConfig1
      );
      delFailResponse.data?.responseCount < 10 && setNomore(false);
      if (delFailResponse.data?.responseCount == 0) {
        setNomore(false);
      } else {
        const failedDel = delFailResponse?.data?.data?.map((ele) => {
          return {
            id: ele.id,
            orderType: "delivery",
            metalType: ele.goldSilverOrderId.metalType,
            createdAt: ele.createdAt,
            totalAmount: ele.goldSilverOrderId.amount,
            //quantity:ele.deliveryGoldSilverProduct[0]?.quantity
          };
        });

        setAllTransactions([...allTransactions, ...failedDel]);
        set_page();
      }
    } catch {}
  };

  //---------------------------failed buysell--------------------------------------------------//
  const get_failed_buySell = async () => {
    const httpConfig3 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        type: "buy/sell",
        status: "FAILED",
        limit: 10,
        from: page == 0 ? 0 : page * 10,
      },
    };

    try {
      const failedresponse = await httpInstance(
        `${GET_TRANS_BY_STATUS}`,
        httpConfig3
      );
      failedresponse.data?.responseCount < 10 && setNomore(false);
      if (failedresponse.data?.responseCount == 0) {
        setNomore(false);
      } else {
        setAllTransactions([...allTransactions, ...failedresponse.data?.data]);
        set_page();
      }
    } catch {}
  };

  const fetch_data = () => {
    if (value == "buysell") {
      if (failed) {
        get_failed_buySell();
      } else {
        get_buySell_data();
      }
    } else {
      if (failed) {
        get_falied_delivery();
      } else {
        get_delivery();
      }
    }
  };

  const handleValue = (data) => {
    setAllTransactions([]);
    setPage(0);
    setNomore(true);
    setValue(data);
  };

  const handle_checkbox = (e) => {
    setAllTransactions([]);
    setPage(0);
    setNomore(true);
    setFailed(e.target.checked);
  };

  const handle_order_status = (type, id) => {
    navigate({
      pathname: `${namespace}/myportfolio/my-order`,
      search: `?${createSearchParams({
        order_type: type.toLowerCase(),
        order_id: id,
        status: failed ? "failed" : "complete",
      })}`,
    });
  };
  return (
    <div>
      {/* subtab */}

      <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        className={classes.subTabroot}
      >
        <Link
          to={`${namespace}/myportfolio?tab=digitalgold`}
          style={{ color: "black" }}
        >
          <Typography className={classes.textUnderline}>
            My Portfolio
          </Typography>
        </Link>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Link
            to={`${namespace}/myportfolio/digitalgold/detail-investment`}
            style={{ color: "black" }}
          >
            <Typography
              className={classes.textUnderline}
              display="flex"
              alignItems="start"
            >
              Detail Investment
            </Typography>
          </Link>
        </Box>
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography
            className={classes.subTabtext}
            display="flex"
            alignItems="start"
          >
            All Transactions
          </Typography>
        </Box>
      </Box>

      {/* all transaction */}
      <Box
        display="flex"
        justifyContent="flex-start"
        px={15}
        mt={2}
        className={classes.backButton}
      >
        <Link to={`${namespace}/myportfolio/digitalgold/detail-investment`}>
          <img src="/images/backIcon.svg" height="20px" width="20px" />
        </Link>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography className={classes.header}>All Transactions</Typography>
      </Box>
      <Box className={classes.root}>
        <Box display="flex" justifyContent="center" mb={3}>
          <Box className={classes.buttonBox}>
            <button
              className={
                value === "buysell" ? classes.primaryButton : classes.button
              }
              onClick={() => handleValue("buysell")}
            >
              Buy/Sell
            </button>
            <button
              className={
                value === "delivery" ? classes.primaryButton : classes.button
              }
              onClick={() => handleValue("delivery")}
            >
              Delivery
            </button>
          </Box>
        </Box>
        <Box className={classes.checkBox} display="flex" justifyContent="end">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="primary"
                onChange={handle_checkbox}
              />
            }
            label="Show failed Transactions"
          />
        </Box>

        <InfiniteScroll
          dataLength={allTransactions?.length}
          next={fetch_data}
          hasMore={noMore}
          loader={<LoaderComp />}
          // endMessage={
          //   <Typography style={{textAlign:"center"}}>No result to display</Typography>
          // }
          style={{ overflow: "unset" }}
        >
          {allTransactions.length == 0 ? (
            <Typography style={{ textAlign: "center" }}>
              No result to display
            </Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  {allTransactions?.map((ele) => {
                    return (
                      <TableRow
                        key={ele.id}
                        onClick={() =>
                          handle_order_status(ele.orderType, ele.id)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell>
                          <Grid container>
                            <Grid item xs={4} style={{ display: "flex" }}>
                              <img
                                src={
                                  ele.metalType == "gold"
                                    ? "/images/image 2.svg"
                                    : "/images/image 3.svg"
                                }
                                height="40px"
                                width="40px"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography className={classes.subTitle}>
                                {value == "buysell"
                                  ? ele.orderType == "BUY"
                                    ? "Invested"
                                    : "Sold"
                                  : "Delivery"}
                              </Typography>
                              <Typography
                                className={classes.textgrey}
                                style={{ marginTop: "5px" }}
                              >
                                {moment(ele.createdAt).format("DD MMMM YYYY")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell align="left">
                          {!failed && (
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography className={classes.textgrey}>
                                  Qt.
                                </Typography>
                                {ele.orderType != "delivery" && (
                                  <Typography
                                    className={classes.textgrey}
                                    style={{ marginTop: "5px" }}
                                  >
                                    Price
                                  </Typography>
                                )}
                              </Grid>

                              <Grid item xs={6}>
                                <Typography className={classes.text}>
                                  {!ele.quantity ? "-" : `${ele.quantity}g`}
                                </Typography>
                                {ele.orderType != "delivery" && (
                                  <Typography
                                    className={classes.text}
                                    style={{ marginTop: "5px" }}
                                  >
                                    {!ele.quantity
                                      ? "-"
                                      : `${(
                                          ele.totalAmount / ele.quantity
                                        ).toLocaleString("en-IN", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}/g`}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </TableCell>
                        <TableCell align="right" className={classes.subTitle}>
                          <span className={classes.rupees}>₹</span>
                          {ele.totalAmount?.toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </InfiniteScroll>
      </Box>
    </div>
  );
};

export default ViewAllTransactions;
