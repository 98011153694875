import React from 'react'
import Goals from '../../../containers/Goals/index'
import Header from '../../../components/header/Header'
import Footer from '../../../components/Footer'

const index = () => {
  return (
    <div>
         {/* <Header/> */}
        <Goals/>
        {/* <Footer/> */}
    </div>
  )
}

export default index