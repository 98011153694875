import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, Grid, CircularProgress, FormControlLabel, Menu, MenuItem } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { GET_ALL_CAPITAL_GAIN_REPORT, UPDATE_PRIMARY_BANK, UPDATE_INVESTOR_ACCOUNT, GET_MANDATE, GET_ALL_BANK, GET_KYC_DATA, GET_USER_ONBOARDING_DETAILS } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { fontSize } from '@mui/system'
import ReactLoading from 'react-loading';
import { BASE_URL } from '../../../environment'
import { styled } from '@mui/material/styles';
import InfiniteScroll from "react-infinite-scroll-component";


const Index = () => {
    const useStyles = makeStyles((theme) => ({
        root: {

        },
        header: {
            fontWeight: 600
        },
        imageBack: {
            padding: "4px 5px 0px",
            background: "#ADDFFF",
            borderRadius: "50%"
        },
        title1: {
            fontSize: "0.8rem",
            padding: "0px 10px",
            fontWeight: 600
        },
        text: {
            fontSize: "0.75rem",
            fontWeight: 500
        },
        textblue: {
            fontSize: "0.75rem",
            fontWeight: 500,
            color: "#0E76BC",
            display: "flex",
            alignItems: "center",
            marginLeft: "10px"
        },
        text2: {
            fontSize: "0.8rem",
            padding: "5px 0px"
        },
        text3: {
            fontSize: "0.8rem",
            padding: "5px 0px",
            fontWeight: 600
        },
        borderBox: {
            border: "1px solid #C0C0C0",
            borderRadius: "5px",

        },



        blueBorderBox: {
            border: "3px solid #0E76BC",
            borderRadius: "5px"
        },

        gridPadding: {
            padding: "0px 7rem 0px 0px "
        },
        primaryButton: {
            padding: "0.8rem 2rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            marginTop: "3rem",
            fontFamily: "inherit"
        },
        checkBox: {
            "& .MuiCheckbox-colorPrimary.Mui-checked": {
                color: "#0E76BC"
            },
            "& .MuiTypography-body1": {
                fontSize: "0.8rem"
            }
        },
        menu: {
            "& .MuiIconButton-root": {
                padding: "2px"
            },
        },

        grid: {
            background: '#f5f8fa',
            borderRadius: "5px",
            borderBottom: "1px solid #C0C0C0",
            // "&:last-child": {
            //   borderBottom: "none",
            // },
        },

        gridCursor: {
            borderBottom: "1px solid #C0C0C0",
            // "&:last-child": {
            //   borderBottom: "none",
            // },
        },

    }))
    const classes = useStyles()

   

    const { userStore } = useSelector((state) => state)
    const [dailyPortfolioUpdate, setDailyPortfolioUpdate] = useState(false)
    const [whatsAppnotification, setWhatsAppnotification] =useState(false)
    const [status, setStatus] = useState("Successful");
    const [orders, setOrders] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const LoaderComp = () => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              overflow: "hidden",
            }}
          >
            <CircularProgress size="2rem" />
          </div>
        );
      };
      const fetchData = () => {
        get_all_gain_report();
        setHasMore(false)
      };
    
    //----------------------------------get all capital gain report ------------------------------------------------------//
    const get_all_gain_report = async () => {
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                // user_id: localStorage.getItem("user_id"),
                // isAllOrders: status ? false : true,
                // status: status,
                // limit: 10,
                page: page ,
                // isDiyOrder: false,
              },
        };
        try {
            const response = await httpInstance(`${GET_ALL_CAPITAL_GAIN_REPORT}`, httpConfig)
          // console.log('order list', response.data)
          if (response.data?.responseCount == 0 || response.data.data.length == 0) {
            setHasMore(false);
          } else {
            setOrders(response.data?.data);
            setPage(page + 1);
           
    
          }
        } catch { }
      };



    //---------------------------------------------------------------------------------//
    useEffect(() => {
        get_all_gain_report()
    }, [])


    const handle_whatsApp = (e) => {
        setWhatsAppnotification(e.target.checked);

    }
    const handle_portfolio = (e) => {
        setDailyPortfolioUpdate(e.target.checked);

    };

    return (
        <div>
            <Box px={{ xs: 2, md: 20 }} mt={5}>
                <Typography className={classes.header}>Capital Gain Report</Typography>
                <Box display={'flex'} flexDirection={'column'}>
                <InfiniteScroll
                    dataLength={orders?.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<LoaderComp />}
                    endMessage={
                      <Typography className={classes.text} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        No Report to display
                      </Typography>
                    }
                  >
                    {orders?.map((data, i) => {
                      return (
                        // <Link
                        //   to={`${namespace}/mutualfund/mutual-fund/order-status/${data?.id ? data?.id : data?.mfOldId
                        //     }`}
                        //   state={{ data: data, order: true, orderType: 1 }}
                        //   style={{ textDecoration: "none", color: "inherit" }}
                        // >
                        //   {" "}
                        //   <OrderList key={i} orderData={data} />
                        // </Link>
                        <Box>

                        </Box>
                      );
                    })}
                  </InfiniteScroll>
                </Box>
            </Box>
        </div>
    )
}

export default Index