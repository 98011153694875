import React from "react";
import {
  Box,
  Button,
  TextField,
  makeStyles,
  Typography,
  Grid,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BASE_URL, namespace } from "../../../../environment";

const SwitchSuccess = () => {
  const useStyles = makeStyles((theme) => ({
    title: {
      fontSize: "1.1rem",
      fontWeight: 500,
    },
    textgrey: {
      fontSize: "0.9rem",
      color: " #797979",
      marginTop: "2rem",
    },

    primaryButton: {
      padding: "0.8rem 5rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      fontFamily: "inherit",
    },
    borderBox: {
      border: "1px solid #DDDDDD",
      borderRadius: "5px",
    },
  }));

  const classes = useStyles();
  const { state } = useLocation();
  const { fromFund, toFund } = state;
  // console.log(state);

  return (
    <div>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        px={{ xs: 5, md: 35 }}
      >
        <img src="/images/Group 22601.svg" height="220px" width="220px" />
        <Typography className={classes.title}>Congratulations!</Typography>
        <Typography className={classes.textgrey}>
          You have successfully Switched
        </Typography>

        <Box display="flex" mt={4} p={2} px={4} className={classes.borderBox}>
          <img src={`${fromFund?.img}`} height="auto" width="30px" />
          <Typography style={{ padding: "0px 10px" }}>
            {fromFund?.name}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center">
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Box display="flex" justifyContent="end">
                <img src="/images/arrowDown.svg" height="180px" width="30px" />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ padding: "0px 10px" }}>SWITCH</Typography>
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" p={2} px={4} className={classes.borderBox}>
          <img src={`${BASE_URL}${toFund?.img}`} height="auto" width="30px" />
          <Typography style={{ padding: "0px 10px" }}>
            {toFund?.name}
          </Typography>
        </Box>

        <Box mt={10}>
          <Link to={`${namespace}/myportfolio?tab=mutualfund`}>
            <button className={classes.primaryButton}>
              Go to My Portfolio
            </button>
          </Link>{" "}
        </Box>
      </Box>
    </div>
  );
};

export default SwitchSuccess;
