// SecondSection.js
import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { namespace } from "../environment";
import { useSelector } from "react-redux";
import CountUp from 'react-countup';


function SecondSection({ offset, handleShowModal }) {
  const [refreshToken, setRefreshToken] = useState("");
  useEffect(() => {
    setRefreshToken(localStorage.getItem("refresh_token"));
  }, [localStorage]);
  console.log(refreshToken);
  const useStyles = makeStyles((theme) => ({
    root: {
      // backgroundImage: "url('./images/introBG.svg')",
      background: "linear-gradient(117.24deg, #0C67A4 0%, #0E76BC 102.4%)",
      padding: "50px 0rem 8px 0rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "column",

    },
    headingOne: {
      fontSize: "39px",
      fontWeight: 600,
      color: "#FFFFFF",
      marginBottom: "1.2rem",
      textAlign: "start",
    },
    headingOneColor: {
      color: "#0E76BC",
    },
    headingTwo: {
      fontSize: "16px",
      fontWeight: 400,
      textAlign: "start",
      color: "#FFFFFF",
      padding: "0px 10px 20px 0px",
      opacity: 0.8,
    },
    headingThree: {
      fontFamily: 'Roboto',
      fontSize: "20px",
      fontWeight: 400,
      textAlign: "start",
      color: "#E6F1F8",
      padding: "0px 0px 5px 0px",
      opacity: 0.8,
    },
    button: {
      padding: "1rem 4rem",
      background: "#FFFFFF",
      color: "#0E76BC",
      borderRadius: "5px",
      border: "none",
      margin: "0 1.8rem 0 0",
      cursor: "pointer",
      fontSize: "18px",
      fontWeight: 600,
      "&:hover": {
        color: "#FFFFFF",
        background: "#0E76BC",
      }
    },

    boxShadow: {
      background: '#FFFFFF40',
      boxShadow: ' 0px 4px 4px 0px #00000040',
      minWidth: '110px',
      borderRadius: '5px',
    },
    boxShadowNumber: {
      fontFamily: "Poppins",
      fontSize: '20px',
      fontWeight: 400,
      textAlign: 'center',
      color: "#000000",
    },
    boxShadowData: {
      fontFamily: "Poppins",
      fontSize: '14px',
      fontWeight: 700,
      textAlign: 'center',
      color: "#0E76BC",
    },
    "@media (max-width: 900px)": {
      root: {
        padding: "8rem 0rem 1rem 0rem",
      },
      headingOne: {
        fontSize: "1.6rem",
      },
      headingTwo: {
        fontSize: "12px",
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root} height="100%">
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: { xs: 'center', md: 'left' }, p: 2 }}>
            <Typography variant="h4" component="div" gutterBottom className={classes.headingThree}>
            GET STARTED IN MINUTES
              </Typography>
              <Typography variant="h4" component="div" gutterBottom className={classes.headingOne}>
              One App For All Your Mutual Funds Investments
              </Typography>
              <Box maxWidth={'550px'}>
                <Typography variant="body1" gutterBottom className={classes.headingTwo} >
                Bring all your mutual fund investment at one place. Set Investment
                Goals, Assess the progress and get the expert recommendations
                </Typography>
              </Box>
              <Button
                  className={classes.button}
                >
                 Join Now
                </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: 'center' }}>
              <img src="/images/sectiontwoImage.svg" alt="Section 2" width="100%" height='838px' className='img-fluid' />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default SecondSection;

