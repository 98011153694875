import React from "react";
import { Box, Hidden, makeStyles } from "@material-ui/core";
function Footer() {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-around",
      flexDirection: "row",
      padding: "4rem 0rem",
      // background: "#484848",
      background: "#FFFFFF"
    },
    heading: {
      fontWeight: 500,
      fontSize: "1.2rem",
      color: "#3F3D56",
      margin: "0px 0px 1.4rem 0px",
    },
    subtitle: {
      fontWeight: 400,
      fontSize: "0.9rem",
      color: "#3F3D56",
      margin: "0.1rem 0",
    },
    subDataAddressMobileNumber: {
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '27px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#3F3D56',
  },
    "@media (max-width: 900px)": {
    root: {
      display: "flex",
      alignItems: "start",
      flexDirection: "column",
      padding: "20px",
    },
    logo: {
      margin: "0 auto!important",
    },
  },
  }));
const classes = useStyles();
return (
  <Box maxWidth="lg" mt={12}>
    <footer className={classes.root}>
      <Box display={"flex"} flexDirection="column">
        {/* <Box overflow={'hidden'} height={100} width={100} textAlign={'center'}> */}
        <img src="/MF 5 6.svg" alt="MF Wealth" height={100} />
        {/* </Box> */}
        {/* <Box >
            <a
              href="https://www.facebook.com/Bullsurge/photos/?ref=page_internal"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/facebook.svg"
                alt="facebook"
                width={22}
                height={24}
                style={{
                  padding: "5px",
                }}
              />
            </a>
            <a
              href="https://www.instagram.com/bullsurge/?utm_medium=copy_link"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/insta2.svg"
                alt="twitter"
                width={22}
                height={24}
                style={{ borderRadius: "50%", padding: "5px" }}
              />
            </a>
            <a
              href="https://twitter.com/bullsurge1"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/twitter.svg"
                alt="twitter"
                width={22}
                height={24}
                style={{
                  padding: "5px",
                }}
              />
            </a>

            <a
              href="https://www.linkedin.com/company/bullsurge/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/linkedin.svg"
                alt="linkedin"
                width={22}
                height={24}
                style={{
                  padding: "5px",
                }}
              />
            </a>

          </Box> */}
        <Box pt={3} className={classes.subDataAddressMobileNumber}>
          {/* <p> */}
          No. 54, Jaynth Nagar, 5th Lane,<br />
          4th Block, Koremangala, <br />
          Bangalore - 560047<br />
          {/* </p> */}
        </Box>
        <Box pt={3} className={classes.subDataAddressMobileNumber}>
          +91-86696 06482
        </Box>
        <Box pt={3} className={classes.subDataAddressMobileNumber}>
          Email Us: support@MF Wealth.in
        </Box>
      </Box>
      <Box my={1}>
        <Box className={classes.heading}>Company</Box>
        <Box display="flex" flexDirection="column" mt={1}>
          <a href="" style={{ textDecoration: "none" }}>
            <Box className={classes.subtitle}>Pricing</Box>
          </a>
        </Box>
      </Box>
      <Box my={1}>
        <Box className={classes.heading}>About Bullsurge</Box>
        <Box display="flex" flexDirection="column" mt={1}>
          <a
            href="https://www.neosurge.money/about"
            style={{ textDecoration: "none" }}
          >
            <Box className={classes.subtitle}>About Us</Box>
          </a>

          <a
            href="https://blogs.neosurge.money/"
            style={{ textDecoration: "none" }}
          >
            <Box className={classes.subtitle}>Blog</Box>
          </a>
        </Box>
      </Box>
      <Box my={1}>
        <Box className={classes.heading}>Product</Box>
        <Box display="flex" flexDirection="column" mt={1}>
          <a
            href="https://www.neosurge.money/Equity"
            style={{ textDecoration: "none" }}
          >
            <Box className={classes.subtitle}>Equity & ETF</Box>
          </a>

          <a
            href="https://www.neosurge.money/USStocks"
            style={{ textDecoration: "none" }}
          >
            <Box className={classes.subtitle}>US Stocks</Box>
          </a>
          <a
            href="https://www.bullsurge.in/"
            style={{ textDecoration: "none" }}
          >
            <Box className={classes.subtitle}>Authorized Partner</Box>
          </a>
        </Box>
      </Box>

      {/* <Box my={1}>
          <Box className={classes.heading}>Contact</Box>
          <Box display="flex" flexDirection="column" mt={1}>
            <Box className={classes.subtitle} fontWeight="fontWeightBold">
              Email Us:
            </Box>
            <a
              href="mailto:support@bullsurge.in"
              style={{ textDecoration: "none" }}
            >
              <Box
                className={classes.subtitle}
                style={{ textDecoration: "underline" }}
              >
                Support@Bullsurge.com
              </Box>
            </a>
            <Box className={classes.subtitle}>Write For Us:</Box>
            <a
              href="mailto:support@bullsurge.in"
              style={{ textDecoration: "none" }}
            >
              <Box
                className={classes.subtitle}
                style={{ textDecoration: "underline" }}
              >
                writeforus@Bullsurge.com
              </Box>
            </a>
          </Box>
        </Box> */}
    </footer>
  </Box>
);
}

export default Footer;
