import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid, Slider, CircularProgress } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { POST_GOALS_CALCULATE_TAX_SAVER, POST_GOALS_CALCULATE_LONG_TERM_WEALTH } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import { namespace, BASE_URL } from "../../environment";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList

} from "recharts";
import ShowRecommendedFunds from "./ShowRecommendedFunds";
import ErrorMessage from "../../components/Validation/ErrorMessage";

const ScrollingNumber = ({ value, time }) => {
    const [displayedValue, setDisplayedValue] = useState(value - 10);

    useEffect(() => {
        const interval = setInterval(() => {
            if (displayedValue < value) {
                setDisplayedValue(prevValue => prevValue + 1);
            } else if (displayedValue > value) {
                setDisplayedValue(prevValue => prevValue - 1);
            }
        }, time); // Adjust speed of animation as needed

        return () => clearInterval(interval);
    }, [displayedValue, value]);
    console.log(value);

    return <span>{displayedValue}</span>;
};







const LongTermWealth = () => {
    const useStyles = makeStyles((theme) => ({
        root: {},
        mainGrid: {
            padding: "30px 4rem 0px 8rem",
        },
        subTabtext: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
        },
        textUnderline: {
            fontSize: "0.7rem",
            textDecoration: "underline",
            fontFamily: "inherit",
        },
        text: {
            fontSize: "0.8rem",
            fontWeight: 700,
        },
        subText: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
            paddingLeft: '5px',
        },
        text1: {
            fontSize: "0.8rem",
        },
        text2: {
            fontSize: "1.2rem",
            fontFamily: "inherit",
            fontWeight: 500,
            // paddingLeft: '5px', 
        },
        text3: {
            fontSize: "1.2rem",
            fontFamily: "inherit",
            fontWeight: 700,
            // paddingLeft: '5px', 
        },
        textBold: {
            fontSize: "0.8rem",
            fontWeight: 700,
            marginBottom: "1rem",
        },

        thumb: {
            background: "#bdbdbd",
            width: "20px",
            height: "20px",
            boxShadow: 'none !important',
            ' &.Mui-focusVisible': {
                boxShadow: 'none !important',
            },
            '&.Mui-active': {
                boxShadow: 'none !important',
            },
            // boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
        },
        mark: {
            background: "black"
        },
        rail: {
            background: "#EBEBEB",
            height: "10px",
            borderRadius: '10px',
        },
        track: {
            background: "#0E76BC",
            height: "10px",
            borderRadius: '10px',
        },
        borderBox: {
            // border: "1px solid #E4E4E4",
            // borderRadius: "5px",
            // padding: "1.5rem",
            // width: "75%",
            '& .MuiSlider-valueLabel': {
                '& > *': {

                    backgroundColor: 'white',
                    color: "black"
                },
            }
        },
        rupees: {
            fontFamily: "Open Sans"
        },
        primaryButton: {
            padding: "0.8rem 5rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            fontFamily: "inherit"

        },
        "@media (max-width: 767px)": {
            mainGrid: {
                padding: "0px 10px 0px 10px",
            },
        },
        rupeesSymbol: {
            position: 'relative',
            left: '9px',
            bottom: '28px',
            marginRight: '5px',
            fontSize: '13px',
            fontFamily: "Open Sans",
            display: 'block',
            height: '0px',
            width: '0px',
        },
        percentageSymbol: {
            position: 'relative',
            left: '98%',
            bottom: '28px',
            marginRight: '5px',
            fontSize: '13px',
            fontFamily: "Open Sans",
            display: 'block',
            height: '0px',
            width: '0px',
        },
        inputBox: {
            background: "#E6E6E6",
            padding: "0.7rem 0.95rem",
            borderRadius: "10px",
            border: "none",
            width: "-webkit-fill-available",
            fontFamily: "inherit",
            paddingLeft: '20px',


        },
        label: {
            fontSize: "0.7rem",
            fontWeight: 500,
        },
        gridBox: {
            // cursor: 'pointer',
            borderRadius: '50px',
            transition: '0.2s ease-out',
            padding: '15px 25px',
            border: '1px solid #0E76BC',
            margin: '15px 0px',
            // "&:hover": {
            //     backgroundColor: '#0E76BC',
            //     color: '#fff',
            //     boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
            // },
        },
    }));
    const classes = useStyles();
    const location = useLocation();

    const [goalsSelection, setGoalsSelection] = useState(null)
    const [sliderValueOnChangeEnd, setSliderValueOnChangeEnd] = useState(10000)
    const [sliderValueOnChangeEndYear, setSliderValueOnChangeEndYear] = useState(2)
    const [loading, setLoading] = useState(false)
    const [calculatedcalculatedLongTermWealth, setCalculatedLongTermWealth] = useState(null);
    const [showBarchart, setShowBarChart] = useState(false);
    const [showRecommendedFundsPage, setShowRecommendedFundsPage] = useState(false)
    const [error, setError] = useState('');
    const [errorsInput, setErrorsInput] = useState('');
    const [percentage, setPercentage] = useState()
    const navigate = useNavigate();


    // calculate my first corre post request 
    const CalculateLongTermWealth = async () => {
        if (percentage <= 100) {
            setErrorsInput('')
            // console.log(amount);
            // CalculateParkYourSpareMoney();



            setLoading(true)
            const formData = {
                target_corpus: sliderValueOnChangeEnd,
                years: sliderValueOnChangeEndYear,
                expected_returns: percentage,
            }
            const httpConfig1 = {
                method: "POST",
                tokenType: "user",
                headers: {
                    "Content-Type": "application/json",
                },
                // params: { isin: fundData?.isinCode },
                data: formData
            };

            try {
                const response = await httpInstance(`${POST_GOALS_CALCULATE_LONG_TERM_WEALTH}`, httpConfig1);

                setCalculatedLongTermWealth(response?.data);
                setShowBarChart(true)
            } catch (error) {
                setError(error.response?.data?.error)
            } finally {
                setLoading(false)

            }
        } else if (percentage > 100 || !percentage) {
            if (!percentage) {
                setErrorsInput('Minimum percentage is 100')
            } else {
                setErrorsInput('Enter Percentage')
            }
        }
    }



    useEffect(() => {
        if (percentage <= 100) {
            setErrorsInput('')


        } else if (percentage > 100) {
            setErrorsInput('Minimum amount is 100')
        }
    }, [percentage])


    // CustomBar Label
    const CustomBarLabel = ({ fill, ...props }) => (
        <g transform={`translate(${props.x},${props.y})`}>
            <text fill={fill} fontSize={10} textAnchor="middle" dominantBaseline="middle" dy={-10} dx={10} >
                ₹{formatNumber(props.value.toFixed(2))}
            </text>
        </g>
    );

    //As the slider change in the area 

    // useEffect(() => {
    //     if (showBarchart) {
    //         CalculateLongTermWealth();
    //     }
    // }, [sliderValueOnChangeEnd])

    //See recomendation function call after graph 

    const SeeRecommendedFunds = () => {
        setShowRecommendedFundsPage(true)
    }


    //show lakhs cr valus shorted 
    const formatNumber = (value) => {
        if (value < 1000) {
            return value.toString();
        } else if (value < 100000) {
            return (value / 1000).toFixed(1) + ' ' + 'K';
        } else if (value < 10000000) {
            return (value / 100000).toFixed(1) + ' ' + 'Lakh';
        } else {
            return (value / 10000000).toFixed(1) + ' ' + 'Crore';
        }
        // if (value < 1000) {
        //     return value.toString();
        // } else if (value < 100000) {
        //     console.log(<ScrollingNumber value={value / 1000} time={3} />?.props.children);
        //     return <ScrollingNumber value={value / 1000} time={3} /> + 'K';
        // } else if (value < 10000000) {
        //     return <ScrollingNumber value={value / 100000} time={2} /> + 'Lakh';
        // } else {
        //     return <ScrollingNumber value={value / 10000000} time={1} /> + 'Crore';
        // }
    };

    const handleInputChange = (event) => {
        // Remove non-numeric characters
        const newValue = event.target.value.replace(/\D/g, '');
        // Update the input value
        // event.target.value = newValue;
        if (newValue <= 100) {
            setPercentage(newValue);
        }



    };

    return (
        < >
            {!showBarchart ? (
                <>
                    < Typography className={classes.text2} style={{ textAlign: 'center', paddingBottom: '10px' }}> Long Term Wealth </Typography>
                    {/* <ScrollingNumber value={7} /> */}
                    <Box>
                        < Typography className={classes.text3} style={{ marginTop: '5px' }}>Secure Your Financial Future: Plan Your Long-Term Wealth Goals Now!</Typography>

                        < Typography className={classes.text1} style={{ marginTop: '5px' }}>What is Your Target Wealth?</Typography>

                        <Box mt={8} px={3} className={classes.borderBox}>
                            <Slider
                                classes={{
                                    thumb: classes.thumb,
                                    rail: classes.rail,
                                    track: classes.track,
                                    valueLabel: classes.valueLabel,
                                    mark: classes.mark
                                }}
                                defaultValue={10000}
                                aria-label="Default"
                                valueLabelDisplay="on"
                                size="medium"
                                valueLabelFormat={(x) => {
                                    return (
                                        <p style={{ paddingLeft: '10px', color: "black" }}><span className={classes.rupees}>₹</span>{x.toLocaleString('en-IN')}</p>
                                    )
                                }}
                                min={10000}
                                max={50000000}
                                // onChange={(e, value) => return_calculator(value)}
                                onChangeCommitted={(e, value) => {
                                    // console.log("Slider value after release:", value);
                                    // sliderAmountChangeIntheReturnCalculator(value)
                                    setSliderValueOnChangeEnd(value)
                                    // Add any other logic you want to perform after the slider is released
                                }}
                            />

                        </Box>
                        <Box px={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                                variant="body2"
                            //   onClick={() => setVal(MIN)}
                            //   sx={{ cursor: 'pointer' }}
                            >
                                <span className={classes.rupees}>₹</span>
                                10000
                            </Typography>
                            <Typography
                                variant="body2"
                            //   onClick={() => setVal(MAX)}
                            //   sx={{ cursor: 'pointer' }}
                            >
                                <span className={classes.rupees}>₹</span>
                                50000000
                            </Typography>
                        </Box>
                        < Typography className={classes.text1} style={{ marginTop: '20px' }}>When Would You Like to Achieve Your Target Amount?</Typography>

                        <Box mt={8} px={3} className={classes.borderBox}>
                            <Slider
                                classes={{
                                    thumb: classes.thumb,
                                    rail: classes.rail,
                                    track: classes.track,
                                    valueLabel: classes.valueLabel,
                                    mark: classes.mark
                                }}
                                defaultValue={2}
                                aria-label="Default"
                                valueLabelDisplay="on"
                                size="medium"
                                valueLabelFormat={(x) => {
                                    return (
                                        <p style={{ paddingLeft: '10px', color: "black" }}><span className={classes.rupees}></span>{x.toLocaleString('en-IN')}Y</p>
                                    )
                                }}
                                min={2}
                                max={40}
                                // onChange={(e, value) => return_calculator(value)}
                                onChangeCommitted={(e, value) => {
                                    // console.log("Slider value after release:", value);
                                    // sliderAmountChangeIntheReturnCalculator(value)
                                    setSliderValueOnChangeEndYear(value)
                                    // Add any other logic you want to perform after the slider is released
                                }}
                            />

                        </Box>
                        <Box px={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                                variant="body2"
                            //   onClick={() => setVal(MIN)}
                            //   sx={{ cursor: 'pointer' }}
                            >
                                {/* <span className={classes.rupees}>₹</span> */}
                                2Y
                            </Typography>
                            <Typography
                                variant="body2"
                            //   onClick={() => setVal(MAX)}
                            //   sx={{ cursor: 'pointer' }}
                            >
                                {/* <span className={classes.rupees}>₹</span> */}
                                40Y
                            </Typography>
                        </Box>
                        <Box marginTop={3}>
                            {/* <label htmlFor="freshInvestment">Fresh Investment</label> */}
                            <label className={classes.label}>How much you want to invest</label>

                            <input
                                className={classes.inputBox}
                                type="text"
                                placeholder="Expected percentage"
                                maxLength={3}
                                // onChange={handleTelChange}
                                onChange={handleInputChange}
                                value={percentage}
                                onClick={(e) => !percentage ? setErrorsInput('Please enter percentage') : ''}
                            />
                            <span className={classes.percentageSymbol} style={{ marginRight: '5px', }}>%</span>

                            {errorsInput ? (
                                <ErrorMessage message={errorsInput} />
                            ) : (
                                <Box height={20}></Box>
                            )}
                        </Box>

                        < Typography className={classes.subText} style={{ marginTop: '5px' }}>Your profile is verified and these details are non-editable, in case any questions please contact support team.</Typography>

                    </Box>
                    {error && <Box textAlign={'center'}>
                        <ErrorMessage message={error} />
                    </Box>}
                    <Box mt={3} display='flex' justifyContent='center' alignItems='center'>
                        <button onClick={CalculateLongTermWealth} className={classes.primaryButton}>
                            {loading ? (
                                <CircularProgress size="1rem" color="inherit" />
                            ) : (
                                "Calculate Now"
                            )}</button>
                    </Box>
                </>
            ) : (
                <>
                    {showRecommendedFundsPage ? (
                        <ShowRecommendedFunds />

                    ) : (




                        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                            < Typography className={classes.text2} style={{ marginTop: '5px' }}>You can achieve</Typography>
                            < Typography className={classes.text3} style={{ marginTop: '5px' }}> <span className={classes.rupees}>₹</span> {(<ScrollingNumber value={calculatedcalculatedLongTermWealth?.total_corpus?.toFixed(2)} time={20} />)}</Typography>

                            <Box style={{ textAlign: 'center' }} className={classes.gridBox} >
                                < Typography className={classes.text1} style={{ marginTop: '5px' }}> In {" "} <ScrollingNumber value={calculatedcalculatedLongTermWealth?.duration} time={50} /> {" "}Years</Typography>

                                < Typography className={classes.text3} style={{ marginTop: '5px' }}><span className={classes.rupees}>₹</span>{(calculatedcalculatedLongTermWealth?.monthly_investment)?.toFixed(2)} /month  </Typography>
                               
                            </Box>

                           


                            <Box display='flex' justifyContent='center' alignItems='center' style={{ width: '100%', }} >
                                <ResponsiveContainer width={'100%'} height={270} >

                                    <BarChart barGap={25}
                                        //  width={900} height={250} 
                                        data={calculatedcalculatedLongTermWealth?.investment_graph}
                                        margin={{ top: 50, right: 30, left: 20, bottom: 5 }}
                                        // style={{overflow: 'scroll'}}
                                        // padding={{ top: 10, right: 10, left: 10, bottom: 10 }}
                                        barCategoryGap={35} // Adjust the value as needed to control the gap between bars

                                    >
                                        <XAxis dataKey="year" xAxisId={0} allowDuplicatedCategory={false}
                                            //yAxisId="left"
                                            // dataKey="baseValue"
                                            // tick={false}
                                            tick={{ fontSize: 12 }}  // Set the font size for X-axis ticks
                                            label={{ fontSize: 14 }}
                                            axisLine={false} />
                                        <XAxis dataKey="year" xAxisId={1} hide allowDuplicatedCategory={false}
                                            //yAxisId="left"
                                            // dataKey="baseValue"
                                            label={false}
                                            tick={false}
                                            // tick={null}  // Remove X-axis ticks
                                            // label={null} // Remove X-axis label
                                            // scale="auto"
                                            axisLine={false}
                                        />
                                        <YAxis allowDuplicatedCategory={false}
                                            //yAxisId="left"
                                            // dataKey="baseValue"
                                            label={false}
                                            tick={false}
                                            // tick={null}  // Remove X-axis ticks
                                            // label={null} // Remove X-axis label
                                            // scale="auto"
                                            hide
                                            axisLine={false}
                                        />
                                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                        <Tooltip />
                                        {/* <Legend /> */}

                                        <Bar dataKey="total" barSize={30} xAxisId={1} fill="#0E76BC" fillOpacity={1}>
                                            <LabelList dataKey="total" position="top" fill='#0E76BC' content={<CustomBarLabel fill="#ff0000" />} offset={10} />
                                        </Bar>

                                        <Bar dataKey="invested_amount" barSize={30} xAxisId={0} fill="#ccc" />


                                    </BarChart>
                                </ResponsiveContainer>
                            </Box>
                   
                            <Box mt={4} display='flex' justifyContent='center' alignItems='center'>
                                <button onClick={SeeRecommendedFunds} className={classes.primaryButton}>
                                    {loading ? (
                                        <CircularProgress size="1rem" color="inherit" />
                                    ) : (
                                        "See Recommended Funds"
                                    )}</button>
                            </Box>
                        </Box>
                    )}
                </>
            )}

        </ >
    );
};

export default LongTermWealth;
