// SecondSection.js
import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, Grid, Button, makeStyles, Card, CardContent } from "@material-ui/core";
import { Link } from "react-router-dom";
import { namespace } from "../environment";
import { useSelector } from "react-redux";
import CountUp from 'react-countup';
import ReactPlayer from 'react-player/youtube';


function EightSection({ offset, handleShowModal }) {
  const [refreshToken, setRefreshToken] = useState("");
  useEffect(() => {
    setRefreshToken(localStorage.getItem("refresh_token"));
  }, [localStorage]);
  // console.log(refreshToken);
  const useStyles = makeStyles((theme) => ({
    root: {
      // backgroundImage: "url('./images/introBG.svg')",
      // backgroundImage: `linear-gradient(116.74deg, rgba(12, 103, 164, 0.8) 1.66%, rgba(14, 118, 188, 0.8) 100%), url('./images/Rectangle Copy 2.svg')`,
      background: "#0E76BC",

      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "column",
      overflow: 'hidden'
    },
    boxImage: {
      width: "100%",
      height: "100%",
      backgroundImage: `url('./images/des.svg')`,
      padding: "50px 0rem 55px 0rem",
    },
    headingOne: {
      fontSize: "48px",
      fontWeight: 600,
      color: "#FFFFFF",
      marginBottom: "1.2rem",
      textAlign: "center",
    },
    headingOneColor: {
      color: "#0E76BC",
    },
    headingTwo: {
      fontSize: "16px",
      fontWeight: 400,
      textAlign: "start",
      color: "#FFFFFF",
      padding: "0px 10px 20px 0px",
      opacity: 0.8,
    },
    headingThree: {
      fontFamily: 'Roboto',
      fontSize: "20px",
      fontWeight: 400,
      textAlign: "start",
      color: "#E6F1F8",
      padding: "0px 0px 5px 0px",
      opacity: 0.8,
    },
    button: {
      padding: "1rem 4rem",
      background: "#0E76BC",
      color: "#FFFFFF",
      borderRadius: "5px",
      border: "none",
      margin: "0 1.8rem 0 0",
      cursor: "pointer",
      fontSize: "18px",
      fontWeight: 600,
    },

    boxShadow: {
      background: '#042338',
      boxShadow: ' 0px 4px 4px 0px #00000040',
      minWidth: '110px',
      borderRadius: '10px',
    },
    insideBoxShadow: {
      background: '#FFFFFF',
      // boxShadow: ' 0px 4px 4px 0px #00000040',
      // minWidth: '110px',
      borderRadius: '10px',
    },
    boxShadowNumber: {
      fontFamily: "Poppins",
      fontSize: '20px',
      fontWeight: 400,
      textAlign: 'center',
      color: "#000000",
    },
    // boxShadowData: {
    //   fontFamily: "Poppins",
    //   fontSize: '14px',
    //   fontWeight: 700,
    //   textAlign: 'center',
    //   color: "#0E76BC",
    // },
    boxShadowSubHeadingOne: {
      fontFamily: "Poppins",
      fontSize: '16px',
      fontWeight: 500,
      textAlign: 'start',
      color: "#000000",
      padding: '6px 0rem 6px 0rem',
      marginTop: '-6px'
    },
    boxShadowSubBox: {
      background: '#042338',
      padding: ' 20px 16px 20px 16px',
      gap: '10px',
    },
    boxShadowSubBoxStartData: {
      fontFamily: "Poppins",
      fontSize: '14px',
      fontWeight: 400,
      textAlign: 'start',
      color: "#FFFFFF",
    },
    boxShadowSubBoxEndData: {
      fontFamily: "Poppins",
      fontSize: '12px',
      fontWeight: 400,
      textAlign: 'start',
      color: "#000000",
    },
    boxShadowSubBoxButton: {
      fontFamily: "Poppins",
      fontSize: '18px',
      fontWeight: 600,
      textAlign: 'center',
      color: "#0E76BC",
      boxShadow: "0px 4px 4px 0px #00000040",
      backgroundColor: "#FFFFFF",
      width: '100%',
      // margin: ' 20px 16px 20px 16px',
      "&:hover": {
        backgroundColor: "#0E76BC",
        color: "#FFFFFF",
      }
    },
    videoTitle: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      padding: theme.spacing(1),
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      color: '#fff',
      fontSize: '1rem',
      textAlign: 'left',
    },
    videoInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: theme.spacing(1),
    },
    "@media (max-width: 900px)": {
      root: {
        padding: "8rem 0rem 1rem 0rem",
      },
      headingOne: {
        fontSize: "1.6rem",
      },
      headingTwo: {
        fontSize: "12px",
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root} height="100%">
      <div className={classes.boxImage} >
        {/* <Container maxWidth="lg"> */}
        <Box padding={'0px 31px'}>


          {/* <Box style={{ height: '0px', display: 'flex', justifyContent: 'end',  }}>
          <img src="/images/floatingFive.svg" alt="Section 2" width="153.43px" height='100px' className='img-fluid' style={{ position: 'relative', bottom: '60px', left: '142px' }} />
        </Box> */}
          <Box display='flex' justifyContent={'center'} >
            <Box maxWidth={'500px'}>

              <Typography variant="h4" component="div" gutterBottom className={classes.headingOne} >
                Digital Gallery
              </Typography>
              <img src="/images/StaticWebDesignIcon copy.svg" alt="Section 2" width="100%" height='30px' className='img-fluid' />
            </Box>
          </Box>

          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={4} style={{ paddingTop: '40px' }}>
              <Box sx={{ textAlign: 'center' }} display={'flex'} justifyContent={'center'}>
                <Box className={classes.boxShadow} maxWidth={'340px'} sx={{ textAlign: 'center' }} padding={'31px 27px'} >
                  <Box padding={'31px 27px'} style={{ backgroundColor: '#ffffff', borderRadius: '10px' }}>
                    {/* <img src="/images/Frame 427320539.svg" alt="Section 2" width="100%" height='182px' className='img-fluid' style={{ marginLeft: '-5px' }} /> */}
                    <ReactPlayer url={'https://youtu.be/eN1Bu3me6Jk?si=WIJgGO4_54HHhEzy'} width="100%" height="150px" className={classes.videoPlayer} />
                    <Box display={'flex'} justifyContent={'between'} paddingTop={'20px'}>
                      <img src="/images/wealthRedefine.jpg" alt="Section 2" width="auto" height='40px' className='img-fluid' />
                      <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} paddingLeft={'15px'} style={{ width: '100%' }}>
                        <Typography variant="h4" component="div" className={classes.boxShadowSubHeadingOne}  >
                          From Analyst to Fund Manager - Edelweiss Small Cap Fund| Investment Strategy & More| Wealth Redefine
                        </Typography>
                        <Box style={{ width: '100%' }}>
                          <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData} style={{ display: 'flex', alignItems: 'center' }}>

                            Wealth Redefine
                            <img src="/images/FrameCheckMark.svg" alt="Section 2" width="15px" height='15px' className='img-fluid' style={{ paddingLeft: '5px' }} />
                          </Typography>
                          <Box display={'flex'} style={{ width: '100%', paddingTop: '5px' }} >
                            <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData}  >

                              3.5K views
                            </Typography>
                            <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData} style={{ paddingLeft: '25px' }}>
                              11 months ago
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} style={{ paddingTop: '40px' }}>
              <Box sx={{ textAlign: 'center' }} display={'flex'} justifyContent={'center'}>
                <Box className={classes.boxShadow} maxWidth={'440px'} maxHeight={'500px'} sx={{ textAlign: 'center' }} padding={'31px 27px'} >
                  <Box padding={'31px 27px'} style={{ backgroundColor: '#ffffff', borderRadius: '10px' }}>
                    {/* <img src="/images/Frame 427320539.svg" alt="Section 2" width="100%" height='182px' className='img-fluid' style={{ marginLeft: '-5px' }} /> */}
                    <ReactPlayer url={'https://youtu.be/9LV89gW82dY?si=pIgvvS8IHQJQO5iL'} width="100%" height="200px" className={classes.videoPlayer} />
                    <Box display={'flex'} justifyContent={'between'} paddingTop={'20px'}>
                      <img src="/images/wealthRedefine.jpg" alt="Section 2" width="auto" height='40px' className='img-fluid' />
                      <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} paddingLeft={'15px'} style={{ width: '100%' }}>
                        <Typography variant="h4" component="div" className={classes.boxShadowSubHeadingOne}  >
                          Join the conversation with White Oak Mutual Fund CEO Mr. Aashish Somaiyaa || Wealth Redefine
                        </Typography>
                        <Box style={{ width: '100%' }}>
                          <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData} style={{ display: 'flex', alignItems: 'center' }}>
                            Wealth Redefine <img src="/images/FrameCheckMark.svg" alt="Section 2" width="15px" height='15px' className='img-fluid' style={{ paddingLeft: '5px' }} />
                          </Typography>
                          <Box display={'flex'} style={{ width: '100%', paddingTop: '5px' }} >
                            <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData}  >

                              9.1K Views
                            </Typography>
                            <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData} style={{ paddingLeft: '25px' }}>
                              7 months ago
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} style={{ paddingTop: '40px' }}>
              <Box sx={{ textAlign: 'center' }} display={'flex'} justifyContent={'center'}>
                <Box className={classes.boxShadow} maxWidth={'340px'} sx={{ textAlign: 'center' }} padding={'31px 27px'} >
                  <Box padding={'31px 27px'} style={{ backgroundColor: '#ffffff', borderRadius: '10px' }}>
                    {/* <img src="/images/Frame 427320539.svg" alt="Section 2" width="100%" height='182px' className='img-fluid' style={{ marginLeft: '-5px' }} /> */}
                    <ReactPlayer url={'https://youtu.be/ATaXdJC9Uos?si=pMho-3Txo8vi_Ijw'} width="100%" height="150px" className={classes.videoPlayer} />
                    <Box display={'flex'} justifyContent={'between'} paddingTop={'20px'}>
                      <img src="/images/wealthRedefine.jpg" alt="Section 2" width="auto" height='40px' className='img-fluid' />
                      <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} paddingLeft={'15px'} style={{ width: '100%' }}>
                        <Typography variant="h4" component="div" className={classes.boxShadowSubHeadingOne}  >
                          MARKET TRENDS WITH MR. HARSHA UPADHYAYA ||CIO KOTAK MUTUAL FUND||WEALTH REDEFINE
                        </Typography>
                        <Box style={{ width: '100%' }}>
                          <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData} style={{ display: 'flex', alignItems: 'center' }}>
                            Wealth Redefine <img src="/images/FrameCheckMark.svg" alt="Section 2" width="15px" height='15px' className='img-fluid' style={{ paddingLeft: '5px' }} />
                          </Typography>
                          <Box display={'flex'} style={{ width: '100%', paddingTop: '5px' }} >
                            <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData}  >
                              4.2K  Views
                            </Typography>
                            <Typography variant="h4" component="div" className={classes.boxShadowSubBoxEndData} style={{ paddingLeft: '25px' }}>
                              4 months ago
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}


export default EightSection;