let BASE_URL = "https://api.maxwealth.money/";
if (process.env.REACT_APP_ENVIRONMENT === "production") {
  BASE_URL = "https://api.maxwealth.money/";
}

let namespace = "";

let tenant_id = 'wealthredefine';
// wealthredefine sahisip

export { BASE_URL, namespace, tenant_id };
