import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, Grid, Table, TableBody, TableCell, TableHead, TableRow, Container, Tab, Tabs, LinearProgress } from "@material-ui/core";
import { GET_HOLDINGS, GET_PEER_COMPARISON_DATA } from '../../utils/endpoints'
import httpInstance from '../../utils/instance'
import { fontSize } from '@mui/system';
import PropTypes from 'prop-types';
import { namespace, BASE_URL } from '../../environment';
import ReactLoading from "react-loading";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={"span"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const PeerComparison = ({ schemeCode }) => {
    const useStyles = makeStyles((theme) => ({
        root: {

        },
        tableRowPeerComparision: {
            cursor: 'pointer',
            "&:hover": {
                backgroundColor: "#f7f9f9 !important"
            }
        },
        tableCellsLists: {

            "& .MuiLinearProgress-colorPrimary": {
                backgroundColor: "#25AAE1",
            },
            "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#0E76BC",
            },
        },
        title: {
            fontWeight: 600,
            padding: "0px 7rem",
            fontSize: "1.2rem"
        },
        tableHead: {
            fontWeight: 600
        },
        tab_box: {
            padding: "0px 0rem"
        },
        tab_style: {
            width: "100%",
            background: "white",
            color: "black",
            borderBottom: '1px solid #E5E3E3',
            "& .MuiTab-root": {
                fontFamily: "inherit",
                fontSize: "1rem",
                color: "black",
                opacity: 1,
                textTransform: "none",
                background: "none",
                minWidth: "auto",
                padding: "0px"
            },
            "& .MuiTab-textColorInheri": {
                opacity: 0.9
            },
            "& .MuiTabs-root": {
                height: "42px",
                minHeight: "42px",
                padding: "0px"
            },
            "& .MuiTabs-flexContainer": {
                display: "flex",
                justifyContent: "space-between"
            },
            "& .MuiTabs-scrollable": {
                overflowY: "clip"
            }


        },
        tabWidth: {
            width: '25%',
            color: '#272727',
            fontSize: '15px',
            fontFamily: 'Poppins',
            fontWeight: '400'
        },
        activeTab: {
            "& .MuiTab-wrapper": {
                borderBottom: "2px solid #0E76BC",
                color: "#0E76BC",
                paddingBottom: "5px",

            }

        },

        inActiveTab: {
            "& .MuiTab-wrapper": {
                paddingBottom: "5px",

            }

        },
        "@media (max-width: 600px)": {
            tab_box: {
                padding: "20px"
            },
            activeTab: {
                "& .MuiTab-wrapper": {
                    padding: "10px"
                }
            },
            inActiveTab: {
                "& .MuiTab-wrapper": {
                    padding: "10px"

                }
            },
        },
        "@media (min-width: 992px)": {
            tablefirstCell: {
                fontWeight: 600,
                width: "45%",
                paddingLeft: "7rem",

            },
            tableBodyfirstCell: {
                paddingLeft: "7rem",
                width: "45%",

            },
            tablelastCell: {
                fontWeight: 600,
                paddingRight: "5rem",

            },
            tableBodylastCell: {
                paddingRight: "5rem",
            },
        },
        viewAll: {
            fontSize: "0.9rem",
            fontWeight: 600,
            color: '#0E76BC',
            textDecoration: "underline",
            cursor: "pointer"
        }


    }))
    const classes = useStyles();

    const [holdingData, setHoldingData] = useState([])
    const [viewAll, setViewAll] = useState(false)
    const [value, setValue] = React.useState(0);

    const [loading, setLoading] = useState(true);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const get_holding = async () => {
        let year = "year5"
        // const formData={
        //     scheme_code:schemeCode,
        //     as_on:"",
        //     portfolio_type:''
        // }
        const httpConfig = {
            method: "GET",
            // tokenType: "user",
            test: 'test',
            // headers:{
            //   "Content-Type": "application/json",
            // },
            // data:formData,
        };
        try {
            const response = await httpInstance(`${GET_PEER_COMPARISON_DATA}?fundPlansId=${schemeCode}&interestReturnTime=${year}`, httpConfig)
            setHoldingData(response.data?.data)
            // console.log(response.data?.data)
            setLoading(false)
        }
        catch {
            setLoading(false)

        }
    }
    // console.log(holdingData)
    useEffect(() => {
        if (schemeCode) {
            setLoading(true)
            get_holding()
        }
    }, [schemeCode])

    const handle_viewAll = () => {
        setViewAll(!viewAll)
    }

    const handle_route = (code) => {
        // navigate(`${namespace}/mutualfund/scheme-code/${code}`);
        window.open(`${namespace}/mutualfund/scheme-code/${code}`, "_blank");
        // window.location.reload();
    };

    return (
        <div>
            <Box mt={5}>
                <Typography className={classes.title}>Peer Comparison</Typography>
                {/* <Container className={classes.tab_box}>

                    <div className={classes.tab_style}>
                        <Tabs indicatorColor=" " value={value} onChange={handleChange} variant="scrollable" allowScrollButtonsMobil={true}>

                            <Tab label="Market Cap" {...a11yProps(0)} className={value == 0 ? classes.activeTab : classes.inActiveTab} />
                            <Tab label="Sector" {...a11yProps(1)} className={value == 1 ? classes.activeTab : classes.inActiveTab} />
                            <Tab label="Companies" {...a11yProps(2)} className={value == 2 ? classes.activeTab : classes.inActiveTab} />

                        </Tabs>
                    </div>

                </Container>
                <TabPanel value={value} index={0} > */}
                {loading ? (
                    <Box display="flex" justifyContent="center" mt={5}>
                        <ReactLoading
                            type="spinningBubbles"
                            color="#0E76BC"
                            height={"3%"}
                            width={"3%"}
                        />
                    </Box>
                ) : (
                    <div style={{ width: 'auto', overflowX: 'auto' }} className={classes.tableCellsLists}>


                        <Table style={{ marginTop: "0rem", }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tablefirstCell} >Fund Name</TableCell>
                                    <TableCell className={classes.tableHead} >1Y Return</TableCell>
                                    <TableCell className={classes.tablelastCell}>2Y Return</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(!viewAll ? holdingData?.slice(0, 10) : holdingData)?.map((ele, i) => {
                                    return (
                                        <TableRow key={i} onClick={() => handle_route(ele.id)} className={classes.tableRowPeerComparision}>
                                            <TableCell className={classes.tableBodyfirstCell}>

                                                <Box item xs={2} md={1} display={"flex"} alignItems="center">
                                                    <img
                                                        // src={fundData?.img_url} 
                                                        // src={`${BASE_URL}${fundData?.amcLogoUrl}`}
                                                        src={`${BASE_URL}${ele?.amcLogoUrl}`}

                                                        height="50px" width="50px" style={{ padding: '5px' }} />
                                                    {ele?.schemeName}
                                                </Box>

                                            </TableCell>
                                            <TableCell>{ele?.lumpsumRet1year?.toFixed(2)}%</TableCell>

                                            <TableCell className={classes.tableBodylastCell}>{ele?.lumpsumRet2year?.toFixed(2)}%</TableCell>

                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                )}
                {/* <Box display="flex" justifyContent="center" mt={2}><Typography className={classes.viewAll} onClick={handle_viewAll}>{!viewAll ? "View All Holdings" : "View less"}</Typography></Box> */}

            </Box>
        </div>
    )
}

export default PeerComparison