import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const Privacy_Policys = () => {
  const useStyles = makeStyles({
    header: {
      fontSize: "1.8rem",
      fontWeight: 600,
      color: "#272727",
    },
    subTitle: {
      fontSize: "1.3rem",
      fontWeight: 500,
    },
    text: {
      fontSize: "0.9rem",
    },
  });
  const classes = useStyles();
  return (
    <div>
      <Box px={7} p={2}>
        <Typography className={classes.header}>Privacy Policys</Typography>

      
        <Box mt={4}>
                <ul>
                  <li>
                    Under no circumstances will we sell or rent your personal information to anyone, for any reason, at any time.

                  </li>
                  <li>
                    MF Wealth shall use your data specifically for the provision of Services you have subscribed to, and in accordance with the Privacy Policy set out in this Agreement.

                  </li>
                  <li>
                    You provide your explicit consent to share your data across the entities comprising the  MF Wealth to the extent required for provision of the Services, and as may be required to comply with any regulatory requirements.

                  </li>
                  <li>
                    Where the Services under this Agreement are being availed together as a family by multiple Clients, each Client provides explicit consent to MF Wealth to share their data with the other Clients who are signatories under this Agreement for the purpose of provision of Services.

                  </li>
                  <li>
                    MF Wealth may use the unique identifier of your device for security purposes to track suspicious activity and for enabling certain features. For instance, if we detect that your account is being accessed from multiple devices, we may contact you to ensure that you have authorised such access.

                  </li>

                </ul>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>
            Fiduciary Duties and Fairness of Charges In providing you its Services, MF Wealth will:
          </Typography>
          <ul className={classes.text}>
            <li>
              Put your best interests first;
            </li>
            <li>
              Act with prudence, that is, with the skill, care, diligence and good judgment of a professional;

            </li>
            <li>
              Provide conspicuous, full and fair disclosure of all important facts and not mislead you in any manner;

            </li>
            <li>
              Avoid conflicts of interest; and

            </li>
            <li>
              Fully disclose and fairly manage, in your favour, any unavoidable conflicts.

            </li>
            <li>

              Limitation of Liability You will not dispute and/or hold MF Wealth responsible for:

            </li>
            <ul>
              <li>
                Any act that is not an obligation of MF Wealth in this Agreement.

              </li>
              <li>
                Any disclosures made by MF Wealth to any statutory body under any law.
              </li>
              <li>
                Any loss, notional or otherwise, incurred because of:

              </li>
              <ul>
                <li>
                  Delays either at the bank, bank payment systems, Registrar and Transfer Agency or any Manufacturer or any other similar agencies or entities;

                </li>
                <li>
                  Rejection of your instructions by the Bank, Registrar and Transfer Agency or any Manufacturer;

                </li>
                <li>
                  Providing access to information and/or processing of instructions authenticated by your Login Credentials;

                </li>
                <li>
                  Non-availability or non-accessibility of the website, mobile application, electronic payment gateway, telephone(s), or office(s) of MF Wealth for reasons including those beyond MF Wealth’s control;

                </li>
                <li>
                  Any penalties, loss or damage to you which is due to the negligence or actions of any Service Partner of MF Wealth and not caused by any action of MF Wealth;

                </li>
                <li>
                  Any act of Force Majeure (as defined hereunder);

                </li>
                <li>
                  Any inaccuracy or typographical error on our website or the mobile application or any written, E-mail & SMS communication. MF Wealth specifically disclaims any liability for such inaccuracies or errors;

                </li>
                <li>
                  Depletion of value of securities or Products as a result of fluctuation in value of the securities / Products or on account of non-performance or underperformance of the securities or Products or any other market conditions; or

                </li>
                <li>
                  Any act, omission or delay attributed to you or any Service Partners resulting in MF Wealth not being able to provide the Services to you.

                </li>
              </ul>

            </ul>
            <li>
              You further acknowledge and agree that, to the maximum extent permitted by law, neither MF Wealth nor any of its Service Partners or Vendors will be liable for any incidental, special, exemplary or consequential damages, including loss of profits, loss of data or loss of goodwill, service interruption or in connection with the terms of this Agreement, as a result of your use of Services.

            </li>
            <li>
              MF Wealth Intellectual Property

            </li>
            <ul>
              <li>
                MF Wealth owns all intellectual property rights in respect of the Services including any literature, reports, data, drawings, copyrights, designs, diagrams, tables, software, source code or object code or other information or materials, howsoever stored or held, acquired, created, developed, designed or in any way prepared, by MF Wealth. The “MF Wealth” name and logos and all related names, trademarks, service marks, design marks, and slogans are the trademarks or service marks of Infiwealth Financial Services LLP

              </li>
              <li>
                Using our Services does not give you ownership of any intellectual property rights, including any branding or logos, in our Services or the content you access. You shall not copy, reproduce, sell, redistribute, publish, enter into a database, display, perform, modify, transmit, license, create derivatives from, transfer or in any way exploit any part of any information, content, materials, services available from or through MF Wealth, except that which you may download for personal, non‐commercial use. You may not use content from our Services unless you obtain our permission first, and attribute ownership appropriately.

              </li>
              <li>
                You may not use the MF Wealth logo in any manner that is deceptive or disparaging, or in connection with any product or service that MF Wealth does not explicitly endorse.

              </li>
            </ul>
            <li>
              Modification and Termination of Services We are constantly changing and improving our Services. We may add or remove functionalities or features, and / or we may stop a Service altogether, either due to a management decision or regulatory restrictions. In each case, we will give you reasonable advance notice. In case you wish to discontinue using the Services of MF Wealth, you may terminate your subscription in accordance with the termination provisions under the applicable Subscription Agreement. Even upon termination of the Subscription Agreement, the terms of this Agreement, to the extent applicable, shall continue to remain in force.

            </li>
            <li>
              General Provisions

            </li>
            <ul>
              <li>
                Force Majeure: The parties hereby agree that the period of time during which MF Wealth is prevented or delayed in the performance or fulfilling any obligation hereunder, due to unavoidable delays caused by an event of Force Majeure shall be added to MF Wealth’s time for performance thereof; and MF Wealth shall have no liability by reason thereof. In such an event MF Wealth agrees and endeavours to fulfil its obligations under this agreement as expeditiously as possible upon ceasing of such event of Force Majeure. A “Force Majeure” event for the purposes of this Agreement shall include an act of God, flood, earthquake, fire, explosion, strike, war, civil commotion, insurrection, embargo, riots, political disturbances, lockouts, epidemics, pandemics, any act, law, regulation or order of the government, systemic electrical, telecommunications, network or other utility failures affecting MF Wealth to render services, including failure on account of a cyber threat / risk.

              </li>
              <li>
                Severability: If any provision of this Agreement is held by a court of competent jurisdiction, or arbitration panel, as applicable, to be unenforceable under applicable law, then such provision shall be excluded from this Agreement and the remainder of this Agreement shall be interpreted as if such provision were so excluded and shall be enforceable in accordance with its terms; provided however that, in such event this agreement shall be interpreted so as to give effect, to the greatest extent consistent with and permitted by applicable law, to the meaning and intention of the excluded provision as determined by such court of competent jurisdiction or arbitration panel, as applicable.

              </li>
              <li>
                Further Assurance: Each of the parties shall perform such further acts and execute such further documents as may reasonably be necessary to carry out and give full effect to the provisions of this Agreement and the intentions of the parties as reflected hereby.

              </li>
              <li>
                Assignment: Neither You or MF Wealth may assign or otherwise transfer the rights under this Agreement without written consent of the other.

              </li>
            </ul>
          </ul>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>
            DESCRIPTION OF SERVICES OF MF Wealth</Typography>
          <p className={classes.text}>MF Wealth has the capability to provide the following services as per the consent and requirement of the Client. The specific set of Services provided to You shall depend upon your specific requirements and consents.</p>
          <ol className={classes.text}>
            <li>
              Data aggregation - Aggregating & consolidating data from multiple sources & providers.
            </li>
            <li>
              Risk Analysis & Asset Allocation - Risk Profiling based on Willingness and Ability as well as Asset Allocation as per the risk profile.
            </li>
            <li>
              Financial Awareness - Personal finance educational content & blog.
            </li>
            <li>
              Mutual funds Services - Incidental advice on Existing Mutual Fund Investments, Mutual funds analysis, rating & suggestions, Periodic mutual fund portfolio review & rebalancing.
            </li>
            <li>
              Servicing & Reporting - Mobile App & Web App
            </li>
          </ol>
        </Box>
        {/* <Box mt={4}>
          <Typography className={classes.subTitle}>
            Links to other Sites
          </Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              The Website/Services may contain links, information, or pointers
              to other websites or services for payments, information
              verifications, information processing, transaction processing, or
              other required / appropriate services; however, you should not
              infer or assume that Bullsurge operates, controls, manages or is
              otherwise connected with these other websites. When you click on a
              link within the Website, Bullsurge may or may not notify you that
              you have left the Website and are subject to the terms and
              conditions, disclaimers (including privacy policies), and other
              policies of another website or its partners & service providers.
              Please read the terms of service and privacy policies of any other
              website before providing any private information or engaging in
              any transactions. You should not depend on Bullsurge's terms to
              govern your usage of another website. Bullsurge is not responsible
              for the content or practises of any other website, even if it
              connects to it or is managed by a firm affiliated with or
              otherwise linked with Bullsurge. You recognise and agree that
              Bullsurge is not responsible or liable to you for any information
              or other materials stored or supplied by websites other than the
              Website. Bullsurge reserves the right to charge customers a fee
              for using its Websites and services, as detailed in the Fees and
              Charges section of the Website. Bullsurge has the right to alter
              the price structure at any moment at its sole discretion, and you
              will be notified properly in advance of any such change in the
              cost structure. Any costs and fees are exclusive of taxes, and you
              are responsible for all government taxes, including but not
              limited to GST. All fees must be non-transferable.
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.text}>
              All payments must be made by Credit Card, Debit Card, UPI, Mobile
              Wallet, Net Banking, OTM, e-mandate / Bank Mandate, or any other
              means specified by Bullsurge from time to time.
            </Typography>
            <Typography className={classes.text}>
              I / We have received no rebates or gifts, and we have not been
              persuaded to do so.
            </Typography>
            <Typography className={classes.text}>
              I confirm that to invest in any units of the plan, I have read and
              understood the Scheme Information Documents / Statement of
              Additional Information. I have also read and comprehended the
              scheme's terms, conditions, details, rules, and regulations.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Confidentiality </Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              You agree that, as a result of your connection with Bullsurge and
              use of the Services, you may acquire information about the
              Services and/or Bullsurge ("Proprietary Information").
            </Typography>
            <Typography className={classes.text}>
              Such Proprietary Information shall be solely owned by Bullsurge
              and shall include, but is not limited to, the features and mode of
              operation of the Services, trade secrets, know-how, inventions
              (whether or not patentable), techniques, processes, programmes,
              ideas, algorithms, schematics, testing procedures, software design
              and architecture, computer code, internal documentation, design
              and function-specific information, and so on. Concerning this
              Proprietary Information:
            </Typography>
            <ol>
              <li className={classes.text}>
                You may not use (save as specifically permitted by this
                Agreement) or disclose Proprietary Information without the prior
                written approval of Bullsurge unless such Proprietary
                Information becomes generally publicly available as a result of
                your breach of this Agreement.
              </li>
              <li className={classes.text}>
                You undertake to take reasonable steps to keep the Proprietary
                Information and Services private.
              </li>
            </ol>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>
            Use and Protection of Intellectual Property Rights
          </Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              Copyright, trademarks, patents, trade secrets, and/or other
              related intellectual property laws protect the Bullsurge
              Application. Without the explicit written consent of Bullsurge, no
              information, content, or material from the Application may be
              copied, reproduced, republished, uploaded, posted, communicated,
              or distributed in any way. Subject to your acceptance of these
              Terms, you are granted a limited licence to use the Application
              for personal and non-commercial purposes. You undertake not to
              sell, licence, distribute, reproduce, alter, publicly perform or
              display, transmit, publish, edit, adapt, create derivative works
              from, or otherwise use the Bullsurge Application illegally.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Privacy</Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              You acknowledge that when using and accessing the Website and/or
              using the Services provided by the Website, you will supply Us
              with certain information and other data as specified in these
              Terms, which may or may not be otherwise publicly available.
              Please be aware that We respect the privacy and confidentiality of
              such data, and the terms relating to such private information and
              data as given by You under these Terms are regulated by the
              Website's Privacy Policy, which may be found at{" "}
              <a href="https://www.bullsurge.in/privacy-policy" target="_blank">
                https://www.bullsurge.in/privacy-policy
              </a>
              . By using and accessing the Website and using its Services, you
              also agree to the terms and conditions of our Privacy Policy.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>
            Limitation of Liability, Indemnity, and Warranty
          </Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              In no event will Bullsurge or its directors, employees,
              associates, partners, or suppliers be liable to you for any loss
              or damage resulting from or concerning these terms and conditions
              or use of this website or investments made using this Website or
              availing of any product or services from any third-party service
              provider. You agree to indemnify Bullsurge or its directors,
              employees, associates, partners, or suppliers for all liabilities
              (including claims, damages, suits, or legal expenses incurred in
              defending itself about the foregoing) arising from I use or misuse
              of the Website, (ii) non-performance and/or non-observance of the
              duties and obligations under these terms and conditions, or due to
              your acts or omissions. You represent and declare that any data
              and information gave by you to Bullsurge or its directors,
              employees, affiliates, partners, or suppliers when using this
              Website (including to make investments) are complete, accurate,
              and authentic. Furthermore, you will be entirely responsible for
              any investment choice you make using the Services, and Bullsurge
              will not be liable for any loss or damage you or other users of
              this Website may suffer as a result of such an investment decision
              or any reliance on it. You expressly acknowledge and accept that
              your use of the Website is at your own risk.
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography className={classes.text}>
              BBullsurge and its directors, employees, associates, third-party
              partners, or suppliers disclaim all warranties or guarantees,
              whether statutory, express, or implied, including, but not limited
              to, implied warranties of merchantability, fitness, and
              non-infringement of proprietary rights, to the fullest extent
              permissible under applicable law. You may not rely on any advice
              or information, whether oral or written, acquired from Bullsurge
              or via the Website to generate any warranty or guarantee not
              specifically contained in this document.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Governing Laws</Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              The Terms of Use shall be regulated and construed following Indian
              law, without regard to considerations of conflict of laws. All
              disputes arising out of or in connection with shall be subject to
              the exclusive jurisdiction of the High Court Mumbai.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Communication</Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              Bullsurge may send You alerts and messages by e-mail, SMS, push
              notifications, conventional mail, or posts on the App/Website,
              among other methods. Notices to Bullsurge must be submitted by
              courier or registered mail to [legal@Bullsurge.com] unless
              otherwise specified below.
            </Typography>
            <Typography className={classes.text}>
              All notices, information, and other correspondence from Bullsurge
              will be sent to you through email or SMS at the email
              address/telephone number given by the User. If you receive any
              notices, information, or other communication by mail, it will be
              delivered to the postal address you have provided as your
              Communication address. If your email address, cellphone number, or
              correspondence address changes, you must notify Bullsurge in
              advance in the specified format so that it can make the necessary
              adjustments in its records.
            </Typography>
            <Typography className={classes.text}>
              Bullsurge makes no assurances that electronic messages will be
              transmitted properly or that they will be secure or virus-free.
              Bullsurge is not responsible for any loss, damage, expense, harm,
              or annoyance resulting from an email being lost, delayed,
              intercepted, corrupted, or otherwise changed, or for failing to be
              delivered for any reason beyond our reasonable control. All
              communications will be conducted in English.
            </Typography>
            <Typography className={classes.text}>
              You consent to be contacted by Bullsurge and its agents regarding
              the Services by phone or registered email id. You acknowledge and
              accept that if your phone number is on the TRAI's Do Not Disturb
              (DND) list, you will not receive SMS from Bullsurge. You agree to
              take measures to remove yourself from the DND list, and you agree
              not to hold Bullsurge responsible for any SMS you do not get
              during this time.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Severability</Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              If any part of this Agreement is found to be illegal, void,
              invalid, or otherwise unenforceable, that provision will be
              reduced or deleted to the extent necessary, with the remaining
              sections being valid and enforceable.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Survival</Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              Upon termination or expiration of this Agreement, any provision
              that, by its nature or stated terms, should remain shall continue
              as applicable to transfers and relationships made before such
              termination or expiration.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Assignment</Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              You can not assign or transfer the Terms of Use or any rights
              granted herein to any other party. The Company's rights under the
              Terms of Service are freely transferable by the Company to any
              third party without Your approval.
            </Typography>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography className={classes.subTitle}>Force Majeure</Typography>
          <Box mt={1}>
            <Typography className={classes.text}>
              If the fulfilment of the parties' respective duties under this
              agreement is prohibited or delayed in whole or in part due to
              Natural disasters, war, arson, civil unrest, unavailability of any
              communication system, including the Internet, breach or virus in
              the processes or payment mechanism, sabotage, acts of government,
              computer hacking, unauthorised access to computer data and storage
              devices, computer crashes, breach of security and encryption
              codes, and other factors beyond a person's reasonable control
              (each a "Force Majeure Event"), then to the extent that either
              party is prevented or delayed from performing all or any part of
              their respective obligations under this Agreement despite due
              diligence and reasonable efforts to do so, such party shall be
              excused from performance hereunder for the duration of the Force
              Majeure Event. A Force Majeure Event shall not impact the parties'
              payment obligations unless there is a legal prohibition/embargo on
              making the payments.
            </Typography>
          </Box>
        </Box> */}
      </Box>
    </div>
  );
};

export default Privacy_Policys;
