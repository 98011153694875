import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid, Slider, CircularProgress } from "@material-ui/core";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { POST_GOALS_CALCULATE_TAX_SAVER } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import { namespace, BASE_URL } from "../../environment";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList

} from "recharts";
import ShowRecommendedFunds from "./ShowRecommendedFunds";


const ScrollingNumber = ({ value, time }) => {
    const [displayedValue, setDisplayedValue] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            if (displayedValue < value) {
                setDisplayedValue(prevValue => prevValue + 1);
            } else if (displayedValue > value) {
                setDisplayedValue(prevValue => prevValue - 1);
            }
        }, time); // Adjust speed of animation as needed

        return () => clearInterval(interval);
    }, [displayedValue, value]);
    console.log(value);

    return <span>{displayedValue}</span>;
};



const TaxSavings = () => {
    const useStyles = makeStyles((theme) => ({
        root: {},
        mainGrid: {
            padding: "30px 4rem 0px 8rem",
        },
        subTabtext: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
        },
        textUnderline: {
            fontSize: "0.7rem",
            textDecoration: "underline",
            fontFamily: "inherit",
        },
        text: {
            fontSize: "0.8rem",
            fontWeight: 700,
        },
        subText: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
            paddingLeft: '5px',
        },
        text1: {
            fontSize: "0.8rem",
        },
        text2: {
            fontSize: "1.2rem",
            fontFamily: "inherit",
            fontWeight: 500,
            // paddingLeft: '5px', 
        },
        text3: {
            fontSize: "1.2rem",
            fontFamily: "inherit",
            fontWeight: 700,
            // paddingLeft: '5px', 
        },
        textBold: {
            fontSize: "0.8rem",
            fontWeight: 700,
            marginBottom: "1rem",
        },

        thumb: {
            background: "#bdbdbd",
            width: "20px",
            height: "20px",
            boxShadow: 'none !important',
            ' &.Mui-focusVisible': {
                boxShadow: 'none !important',
            },
            '&.Mui-active': {
                boxShadow: 'none !important',
            },
            // boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
        },
        mark: {
            background: "black"
        },
        rail: {
            background: "#EBEBEB",
            height: "10px",
            borderRadius: '10px',
        },
        track: {
            background: "#0E76BC",
            height: "10px",
            borderRadius: '10px',
        },
        borderBox: {
            // border: "1px solid #E4E4E4",
            // borderRadius: "5px",
            // padding: "1.5rem",
            // width: "75%",
            '& .MuiSlider-valueLabel': {
                '& > *': {

                    backgroundColor: 'white',
                    color: "black"
                },
            }
        },
        rupees: {
            fontFamily: "Open Sans"
        },
        primaryButton: {
            padding: "0.8rem 5rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            fontFamily: "inherit"

        },
        "@media (max-width: 767px)": {
            mainGrid: {
                padding: "0px 10px 0px 10px",
            },
        },
    }));
    const classes = useStyles();
    const location = useLocation();

    const [goalsSelection, setGoalsSelection] = useState(null)
    const [sliderValueOnChangeEnd, setSliderValueOnChangeEnd] = useState(2000)
    const [sliderValueOnChangeEndYear, setSliderValueOnChangeEndYear] = useState(3)
    const [loading, setLoading] = useState(false)
    const [calculatedMyFirstCroreData, setCalculatedMyFirstCroreData] = useState(null);
    const [showBarchart, setShowBarChart] = useState(false);
    const [showRecommendedFundsPage, setShowRecommendedFundsPage] = useState(false)

    const navigate = useNavigate();


    // calculate my first corre post request 
    const CalculateMyFirstCrore = async () => {
        setLoading(true)
        const formData = {
            investment_per_month: sliderValueOnChangeEnd,
            years: sliderValueOnChangeEndYear,
        }
        const httpConfig1 = {
            method: "POST",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            // params: { isin: fundData?.isinCode },
            data: formData
        };

        try {
            const response = await httpInstance(`${POST_GOALS_CALCULATE_TAX_SAVER}`, httpConfig1);
            console.log(response?.data, 'fadfadfadsfasdfasdfasdf')

            setCalculatedMyFirstCroreData(response?.data);
            setShowBarChart(true)
        } catch {

        } finally {
            setLoading(false)

        }
    }

    // CustomBar Label
    const CustomBarLabel = ({ fill, ...props }) => (
        <g transform={`translate(${props.x},${props.y})`}>
            <text fill={fill} fontSize={10} textAnchor="middle" dominantBaseline="middle" dy={-10} dx={10} >
            ₹{formatNumber(props.value.toFixed(2))}
            </text>
        </g>
    );

    //As the slider change in the area 

    useEffect(() => {
        if (showBarchart) {
            CalculateMyFirstCrore();
        }
    }, [sliderValueOnChangeEnd])

    //See recomendation function call after graph 

    const SeeRecommendedFunds = () => {
        setShowRecommendedFundsPage(true)
    }


    //show lakhs cr valus shorted 
    const formatNumber = (value) => {
        if (value < 1000) {
            return value.toString();
        } else if (value < 100000) {
            return (value / 1000).toFixed(1) + ' ' + 'K';
        } else if (value < 10000000) {
            return (value / 100000).toFixed(1) + ' ' + 'Lakh';
        } else {
            return (value / 10000000).toFixed(1) + ' ' + 'Crore';
        }
        // if (value < 1000) {
        //     return value.toString();
        // } else if (value < 100000) {
        //     console.log(<ScrollingNumber value={value / 1000} time={3} />?.props.children);
        //     return <ScrollingNumber value={value / 1000} time={3} /> + 'K';
        // } else if (value < 10000000) {
        //     return <ScrollingNumber value={value / 100000} time={2} /> + 'Lakh';
        // } else {
        //     return <ScrollingNumber value={value / 10000000} time={1} /> + 'Crore';
        // }
    };



    return (
        <div >
            {!showBarchart ? (
                <>
                    < Typography className={classes.text2} style={{ textAlign: 'center', paddingBottom: '10px' }}> Tax Saivngs </Typography>
                    {/* <ScrollingNumber value={7} /> */}
                    <Box>
                        < Typography className={classes.text3} style={{ marginTop: '5px' }}>Maxmize Return, Minimize taxes: Invest in Tax-Saving Funds!</Typography>

                        < Typography className={classes.text1} style={{ marginTop: '5px' }}>How much you want to invest monthly?</Typography>

                        <Box mt={8} px={3} className={classes.borderBox}>
                            <Slider
                                classes={{
                                    thumb: classes.thumb,
                                    rail: classes.rail,
                                    track: classes.track,
                                    valueLabel: classes.valueLabel,
                                    mark: classes.mark
                                }}
                                defaultValue={2000}
                                aria-label="Default"
                                valueLabelDisplay="on"
                                size="medium"
                                valueLabelFormat={(x) => {
                                    return (
                                        <p style={{ paddingLeft: '10px', color: "black" }}><span className={classes.rupees}>₹</span>{x.toLocaleString('en-IN')}</p>
                                    )
                                }}
                                min={2000}
                                max={50000}
                                // onChange={(e, value) => return_calculator(value)}
                                onChangeCommitted={(e, value) => {
                                    // console.log("Slider value after release:", value);
                                    // sliderAmountChangeIntheReturnCalculator(value)
                                    setSliderValueOnChangeEnd(value)
                                    // Add any other logic you want to perform after the slider is released
                                }}
                            />

                        </Box>
                        <Box px={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                                variant="body2"
                            //   onClick={() => setVal(MIN)}
                            //   sx={{ cursor: 'pointer' }}
                            >
                                <span className={classes.rupees}>₹</span>
                                2000
                            </Typography>
                            <Typography
                                variant="body2"
                            //   onClick={() => setVal(MAX)}
                            //   sx={{ cursor: 'pointer' }}
                            >
                                <span className={classes.rupees}>₹</span>
                                50000
                            </Typography>
                        </Box>
                        < Typography className={classes.text1} style={{ marginTop: '5px' }}>For what period you want to invest?</Typography>

                        <Box mt={8} px={3} className={classes.borderBox}>
                            <Slider
                                classes={{
                                    thumb: classes.thumb,
                                    rail: classes.rail,
                                    track: classes.track,
                                    valueLabel: classes.valueLabel,
                                    mark: classes.mark
                                }}
                                defaultValue={3}
                                aria-label="Default"
                                valueLabelDisplay="on"
                                size="medium"
                                valueLabelFormat={(x) => {
                                    return (
                                        <p style={{ paddingLeft: '10px', color: "black" }}><span className={classes.rupees}></span>{x.toLocaleString('en-IN')}Y</p>
                                    )
                                }}
                                min={3}
                                max={30}
                                // onChange={(e, value) => return_calculator(value)}
                                onChangeCommitted={(e, value) => {
                                    // console.log("Slider value after release:", value);
                                    // sliderAmountChangeIntheReturnCalculator(value)
                                    setSliderValueOnChangeEndYear(value)
                                    // Add any other logic you want to perform after the slider is released
                                }}
                            />

                        </Box>
                        <Box px={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                                variant="body2"
                            //   onClick={() => setVal(MIN)}
                            //   sx={{ cursor: 'pointer' }}
                            >
                                {/* <span className={classes.rupees}>₹</span> */}
                                3Y
                            </Typography>
                            <Typography
                                variant="body2"
                            //   onClick={() => setVal(MAX)}
                            //   sx={{ cursor: 'pointer' }}
                            >
                                {/* <span className={classes.rupees}>₹</span> */}
                                30Y
                            </Typography>
                        </Box>
                        < Typography className={classes.subText} style={{ marginTop: '5px' }}>Your profile is verified and these details are non-editable, in case any questions please contact support team.</Typography>

                    </Box>
                    <Box mt={3} display='flex' justifyContent='center' alignItems='center'>
                        <button onClick={CalculateMyFirstCrore} className={classes.primaryButton}>
                            {loading ? (
                                <CircularProgress size="1rem" color="inherit" />
                            ) : (
                                "Calculate Now"
                            )}</button>
                    </Box>
                </>
            ) : (
                <>
                    {showRecommendedFundsPage ? (
                        <ShowRecommendedFunds />

                    ) : (




                        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                            < Typography className={classes.text1} style={{ marginTop: '5px' }}>Discover How Much You Can Save & Reach Your Financial Goals with Ease!</Typography>
                            <Grid container>
                                <Grid item xs={5} sm={3} md={3} lg={3} style={{ textAlign: 'center' }}>
                                    < Typography className={classes.text1} style={{ marginTop: '5px' }}>Duration</Typography>

                                    < Typography className={classes.text3} style={{ marginTop: '5px' }}><ScrollingNumber value={calculatedMyFirstCroreData?.duration} time={50} /> {" "} Years</Typography>

                                </Grid>
                                <Grid item xs={5} sm={3} md={3} lg={3} style={{ textAlign: 'center' }}>
                                    < Typography className={classes.text1} style={{ marginTop: '5px' }}>Invested</Typography>

                                    < Typography className={classes.text3} style={{ marginTop: '5px' }}><span className={classes.rupees}>₹</span>{formatNumber(calculatedMyFirstCroreData?.invested)}  </Typography>
                                    {/* <Typography className={classes.text3} style={{ marginTop: '5px' }}>
                                        <span className={classes.rupees}>₹</span>
                                        {formatNumber(<ScrollingNumber value={calculatedMyFirstCroreData?.invested} time={3} />)}
                                    </Typography> */}
                                    {console.log(<ScrollingNumber value={calculatedMyFirstCroreData?.invested} time={3} />)}
                                </Grid>
                                <Grid item xs={5} sm={3} md={3} lg={3} style={{ textAlign: 'center' }}>
                                    < Typography className={classes.text1} style={{ marginTop: '5px' }}>Gain</Typography>

                                    < Typography className={classes.text3} style={{ marginTop: '5px' }}><span className={classes.rupees}>₹</span>{formatNumber(calculatedMyFirstCroreData?.profit)} </Typography>

                                </Grid>
                                <Grid item xs={5} sm={3} md={3} lg={3} style={{ textAlign: 'center' }}>
                                    < Typography className={classes.text1} style={{ marginTop: '5px' }}>Maturity Value</Typography>

                                    < Typography className={classes.text3} style={{ marginTop: '5px' }}><span className={classes.rupees}>₹</span>{formatNumber(calculatedMyFirstCroreData?.total)}</Typography>
                                </Grid>
                            </Grid>

                            {/* < Typography className={classes.text3} style={{ marginTop: '5px' }}>{calculatedMyFirstCroreData?.assumed_return} </Typography> */}



                            {/* <Box display='flex' justifyContent='center' alignItems='center' style={{ maxWidth: '1000px', }} > */}
                            <ResponsiveContainer width={'100%'} height={270} >

                                <BarChart barGap={25}
                                    //  width={900} height={250} 
                                    data={calculatedMyFirstCroreData?.investment_graph}
                                    margin={{ top: 50, right: 30, left: 20, bottom: 5 }}
                                    // style={{overflow: 'scroll'}}
                                    // padding={{ top: 10, right: 10, left: 10, bottom: 10 }}
                                    barCategoryGap={35} // Adjust the value as needed to control the gap between bars

                                >
                                    <XAxis dataKey="year" xAxisId={0} allowDuplicatedCategory={false}
                                        //yAxisId="left"
                                        // dataKey="baseValue"
                                        // tick={false}
                                        tick={{ fontSize: 12 }}  // Set the font size for X-axis ticks
                                        label={{ fontSize: 14 }}
                                        axisLine={false} />
                                    <XAxis dataKey="year" xAxisId={1} hide allowDuplicatedCategory={false}
                                        //yAxisId="left"
                                        // dataKey="baseValue"
                                        label={false}
                                        tick={false}
                                        // tick={null}  // Remove X-axis ticks
                                        // label={null} // Remove X-axis label
                                        // scale="auto"
                                        axisLine={false}
                                    />
                                    <YAxis allowDuplicatedCategory={false}
                                        //yAxisId="left"
                                        // dataKey="baseValue"
                                        label={false}
                                        tick={false}
                                        // tick={null}  // Remove X-axis ticks
                                        // label={null} // Remove X-axis label
                                        // scale="auto"
                                        hide
                                        axisLine={false}
                                    />
                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                    <Tooltip />
                                    {/* <Legend /> */}

                                    <Bar dataKey="total" barSize={30} xAxisId={1} fill="#0E76BC" fillOpacity={1}>
                                        <LabelList dataKey="total" position="top" fill='#0E76BC' content={<CustomBarLabel fill="#ff0000" />} offset={10} />
                                    </Bar>

                                    <Bar dataKey="invested_amount" barSize={30} xAxisId={0} fill="#ccc" />


                                </BarChart>
                            </ResponsiveContainer>
                            {/* </Box> */}
                            <Box width={'100%'}>
                                <Box mt={8} px={3} className={classes.borderBox} >
                                    <Slider
                                        classes={{
                                            thumb: classes.thumb,
                                            rail: classes.rail,
                                            track: classes.track,
                                            valueLabel: classes.valueLabel,
                                            mark: classes.mark
                                        }}
                                        defaultValue={2000}
                                        aria-label="Default"
                                        valueLabelDisplay="on"
                                        size="medium"
                                        valueLabelFormat={(x) => {
                                            return (
                                                <p style={{ paddingLeft: '10px', color: "black" }}><span className={classes.rupees}>₹</span>{x.toLocaleString('en-IN')}</p>
                                            )
                                        }}
                                        min={2000}
                                        max={50000}
                                        // onChange={(e, value) => return_calculator(value)}
                                        onChangeCommitted={(e, value) => {
                                            console.log("Slider value after release:", value);
                                            // sliderAmountChangeIntheReturnCalculator(value)
                                            setSliderValueOnChangeEnd(value)
                                            // Add any other logic you want to perform after the slider is released
                                        }}
                                    />

                                </Box>
                                <Box px={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography
                                        variant="body2"
                                    //   onClick={() => setVal(MIN)}
                                    //   sx={{ cursor: 'pointer' }}
                                    >
                                        <span className={classes.rupees}>₹</span>
                                        2000
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                    //   onClick={() => setVal(MAX)}
                                    //   sx={{ cursor: 'pointer' }}
                                    >
                                        <span className={classes.rupees}>₹</span>
                                        50000
                                    </Typography>
                                </Box>
                                < Typography className={classes.text1} style={{ marginTop: '5px', textAlign: 'center' }}>Assuming returns of {calculatedMyFirstCroreData?.assumed_return} %</Typography>

                            </Box>
                            <Box mt={3} display='flex' justifyContent='center' alignItems='center'>
                                <button onClick={SeeRecommendedFunds} className={classes.primaryButton}>
                                    {loading ? (
                                        <CircularProgress size="1rem" color="inherit" />
                                    ) : (
                                        "See Recommended Funds"
                                    )}</button>
                            </Box>
                        </Box>
                    )}
                </>
            )}

        </div >
    );
};

export default TaxSavings;
