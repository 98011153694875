import React, { useEffect, useState } from 'react'
import { Box, Container, makeStyles, Typography, Select, FormControl } from "@material-ui/core";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,

} from "recharts";
import moment from "moment";
import { GET_NAV_HISTORY } from '../../utils/endpoints'
import httpInstance from '../../utils/instance'
import ReactLoading from 'react-loading'

const FundGraph = ({ code }) => {
  const useStyles = makeStyles((theme) => ({
    graphButtonInactive: {
      border: "none",
      background: "none",
      padding: "5px",
      borderRadius: "10px",
      fontSize: "0.7rem",
      cursor: "pointer",
      outline: "none",
      "&:hover": {
        background: "#6FDDB8",
        color: "white",
        padding: "5px",
      },
      "&:active": {
        background: "#6FDDB8",
        color: "white"
      }
    },

    activeButton: {
      background: "#6FDDB8",
      color: "white",
      padding: "5px",
      border: "none",
      borderRadius: "10px",
      fontSize: "0.7rem",
      cursor: "pointer",
      outline: "none",
    },

    tootipBox: {
      boxShadow: "0px 4.45122px 4.45122px rgba(0, 0, 0, 0.12)",
      borderRadius: "13px",
      background: "white",
    },

    tooltipText: {
      fontSize: "0.7rem",
      marginBottom: "3px",
      padding: "0px 5px",
    },

    livetext: {
      fontSize: "1.1rem",
      color: "#0E76BC",
      fontFamily: "inherit"

    },
    rupees: {
      fontFamily: "Open Sans"
    },
    spinner: {
      height: "250px"
    }
  }));

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box display="flex" className={classes.tootipBox}>
          <p className={classes.tooltipText}>
            {" "}
            NAV: <span className={classes.rupees}>₹</span> {payload[0].payload.nav}
          </p>
          <p className={classes.tooltipText}>
            {moment(payload[0].payload.navDate).format("DD MMM YYYY")}
          </p>
        </Box>
      );
    }

    return null;
  };

  const formattedDate = (date) => {
    try {
      const parsedDate = new Date(date);
      if (isNaN(parsedDate)) {
        throw new Error("Invalid date string");
      }
      return parsedDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
    } catch (error) {
      console.error("Error parsing date:", error.message);
      return "Invalid Date"; // Return a default value or handle the error appropriately
    }

  }


  const classes = useStyles();

  const [spinner, setSpinner] = useState(true)
  const [button, setButton] = useState(365)
  const [data, setData] = useState()

  useEffect(() => {
    get_filteredData(365)
  }, [])



  const get_filteredData = async (range) => {
    // console.log(range)
    setButton(range)
    setSpinner(true)
    // console.log(range)
    const fromDate = moment().subtract(range, 'months').format('YYYY-MM-DD')
    // console.log(fromDate)
    const formData = {
      scheme_code: code,
      from: fromDate,
      to: moment().format('YYYY-MM-DD'),
      frequency: range < 13 ? "day" : "week"
    }
    const httpConfig = {
      method: "GET",
      // tokenType: "user",
      test: 'test',
      // headers: {
      //   "Content-Type": "application/json",
      // },
      // data:formData
    };

    try {
      const response = await httpInstance(`${GET_NAV_HISTORY}?fundPlanId=${code}&duration=${range}`, httpConfig)
      // console.log(response.data.data)
      const navData = response.data?.data?.map((ele, i) => {
        return { ...ele, xid: i }
      })

      setData(navData)
      setSpinner(false)

    }
    catch {

    }
  }

  return (
    <div>
      <Box py={10}>
        <Box pr={5} >
          {spinner ?
            <Box display="flex" justifyContent="center" className={classes.spinner}><ReactLoading type="spinningBubbles" color="	#0E76BC" height={'5%'} width={'5%'} /></Box> :
            <ResponsiveContainer width={"100%"} height={250}>
              <LineChart
                width={700}
                height={200}
                connectNulls={true}
                data={data}


              >
                <Tooltip
                  content={<CustomTooltip />}
                  position={{ x: "auto", y: 0 }}
                />




                <Line
                  data={data}
                  type="monotone"
                  connectNulls={false}
                  dataKey="nav"
                  stroke={"#0E76BC"}
                  activeDot={{ r: 8, color: "#ef731f" }}
                  dot={false}
                  //className={props?.data?.amc?.amcShortName}
                  //type="number"
                  strokeWidth={2}

                />


                <XAxis
                  interval={0}
                  dataKey="xid"
                  //interval={0}
                  type="number"
                  tick={false}
                  tickLine={false}
                  axisLine={false}
                  domain={["dataMin", "dataMax + 1"]}
                  allowDuplicatedCategory={false}
                />


                <YAxis

                  allowDuplicatedCategory={false}
                  //yAxisId="left"
                  // dataKey="baseValue"
                  tick={false}
                  axisLine={false}
                  domain={["dataMin", "dataMax + 1"]}
                />


              </LineChart>
            </ResponsiveContainer>
          }
        </Box>
        {data &&
          <Box style={{ borderBottom: "1px solid #0D87F4", height: "30px" }} display="flex" justifyContent="space-between" mx={5}>
            <p className={classes.tooltipText}>{moment(data?.[0]?.navDate).format("DD MMM YYYY")}</p>
            {/* <p className={classes.tooltipText}>{formattedDate(data[0].navData)}</p> */}
            <p className={classes.tooltipText}>{moment(data[data?.length - 1].navDate).format("DD MMM YYYY")}</p>

          </Box>
        }
        <Box display="flex" justifyContent="space-between" px={15} mt={2}>
          <button
            // className={button==6 ? classes.activeButton : classes.graphButtonInactive}
            // onClick={()=>{get_filteredData(1)}}
            className={button == 30 ? classes.activeButton : classes.graphButtonInactive}
            onClick={() => { get_filteredData(30) }}
          >
            1M
          </button>
          <button
            // className={button==6 ? classes.activeButton : classes.graphButtonInactive}
            // onClick={()=>{get_filteredData(1)}}
            className={button == 90 ? classes.activeButton : classes.graphButtonInactive}
            onClick={() => { get_filteredData(90) }}
          >
            3M
          </button>
          <button
            // className={button==6 ? classes.activeButton : classes.graphButtonInactive}
            // onClick={()=>{get_filteredData(1)}}
            className={button == 180 ? classes.activeButton : classes.graphButtonInactive}
            onClick={() => { get_filteredData(180) }}
          >
            6M
          </button>
          <button
            // className={button==12 ? classes.activeButton :classes.graphButtonInactive}
            // onClick={()=>{get_filteredData(12)}}
            className={button == 365 ? classes.activeButton : classes.graphButtonInactive}
            onClick={() => { get_filteredData(365) }}
          >
            1Y
          </button>
          <button
            // className={button==36 ? classes.activeButton :classes.graphButtonInactive}
            // onClick={()=>{get_filteredData(36)}}
            className={button == 1095 ? classes.activeButton : classes.graphButtonInactive}
            onClick={() => { get_filteredData(1095) }}
          >
            3Y
          </button>
          <button
            // className={button==60 ? classes.activeButton :classes.graphButtonInactive}
            // onClick={()=>{get_filteredData(60)}}
            className={button == 1825 ? classes.activeButton : classes.graphButtonInactive}
            onClick={() => { get_filteredData(1825) }}
          >
            5Y
          </button>
          <button
            // className={button == "all" ? classes.activeButton : classes.graphButtonInactive}
            // onClick={()=>{get_filteredData(120)}}
            className={button == "1826" ? classes.activeButton : classes.graphButtonInactive}
            onClick={() => { get_filteredData(1826) }}
          >
            All
          </button>
        </Box>
      </Box>

    </div>
  )
}

export default FundGraph