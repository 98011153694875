import React, { useEffect, useState } from "react";
import {
  Typography,
  makeStyles,
  Box,
  Grid,
  Container,
} from "@material-ui/core";
import {
  POST_PAYMENT,
  SELL_GOLD_SILVER,
  GET_GOLD_SILVER_RATE,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate, Link } from "react-router-dom";
import ReactLoading from "react-loading";
import { namespace } from "../../../environment";

const OrderSummary = ({ handleOrderSummary, checkoutRate, orderData }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },

    subTab: {
      position: "fixed",
      width: "100%",
      background: "#fff",
    },

    card: {
      boxShadow: "0 0 8px -3px #444444",
      borderRadius: "10px",
    },
    borderRight: {
      borderRight: "3px solid #0E76BC",
    },
    rateText: {
      display: "flex",
      justifyContent: "center",
      fontSize: "1rem",
      fontFamily: "inherit",
      padding: "4px",
    },

    counterText: {
      fontSize: "1rem",
      fontFamily: "inherit",
      color: "#48D4A5",
    },

    text: {
      fontSize: "1rem",
      padding: "12px 0px",
      fontFamily: "inherit",
    },
    textBlue: {
      fontSize: "1rem",
      padding: "12px 0px",
      color: "#0E76BC",
      fontFamily: "inherit",
    },
    totalAmount: {
      width: "48%",
      boxShadow: "0 0 8px -3px #444444",
      borderRadius: "8px",
      padding: "0px 10px",
      fontFamily: "inherit",
    },

    gridWidth: {
      // width:"45%",
    },

    table: {
      width: "46%",
    },

    primaryButton: {
      padding: "0.8rem 5rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.9rem",
      fontWeight: 500,
      fontFamily: "inherit",
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      width: "100%",
    },

    tab: {
      fontSize: "0.7rem",
    },
    rightArrowIcon: {
      fontSize: "small",
    },
    textUnderline: {
      textDecoration: "underline",
      fontFamily: "inherit",
      paddingRight: "10px",
      margin: "1px",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    "@media (max-width: 480px)": {
      table: {
        width: "62%",
      },
      totalAmount: {
        width: "62%",
      },
    },
    "@media(max-width:600px)": {
      subTab: {
        position: "relative",
      },
    },
  }));
  const classes = useStyles();

  let navigate = useNavigate();

  const [goldRate, setGoldRate] = useState("");
  const [silverRate, setSilverRate] = useState("");
  const [goldSellRate, setGoldSellRate] = useState("");
  const [silverSellRate, setSilverSellRate] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [time, setTime] = React.useState({
    minutes: 5,
    seconds: 59,
  });
  const [expired, setExpired] = useState(false);

  const countDown = () => {
    if (time.minutes == 0 && time.seconds == 0) {
      setExpired(true);
    } else if (time.seconds == 0) {
      setTime({
        minutes: time.minutes - 1,
        seconds: 59,
      });
    } else {
      setTime({
        minutes: time.minutes,
        seconds: time.seconds - 1,
      });
    }
  };

  useEffect(() => {
    let timerID = setInterval(() => countDown(), 1000);
    return () => clearInterval(timerID);
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    var requestOptions = {
      method: "GET",
    };

    const httpConfig1 = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const allRates = await httpInstance(`${GET_GOLD_SILVER_RATE}`, httpConfig1);
    try {
      // const agm = await fetch(
      //   `https://www.augmont.com/api/digital-gold/rates`,
      //   requestOptions
      // );

      // const allRates = await agm.json();

      setGoldRate(allRates?.data?.data?.goldBuyRate);
      setGoldSellRate(allRates?.data?.data?.goldSellRate);
      setSilverRate(allRates?.data?.data?.silverBuyRate);
      setSilverSellRate(allRates?.data?.data?.silverSellRate);
    } catch (error) {}
  };
  //--------------------------payment success---------------------------------------------//
  const postPayment = async (response) => {
    const formData = {
      orderId: response.razorpay_order_id,
      paymentId: response.razorpay_payment_id,
      status: "success",
    };

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      await httpInstance(`${POST_PAYMENT}`, httpConfig);
      navigate(`${namespace}/digitalgold/payment-success`);
    } catch {}
  };
  //--------------------------post payment failed----------------------------------//
  const postPaymentFailed = async () => {
    const formData = {
      orderId: orderData.orderId,
      status: "failed",
    };

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      await httpInstance(`${POST_PAYMENT}`, httpConfig);
      navigate(`${namespace}/digitalgold/payment-failed`);
    } catch {}
  };

  const handlePayment = () => {
    var options = {
      key: orderData?.key,
      amount: orderData.amount,
      currency: "INR",
      name: orderData.name,
      description: orderData.description,
      //image: "https://example.com/your_logo",
      order_id: orderData?.orderId,

      prefill: {
        name: orderData.name,
        email: orderData?.email,
        contact: orderData?.contact,
      },
      config: {
        display: {
          hide: [
            {
              method: "wallet",
            },
          ],
          preferences: {
            show_default_blocks: true,
          },
        },
      },

      handler: function (response) {
        setSpinner(true);
        postPayment(response);
      },
      timeout: time.minutes * 60 + time.seconds - 2,

      modal: {
        ondismiss: function () {
          postPaymentFailed();
        },
      },
    };

    var rzp1 = new window.Razorpay(options);
    // rzp1.on('payment.failed', function (response){
    //   postPaymentFailed()
    // });
    rzp1.open();
  };

  const handleSell = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { order_id: orderData?.orderId },
    };

    try {
      await httpInstance(`${SELL_GOLD_SILVER}`, httpConfig);
      navigate(`${namespace}/digitalgold/sell-success`);
    } catch {}
  };
  return (
    <Box>
      {spinner ? (
        <Box
          display="flex"
          justifyContent="center"
          style={{ height: "300px" }}
          mt={5}
        >
          {" "}
          <ReactLoading
            type="spokes"
            color="	#0E76BC"
            height={"5%"}
            width={"5%"}
          />{" "}
        </Box>
      ) : (
        <>
          <Box className={classes.subTab}>
            <Box style={{ borderBottom: "1px solid  #E6E3E3" }}>
              <Box
                display="flex"
                alignItems="center"
                className={classes.tab}
                px={{ xs: 3, md: 15 }}
              >
                <a href="/dashboard/digitalgold" style={{ color: "black" }}>
                  <p className={classes.textUnderline}>Digital Gold</p>
                </a>
                <ArrowForwardIosIcon style={{ fontSize: "10px" }} />
                <p
                  style={{
                    paddingLeft: "10px",
                    margin: "1px",
                    fontWeight: 500,
                  }}
                >
                  Order Summary
                </p>
              </Box>
            </Box>

            <Box px={{ xs: 3, md: 18 }} pt={1}>
              <img
                src="/images/backIcon.svg"
                height="20px"
                width="20px"
                onClick={handleOrderSummary}
              />
            </Box>
          </Box>
          <Box className={classes.root} px={{ md: 15 }} pt={4}>
            <p>Order Summary</p>

            <Box justifyContent="center" display="flex" mt={2}>
              <Box
                className={classes.card}
                display="flex"
                justifyContent="center"
                p={2}
              >
                <Box className={classes.borderRight} px={3}>
                  <Typography className={classes.rateText}>
                    GOLD Rate / 1 gm
                  </Typography>
                  <Typography className={classes.rateText}>
                    {checkoutRate.type == "buy" ? goldRate : goldSellRate}
                  </Typography>
                </Box>
                <Box px={3}>
                  <Typography className={classes.rateText}>
                    SILVER Rate / 1 gm
                  </Typography>
                  <Typography className={classes.rateText}>
                    {checkoutRate.type == "buy" ? silverRate : silverSellRate}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box mt={3}>
              {!expired ? (
                <Typography className={classes.rateText}>
                  {" "}
                  {`This price will be valid for -`}
                  <span className={classes.counterText}>{`${time.minutes
                    .toString()
                    .padStart(2, "0")}:${time.seconds
                    .toString()
                    .padStart(2, "0")}`}</span>
                </Typography>
              ) : (
                "Price Expired ! Please try again"
              )}
            </Box>

            <Grid container mt={4} className={classes.table} px={3}>
              <Grid item xs={8} md={10}>
                <Typography className={classes.text} pl={3}>
                  Quantity(gms)
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography className={classes.text}>
                  {checkoutRate?.metalWeight.toFixed(4)}
                </Typography>
              </Grid>
            </Grid>

            <Grid container mt={4} className={classes.table} px={3}>
              <Grid item xs={8} md={10}>
                <Typography className={classes.text} pl={3}>
                  Amount(<span className={classes.rupees}>₹</span>)
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography className={classes.text}>
                  {checkoutRate?.metalAmount.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={4} className={classes.table} px={3}>
              <Grid item xs={8} md={10}>
                <Typography className={classes.text} pl={3}>
                  Tax(<span className={classes.rupees}>₹</span>)(GST+3%)
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography className={classes.text}>
                  {checkoutRate?.taxAmount.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={4} className={classes.table} px={3}>
              <Grid item xs={8} md={10}>
                <Typography className={classes.text} pl={3}>
                  Total Amount(<span className={classes.rupees}>₹</span>)
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography className={classes.text}>
                  {checkoutRate?.totalAmount.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>

            {/* <Grid container mt={4} className={classes.table} px={3}>
          <Grid item xs={8} md={10}>
            <Typography className={classes.textBlue}>
              Paid from wallet(₹)
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography className={classes.textBlue}>00</Typography>
          </Grid>
        </Grid> */}

            <Grid container mt={4} className={classes.totalAmount} px={3}>
              <Grid item xs={8} md={10}>
                <Typography className={classes.text} pl={3}>
                  Amount Payable(<span className={classes.rupees}>₹</span>)
                </Typography>
              </Grid>
              <Grid item xs={4} md={2}>
                <Typography className={classes.text}>
                  {checkoutRate.totalAmount.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Box display="flex" alignItems="center" mt={4}>
              <Typography style={{ fontSize: "0.8rem", padding: "0px 10px" }}>
                Powered By
              </Typography>
              <img
                src="/images/Augmont Logo 1.svg"
                height="35px"
                width="80px"
              />
            </Box>
            <Box mt={4}>
              {checkoutRate.type === "buy" ? (
                <button
                  className={classes.primaryButton}
                  onClick={handlePayment}
                >
                  Proceed to Pay
                </button>
              ) : (
                <button className={classes.primaryButton} onClick={handleSell}>
                  Proceed to Sell
                </button>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default OrderSummary;
