import React from 'react'
import {
    Box,
    makeStyles,
    Container,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
  } from "@material-ui/core";
  import CloseIcon from "@mui/icons-material/Close";

const UnderReview = ({dialog,closeDialog}) => {
    const useStyles = makeStyles({
        closeIcon: {
          padding: "5px",
          justifyContent: "flex-end",
    
        },
    
        title: {
          fontSize: "1.1rem",
          fontWeight: 500,
          textAlign: "center",
          fontFamily:"inherit"
        },
        dialogTitle: {
          display: "flex",
          justifyContent: "center",
          alignItems: " center",
          textAlign: "center",
          padding: "5px",
        },
        dialogPaper: {
         // maxWidth: "25.5rem",
          justifyContent: "center",
        },
        image: {
          textAlign: "center",
         
        },
        text: {
          fontSize: "0.8rem",
          textAlign: "center",
          fontFamily:"inherit"
        },
        primaryButton: {
          padding: "0.8rem 3rem",
          background: "#0E76BC",
          color: "#fff",
          fontSize: "0.8rem",
          fontWeight: 600,
          borderRadius: "45px",
          border: "none",
          cursor: "pointer",
          
        },
        dialogContent: {
          padding: "8px 30px 30px",
        },
    });

    const classes = useStyles();
  return (
   
        <Dialog
        open={dialog}
        onClose={closeDialog}
        classes={{ paper: classes.dialogPaper }}
      >
        <Box display="flex" className={classes.closeIcon}>
          {" "}
          <CloseIcon onClick={closeDialog} style={{cursor:"pointer"}}/>
        </Box>

    
      <DialogTitle
          classes={{ root: classes.dialogTitle }}
        >
          <Typography className={classes.title}>KYC Under review</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Box className={classes.image}>
          <img src="/images/undraw_process_re_gws7 1.svg" width="200px" height="200px" />
          </Box>
          <Typography className={classes.text}>
             We are currently reviewing your application.
          </Typography>
          <Typography className={classes.text}>
            We will get back with an update in next 48 hours
          </Typography>

          
        </DialogContent>
         
      </Dialog>
    
  )
}

export default UnderReview