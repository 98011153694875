import React, { useState } from "react";
import { useStyles } from "../styles";
import { Box, CircularProgress } from "@material-ui/core";
import TitleLayoult from "../TitleLayoult";
import httpInstance from "../../../utils/instance";
import {
  VERIFY_MOBILE_OTP,
  ADD_MOBILE_NUMBER,
  VERIFY_FORGOT_MPIN,
  VERIFY_MOBILE_OTP_LOGIN,
  GET_USER,
  GET_USER_ONBOARDING_DETAILS,
  GET_USER_ONBOARDING_STATUS,
} from "../../../utils/endpoints";
import ErrorMessage from "../../Validation/ErrorMessage";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import OtpInput from "react-otp-input";
import { namespace } from "../../../environment";


function PhoneOtp({ step, descriptionData }) {

  // const LoaderComp = () => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         marginTop: "1rem",
  //         overflow: "hidden",
  //       }}
  //     >
  //       <CircularProgress size="2rem" />
  //     </div>
  //   );
  // };


  const classes = useStyles();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const resetMpin = searchParams.get("reset");

  const [otpError, setOtpError] = useState(false);
  const [otp, setOpt] = useState("");
  const [resendmsg, setResendMsg] = useState("");

  const handleChange = (code) => setOpt(code);

  //-------------------------------------SUBMIT OTP----------------------------------------------------------------------//
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    // if (resetMpin) {
    // const httpConfig = {
    //   method: "POST",
    //   tokenType: "user",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   data: { otp: otp },
    // };
    // try {
    //   await httpInstance(`${VERIFY_FORGOT_MPIN}`, httpConfig);
    //   navigate({
    //     pathname: `${namespace}/signIn`,
    //     search: `?${createSearchParams({
    //       stepcount: "mpin_generate",
    //       reset: "mpin",
    //     })}`,
    //   });
    // } catch { }
    // } else {

    // Old code ---------------------------------------------------
    // const formData = {
    //   mobile: descriptionData.mobile,
    //   otp: otp,
    // };

    // New code -----------------------------------------
    console.log(descriptionData)
    const formData = {
      // ...(descriptionData.Alldata ? { user_id: descriptionData?.Alldata?.id } : { mobile: descriptionData?.mobile }),
      ...(descriptionData.Alldata ? { mobile: descriptionData?.mobile } :  { user_id: descriptionData?.Alldata?.id } ),

      otp: otp,
    };


    const httpConfig = {
      method: "POST",
      // tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    // console.log("httpConfig", httpConfig)
    // console.log(descriptionData.Alldata.mobile_verified)
    // console.log(`${descriptionData.Alldata.mobile_verified ? VERIFY_MOBILE_OTP:  VERIFY_MOBILE_OTP_LOGIN }`)

    try {
      const response = await httpInstance(`${!descriptionData.Alldata.id ?  VERIFY_MOBILE_OTP_LOGIN : VERIFY_MOBILE_OTP}`, httpConfig);
      console.log(response.data)
      // -------------------------------------------------------- old code ----------------------------------------------- //
      // navigate({
      //   pathname: `${namespace}/signIn`,
      //   // search: `?${createSearchParams({ stepcount: "mpin_generate" })}`,
      // });

      // -------------------------------------------------------- new code ----------------------------------------------- //
      localStorage.setItem("user_token", response.data?.access_token);
      localStorage.setItem("refresh_token", response.data?.refreshToken);
      localStorage.setItem("user_id", response.data?.id);

      const httpConfig1 = {
        method: "GET",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
      };
       navigate({
        pathname: `${namespace}/dashboard/mutualfund/diy`,
        // search: `?${createSearchParams({ stepcount: "mpin_generate" })}`,
      });

      const status = await httpInstance(`${GET_USER_ONBOARDING_DETAILS}`, httpConfig1);
      const Kyc_status = await httpInstance(`${GET_USER_ONBOARDING_STATUS}`, httpConfig1);
      // console.log(Kyc_status)
      // console.log(status)
      if (Kyc_status.data.data.is_kyc_compliant) {
        // console.log(Kyc_status.data.data.is_kyc_compliant)
        if (Kyc_status.data.data.status === 'kyc_check') {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "4" })}`,
          });
        }
        else if (Kyc_status.data.data.status === "confirm_pan") {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "5" })}`,
          });
        }
        // else if (Kyc_status.data.data.status === "confirm_pan") {
        //   navigate({
        //     pathname: `${namespace}/signIn`,
        //     search: `?${createSearchParams({ stepcount: "6" })}`,
        //   });
        // }
        else if (Kyc_status.data.data.status === "personal_details") {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "7" })}`,
          });
        }
        else if (Kyc_status.data.data.status === "address_details") {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "8" })}`,
          });
        }
        else if (Kyc_status.data.data.status === "signature") {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "9" })}`,
          });
        }
        else if (Kyc_status.data.data.status === "video") {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "5" })}`,
          });
        }
        else if (Kyc_status.data.data.status === "nominee") {
          navigate({
            pathname: `${namespace}/dashboard/mutualfund/diy`,
          });
        }

      }
      else if (!Kyc_status.data.data.is_kyc_compliant) {
        if (Kyc_status.data.data.status === "personal_details") {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "20" })}`,
          });
        }
        else if (Kyc_status.data.data.status === "video") {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "25" })}`,
          });
        }
        else if (Kyc_status.data.data.status === "confirm_pan") {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "5" })}`,
          });
        }
        else if (Kyc_status.data.data.status === "geo_tag") {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "23" })}`,
          });
        }
        else if (Kyc_status.data.data.status === "proof_of_identity") {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "26" })}`,
          })  
        }
        else if (Kyc_status.data.data.status === "proof_of_address") {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "26" })}`,
          })
        }
        else if (Kyc_status.data.data.status === "bank_details") {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "21" })}`,
          });
        }
        else if (Kyc_status.data.data.status === "verify_aadhaar_number") {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "29" })}`,
          });
        }
        else if (Kyc_status.data.data.status === "initiate_esign") {
          if (Kyc_status.data.data.fp_kyc_status === "submitted"){
            navigate({
              pathname: `${namespace}/dashboard/mutualfund/diy`,
              // search: `?${createSearchParams({ stepcount: "mpin_generate" })}`,
            });
          }
         
        }
      }
      else {
        console.log('sadfasdf')
        navigate({
          pathname: `${namespace}/signIn`,
          search: `?${createSearchParams({ stepcount: "2" })}`,
        });
      }
      if (status.data?.data?.mpin) {
        navigate({
          pathname: `${namespace}/signIn`,
          search: `?${createSearchParams({ stepcount: "mpin" })}`,
        });
      } else {
        if (status.data?.data?.isMobileNumberVerified) {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "mpin_generate" })}`,
          });
        } else {
          console.log('sadfasdf')
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "2" })}`,
          });
        }
      }
    } catch (error) {
      console.log(error.response)
      setOtpError(error.response.data?.error);
      if (error.response.data?.error === "No Onboarding found" && error.response.data?.status === 404) {
        navigate({
          pathname: `${namespace}/signIn`,
          search: `?${createSearchParams({ stepcount: "2" })}`,
        });
      }

      // if (
      //   error.response.data?.message === "Invalid OTP" ||
      //   error.response.data?.message === "OTP Expired"
      // ) {
      //   // setOtpError(true);
      //   setOtpError(error.response?.error);
      // }
    }


    // } catch (error) {
    //   console.log(error)
    //   // setOtpError(error.response.data?.message);
    // }
    // }
  };
  //-------------------------------resend OTP------------------------------------------------------------------------//
  const handleResentOtp = async (data) => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: { mobile: descriptionData.mobile },
    };

    try {
      const response = await httpInstance(`${ADD_MOBILE_NUMBER}`, httpConfig);
      setResendMsg(response.data?.message);
    } catch (error) { }
  };

  return (
    <div>
      <Box display={"flex"} justifyContent="center">
        <Box
          display={"flex"}
          alignItems="flex-start"
          flexDirection={"column"}
          p={3}
        >
          <Box>
            <TitleLayoult
              title="Verify your Phone number"
              description={`Please Enter the OTP sent to ${descriptionData.mobile
                ? descriptionData.mobile.substring(0, 2)
                : ""
                }********to verify your phone number`}
            />
          </Box>
          <form onSubmit={handleOtpSubmit}>
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={4}
              separator={<span style={{ width: "8px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                border: "none",
                borderRadius: "8px",
                width: "40px",
                height: "40px",
                fontSize: "13px",
                color: "#000",
                fontWeight: "400",
                caretColor: "blue",
                background: "#E6E6E6",
              }}
              focusStyle={{
                border: "1px solid #CFD3DB",
                outline: "none",
              }}
            />
            <p className={classes.resendOtpText} onClick={handleResentOtp}>
              Resend OTP
            </p>
            <p className={classes.verifiedKycText}>{resendmsg}</p>
            {otpError && <ErrorMessage message={otpError} />}
            <Box mt={5} display="flex" justifyContent="center">
              <button className={classes.primaryButton} type="submit">
                Verify
              </button>
            </Box>
            {/* <LoaderComp /> */}
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default PhoneOtp;
