import React, { useState, useEffect } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import {
  CREATE_ASSISTED_ORDER,
  GET_ASSISTED_SRVICE_AMOUNT,
  POST_ASSISTED_PAYEMNT,
  VERIFY_ASSISTED_SERVICE_PIN,
} from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import GetStarted from "../../Dashboard/Funds/GetStarted";
import { useDispatch } from "react-redux";
import { getUser } from "../../../redux/User/userActions";
import ReactLoading from "react-loading";
import toLocaleString from "../../../hooks/LocaleString";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";

const AssistedOrderSummary = () => {
  const useStyles = makeStyles({
    root: {
      padding: "0 30%",
    },
    primaryButton: {
      color: "white",
      backgroundColor: "#0E76BC",
      border: "none",
      borderRadius: "39px",
      fontSize: "15px",
      fontWeight: "500",
      padding: "10px 3rem",
      fontFamily: "inherit",
      cursor: "pointer",
    },
    header: {
      fontSize: "1.5rem",
      margin: "15px 0px",
    },

    borderBox: {
      border: "1px solid  #C4C4C4",
      borderRadius: "25px",
    },
    rupees: {
      fontFamily: "Open Sans",
      color: "#C4C4C4",
      fontSize: "2rem",
    },
    amountText: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    text: {
      fontSize: "0.85rem",
      marginLeft: "5px",
    },
    "@media (max-width: 900px)": {
      root: {
        padding: "0 10%",
      },
    },
  });

  const classes = useStyles();

  const [amount, setAmount] = useState("");
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [otp, setOTP] = useState("");
  const [otpError, setOTPError] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const { userStore } = useSelector((state) => state);
  const get_amount = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(
        `${GET_ASSISTED_SRVICE_AMOUNT}`,
        httpConfig
      );
      setAmount(response.data?.data?.amount);
    } catch {}
  };

  useEffect(() => {
    get_amount();
  }, []);
  //---------------------------------------handle payement--------------------------------------//
  const handle_payment = async (orderData) => {
    var options = {
      key: orderData?.key,
      amount: orderData.amount,
      currency: "INR",
      name: orderData.name,
      description: orderData.description,
      //image: "https://example.com/your_logo",
      order_id: orderData?.orderId,
      prefill: {
        name: orderData.name,
        email: orderData?.email,
        contact: orderData?.contact,
      },
      config: {
        display: {
          hide: [
            {
              method: "wallet",
            },
          ],
          preferences: {
            show_default_blocks: true,
          },
        },
      },
      handler: function (response) {
        setPaymentCompleted(true);
        postPayment(response);
      },

      //timeout:(time.minutes*60+time.seconds)-2
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on("payment.failed", function (response) {
      setPaymentCompleted(false);
    });
  };

  //-----------------------------create ordder-----------------------------------------------------------//
  const create_order = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: { amount: amount },
    };

    try {
      const response = await httpInstance(
        `${CREATE_ASSISTED_ORDER}`,
        httpConfig
      );
      //setOrderData(response.data?.data)
      handle_payment(response.data?.data);
    } catch {}
  };
  //----------------------------post payment----------------------------------------//
  const postPayment = async (response) => {
    const formData = {
      orderId: response.razorpay_order_id,
      paymentId: response.razorpay_payment_id,
      status: "success",
    };

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      await httpInstance(`${POST_ASSISTED_PAYEMNT}`, httpConfig);
      dispatch(getUser());
    } catch {}
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        pin: otp,
        userId: userStore?.user?.id,
      },
    };

    try {
      const response = await httpInstance(
        `${VERIFY_ASSISTED_SERVICE_PIN}`,
        httpConfig
      );
      setPaymentCompleted(true);
      postPayment(response);
    } catch {}
  };
  const handleOTPChange = (code) => setOTP(code);
  return (
    <div>
      {!paymentCompleted ? (
        !amount ? (
          <Box
            display="flex"
            justifyContent="center"
            style={{ height: "300px" }}
            mt={5}
          >
            <ReactLoading
              type="spokes"
              color="	#0E76BC"
              height={"5%"}
              width={"5%"}
            />
          </Box>
        ) : (
          <>
            <form onSubmit={handleOtpSubmit}>
              <Box
                className={classes.root}
                display="flex"
                flexDirection="column"
                alignItems="start"
                style={{ marginTop: "40px" }}
              >
                <img src="/images/neologo.svg" />
                <Typography className={classes.header}>
                  Start your Investment Journey with Neosurge
                </Typography>
                <Typography>
                  To Unlock Assisted Services, Please create a pin
                </Typography>
                <div
                  className="d-flex justify-content-center"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "40px",
                  }}
                >
                  <OtpInput
                    value={otp}
                    onChange={handleOTPChange}
                    numInputs={6}
                    separator={<span style={{ width: "8px" }}></span>}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    inputStyle={{
                      border: "none",
                      borderRadius: "8px",
                      width: "40px",
                      height: "40px",
                      fontSize: "13px",
                      color: "#000",
                      fontWeight: "400",
                      caretColor: "blue",
                      background: "#E6E6E6",
                    }}
                    focusStyle={{
                      border: "1px solid #CFD3DB",
                      outline: "none",
                    }}
                  />
                </div>
              </Box>

              {otpError && (
                <ErrorMessage message="OTP you have entered is invalid or expired " />
              )}
              <Box mt={5} px={3} display="flex" justifyContent="center">
                <button className={classes.primaryButton} type="submit">
                  Verify
                </button>
              </Box>
            </form>
            {/* <Box
              className={classes.root}
              display="flex"
              flexDirection="column"
              alignItems="start"
            >
              <img src="/images/neologo.svg" />
              <Typography className={classes.header}>
                Start your Investment Journey with Neosurge
              </Typography>
              <Typography>
                To Unlock Assisted Services, Please make the payment
              </Typography>

              <Box p={3} className={classes.borderBox} mt={4}>
                <Typography className={classes.amountText}>
                  <span className={classes.rupees}>₹</span>{" "}
                  {toLocaleString(amount)} /-{" "}
                </Typography>
                <Box mt={3}>
                  <Box display="flex" alignItems="center" mt={2}>
                    <img src="/images/tick.svg" height="16px" width="16px" />
                    <Typography className={classes.text}>
                      Invest Advisory from Top Investment experts{" "}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mt={2}>
                    <img src="/images/tick.svg" height="16px" width="16px" />
                    <Typography className={classes.text}>
                      Timely Exit Triggers for Best Returns
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center" mt={2}>
                    <img src="/images/tick.svg" height="16px" width="16px" />
                    <Typography className={classes.text}>
                      Active rebalancing to ensure calibration with goals &
                      criteria
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box display="flex" justifyContent="center" mt={5}>
              <button className={classes.primaryButton} onClick={create_order}>
                Proceed to pay
              </button>
            </Box> */}
          </>
        )
      ) : (
        <GetStarted />
      )}
    </div>
  );
};

export default AssistedOrderSummary;
