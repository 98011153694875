import axios from "axios";
import {
  GET_GOLD_USER_DATA,
  GET_USER_DATA,
  VERIFY_LOADING,
  VERIFY_ERROR,UPLOAD_PIC

} from "./userActionTypes";
import httpInstance from '../../utils/instance'
import {GET_GOLD_USER,GET_USER,KYC_STATUS,CREAT_MF_ACCOUNT,CREATE_GOLD_USER,ACCEPT_TERMS,ADD_GOLD_BANK,CREAT_GOLD_KYC} from '../../utils/endpoints'


// import CustomToast from "components/UI/CustomToast";

export const getGoldUser = () => {
  
  const httpConfig = {
    method: "GET",
    tokenType: "user",
    headers:{
      "Content-Type": "application/json",
    },
  };
  return async (dispatch) => {
    dispatch({
      type: VERIFY_LOADING,
    });
    try {
      const data = await httpInstance(`${GET_GOLD_USER}`, httpConfig);
   
      dispatch({
        type: GET_GOLD_USER_DATA,
        payload: data?.data?.data
      });
    } catch (error) {
      
    }
  };
};

export const getUser = () => {
  console.log('data of the user' )

  const httpConfig = {
    method: "GET",
    tokenType: "user",
    headers:{
      "Content-Type": "application/json",
    },
  };
  return async (dispatch) => {
    dispatch({
      type: VERIFY_LOADING,
    });
    try {
      const data = await httpInstance(`${GET_USER}`, httpConfig);
      // console.log(data, 'data of the user' )
      dispatch({
        type: GET_USER_DATA,
        payload: data?.data?.data
      });
    } catch (error) {
      
    }
  };
};

export const creatMfAccount = () => {
  
  const userId=localStorage.getItem('user_id')
  const httpConfig = {
    method: "POST",
    tokenType: "user",
    headers:{
      "Content-Type": "application/json",
    },
    params:{userId:userId}
    
  };
  const httpConfig2 = {
    method: "GET",
    tokenType: "user",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const httpConfig1 = {
    method: "POST",
    tokenType: "user",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return async (dispatch) => {
    dispatch({
      type: VERIFY_LOADING,
    });
    try {
      const data = await httpInstance(`${KYC_STATUS}`, httpConfig2);
      let response=data?.data?.data
     
       if(response.isInvestorAccCreateDone && !response.isMfAccCreateDone){
        await httpInstance(`${CREAT_MF_ACCOUNT}`,httpConfig)
        await httpInstance(`${CREATE_GOLD_USER}`,httpConfig1)
        await httpInstance(`${ACCEPT_TERMS}`,httpConfig1)
        await httpInstance(`${ADD_GOLD_BANK}`,httpConfig1)
        await httpInstance(`${CREAT_GOLD_KYC}`,httpConfig1)
       }
   
      
    } catch (error) {
      
    }
  };
};

export const uploadProfilePic = (img) => {
  
  return(dispatch)=>{
    dispatch({
      type: VERIFY_LOADING,
    });

    dispatch({
      type: UPLOAD_PIC,
      payload: img
    });
  
  }
 
      
  
};

