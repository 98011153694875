import React from "react";
import {
  Cell,
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import { isMobile } from "react-device-detect";

const HoldingPieChart = ({ data, colors, title, fundSize }) => {
 
  const useStyles = makeStyles((theme) => ({
    LegendList: {
      //display: "grid",
      //gridTemplateColumns: "1fr 1fr",
      listStyle: "none" /* Remove default bullets */,
      "& li": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        margin: "5px",
      },
    },
    cirecleBullet: {
      borderRadius: "50%",
      minWidth: "15px",
    },
    bulletLabel: {
      display: "flex",
      alignItems: "center",
      wordBreak: "break-all",
    },
    subTitle: {
      fontWeight: 600,
      padding: "0px 3rem",
    },

    desktop: {
      display: "block",
    },
    mobileView: {
      display: "none",
    },

    "@media (max-width: 991px)": {
      desktop: {
        display: "none",
      },
      mobileView: {
        display: "block",
      },
    },
  }));

  const classes = useStyles();

  const Bullet = ({ backgroundColor, size }) => {
    return (
      <div
        className={classes.cirecleBullet}
        style={{
          backgroundColor,
          width: size,
          height: size,
        }}
      ></div>
    );
  };


  const CustomizedLegend = (props) => {
    const { payload } = props;

    return (
      <div>
        <p className={classes.subTitle}>{title}</p>
        <ul className={classes.LegendList}>
          {payload.map((entry, index) => (
            <li key={index}>
              <Grid container>
                <Grid item xs={8}>
                  <div className={classes.bulletLabel}>
                    <Bullet backgroundColor={entry.payload.fill} size="15px" />

                    <div style={{ marginLeft: "10px", marginRight: "20px" }}>
                      {entry.value}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  {entry.payload.value.toFixed(2)}%
                </Grid>
              </Grid>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const CustomLabel = ({ viewBox, labelText, value }) => {
    const { cx, cy } = viewBox;
    return (
      <g>
        <text
          x={cx}
          y={cy}
          // className="recharts-text recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
          alignmentBaseline="middle"
          fill="#272B41"
          fontSize="13"
          fontWeight={600}
        >
          {`₹${value?.toFixed(2)}Cr.`}
        </text>
      </g>
    );
  };

  return (
    <div>
      {!isMobile ? (
        <div className={classes.desktop}>
          <ResponsiveContainer width={"100%"} height={250}>
            <PieChart>
              <Legend
                content={<CustomizedLegend />}
                layout="vetical"
                verticalAlign="middle"
                align="left"
                width="40%"
                wrapperStyle={{
                  left: 0,
                  top: 0,
                  paddingBottom: 10,
                  position: "absolute",
                }}
              />
              {/* <Legend
            content={<CustomizedLegend />}
            layout="vetical"
            verticalAlign="middle"
            align="bottom"
            width="40%"
          /> */}

              <Pie
                data={data}
                dataKey="value"
                outerRadius={100}
                innerRadius={75}
                cx="55%"
                cy="50%"
              >
                {data.map((entry, index) => (
                  <Cell key={index} fill={colors[index]} />
                ))}
                <Label
                  content={<CustomLabel value={fundSize} />}
                  position="center"
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className={classes.mobileView}>
          <ResponsiveContainer width={"100%"} height={550}>
            <PieChart>
              <Legend content={<CustomizedLegend />} />

              <Pie
                data={data}
                dataKey="value"
                outerRadius={100}
                innerRadius={75}
                cx="55%"
                cy="35%"
              >
                {data.map((entry, index) => (
                  <Cell key={index} fill={colors[index]} />
                ))}
                <Label
                  content={<CustomLabel value={fundSize} />}
                  position="center"
                />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default HoldingPieChart;
