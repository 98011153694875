import React, { useState } from "react";
import {
    Box,
    makeStyles,
    Typography,
    Grid,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";

const OrderTypeSelection = ({ handleStatus, status, values }) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            // border: "1px solid #DDDDDD",
            borderRadius: "5px",
        },
        box1: {
            borderBottom: "1px solid #DDDDDD",
        },
        checkBox: {
            fontSize: "0.85rem",
            "& .MuiTypography-body1": {
                fontSize: "0.85rem",
            },
            "& .MuiCheckbox-colorPrimary.Mui-checked": {
                color: "#0E76BC",
            },
        },
        box2: {
            borderBottom: "1px solid #E4E4E4",
        },
        box3: {
            padding: "1rem 10px",
            cursor: "pointer",
        },
        bluebox3: {
            padding: "1rem 10px",
            color: "#0E76BC",
            borderBottom: "3px solid #0E76BC",
            cursor: "pointer",
        },
    }));
    const classes = useStyles();

    // ---------------------------- Order type selection ------------------------------------ // 

    const handle_tab = (data) => {
        handleStatus(data);
    };


    return (
        <div>
            <Box className={classes.root} p={2}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    px={6}
                    // pb={2}
                    className={classes.box2}
                >

                    {values?.map((items, i) => {
                        return (
                            <Box
                                px={3}
                                className={status == items ? classes.bluebox3 : classes.box3}
                                onClick={() => handle_tab(items)}
                            >
                            <Typography >{items}</Typography>
                            </Box>
                        )
                    })
                    }
                 {/* <Box
                    display="flex"
                    justifyContent="space-between"
                    px={6}
                    // pb={2}
                    className={classes.box2}
                >
                    <Box
                        px={3}
                        className={status == "Successful" ? classes.bluebox3 : classes.box3}
                        onClick={() => handle_tab("Successful")}
                    >
                        <Typography  >Success</Typography>
                    </Box>
                    <Box
                        px={3}
                        className={status == "Failed" ? classes.bluebox3 : classes.box3}
                        onClick={() => handle_tab("Failed")}
                    >
                        <Typography  >Failed</Typography>
                    </Box>
                    <Box
                        px={3}
                        className={status == "Pending" ? classes.bluebox3 : classes.box3}
                        onClick={() => handle_tab("Pending")}
                    >
                        <Typography >Pending</Typography>
                    </Box> */}
                </Box >
            </Box >
        </div >
    );
};

export default OrderTypeSelection;
