import React,{useState} from "react";
import { Box, makeStyles,CircularProgress   } from "@material-ui/core";
import { useForm } from "react-hook-form";
import axios from 'axios'


function Contact({ setModal,handleShowSuccess }) {
  
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: "2rem 12rem",
      position: "fixed",
      top: 0,
      zIndex: 30,
      backdropFilter: "grayscale(0.2) blur(1px)"
    },
    innerContainer: {
      borderRadius: "1rem"
    },
    heading: {
      color: "#272727",
      fontSize: "1.2rem"
    },
    paragraph: {
      fontSize: "0.9rem",
      fontWeight: "normal",
      color: "#474747",
      lineHeight: "1.4rem"
    },
    label: {
      color: "#272727",
      fontSize: "0.8rem"
    },
    inputText: {
      background: "#e6e6e6",
      fontSize: "0.9rem",
      border: "none",
      borderRadius: "10px",
      padding: "0.6rem 0.4rem"
    },
    messageInputText:{
      fontSize:"0.82rem",
      background: "#e6e6e6",
      border: "none",
      borderRadius: "10px",
      padding: "0.6rem 0.4rem",
      height:"6rem",
      resize:"none",
      fontFamily:"inherit"

    },
    buttonHolder: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "start",
      padding: "0px 0px 1rem 0px",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "0px 710px 0px 0px"
    },
    button: {
      border: "none",
      borderRadius: "40px",
      background: "#0E76BC",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      padding: "0.7rem 3rem",
      cursor:"pointer"
    },
    borderedButton: {
      border: "1px solid #0E76BC",
      borderRadius: "40px",
      background: "#fff",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      color: "#0E76BC",
      padding: "0.5rem 3rem",
      cursor:"pointer"
    },
    closeBtn: {
      cursor: "pointer"
    },

    error:{
      color: "#cf1020",
      fontSize: "0.8rem"
    },

    mobileError:{
      display:"none"
    },

    circularProgress:{
      height:"10px",
      width:"10px"
    },

    "@media (max-width: 900px)": {
      button: {
        padding: "0.7rem 1.4rem",
      },
      borderedButton:{
        padding: "0.5rem 1.4rem",
      }
    },

    "@media (max-width: 468px)": {
      mobileError:{
        display:"block",
        color: "#cf1020",
        fontSize: "0.8rem",
        marginTop:"0px"
      },

      error:{
        display:"none"
      }
    }
    
  }));
  const classes = useStyles();
//--------------------------------------------------------------------------------------------//
const [loading, setLoading]=useState(false)
  
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

 const handleFormSubmit=async (data)=>{
   setLoading(true)


  const formData={
    
      email: data.email,
      product:"MUTUAL_FUNDS",
      subject: data.subject,
      typeOfOrg: null,
      message: data.message,
      name: data.name,
      company: null,
      category: "B2C",
      phone: null,
      type: "B2C"
  
  }
  

axios.post('https://vi8mln4bah.execute-api.ap-south-1.amazonaws.com/Prod/update/',formData,{
  headers:{
    "x-api-key":"Wh1rzC7Mq52fwgRC5Bm2ca4uHkOzRh2q51X4fNIJ",
    "Content-Type":"application/json",
    "Accept":"application/json"
  }
} )
  .then((response)=>{
    
    setModal()
    handleShowSuccess()
  })
 

 }


 
  return (
   
      <Box className={classes.innerContainer}>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="space-between"
          flexDirection="row"
          width="100%"
        >
          <Box className={classes.paragraph}>
            Our Platform is under construction! Meanwhile...
          </Box>
          <Box  onClick={() => setModal(false)}>
            <img
              src="/images/Icon.svg"
              height="16"
              width="16"
              className={classes.closeBtn}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" width="100%" mt={2} >
          <Box width="50%">
            <Box className={classes.heading}>Connect with us</Box>
            <Box className={classes.paragraph} width="85%" mt={2}>
              This is where you'll find the finest investing opportunities.
              Contact us right away if you have any questions.
            </Box>
          </Box>
          <Box width="50%" ml={5}>
            <Box className={classes.heading}>Schedule a call with us</Box>
            <Box className={classes.paragraph} width="85%" mt={2}>
              You may plan a virtual meeting with us while you're sipping on
              your coffee. Let's talk about a few financial issues
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" mt={4} width="100%">
          <Box width="50%">
            <Box className={classes.heading}>Feel free to write to us</Box>
            <Box display="flex" flexDirection="column" mt={2} mr={3}> 
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <Box width="100%" display="flex" mb={1} sx={{flexDirection:{xs:"column", md:"row"}}}>
                
                <Box display="flex" flexDirection="column" mr={2} sx={{width:{xs:"100%",md:"45%"}}}>
                  <label className={classes.label}>Your Name</label>
                  <input
                  type="text" 
                  className={classes.inputText} 
                  py={1} 
                  {...register("name",{required:true})}
                  />
                  {errors.name && <p className={classes.mobileError}>*Fill this field</p>}
                </Box>
                <Box display={"flex"} flexDirection="column"  sx={{width:{xs:"100%",md:"50%"}}}>
                  <label className={classes.label}>Your Email</label>
                  <input 
                  type="text" 
                  className={classes.inputText} 
                  py={1}
                  {...register("email",{
                    required:true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                    }
                  })}
                  />
                  {errors.email && (errors.email.type==="pattern" ?  <p className={classes.error}>Invalid Email</p>:<p className={classes.mobileError}>*Fill this field</p>)}
                  
                </Box>
              </Box>

              <Box display={"flex"} flexDirection="column" mb={2}>
                <label className={classes.label}>Subject</label>
                <input 
                type="text" 
                className={classes.inputText} 
                py={1} 
                {...register("subject",{required:true})}
                />
                {errors.subject && <p className={classes.mobileError}>*Fill this field</p>}
              </Box>
              <Box display={"flex"} flexDirection="column" mb={2}>
                <label className={classes.label}>Message</label>
                <textarea 
                type="text" 
                className={classes.messageInputText}
                py={4} 
                {...register("message",{required:true})}
                />
                {errors.message && <p className={classes.mobileError}>*Fill this field</p>}
              </Box>
              {(errors.name || errors.email && errors.email.type==="required" || errors.subject || errors. message) && 
              <p className={classes.error}>*Fill all details</p> }
              
              <button 
                className={classes.button}
                type="submit"
                >
                {loading ? <CircularProgress size="1rem" color="inherit"/> : "Submit" }
                </button>
              </form>
            </Box>
          </Box>
          <Box className={classes.buttonHolder} mt={6} ml={3}>
           <a href="https://calendly.com/bullsurge?month=2022-04" style={{textDecoration:"none"}}>
           <button className={classes.button} width="54%">
              Schedule a call
            </button>
             </a> 
            <Box mt={2}>
             <a href="https://api.whatsapp.com/send?phone=918669606482&lang=en" target={"_blank"} style={{textDecoration:"none"}}>
             <button className={classes.borderedButton} width="54%">
                <img
                  src="/images/whatsapp.svg"
                  height="20"
                  width="20"
                />{" "}
                Contact Us
              </button>
               
               </a> 
            </Box>
          </Box>
        </Box>
       
      </Box>
    
  );
}

export default Contact;
