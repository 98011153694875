import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

function NriPopup({ dialog, openDialog, closeDialog, handleContact }) {
  const useStyles = makeStyles((theme) => ({
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
    },

    title: {
      fontSize: "1.3rem",
      fontWeight: 400,
      textAlign: "center",
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "center",
      alignItems: " center",
      textAlign: "center",
      padding: "5px",
    },
    dialogPaper: {
      maxWidth: "20rem",
      justifyContent: "center",
    },
    image: {
      textAlign: "center",
    },
    text: {
      fontSize: "0.8rem",
      textAlign: "center",
    },
    primaryButton: {
      padding: "0.8rem 0.3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.9rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      width: "100%",
    },
    dialogContent: {
      padding: "8px 30px",
    },
  }));

  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={dialog}
        onClose={closeDialog}
        classes={{ paper: classes.dialogPaper }}
      >
        <Box display="flex" className={classes.closeIcon}>
          {" "}
          <CloseIcon onClick={closeDialog} />
        </Box>

        <DialogTitle
          classes={{ paper: classes.dialogPaper, root: classes.dialogTitle }}
        >
          <Typography className={classes.title}>Uh oh! Sorry</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Box className={classes.image}>
            <img src="/images/undraw_cancel_u-1-it 1.svg" />
          </Box>
          <Typography className={classes.text}>
            At this time, we are not accepting non-resident members on our
            online platform.
          </Typography>
          <Typography className={classes.text}>
            You can connect with us Offline
          </Typography>

          <Box my={3}>
            <button
              className={classes.primaryButton}
              type="submit"
              onClick={handleContact}
            >
              Contact Us
            </button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default NriPopup;
