import React from 'react'
import About from '../../containers/About/Aboutus'
// import Header from '../../components/header/Header'
import Header from "../../components/Header";
import Footer from '../../components/Footer'
import { isMobile } from "react-device-detect";
import FooterStatic from '../../components/FooterStatic';

const AboutUs = () => {
  return (
    <div>
   {!isMobile && <Header/>}
    <About/>
 { !isMobile &&  <FooterStatic/>}
    </div>
  )
}

export default AboutUs