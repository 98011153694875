import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, Grid, Slider, } from "@material-ui/core";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList

} from "recharts";



const ReturnCal = ({ returns, sliderAmountChangeIntheReturnCalculator }) => {
  const useStyles = makeStyles((theme) => ({

    // graphButtonInactive: {
    //   border: "none",
    //   background: "none",
    //   padding: "5px",
    //   borderRadius: "10px",
    //   fontSize: "0.7rem",
    //   cursor: "pointer",
    //   outline: "none",
    //   "&:hover": {
    //     background: "#6FDDB8",
    //     color: "white",
    //     padding: "5px",
    //   },
    //   "&:active": {
    //     background: "#6FDDB8",
    //     color: "white"
    //   }
    // },

    // activeButton: {
    //   background: "#6FDDB8",
    //   color: "white",
    //   padding: "5px",
    //   border: "none",
    //   borderRadius: "10px",
    //   fontSize: "0.7rem",
    //   cursor: "pointer",
    //   outline: "none",
    // },

    root: {
      marginTop: "3rem",

    },

    borderBox: {
      border: "1px solid #E4E4E4",
      borderRadius: "5px",
      padding: "1.5rem",
      width: "75%",
      '& .MuiSlider-valueLabel': {
        '& > *': {

          backgroundColor: 'white',
          color: "black"
        },
      }
    },
    ButtonInactive: {
      border: "none",
      background: "none",
      padding: "5px 20px",
      borderRadius: "20px",
      fontSize: "0.75rem",
      fontFamily: "inherit",
      marginRight: "1rem",
      marginLeft: "1rem",
      cursor: "pointer",
      outline: "none",
      "&:hover": {
        background: "#0E76BC",
        color: "white",

      },
      "&:active": {
        background: "#0E76BC",
        color: "white"
      }
    },

    activeButton: {
      background: "#0E76BC",
      color: "white",
      padding: "5px 20px",
      border: "none",
      borderRadius: "20px",
      fontSize: "0.75rem",
      fontFamily: "inherit",
      marginRight: "1rem",
      marginLeft: "1rem",
      cursor: "pointer",
      outline: "none",
    },
    activeYearButton: {
      background: "#0E76BC",
      color: "white",
      padding: "5px 20px",
      border: "none",
      borderRadius: "20px",
      fontSize: "0.7rem",
      fontWeight: 700,
      fontFamily: "inherit",
      marginRight: "1rem",
      marginLeft: "1rem",
      cursor: "pointer",
      outline: "none",

    },

    inactiveYearButton: {
      border: "1px solid #0E76BC ",
      background: "none",
      padding: "5px 20px",
      borderRadius: "20px",
      fontSize: "0.7rem",
      fontWeight: 700,
      fontFamily: "inherit",
      marginRight: "1rem",
      marginLeft: "1rem",
      color: "#0E76BC",
      cursor: "pointer",
      outline: "none",
      "&:hover": {
        background: "#0E76BC",
        color: "white",

      },
      "&:active": {
        background: "#0E76BC",
        color: "white"
      }
    },

    text: {
      fontSize: "0.8rem"
    },

    title: {
      fontSize: "0.9rem",
      marginTop: "1rem"
    },

    thumb: {
      background: "#F3C04F",
      width: "15px",
      height: "15px",
      boxShadow: 'none !important',
      ' &.Mui-focusVisible': {
        boxShadow: 'none !important',
      },
      '&.Mui-active': {
        boxShadow: 'none !important',
      },

    },
    mark: {
      background: "black"
    },
    rail: {
      background: "#FFE3A4",
      height: "5px"
    },
    track: {
      background: "#F3C04F",
      height: "5px"
    },
    rupees: {
      fontFamily: "Open Sans"
    },
    "@media (max-width: 600px)": {
      activeButton: {
        marginRight: "0rem",
      },
      ButtonInactive: {
        marginRight: "0rem",
      },
      activeYearButton: {
        marginRight: "0.5rem",
      },
      inactiveYearButton: {
        marginRight: "0.5rem",
      }
    }

  }))
  const classes = useStyles();

  const [button, setButton] = useState('sip')
  const [yearButton, setYearButton] = useState('1')
  const [totalReturn, setTotalReturn] = useState()
  const [invest, setInvest] = useState(5000)

  const [sliderVlaueOnChangeEnd, setSliderVlaueOnChangeEnd] = useState(5000)
  const [calculatedThisFund, setCalculatedThisFund] = useState(null);
  const [calculatedProfit, setCalculatedProfit] = useState(null)

  const [barChartData, setBarChartData] = useState([
    {
      name: 'Category Avg',
      total: 0,
      invested_amount: 0,
      // amt: 2400,
    },
    {
      name: 'Bechmark',
      total: 0,
      invested_amount: 0,
      // amt: 2210,
    },
    {
      name: 'This Fund',
      total: 0,
      invested_amount: 0,
      // amt: 2290,
    },

  ])

  const CustomBarLabel = ({ fill, ...props }) => (
    <g transform={`translate(${props.x},${props.y})`}>
      <text fill={fill} fontSize={14} textAnchor="middle" dominantBaseline="middle" dy={-10} dx={10} >
      ₹{props.value.toFixed(2)}
      </text>
    </g>
  );


  useEffect(() => {
    sliderAmountChangeIntheReturnCalculator(sliderVlaueOnChangeEnd, button)
  }, [sliderVlaueOnChangeEnd, button])

  //Calculating Past Return
  const calculatingPastReturn = (value) => {
    // console.log(value)
    // console.log(returns.fund_1_year)
    let selectedFundData;
    switch (value) {
      case '1':
        selectedFundData = returns.fund_1_year;
        break;
      case '3':
        selectedFundData = returns.fund_3_year;
        break;
      case '5':
        selectedFundData = returns.fund_5_year;
        break;
      default:
        console.log("Invalid index. Please provide a valid index (1, 2, or 3).");
        return null; // Return null or handle the error case appropriately
    }

    // Now you can use 'selectedFundData' as needed
    setCalculatedThisFund((selectedFundData.profit / selectedFundData.invested_amount) * 100)

  }

  //Calculate Profit aboslute return
  const calculatingProfitAbsoluteReturn = (value) => {
    // console.log(value)
    // console.log(returns.fund_1_year)
    let selectedFundData;
    switch (value) {
      case '1':
        selectedFundData = returns.fund_1_year;
        break;
      case '3':
        selectedFundData = returns.fund_3_year;
        break;
      case '5':
        selectedFundData = returns.fund_5_year;
        break;
      default:
        console.log("Invalid index. Please provide a valid index (1, 2, or 3).");
        return null; // Return null or handle the error case appropriately
    }

    // Now you can use 'selectedFundData' as needed
    setCalculatedProfit((selectedFundData.profit * 100) / selectedFundData.total)

  }

  //Calculate Profit aboslute return
  const calculatingBarGraphValue = (value) => {
    // console.log(value)
    // console.log(returns.fund_1_year)
    let selectedFundData;
    switch (value) {
      case '1':
        selectedFundData = [
          {
            name: 'Category Avg',
            total: returns.fund_catavg_1_year.total,
            invested_amount: returns.fund_catavg_1_year.invested_amount,
            // amt: 2400,
          },
          {
            name: 'Bechmark',
            total: returns.benchmark_1_year.total,
            invested_amount: returns.benchmark_1_year.invested_amount,
            // amt: 2210,
          },
          {
            name: 'This Fund',
            total: returns.fund_1_year.total,
            invested_amount: returns.fund_1_year.invested_amount,
            // amt: 2290,
          },
        ]
        // returns.fund_1_year;
        break;
      case '3':
        selectedFundData = [
          {
            name: 'Category Avg',
            total: returns.fund_catavg_3_year.total,
            invested_amount: returns.fund_catavg_3_year.invested_amount,
            // amt: 2400,
          },
          {
            name: 'Bechmark',
            total: returns.benchmark_3_year.total,
            invested_amount: returns.benchmark_3_year.invested_amount,
            // amt: 2210,
          },
          {
            name: 'This Fund',
            total: returns.fund_3_year.total,
            invested_amount: returns.fund_3_year.invested_amount,
            // amt: 2290,
          },
        ];
        break;
      case '5':
        selectedFundData = selectedFundData = [
          {
            name: 'Category Avg',
            total: returns.fund_catavg_5_year.total,
            invested_amount: returns.fund_catavg_5_year.invested_amount,
            // amt: 2400,
          },
          {
            name: 'Bechmark',
            total: returns.benchmark_5_year.total,
            invested_amount: returns.benchmark_5_year.invested_amount,
            // amt: 2210,
          },
          {
            name: 'This Fund',
            total: returns.fund_5_year.total,
            invested_amount: returns.fund_5_year.invested_amount,
            // amt: 2290,
          },
        ];
        break;
      default:
        console.log("Invalid index. Please provide a valid index (1, 2, or 3).");
        return []; // Return null or handle the error case appropriately
    }

    // Now you can use 'selectedFundData' as needed
    setBarChartData(selectedFundData)

  }



  const handleButton = (type) => {
    setButton(type)
  }

  const handleYearButton = (type) => {
    setYearButton(type)

  }

  const get_return = () => {
    if (yearButton == '1') {
      return returns?.oneY
    } else if (yearButton == "3") {
      return returns?.threeY
    } else {
      return returns?.fiveY
    }
  }

  useEffect(() => {
    return_calculator(5000)
  }, [returns])

  useEffect(() => {
    return_calculator(invest)

  }, [yearButton, button])

  useEffect(() => {
    if (returns) {
      calculatingPastReturn(yearButton)
      calculatingProfitAbsoluteReturn(yearButton)
      calculatingBarGraphValue(yearButton)
    }
  }, [yearButton, returns])

  const return_calculator = (value) => {
    // console.log("value",value)
    setInvest(value)
    if (button == "monthly") {
      let n = yearButton * 12
      let i = (Number(get_return()) / (12)) / 100
      //M = P × ({[1 + i]n – 1} / i) × (1 + i).
      const totalReturnValue = value * ((Math.pow(1 + i, n) - 1) / i) * (1 + i)
      setTotalReturn(totalReturnValue)
    } else {
      //A = P (1 + r/n) ^ nt
      let n = 2
      let r = get_return() / 100
      let t = yearButton

      const totalReturnValue = value * (Math.pow(1 + r / n, n * t))
      setTotalReturn(totalReturnValue)




    }
  }


  const datasssss = [
    { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
  ];



  return (
    <div className={classes.root}>
      <Typography>Return Calculator</Typography>
      <Box className={classes.borderBox} mt={3}>
        <Box display='flex' justifyContent='center'>
          <button className={button == 'sip' ? classes.activeButton : classes.ButtonInactive} onClick={() => handleButton('sip')}>Monthly SIP</button>
          <button className={button == 'onetime' ? classes.activeButton : classes.ButtonInactive} onClick={() => handleButton('onetime')}>One Time</button>
        </Box>

        <Box mt={8} px={3}>
          <Slider classes={{
            thumb: classes.thumb,
            rail: classes.rail,
            track: classes.track,
            valueLabel: classes.valueLabel,
            mark: classes.mark
          }}
            defaultValue={5000} aria-label="Default" valueLabelDisplay="on"
            size="medium"
            valueLabelFormat={(x) => {
              return (
                <p style={{ color: "black" }}><span className={classes.rupees}>₹</span>{x.toLocaleString('en-IN')}</p>
              )
            }}
            min={500}
            max={100000}
            onChange={(e, value) => return_calculator(value)}
            onChangeCommitted={(e, value) => {
              console.log("Slider value after release:", value);
              // sliderAmountChangeIntheReturnCalculator(value)
              setSliderVlaueOnChangeEnd(value)
              // Add any other logic you want to perform after the slider is released
            }}
          />
        </Box>

        <Typography className={classes.title}>Over the past</Typography>
      

        <Box mt={3}>
          <Typography className={classes.text}>Total Investment of <span className={classes.rupees}>₹</span>{button == "monthly" ? (invest * yearButton * 12).toLocaleString('en-IN') : invest}</Typography>
          {/* <Typography className={classes.text}>would have become <span className={classes.rupees}>₹</span>{(totalReturn)?.toLocaleString('en-IN', { maximumFractionDigits: 0 })} <span style={{ color: "#0E76BC" }}>({get_return()}%)</span></Typography> */}
          <Typography className={classes.text}>This funds past return <span className={classes.rupees}> </span>  <span style={{ color: "#0E76BC" }}>({calculatedThisFund ? calculatedThisFund.toFixed(2) : '0'}%)</span></Typography>
          <Typography className={classes.text}>Profit % (Absolute Retrun, based on past data) <span className={classes.rupees}> </span>  <span style={{ color: "#0E76BC" }}>({calculatedProfit ? calculatedProfit.toFixed(2) : '0'}%)</span></Typography>
        </Box>
        {/* <Box display="flex" justifyContent="space-between" px={15} mt={2}>
          <button
            // className={button==12 ? classes.activeButton :classes.graphButtonInactive}
            // onClick={()=>{get_filteredData(12)}}
            className={button == 365 ? classes.activeButton : classes.graphButtonInactive}
            onClick={() => { get_filteredData(365) }}
          >
            1Y
          </button>
          <button
            // className={button==36 ? classes.activeButton :classes.graphButtonInactive}
            // onClick={()=>{get_filteredData(36)}}
            className={button == 1095 ? classes.activeButton : classes.graphButtonInactive}
            onClick={() => { get_filteredData(1095) }}
          >
            3Y
          </button>
          <button
            // className={button==60 ? classes.activeButton :classes.graphButtonInactive}
            // onClick={()=>{get_filteredData(60)}}
            className={button == 1825 ? classes.activeButton : classes.graphButtonInactive}
            onClick={() => { get_filteredData(1825) }}
          >
            5Y
          </button>
        </Box> */}
        <Box display='flex' justifyContent='center'>
        <ResponsiveContainer width={400} height={270} >

          <BarChart barGap={-30} width={300} height={250} data={barChartData}  
            margin={{ top: 50, right: 30, left: 20, bottom: 5 }}>
            <XAxis dataKey="name" xAxisId={0} allowDuplicatedCategory={false}
              //yAxisId="left"
              // dataKey="baseValue"
              // tick={false}
              tick={{ fontSize: 12 }}  // Set the font size for X-axis ticks
              label={{ fontSize: 14 }}
              axisLine={false} />
            <XAxis dataKey="name" xAxisId={1} hide allowDuplicatedCategory={false}
              //yAxisId="left"
              // dataKey="baseValue"
              label={false}
              tick={false}
              // tick={null}  // Remove X-axis ticks
              // label={null} // Remove X-axis label
              // scale="auto"
              axisLine={false} 
              />
            <YAxis allowDuplicatedCategory={false}
              //yAxisId="left"
              // dataKey="baseValue"
              label={false}
              tick={false}
              // tick={null}  // Remove X-axis ticks
              // label={null} // Remove X-axis label
              // scale="auto"
              hide
              axisLine={false}
            />
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <Tooltip />
            {/* <Legend /> */}

            <Bar dataKey="total" barSize={20} xAxisId={1} fill="#0E76BC" fillOpacity={1}>
              <LabelList dataKey="total" position="top" fill='#0E76BC' content={<CustomBarLabel fill="#ff0000" /> } offset={10}/>
            </Bar>

            <Bar dataKey="invested_amount" barSize={20} xAxisId={0} fill="#ccc" />


          </BarChart>
        </ResponsiveContainer>
        </Box>
        <Box mt={3}  display='flex' justifyContent='center'>
          <button className={yearButton == "1" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("1")}>1 Year</button>
          <button className={yearButton == "3" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("3")}>3 Year</button>
          <button className={yearButton == "5" ? classes.activeYearButton : classes.inactiveYearButton} onClick={() => handleYearButton("5")}>5 Year</button>
        </Box>
      </Box>
    </div>
  )
}

export default ReturnCal