import React, { useState, useEffect } from "react";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import { useParams, useLocation } from "react-router-dom";
import {
  GET_INSTALLMENT_BYID,
  STOP_SIP,
  STOP_SWP,
  STOP_STP,
  GET_STP_INSTALLMENTSS,
  GET_SWP_INSTALLMENTS,
} from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import moment from "moment";
import number_ordinals from "../../../../hooks/NumberOrdinal";
import ConfirmStopSip from "./ConfirmStopSip";
import StopSuccess from "./StopSuccess";

const MySip = () => {
  const useStyles = makeStyles({
    root: {
      border: "1px solid #DDDDDD",
      borderRadius: "5px",
    },

    boxBorder: {
      borderBottom: "1px solid #DDDDDD",
    },
    greyText: {
      color: "#C0C0C0",
      fontSize: "0.9rem",
    },
    text: {
      fontSize: "0.9rem",
    },
    buttonBorder: {
      border: "2px solid #0E76BC",
      borderRadius: "30px",
      color: "#0E76BC",
      padding: "0.8rem 3rem",
      background: "#fff",
      fontWeight: 500,
      cursor: "pointer",
    },
    verticleLine: {
      borderLeft: "0.5px dashed #C4C4C4",
      height: "50px",
      marginTop: "-5px",
      marginLeft: "9px",
    },
    transText: {
      fontSize: "0.8rem",
      color: "#676767",
    },
    rupees: {
      fontFamily: "Open Sans",
    },
    "@media(max-width:600px)": {
      buttonBorder: {
        padding: "0.8rem 0.6rem",
      },
    },
  });
  const classes = useStyles();

  const { id } = useParams();
  const { state } = useLocation();
  const { fundData, status } = state;
  const [msg, setMsg] = useState("");
  let sip_data = fundData;

  const [installmentData, setinstallmentData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [stop, setStop] = useState(false);

  const orderTypeObj = {
    2: "SIP",
    5: "STP",
    6: "SWP",
  };
  const orderTypeColor = {
    2: "#F27E45",
    5: "#4579F5",
    6: "#CB5D38",
  };

  const sort_intallments = (data) => {
    const arry = data?.sort(function (a, b) {
      return b.id - a.id;
    });
    return arry;
  };

  const get_sipData = async () => {
    const userId = localStorage.getItem("user_id");
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { userId: userId, sipId: id },
    };
    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { userId: userId, stpId: id },
    };
    const httpConfig2 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: { userId: userId, mf_redemption_plan: id },
    };

    try {
      if (fundData?.orderType == 2) {
        const response = await httpInstance(
          `${GET_INSTALLMENT_BYID}`,
          httpConfig
        );
        setinstallmentData(sort_intallments(response.data?.data));
      } else if (fundData?.orderType == 5) {
        const response = await httpInstance(
          `${GET_STP_INSTALLMENTSS}`,
          httpConfig1
        );
        setinstallmentData(sort_intallments(response.data?.data));
      } else {
        const response = await httpInstance(
          `${GET_SWP_INSTALLMENTS}`,
          httpConfig2
        );
        setinstallmentData(sort_intallments(response.data?.data));
      }
    } catch {}
  };

  useEffect(() => {
    if (fundData?.orderType == 2) {
      get_sipData();
    }
  }, []);

  //---------------stop SIP-----------------------------------------//

  const handle_stop_SIP = async () => {
    let httpConfig;

    const userId = localStorage.getItem("user_id");
    if (fundData?.orderType == 2) {
      httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        params: { userId: userId, sipId: sip_data?.id },
      };
    } else if (fundData?.orderType == 5) {
      httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: { userId: userId, stpId: sip_data?.id },
      };
    } else if (fundData?.orderType == 6) {
      httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: { userId: userId, swpId: sip_data?.id },
      };
    }

    try {
      if (fundData?.orderType == 2) {
        await httpInstance(`${STOP_SIP}`, httpConfig);
      } else if (fundData?.orderType == 5) {
        await httpInstance(`${STOP_STP}`, httpConfig);
      } else if (fundData?.orderType == 6) {
        await httpInstance(`${STOP_SWP}`, httpConfig);
      }

      setShowModal(false);
      setStop(true);
    } catch (error) {
      setMsg(error?.response?.data?.message);
    }
  };
  return (
    <div>
      {!stop ? (
        <>
          <Box className={classes.root} mt={3}>
            <Box className={classes.boxBorder} p={2} px={5}>
              <Grid container display="flex" alignItems="center">
                <Grid item xs={3} md={1}>
                  <img src={fundData?.imgUrl} height="50px" width="50px" />
                </Grid>
                <Grid item xs={9} md={9}>
                  {fundData?.plan}
                </Grid>
                <Grid item xs={12} md={2}>
                  <Box display="flex" flexDirection="column" alignItems="end">
                    <Typography>
                      <span className={classes.rupees}>₹</span>
                      {fundData?.amount?.toLocaleString("en-IN")}
                    </Typography>
                    <Typography className={classes.greyText}>
                      {status == "active"
                        ? `${orderTypeObj[fundData.orderType]} Amount`
                        : `Invested ${orderTypeObj[fundData.orderType]} Amount`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {status == "active" && (
              <Box p={2} px={5}>
                <Grid container display="flex" alignItems="center">
                  <Grid item xs={4}>
                    <Typography className={classes.greyText}>{`Next ${
                      orderTypeObj[fundData.orderType]
                    } on`}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex" justifyContent="center">
                      <Typography className={classes.text}>
                        {moment(fundData?.nextInstallmentDate).format(
                          "DD MMM YYYY"
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box display="flex" justifyContent="end">
                      <button
                        className={classes.buttonBorder}
                        onClick={() => setShowModal(true)}
                      >{`Stop ${orderTypeObj[fundData.orderType]}  `}</button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>

          <Box mt={4}>
            <Typography>Transaction History</Typography>
            {installmentData.length > 0 && (
              <Box mt={3}>
                {status == "active" && (
                  <Grid container>
                    <Grid item xs={1}>
                      <img src="/images/info.svg" height="20px" width="20px" />
                      <div className={classes.verticleLine}></div>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.transText}>
                        {number_ordinals(installmentData?.length + 1)}{" "}
                        {`${orderTypeObj[fundData?.orderType]}`} Due{" "}
                      </Typography>
                      <Typography className={classes.transText}>
                        in{" "}
                        {moment(fundData?.nextInstallmentDate).diff(
                          moment(new Date()),
                          "days"
                        )}{" "}
                        days
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {installmentData?.map((ele, i) => {
                  return (
                    <Grid key={ele.id} container>
                      <Grid item xs={1}>
                        <img
                          src={
                            ele.status == "SUCCESSFUL"
                              ? "/images/Icontick.svg"
                              : ele.status == "FAILED"
                              ? "/images/failed.svg"
                              : "/images/Icontimer.svg"
                          }
                          height="20px"
                          width="20px"
                        />
                        {i + 1 != installmentData?.length && (
                          <div className={classes.verticleLine}></div>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.transText}>
                          {number_ordinals(installmentData?.length - i)}{" "}
                          {`${orderTypeObj[fundData?.orderType]} ${
                            ele.status == "SUCCESSFUL"
                              ? fundData?.orderType === 2
                                ? "Paid"
                                : "Processed"
                              : ele.status == "FAILED"
                              ? "Failed"
                              : "Pending"
                          }`}{" "}
                        </Typography>
                        <Typography className={classes.transText}>
                          {moment(
                            ele.status == "SUCCESSFUL"
                              ? ele.succeededAt
                              : ele.status == "FAILED"
                              ? ele.failedAt
                              : ele.submittedAt || ele.createdAt
                          ).format("DD MMM YYYY hh:mm A")}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            )}
          </Box>
          <ConfirmStopSip
            show={showModal}
            handleClose={() => setShowModal(false)}
            handle_stop_SIP={handle_stop_SIP}
            type={orderTypeObj[fundData.orderType]}
            msg={msg}
          />
        </>
      ) : (
        <StopSuccess
          data={{
            plan_name: sip_data?.plan,
            logo: sip_data?.imgUrl,
            sipAmount: sip_data?.amount,
            type: orderTypeObj[fundData.orderType],
          }}
        />
      )}
    </div>
  );
};

export default MySip;
