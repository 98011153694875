import React, { useState } from "react";
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  Link,
} from "react-router-dom";
import Step1 from "../../../components/signup/complient/Step1";
import EmailOtp from "../../../components/signup/complient/EmailOtp";
import PhoneOtp from "../../../components/signup/complient/PhoneOtp";

const Index = () => {
  const [step, setStep] = React.useState(1);
  const [descriptionData, setDescriptionData] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const steps = searchParams.get("stepcount");

  const stepCount = (step) => {
    setStep(step);
  };

  const setData = (data) => {
    setDescriptionData({ ...descriptionData, ...data });
  };

  const useStyles = makeStyles((theme) => ({
    rotatedOne: {
      // content: ''; /* Create a pseudo-element */
      // position: absolute;
      // top: 0;
      // left: 0;
      // width: 100%;
      // height: 100%;
      // background: url('your-background-image.jpg'); /* Set your background image */

      // width: '886.68px',
      width: '1180px',
      height: '1180px',
      // bottom: '-100px',
      left: '-513px',
      top: '-442px',
      borderRadius: '100%',
      position: 'fixed',
      // angle: '154.95 deg',

      // barderRaduis: '106.11 deg',
      background: ' #25AAE1',
      transition: 'all 1s ease-out',
      // background: 'linear-gradient(to bottom, #1a237e, #283593)', /* Gradient colors */
      // transform: 'rotate(20deg)',
      // zIndex: '-1',
      // angle: 106.11 deg
      zIndex: '-1',
      [theme.breakpoints.up('lg')]: {
        left: '-513px',
        top: '-442px',
      },
      [theme.breakpoints.down('lg')]: {
        left: '-620px', // Adjust based on your requirement
        top: '-442px', // Adjust based on your requirement
      },
      [theme.breakpoints.down('md')]: {
        left: '-750px', // Adjust for smaller screens
        top: '-442px', // Adjust for smaller screens
      },
      [theme.breakpoints.down('sm')]: {
        left: '-850px', // Adjust for smaller screens
        top: '-442px', // Adjust for smaller screens
      },
    },
    rotatedTwo: {
      width: '1180px',
      height: '1180px',
      left: '-470px',
      top: '-442px',
      borderRadius: '50%',
      position: 'fixed',
      background: '#0E76BC',
      transition: 'all 1s ease-out',
      // background: 'linear-gradient(to bottom, #1a237e, #283593)', /* Gradient colors */
      // transform: 'rotate(20deg)',
      zIndex: '-2',
      
      [theme.breakpoints.up('lg')]: {
        left: '-470px',
        top: '-442px',
      },
      [theme.breakpoints.down('lg')]: {
        left: '-577px', // Adjust based on your requirement
        top: '-442px', // Adjust based on your requirement
      },
      [theme.breakpoints.down('md')]: {
        left: '-720px', // Adjust for smaller screens
        top: '-442px', // Adjust for smaller screens
      },
      [theme.breakpoints.down('sm')]: {
        left: '-820px', // Adjust for smaller screens
        top: '-442px', // Adjust for smaller screens
      },
    },
    section1: {
      // background: "#0E76BC",
      minHeight: "100vh",
      width: "30%",
      boxSizing: "border-box",
      // position: "sticky",
      position: "fixed",
      height: "100%",

    },
    sectionTwo: {
      height: "80vh",
      padding: "4rem 0",
      display: "flex",
      //alignItems: "center",
      //justifyContent: "center",
      flexDirection: "column",
      flex: 1,
      width: "70%",
      overflowY: "scroll",
      position: "sticky",
    },
    detailsBox: {
      margin: "0 auto",
    },
    headingOne: {
      // fontSize: "1.6rem",
      color: "white",
      fontWeight: 400,
      fontSize: '40px',
      // padding: "0px 2rem",
      // margin: '0 auto',
      // textAlign: 'center',
    },
    headingTwo: {
      // fontSize: "1.3rem",
      fontSize: '38px',
      color: "#fff",
      fontWeight: 400,
      textAlign: "center",

    },
    headingThree: {
      fontSize: "0.9rem",
      color: "#fff",
      fontWeight: 400,
      textAlign: "center",
    },
    paragraph: {
      fontSize: "0.7rem",
      color: "white",
    },
    "@media (max-width: 480px)": {
      section1: {
        display: "none",
      },
    },
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="row" flex={1} height="100%">
        <Box width={'30%'}>
          <Box className={classes.rotatedOne}>
          </Box>
          <Box className={classes.rotatedTwo}>
          </Box>
          <Box className={classes.section1} position={'fixed'} py={4}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="space-between"
              justifyContent="space-around"
              height="100%"
              px={2}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="center"
                flexDirection="column"
                className={classes.detailsBox}
              >
                <Box className={classes.headingOne} pl={4} mb={3}>
                  Investment made simple
                  {/* <br />  */}
                </Box>
              </Box>
              <img src="/images/pngtree-financial-expert-concept-with-woman-specialist-with-laptop-sit-on-stack.svg" height="318" width="100%" />
              <Box
                display="flex"
                alignItems="flex-start"
                justifyContent="center"
                flexDirection="column"
                className={classes.detailsBox}
              >
                {/* <Box className={classes.headingTwo} mt={3} textAlign="center">
                  Invest in Mutual Funds
                  // {/* <span style={{ color: "#6FDDB8" }}>
                  //    Mutual Funds
                  //   </span> 
                </Box>
                <Box className={classes.headingThree} mt={2}>
                  MF Wealth is secure and private
                </Box>
                <Link
                  to="/terms-conditions"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Box className={classes.paragraph} mt={1}>
                    Terms & Conditions *
                  </Box>
                </Link> */}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.sectionTwo}>
          {steps === null ? (
            <Step1 step={stepCount} setData={setDescriptionData} />
          ) : (
            ""
          )}
          {steps === "1" ? (
            // <EmailOtp step={stepCount} descriptionData={descriptionData} />
            <PhoneOtp step={stepCount} descriptionData={descriptionData} />
          ) : (
            ""
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Index;
