import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, Grid, Table, TableBody, TableCell, TableHead, TableRow, Container, Tab, Tabs, LinearProgress } from "@material-ui/core";
import { GET_HOLDINGS, GET_ASSEST_ALLOCATION } from '../../../../utils/endpoints'
import httpInstance from '../../../../utils/instance'
import { fontSize } from '@mui/system';
import PropTypes from 'prop-types';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={"span"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const AssestAllocation = () => {
    const useStyles = makeStyles((theme) => ({
        root: {

        },
        tableCellsLists: {

            "& .MuiLinearProgress-colorPrimary": {
                backgroundColor: "#25AAE1",
            },
            "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#0E76BC",
            },
        },
        title: {
            fontWeight: 600,
            padding: "0px 7rem",
            fontSize: "1.2rem"
        },
        tableHead: {
            fontWeight: 600
        },
        tab_box: {
            padding: "0px 0rem"
        },
        tab_style: {
            width: "100%",
            background: "white",
            color: "black",
            borderBottom: '1px solid #E5E3E3',
            "& .MuiTab-root": {
                fontFamily: "inherit",
                fontSize: "1rem",
                color: "black",
                opacity: 1,
                textTransform: "none",
                background: "none",
                minWidth: "auto",
                padding: "0px"
            },
            "& .MuiTab-textColorInheri": {
                opacity: 0.9
            },
            "& .MuiTabs-root": {
                height: "42px",
                minHeight: "42px",
                padding: "0px"
            },
            "& .MuiTabs-flexContainer": {
                display: "flex",
                justifyContent: "space-between"
            },
            "& .MuiTabs-scrollable": {
                overflowY: "clip"
            }


        },
        tabWidth: {
            width: '25%',
            color: '#272727',
            fontSize: '15px',
            fontFamily: 'Poppins',
            fontWeight: '400'
        },
        activeTab: {
            "& .MuiTab-wrapper": {
                borderBottom: "2px solid #0E76BC",
                color: "#0E76BC",
                paddingBottom: "5px",

            }

        },

        inActiveTab: {
            "& .MuiTab-wrapper": {
                paddingBottom: "5px",

            }

        },
        "@media (max-width: 600px)": {
            tab_box: {
                padding: "20px"
            },
            activeTab: {
                "& .MuiTab-wrapper": {
                    padding: "10px"
                }
            },
            inActiveTab: {
                "& .MuiTab-wrapper": {
                    padding: "10px"

                }
            },
        },
        "@media (min-width: 992px)": {
            tablefirstCell: {
                fontWeight: 600,
                width: "45%",
                paddingLeft: "7rem",

            },
            tableBodyfirstCell: {
                paddingLeft: "7rem",
                width: "45%",

            },
            tablelastCell: {
                fontWeight: 600,
                paddingRight: "5rem",

            },
            tableBodylastCell: {
                paddingRight: "5rem",
            },
        },
        viewAll: {
            fontSize: "0.9rem",
            fontWeight: 600,
            color: '#0E76BC',
            textDecoration: "underline",
            cursor: "pointer"
        }


    }))
    const classes = useStyles();

    const [holdingData, setHoldingData] = useState([])
    const [viewAll, setViewAll] = useState(false)
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const get_holding = async () => {
        // console.log(schemeCode)
        // const formData={
        //     scheme_code:schemeCode,
        //     as_on:"",
        //     portfolio_type:''
        // }
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            // test: 'test',
            headers: {
                "Content-Type": "application/json",
            },
            // data:formData,
        };
        try {
            const response = await httpInstance(`${GET_ASSEST_ALLOCATION}`, httpConfig)
            setHoldingData(response.data?.data)
            // holdingDataOfSector(response?.data?.data?.allocationBySector)
            // console.log(response?.data?.data?.allocationBySector)
        }
        catch {

        }
    }

    useEffect(() => {
        get_holding()
    }, [])

    const handle_viewAll = () => {
        setViewAll(!viewAll)
    }
    const getProgressColor = (percentage) => {
        // Define threshold values for different colors
        const redThreshold = 50;
        const yellowThreshold = 80;

        // Determine the color based on the percentage
        if (percentage < redThreshold) {
            return 'green'; // Green color for low percentages
        } else if (percentage < yellowThreshold) {
            return 'yellow'; // Yellow color for medium percentages
        } else {
            return 'red'; // Red color for high percentages
        }
    };



    return (
        <div>
            <Box mt={5}>
                <Typography className={classes.title}>Category Distribution</Typography>
                <TabPanel  >
                    <div style={{ width: 'auto', overflowX: 'auto' }} className={classes.tableCellsLists}>
                        <Table style={{ marginTop: "0rem", }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tablefirstCell} >Name</TableCell>
                                    <TableCell className={classes.tablelastCell}>Distribution</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {holdingData?.category_base_allocation &&
                                    <>
                                        {Object.entries(holdingData?.category_base_allocation)?.map(([sector, value], index) => (
                                            <TableRow key={index}>
                                                <TableCell className={classes.tableBodyfirstCell}>{sector}</TableCell>
                                                <TableCell className={classes.tableBodylastCell}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                                        <Typography variant="caption" color="textSecondary">{`${Math.round(value)}%`}</Typography>
                                                        <LinearProgress variant="determinate" value={value}
                                                            style={{ width: '50%', }}
                                                            // sx={{ color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8') }} thickness={10}
                                                            // sx={{
                                                            //     backgroundColor: `rgb(255,0,0,0.4)`,
                                                            //     "& .MuiLinearProgress-bar": {
                                                            //       backgroundColor: `rgb(0,255,0) !important`
                                                            //     }
                                                            // }}
                                                            color='primary'
                                                            valueBuffer="100"
                                                            // sx={{
                                                            //     "& .MuiLinearProgress-colorPrimary": {
                                                            //         backgroundColor: "red",
                                                            //     },
                                                            //     "& .MuiLinearProgress-barColorPrimary": {
                                                            //         backgroundColor: "red",
                                                            //     },
                                                            // }}
                                                            
                                                        />
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                }
                            </TableBody>
                        </Table>
                    </div>
                </TabPanel>
            </Box>
            <Box mt={5}>
                <Typography className={classes.title}>Fund Distribution</Typography>
                <Container className={classes.tab_box}>

                    <div className={classes.tab_style}>
                        <Tabs indicatorColor=" " value={value} onChange={handleChange} variant="scrollable" allowScrollButtonsMobil={true}>

                            <Tab label="Market Cap" {...a11yProps(0)} className={value == 0 ? classes.activeTab : classes.inActiveTab} />
                            <Tab label="Sector" {...a11yProps(1)} className={value == 1 ? classes.activeTab : classes.inActiveTab} />
                            <Tab label="Companies" {...a11yProps(2)} className={value == 2 ? classes.activeTab : classes.inActiveTab} />

                        </Tabs>
                    </div>

                </Container>


                <TabPanel value={value} index={0} >
                    <div style={{ width: 'auto', overflowX: 'auto' }} className={classes.tableCellsLists}>


                        <Table style={{ marginTop: "0rem", }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tablefirstCell} >Name</TableCell>
                                    {/* <TableCell className={classes.tableHead} >Sector</TableCell> */}
                                    <TableCell className={classes.tablelastCell}>Assets</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {(!viewAll ? holdingData?.allocationByMarketCap?.slice(0, 10) : holdingData?.allocationByMarketCap)?.map((ele, i) => {
                                    return ( */}
                                {/* <TableRow key={i}>
                                            <TableCell className={classes.tableBodyfirstCell}>{ele[i]}</TableCell>
                                            <TableCell className={classes.tableBodylastCell}>{ele[i].assetAercentage}%</TableCell>

                                        </TableRow> */}
                                {/* )
                                })} */}
                                <TableRow >
                                    <TableCell className={classes.tableBodyfirstCell}>{`Giant Cap`}</TableCell>
                                    <TableCell className={classes.tableBodylastCell}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography variant="caption" color="textSecondary">{`${Math.round(holdingData?.cap_base_allocation?.giant)}%`}</Typography>
                                            <LinearProgress variant="determinate" value={holdingData?.cap_base_allocation?.giant} style={{ width: '50%', }} color='primary'
                                             sx={{
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'red',
                                                },
                                            }} 
                                            valueBuffer="100"

                                            />
                                        </Box></TableCell>

                                </TableRow>
                                <TableRow >
                                    <TableCell className={classes.tableBodyfirstCell}>{`Large Cap`}</TableCell>
                                    <TableCell className={classes.tableBodylastCell}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography variant="caption" color="textSecondary">{`${Math.round(holdingData?.cap_base_allocation?.large)}%`}</Typography>
                                            <LinearProgress variant="determinate" value={holdingData?.cap_base_allocation?.large} style={{ width: '50%', }} color='primary' 
                                            sx={{
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'red',
                                                },
                                            }}
                                            valueBuffer="100"

                                            />
                                        </Box></TableCell>

                                </TableRow>
                                <TableRow >
                                    <TableCell className={classes.tableBodyfirstCell}>{`Mid Cap`}</TableCell>
                                    <TableCell className={classes.tableBodylastCell}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography variant="caption" color="textSecondary">{`${Math.round(holdingData?.cap_base_allocation?.mid)}%`}</Typography>
                                            <LinearProgress variant="determinate" value={holdingData?.cap_base_allocation?.mid} style={{ width: '50%', }} color='primary'
                                             sx={{
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'red',
                                                },
                                            }}
                                            valueBuffer="100"
                                            />
                                        </Box>




                                    </TableCell>

                                </TableRow>

                                <TableRow >
                                    <TableCell className={classes.tableBodyfirstCell}>{`Small Cap`}</TableCell>
                                    <TableCell className={classes.tableBodylastCell}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography variant="caption" color="textSecondary">{`${Math.round(holdingData?.cap_base_allocation?.small)}%`}</Typography>
                                            <LinearProgress variant="determinate" value={holdingData?.cap_base_allocation?.small} style={{ width: '50%', }} color='primary'
                                             sx={{
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'red',
                                                },
                                            }}
                                            valueBuffer="100"
                                            />
                                        </Box>
                                    </TableCell>

                                </TableRow>
                                <TableRow >
                                    <TableCell className={classes.tableBodyfirstCell}>{`Tiny Cap`}</TableCell>
                                    <TableCell className={classes.tableBodylastCell}>

                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography variant="caption" color="textSecondary">{`${Math.round(holdingData?.cap_base_allocation?.tiny)}%`}</Typography>
                                            <LinearProgress variant="determinate" value={holdingData?.cap_base_allocation?.tiny} style={{ width: '50%', }} color='primary'
                                             sx={{
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'red',
                                                },
                                            }}
                                            valueBuffer="100"
                                            />
                                        </Box>
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                    {/* <Box display="flex" justifyContent="center" mt={2}><Typography className={classes.viewAll} onClick={handle_viewAll}>{!viewAll ? "View All Holdings" : "View less"}</Typography></Box> */}


                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div style={{ width: 'auto', overflowX: 'auto' }} className={classes.tableCellsLists}>


                        <Table style={{ marginTop: "0rem" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tablefirstCell} >Name</TableCell>
                                    <TableCell className={classes.tablelastCell}>Assets</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {(!viewAll ? holdingData?.sector_base_alloction?.slice(0, 10) : holdingData?.sector_base_alloction)?.map((ele, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className={classes.tableBodyfirstCell}>{ele?.sector}</TableCell>
                                            <TableCell className={classes.tableBodylastCell}>

                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                                    <Typography variant="caption" color="textSecondary">{`${Math.round(ele?.percentage)}%`}</Typography>
                                                    <LinearProgress variant="determinate" value={ele?.percentage} style={{ width: '50%', }} color='primary' sx={{
                                                        '& .MuiLinearProgress-bar': {
                                                            backgroundColor: 'red',
                                                        },
                                                    }} />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })} */}
                                <>
                                    {holdingData?.sector_base_alloction &&
                                        <>
                                            {(!viewAll ? Object.entries(holdingData?.sector_base_alloction).slice(0, 10) : Object.entries(holdingData?.sector_base_alloction))?.map(([sector, value], index) => (
                                                <TableRow key={index}>
                                                    <TableCell className={classes.tableBodyfirstCell}>{sector}</TableCell>
                                                    <TableCell className={classes.tableBodylastCell}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                                            <Typography variant="caption" color="textSecondary">{`${Math.round(value)}%`}</Typography>
                                                            <LinearProgress variant="determinate" value={value} style={{ width: '50%' }} sx={{ color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8') }} thickness={10} />
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </>
                                    }
                                </>
                            </TableBody>
                        </Table>
                    </div>
                    <Box display="flex" justifyContent="center" mt={2}><Typography className={classes.viewAll} onClick={handle_viewAll}>{!viewAll ? "View All Holdings" : "View less"}</Typography></Box>

                </TabPanel>
                <TabPanel value={value} index={2} >
                    <div style={{ width: 'auto', overflowX: 'auto' }} className={classes.tableCellsLists}>


                        <Table style={{ marginTop: "0rem" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tablefirstCell} >Name</TableCell>
                                    <TableCell className={classes.tablelastCell}>Assets</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {(!viewAll ? Object.entries(holdingData?.stock_base_allocation)?.slice(0, 10) : Object.entries(holdingData?.stock_base_allocation))?.map((value, key, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className={classes.tableBodyfirstCell}>{key}</TableCell>
                                            <TableCell className={classes.tableBodylastCell}>

                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                                    <Typography variant="caption" color="textSecondary">{`${Math.round(value)}%`}</Typography>
                                                    <LinearProgress variant="determinate" value={value} style={{ width: '50%' }} sx={{ color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'), }} thickness={10} />
                                                </Box>
                                            </TableCell>

                                        </TableRow>
                                    )
                                })} */}
                                <>
                                    {holdingData?.stock_base_allocation &&
                                        <>
                                            {(!viewAll ? Object.entries(holdingData?.stock_base_allocation).slice(0, 10) : Object.entries(holdingData?.stock_base_allocation))?.map(([sector, value], index) => (
                                                <TableRow key={index}>
                                                    <TableCell className={classes.tableBodyfirstCell}>{sector}</TableCell>
                                                    <TableCell className={classes.tableBodylastCell}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                                            <Typography variant="caption" color="textSecondary">{`${Math.round(value)}%`}</Typography>
                                                            <LinearProgress variant="determinate" value={value} style={{ width: '50%' }} sx={{ color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8') }} thickness={10} />
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </>
                                    }
                                </>
                            </TableBody>
                        </Table>
                    </div>
                    <Box display="flex" justifyContent="center" mt={2}><Typography className={classes.viewAll} onClick={handle_viewAll}>{!viewAll ? "View All Holdings" : "View less"}</Typography></Box>

                </TabPanel>

            </Box>
        </div>
    )
}

export default AssestAllocation