import React from 'react'
// import Header from '../../components/header/Header'
import Header from "../../components/Header";
import Footer from '../../components/Footer'
import { isMobile } from "react-device-detect";
import FooterStatic from '../../components/FooterStatic';
import Cancellation_Policys from '../../containers/About/Cancellation_Policys';

const Cancellation_Policy = () => {
  return (
    <div>
        {!isMobile && <Header/>}
        <Cancellation_Policys/>
        { !isMobile &&  <FooterStatic/>}
    </div>
  )
}

export default Cancellation_Policy