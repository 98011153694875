import React from 'react'
import SpecificGoal from '../../../containers/Goals/SpecificGoal'

const specific_goals = () => {
  return (
    <div>
        <SpecificGoal/>
    </div>
  )
}

export default specific_goals