import React from 'react'
import {
    Box,
    Typography,
    makeStyles,
    Container
} from "@material-ui/core";
import { Subtitles } from '@mui/icons-material';

const Contactus = () => {
    const useStyles = makeStyles((theme) => ({
        header: {
            fontSize: "1.5rem",
            fontWeight: 500
        },

        text: {
            fontSize: "0.9rem",
            color: '#000',
        },
        text1: {
            fontSize: "1.3rem",
            fontWeight: 600,
        },
        subtitles: {
            fontSize: "1.1rem",
            fontWeight: 500
        }
    }))

    const classes = useStyles()
    return (
        <div>
            <Box py={7}>
                <Container >
                    <Typography className={classes.header}>CONTACT US</Typography>

                    <Box my={2}>
                        <Typography className={classes.text1}>
                            Email Address:
                        </Typography>
                        <Typography className={classes.text}>
                            {/* Infiwealth Financial Services LLP, <span style={{fontWeight: 600}}>(Brand Name - MF Wealth)</span>, having its principal place of business at 3rd floor, D13, Noida Sector 3, Uttar Pradesh - 201301 */}
                            <a href="mailto:support@mfwealth.co.in" style={{ textDecoration: 'none' }} className={classes.text}>&nbsp; support@mfwealth.co.in</a>

                        </Typography>
                        <Typography className={classes.text}>

                            <a href="mailto:service@mfwealth.co.in" style={{ textDecoration: 'none' }} className={classes.text}>&nbsp; service@mfwealth.co.in</a>
                        </Typography>

                    </Box>

                    {/* <Typography className={classes.header}>AUGMONT GOLD FOR ALL</Typography> */}
                    <Box my={2} >
                        <Typography className={classes.text1}>
                            Mobile:
                        </Typography>
                        <Typography className={classes.text}>
                            {/* Infiwealth Financial Services LLP is a registered distributor with Association of Mutual Funds in India (AMFI) vide registration no. (ARN) 296490 under one its Partner. */}
                            <a href="tel:+919717438991" style={{ textDecoration: 'none' }} className={classes.text}>+91 9717438991</a>

                        </Typography>
                        <Typography className={classes.text}>
                            {/* Infiwealth Financial Services LLP is a registered distributor with Association of Mutual Funds in India (AMFI) vide registration no. (ARN) 296490 under one its Partner. */}
                            <a href="tel:+919717004381" style={{ textDecoration: 'none' }} className={classes.text}>+91 9717004381</a>

                        </Typography>
                    </Box>
                    <Box my={2} >
                        <Typography className={classes.text1}>
                            Phone:
                        </Typography>
                        <Typography className={classes.text}>
                            {/* Infiwealth Financial Services LLP is a registered distributor with Association of Mutual Funds in India (AMFI) vide registration no. (ARN) 296490 under one its Partner. */}
                            <a href="tel:01204151763" style={{ textDecoration: 'none' }} className={classes.text}>0120-4151763</a>

                        </Typography>
                    </Box>

                </Container>
            </Box>

        </div>
    )
}

export default Contactus