import React, { useEffect, useState } from "react";
import TitleLayoult from "../TitleLayoult";
import { useDropzone } from "react-dropzone";
import { Box } from "@material-ui/core";
import FileUpload from "../uploader/FileUpload";
import { useStyles } from "../styles";
import ErrorMessage from "../../Validation/ErrorMessage";
import { UPLOAD_PAN, GET_UPLOADED_PAN, GET_KYC_DATA } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams } from "react-router-dom";
import { namespace } from "../../../environment";

function UploadPan() {
  const navigate = useNavigate();

  const classes = useStyles();

  const [panUrl, setPanUrl] = useState("");
  const [nextbutton, setNextButton] = useState(false);

  useEffect(async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // const response = await httpInstance(`${GET_KYC_DATA}`, httpConfig);
      const response = await httpInstance(`${GET_UPLOADED_PAN}`, httpConfig);
      // setPanUrl(response.data?.data?.userKycFilesData?.panPublicUrl);
      setPanUrl(response.data?.data?.front_document_path)
    } catch {}
  }, []);

  const submit_signature = async (acceptedFile, handleNext) => {
    console.log('asdfasdfa')
    console.log(acceptedFile)
    console.log(handleNext)
    const formData = new FormData();
    // formData.append("pan_file", acceptedFile[0]);
    formData.append("front_side_file", acceptedFile[0]);
    formData.append("user_id", localStorage.getItem("user_id"));
    formData.append("type", "proof_of_identity");
    formData.append("document_type", "pan")

    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };
    try {
      await httpInstance(`${UPLOAD_PAN}`, httpConfig);
      setNextButton(true);
      // navigate({
      //   pathname: `${namespace}/signIn`,
      //   search: `?${createSearchParams({stepcount:"6"})}`,
      // });
    } catch {}
  };

  const handleNext = () => {
    navigate({
      pathname: `${namespace}/signIn`,
      // search: `?${createSearchParams({ stepcount: "6" })}`,
      search: `?${createSearchParams({ stepcount: "25" })}`,
    });
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/signIn`,
      search: `?${createSearchParams({ stepcount: "18" })}`,
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={20}
        className={classes.backButton}
        onClick={handleback}
      >
        <img src="/images/backIcon.svg" />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="flex-start" flexDirection={"column"}>
          <TitleLayoult
            title={"Upload Proof Of Identity"}
            description={"Please Upload PAN Card as Proof of Identity"}
            align={""}
          />
          <Box>
            <FileUpload
              handleSubmit={submit_signature}
              fileUrl={panUrl}
              handleNext={handleNext}
              nextbutton={nextbutton}
              validation={/\.(png|jpg)$/}
              type=".png/.jpg"
            />
          </Box>
          {/* {uploadCount === 3 ? (
        <Box mt={3}>
          <button className={classes.primaryButton} >
            Next
          </button>
        </Box>
      ) : (
        ""
      )} */}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default UploadPan;
