import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, createSearchParams } from "react-router-dom";
import { REJECT_KYC } from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";
import { namespace } from "../../../../environment";

function RejectedReason({ showModal, handleCloseModal }) {
  const useStyles = makeStyles((theme) => ({
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
      cursor: "pointer",
    },

    title: {
      fontSize: "1.2rem",
      fontWeight: "bold",
      textAlign: "center",
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "center",
      alignItems: " center",
      textAlign: "center",
      padding: "5px",
    },
    dialogPaper: {
      maxWidth: "25rem",
      justifyContent: "center",
    },
    image: {
      textAlign: "center",
    },
    text: {
      fontSize: "0.8rem",
      //textAlign: "center",
    },
    primaryButton: {
      padding: "0.8rem 0.3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.9rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      width: "100%",
    },
    dialogContent: {
      padding: "8px 30px",
    },
  }));

  const classes = useStyles();

  const navigate = useNavigate();

  const handleRetakeKyc = async () => {
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      await httpInstance(`${REJECT_KYC}`, httpConfig);
      navigate({
        pathname: `${namespace}/signIn`,
        search: `?${createSearchParams({ stepcount: "4" })}`,
      });
    } catch {}
  };

  return (
    <div>
      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        classes={{ paper: classes.dialogPaper }}
      >
        <Box display="flex" className={classes.closeIcon}>
          {" "}
          <CloseIcon onClick={handleCloseModal} />
        </Box>

        <DialogTitle
          classes={{ paper: classes.dialogPaper, root: classes.dialogTitle }}
        >
          <Typography className={classes.title}>KYC Rejected</Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Typography className={classes.text}>Dear Abhishek ,</Typography>
          <Typography className={classes.text}>
            Your KYC application has been rejected. We request you to undergo
            the KYC application process on our website or visit the nearest
            ICICI Prudential Mutual Fund branch.
          </Typography>
          <Typography className={classes.text}>
            The reason for rejection is/are as follows:
          </Typography>
          <ul className={classes.text}>
            <li>Name mismatch with pan</li>
            <li>Name mismatch with proof of address</li>
            <li> Pan number mismatch with pan card</li>
          </ul>

          <Box my={3}>
            <button
              className={classes.primaryButton}
              type="button"
              onClick={handleRetakeKyc}
            >
              Retake KYC
            </button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default RejectedReason;
