import React from 'react'
import Lottie from 'react-lottie';
import animationData from '../../../lotties/payment_failed.json';
import { Box,makeStyles, } from '@material-ui/core';
import { Link } from 'react-router-dom';

const PaymentFailed = ({cancel,retry}) => {
    const useStyles = makeStyles((theme) => ({
        title:{
           fontSize:"1.2rem",
           fontWeight:500,
           margin:"3px"
        },
        textgrey:{
            fontSize:"0.85rem",
            color:" #797979",
            margin:"3px"
          },
          primaryButton: {
            padding: "0.8rem 5rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
            fontFamily:"inherit"
           
          },

          secondaryButton: {
            padding: "0.7rem 5rem",
            background: "#fff",
            color: "#0E76BC",
            fontSize: "0.8rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "1px solid #0E76BC",
            cursor: "pointer",
            fontFamily:"inherit",
            marginRight:"1rem",
            
           
          },

    }))
    const classes = useStyles();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
  return (
    <div>
        <Box mt={10}>
        <Lottie 
	    options={defaultOptions}
        height={100}
        width={100}
      />
        </Box>
        <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" mt={5}>
            <p className={classes.title}>Payment Failed</p>
            <p className={classes.textgrey}>Your Payment failed, you can try again or cancel transaction</p>
        </Box>
        <Box display="flex" justifyContent="center" mt={20}>
          <Link to={cancel}><button  className={classes.secondaryButton}>Cancel</button></Link>  
          <Link to={retry}><button className={classes.primaryButton}>Try again</button></Link>  
        </Box>
    </div>
  )
}

export default PaymentFailed