import React,{useState} from 'react';
import {Box} from '@material-ui/core'
import AutoRebalancing from './autoRebalancing';
import FilteredFundHouse from './filteredFundHouse';
import DIY from './diy';
import GetStarted from './GetStarted';
import { Button, makeStyles } from '@material-ui/core';
import AssistedOrderSummary from '../../Assisted/Dashboard/AssistedOrderSummary';
import AssistedExpired from '../../Assisted/Dashboard/AssistedExpired';
import { useSelector } from 'react-redux';
import Kyc_status from './KycStatusCheck/Kyc_status';
import ErrorMessage from '../../../components/Validation/ErrorMessage';


const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    btnCenter:{
        alignItems:'center',
        justifyContent:'center',
        display:'flex',
        marginTop:'3rem',
      },
      unlockBtn:{
        color: 'white',
        backgroundColor: "#0E76BC",
        border:"none",
        borderRadius:'39px',
        fontSize:'15px',
        fontWeight:'500',
        padding:"10px 3rem",
        fontFamily:"inherit",
        cursor:"pointer",
        outline:"none",
        '&:hover': {
          backgroundColor: "#0E76BC",
        },
    }
  });
const colors = ["Slide1", "Slide2", "Slide3"];
const delay = 3000;
export default function Slideshow() {
const classes = useStyles();
  const [index, setIndex] = React.useState(0);
  const [unlock, setUnlock] = React.useState(false);
  const [kycStatus,setKycStatus]=useState(false)

  const timeoutRef = React.useRef(null);


  const {userStore}=useSelector((state)=>state)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  const handle_unlock=()=>{
   if(userStore?.user?.isOnBoardingCompleted){
    setUnlock(!unlock)
   }else{
     let kyc_status=localStorage.getItem("kycStatus")
     if(kyc_status=="submitted" || kyc_status=="successful"){
      setKycStatus("Your KYC is under review")
     }else{
      setKycStatus("Please complete your KYC")
     }
   }
  }

  return (
    <div>
    <div className="slideshow">
        {unlock ?
            <AssistedOrderSummary/>
            :
           userStore?.user?.assistedServiceUserDetailsId?.is_expired ? 
            <AssistedExpired handleResubscribe={handle_unlock}/> :
            <>
            
          <div
            className="slideshowSlider"
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
            {colors.map((slider, index) => (
                <div className="slide" key={index}>
                    {slider=='Slide1'?
                    <DIY/>
                    :slider=='Slide2'?
                    <FilteredFundHouse/>
                    : <AutoRebalancing/>}
                </div>
            ))}
          </div>
        <div className={classes.btnCenter}>
            <button onClick={handle_unlock} className={classes.unlockBtn} variant="contained">Unlock Now</button>
           
        </div>
        <Box display="flex" justifyContent="center">
        {kycStatus && <ErrorMessage message={kycStatus}/>}
        </Box>
        </>
    }
    </div>
    </div>
  );
}


