import { Box, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { GET_USER_ADDRESS, DELETE_ADDRESS } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams, Link } from "react-router-dom";
import AddAddress from "./AddAddress";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import { namespace } from "../../../environment";
const DeliveryAddress = ({ handleAddressId }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      border: "1px solid #C4C4C4",
      borderRadius: "10px",
      // padding:"5px 85px",
      marginTop: "1rem",
      fontSize: "0.8rem",
      fontWeight: 400,
      //width:"55%"
    },

    blueBorder: {
      border: "3px solid #0E76BC",
      borderRadius: "10px",
      marginTop: "1rem",
      fontSize: "0.8rem",
      fontWeight: 400,
    },

    boxWidth: {
      width: "35%",
    },
    name: {
      fontSize: "0.8rem",
      fontWeight: 600,
    },
    deleteText: {
      fontSize: "0.7rem",
      color: "#F32424",
      cursor: "pointer",
      width: "fit-content",
    },

    checkboxLabel: {
      fontSize: "0.7rem",
    },

    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontFamily: "inherit",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
    },

    secondaryButton: {
      padding: "0.7rem 2rem",
      background: "#fff",
      color: "#0E76BC",
      fontSize: "0.8rem",
      fontFamily: "inherit",
      fontWeight: 600,
      borderRadius: "45px",
      border: "1px solid #0E76BC",
      marginRight: "15px",
      cursor: "pointer",
    },

    "@media (max-width:480px)": {
      boxWidth: {
        width: "75%",
      },
      primaryButton: {
        padding: "0.8rem 2rem",
      },
      secondaryButton: {
        padding: "0.7rem 1rem",
      },
    },
  }));

  const classes = useStyles();
  //--------------------------------------------------------------------------------------------------------------------------------//
  const [address, setAddress] = useState([]);
  const [addAddress, setAddAddress] = useState(false);
  const [billingAddressList, setBillingAddressList] = useState([]);
  const [deliveryAddressObj, setDeliveryAddressObj] = useState({});
  const [sameforBilling, setSameForBilling] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [error, setError] = useState(false);
  const [deleteError, setDeleteError] = useState(false);

  const handleCloseAddAddress = () => {
    setAddAddress(false);
  };

  const navigate = useNavigate();

  const get_address = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_USER_ADDRESS}`, httpConfig);
      setAddress(response.data?.data);
      setSelectedAddress({
        deliveryAddress: response.data?.data[0]?.userAddressId,
      });
    } catch {}
  };

  useEffect(() => {
    get_address();
  }, []);

  //--------------------------set billing address list---------------------------------------//

  const set_address = () => {
    const arr = address.filter((ele) => {
      return ele.userAddressId != selectedAddress.deliveryAddress;
    });
    setBillingAddressList(arr);
    const delivery = address.filter((ele) => {
      return ele.userAddressId == selectedAddress.deliveryAddress;
    });
    setDeliveryAddressObj(delivery[0]);
  };

  useEffect(() => {
    set_address();
  }, [selectedAddress.deliveryAddress, address]);

  //-------------handlecheckbox--------------------------------//

  const handleCheckbox = (e) => {
    setSameForBilling(e.target.checked);

    setSelectedAddress({ ...selectedAddress, billingAddress: "" });
  };

  //-----------------handle delivery address select------------------------------------//
  const handleDeliveryAddress = (id) => {
    if (sameforBilling) {
      setSelectedAddress({ deliveryAddress: id, billingAddress: id });
    } else {
      setSelectedAddress({ deliveryAddress: id, billingAddress: "" });
    }
  };

  //-------------------------handle billing address----------------------------------------//
  const handleBillingAddress = (id) => {
    setSelectedAddress({ ...selectedAddress, billingAddress: id });
  };

  const handleCheckOut = () => {
    if (!sameforBilling && !selectedAddress.billingAddress) {
      setError(true);
    } else {
      setError(false);
      handleAddressId({
        shippingUserAddressId: selectedAddress.deliveryAddress,
        billingUserAddressId: sameforBilling
          ? selectedAddress.deliveryAddress
          : selectedAddress.billingAddress,
        isShippingSameAsBilling: sameforBilling,
      });
      navigate({
        pathname: `${namespace}/myportfolio/delivery`,
        search: `?${createSearchParams({ tab: "goldcoin" })}`,
      });
    }
  };

  const handleDelete = async (userId) => {
    setDeleteError(false);
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: { id: userId },
    };

    try {
      await httpInstance(`${DELETE_ADDRESS}`, httpConfig);
      get_address();
    } catch (error) {
      setDeleteError(error.response.data?.message);
    }
  };
  return (
    <>
      {sameforBilling ? (
        <div>
          <Box px={{ xs: 2, md: 10 }} pt={2}>
            {" "}
            <Link to={`${namespace}/myportfolio/delivery?tab=getDelivery`}>
              <img src="/images/backIcon.svg" height="20px" width="20px" />
            </Link>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            {address?.map((ele) => {
              return (
                <Box key={ele.id} className={classes.boxWidth}>
                  <Box
                    key={ele.id}
                    className={
                      selectedAddress.deliveryAddress === ele.userAddressId ||
                      selectedAddress.billingAddress === ele.userAddressId
                        ? classes.blueBorder
                        : classes.root
                    }
                    px={3}
                  >
                    <Box
                      style={{
                        borderBottom: "1px solid #C4C4C4",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeliveryAddress(ele.userAddressId)}
                    >
                      <p className={classes.name}>{ele.fullName}</p>
                      <p>
                        {ele.address}, {ele.city?.name}
                      </p>
                      <p>
                        {ele.state?.name} - {ele.pincode}
                      </p>
                      <p>Phone :{ele.mobileNumber}</p>
                    </Box>
                    <p
                      className={classes.deleteText}
                      onClick={() => handleDelete(ele.id)}
                    >
                      Delete
                    </p>
                  </Box>
                  {ele.userAddressId === selectedAddress.deliveryAddress && (
                    <Box mt={1} display="flex" alignItems="center">
                      <input
                        type="checkbox"
                        onChange={handleCheckbox}
                        checked={sameforBilling ? true : false}
                      />
                      <label className={classes.checkboxLabel}>
                        Select the same address for Billing
                      </label>
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        </div>
      ) : (
        <div>
          <Box px={10} pt={2}>
            {" "}
            <img
              src="/images/backIcon.svg"
              height="20px"
              width="20px"
              onClick={() => {
                setSameForBilling(true);
              }}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            {/* delivery address */}
            <Box className={classes.boxWidth}>
              <Box className={classes.blueBorder} px={3}>
                <Box style={{ borderBottom: "1px solid #C4C4C4" }}>
                  <p className={classes.name}>{deliveryAddressObj.fullName}</p>
                  <p>
                    {deliveryAddressObj.address},{" "}
                    {deliveryAddressObj.city?.name}
                  </p>
                  <p>
                    {deliveryAddressObj.state?.name} -{" "}
                    {deliveryAddressObj.pincode}
                  </p>
                  <p>Phone :{deliveryAddressObj.mobileNumber}</p>
                </Box>
                <p
                  className={classes.deleteText}
                  onClick={() => {
                    setSameForBilling(true);
                    handleDelete(deliveryAddressObj.id);
                  }}
                >
                  Delete
                </p>
              </Box>
              <Box mt={1} display="flex" alignItems="center">
                <input
                  type="checkbox"
                  onChange={handleCheckbox}
                  checked={sameforBilling ? true : false}
                />
                <label className={classes.checkboxLabel}>
                  Select the same address for Billing
                </label>
              </Box>
            </Box>
            {/* billing address list */}
            <Box
              display="flex"
              alignItems="start"
              className={classes.boxWidth}
              mt={4}
            >
              <p style={{ margin: "3px" }}>Select Billing Address</p>
            </Box>
            {billingAddressList?.map((ele) => {
              return (
                <Box key={ele.id} className={classes.boxWidth}>
                  <Box
                    key={ele.id}
                    className={
                      selectedAddress.deliveryAddress === ele.userAddressId ||
                      selectedAddress.billingAddress === ele.userAddressId
                        ? classes.blueBorder
                        : classes.root
                    }
                    px={3}
                    onClick={() => handleBillingAddress(ele.userAddressId)}
                  >
                    <Box style={{ borderBottom: "1px solid #C4C4C4" }}>
                      <p className={classes.name}>{ele.fullName}</p>
                      <p>
                        {ele.address}, {ele.city?.name}
                      </p>
                      <p>
                        {ele.state?.name} - {ele.pincode}
                      </p>
                      <p>Phone :{ele.mobileNumber}</p>
                    </Box>
                    <p
                      className={classes.deleteText}
                      onClick={() => handleDelete(ele.id)}
                    >
                      Delete
                    </p>
                  </Box>
                  {ele.userAddressId === selectedAddress.deliveryAddress && (
                    <Box mt={1} display="flex" alignItems="center">
                      <input
                        type="checkbox"
                        onChange={handleCheckbox}
                        checked={sameforBilling ? true : false}
                      />
                      <label className={classes.checkboxLabel}>
                        Select the same address for Billing
                      </label>
                    </Box>
                  )}
                </Box>
              );
            })}
            {error && <ErrorMessage message="Please select Billing Address" />}
          </Box>
        </div>
      )}
      <Box display="flex" justifyContent="center" alignItems="center">
        {deleteError && <ErrorMessage message={deleteError} />}
      </Box>

      <Box mt={6} display="flex" justifyContent="center" alignItems="center">
        <button
          className={classes.secondaryButton}
          onClick={() => setAddAddress(true)}
        >
          Add New Address
        </button>
        {address.length > 0 && (
          <button className={classes.primaryButton} onClick={handleCheckOut}>
            CHECK-OUT
          </button>
        )}
      </Box>

      <AddAddress
        dialog={addAddress}
        closeDialog={handleCloseAddAddress}
        get_address={get_address}
      />
    </>
  );
};

export default DeliveryAddress;
