import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, Grid, Slider, } from "@material-ui/core";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList

} from "recharts";

import httpInstance from '../../utils/instance'
import { GET_HOLDINGS, GET_FUND_HOUSING_DETAILS } from '../../utils/endpoints';
import { namespace, BASE_URL } from "../../environment";
import moment from "moment";


const FundHouseDetails = ({ schemeCode, fundData }) => {
    const useStyles = makeStyles((theme) => ({

        // graphButtonInactive: {
        //   border: "none",
        //   background: "none",
        //   padding: "5px",
        //   borderRadius: "10px",
        //   fontSize: "0.7rem",
        //   cursor: "pointer",
        //   outline: "none",
        //   "&:hover": {
        //     background: "#6FDDB8",
        //     color: "white",
        //     padding: "5px",
        //   },
        //   "&:active": {
        //     background: "#6FDDB8",
        //     color: "white"
        //   }
        // },

        // activeButton: {
        //   background: "#6FDDB8",
        //   color: "white",
        //   padding: "5px",
        //   border: "none",
        //   borderRadius: "10px",
        //   fontSize: "0.7rem",
        //   cursor: "pointer",
        //   outline: "none",
        // },

        root: {
            marginTop: "3rem",

        },
        title: {
            fontWeight: 600,
            padding: "0px 8rem",
            fontSize: "1.2rem"
        },

        borderBox: {
            // border: "1px solid #E4E4E4",
            borderRadius: "5px",
            padding: "1.5rem",
            margin: "0 7rem",
            // width: "75%",
            '& .MuiSlider-valueLabel': {
                '& > *': {

                    backgroundColor: 'white',
                    color: "black"
                },
            }
        },
        ButtonInactive: {
            border: "none",
            background: "none",
            padding: "5px 20px",
            borderRadius: "20px",
            fontSize: "0.75rem",
            fontFamily: "inherit",
            marginRight: "1rem",
            marginLeft: "1rem",
            cursor: "pointer",
            outline: "none",
            "&:hover": {
                background: "#0E76BC",
                color: "white",

            },
            "&:active": {
                background: "#0E76BC",
                color: "white"
            }
        },

        activeButton: {
            background: "#0E76BC",
            color: "white",
            padding: "5px 20px",
            border: "none",
            borderRadius: "20px",
            fontSize: "0.75rem",
            fontFamily: "inherit",
            marginRight: "1rem",
            marginLeft: "1rem",
            cursor: "pointer",
            outline: "none",
        },
        activeYearButton: {
            background: "#0E76BC",
            color: "white",
            padding: "5px 20px",
            border: "none",
            borderRadius: "20px",
            fontSize: "0.7rem",
            fontWeight: 700,
            fontFamily: "inherit",
            marginRight: "1rem",
            marginLeft: "1rem",
            cursor: "pointer",
            outline: "none",

        },

        inactiveYearButton: {
            border: "1px solid #0E76BC ",
            background: "none",
            padding: "5px 20px",
            borderRadius: "20px",
            fontSize: "0.7rem",
            fontWeight: 700,
            fontFamily: "inherit",
            marginRight: "1rem",
            marginLeft: "1rem",
            color: "#0E76BC",
            cursor: "pointer",
            outline: "none",
            "&:hover": {
                background: "#0E76BC",
                color: "white",

            },
            "&:active": {
                background: "#0E76BC",
                color: "white"
            }
        },

        text: {
            fontSize: "0.8rem"
        },

        // title: {
        //   fontSize: "0.9rem",
        //   marginTop: "1rem"
        // },

        thumb: {
            background: "#F3C04F",
            width: "15px",
            height: "15px",
            boxShadow: 'none !important',
            ' &.Mui-focusVisible': {
                boxShadow: 'none !important',
            },
            '&.Mui-active': {
                boxShadow: 'none !important',
            },

        },
        mark: {
            background: "black"
        },
        rail: {
            background: "#FFE3A4",
            height: "5px"
        },
        track: {
            background: "#F3C04F",
            height: "5px"
        },
        rupees: {
            fontFamily: "Open Sans"
        },
        "@media (max-width: 600px)": {
            activeButton: {
                marginRight: "0rem",
            },
            ButtonInactive: {
                marginRight: "0rem",
            },
            activeYearButton: {
                marginRight: "0.5rem",
            },
            inactiveYearButton: {
                marginRight: "0.5rem",
            }
        }

    }))
    const classes = useStyles();

    const [button, setButton] = useState('sip')
    const [yearButton, setYearButton] = useState('1Y')
    const [totalReturn, setTotalReturn] = useState()
    const [invest, setInvest] = useState(5000)

    const [sliderVlaueOnChangeEnd, setSliderVlaueOnChangeEnd] = useState(5000)
    const [calculatedThisFund, setCalculatedThisFund] = useState(null);
    const [calculatedProfit, setCalculatedProfit] = useState(null)
    const [fundHouseDetails, setFundHouseDetails] = useState()






    const get_holding = async () => {
        // console.log(schemeCode)
        // const formData={
        //     scheme_code:schemeCode,
        //     as_on:"",
        //     portfolio_type:''
        // }
        const httpConfig = {
            method: "GET",
            // tokenType: "user",
            test: 'test',
            // headers:{
            //   "Content-Type": "application/json",
            // },
            // data:formData,
        };
        try {
            const response = await httpInstance(`${GET_FUND_HOUSING_DETAILS}?fundPlanId=${schemeCode}`, httpConfig)
            setFundHouseDetails(response.data?.data)
            // console.log(response?.data?.data, 'response')
        }
        catch {

        }
    }
    // console.log(holdingData)
    useEffect(() => {
        get_holding()
    }, [])






    return (
        <div className={classes.root}>
            <Typography className={classes.title}>Fund Housing Details</Typography>
            <Box className={classes.borderBox} mt={3}>
                {/* <Box px={{ xs: 2, md: 10 }} > */}

                {/* <Box display='flex' justifyContent='center'> */}
                {(fundHouseDetails)?.map((ele, i) => {
                    return (
                        // <Box   display='flex' flexDirection='column'>
                        <Box container key={ele.id} mt={3} display='flex' alignItems='center'>
                            {/* <Grid style={{ width: '100px' }}>
                                <Box item >
                                    {ele?.person?.personName.charAt(0)}
                                </Box>

                            </Grid> */}
                            <Grid item xs={3}>
                                <Box item >
                                    {ele?.person?.personName}
                                    {/* Add other details you want to display here */}
                                </Box>
                                <Box item>
                                    Since {ele?.dateFrom}
                                    {/* Add other details you want to display here */}
                                </Box>
                            </Grid>
                            <Grid item xs={9}>
                                <Box item display='flex' flexDirection='column'>
                                    <Box>
                                        Education:
                                    </Box>
                                    {ele?.person?.education}
                                    {/* Add other details you want to display here */}
                                </Box>
                                <Box item display='flex' flexDirection='column'>
                                    <Box>
                                        Experience:
                                    </Box>
                                    {ele?.person?.experience}
                                    {/* Add other details you want to display here */}
                                </Box>
                            </Grid>
                        </Box>
                        // </Box>
                    )
                })}
                {/* </Box> */}
                {/* <Box mt={3} display='flex' >
                    <Grid item xs={2} md={1} display='flex' alignItems='center' justifyContent='center'>
                        <img
                            src={`${BASE_URL}${fundData?.amc?.logoUrl}`}
                            height="auto" width="70px" />
                    </Grid>
                    <Grid item xs={4} display='flex' alignItems='center' justifyContent='center' flexDirection='row'>
                        <Typography>{fundData?.schemeName} </Typography>
                        <Box display='flex' flexDirection='row' alignItems='center'>
                            <Typography className={classes.subText}>As of {moment(fundData?.amc?.updatedAt).format("DD MMM YYYY")} </Typography>

                        </Box>


                    </Grid>
                    <Grid item xs={7} md={2}>
                        <Typography className={classes.text}>

                            {fundData?.amc?.address1}
                        </Typography>
                        <Typography className={classes.text}>

                            {fundData?.amc?.address2}
                        </Typography>
                        <Typography className={classes.text}>

                            {fundData?.amc?.address3}
                        </Typography>
                        <Typography className={classes.text}>{fundData?.amc?.phone}</Typography>
                    </Grid>

                </Box> */}
                <Box mt={3} display='flex'>
                    <Grid container>
                        <Grid item xs={2} md={1} container alignItems='center' justifyContent='center'>
                            <img src={`${BASE_URL}${fundData?.amc?.logoUrl}`} height="auto" width="70px" />
                        </Grid>
                        <Grid item xs={6} container alignItems='center' justifyContent='center' flexDirection='row'>
                            <Box  >

                                <Typography>{fundData?.amc?.amcFullName}</Typography>
                                <Typography className={classes.subText}>As of {moment(fundData?.amc?.updatedAt).format("DD MMM YYYY")}</Typography>

                            </Box>
                        </Grid>
                        <Grid item xs={6} md={5} alignItems='center' justifyContent='center'>
                            <Box  >
                                <Typography className={classes.text}>Address:</Typography>

                                <Typography className={classes.text}>{fundData?.amc?.address1} {fundData?.amc?.address2} {fundData?.amc?.address3} {fundData?.amc?.pin}</Typography>
                                {/* <Typography className={classes.text}>{fundData?.amc?.address2}</Typography>
                                <Typography className={classes.text}>{fundData?.amc?.address3} {fundData?.amc?.pin}</Typography> */}
                            </Box>
                            <Box  >
                                <Typography className={classes.text}>Phone:</Typography>
                                <Typography className={classes.text}>{fundData?.amc?.phone}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>

    )
}

export default FundHouseDetails