
import React, { useEffect, useState } from 'react'
import { Cell, PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from "recharts";
import { Box, Typography, makeStyles, Grid } from "@material-ui/core";
import HoldingPieChart from '../../components/MutualFund/HoldingsPieChart';
import { GET_ANALYTICAL_DATA, GET_HOLDING_SECTOR } from '../../utils/endpoints'
import httpInstance from '../../utils/instance'

const HoldingAnalysis = ({ exitLoad, fundSize, schemeCode, fundData, holdingDataOfSectors }) => {

  const useStyles = makeStyles((theme) => ({
    root: {

    },
    title: {
      fontWeight: 600,
      padding: "0px 8rem",
      fontSize: "1.2rem"
    },
  }))

  const classes = useStyles();



  const [analyticalData, setAnalyticalData] = useState([])
  const [sectorData, setSectorData] = useState([])


  const get_data = async () => {
    // const httpConfig = {
    //   method: "POST",
    //   tokenType: "user",
    //   headers:{
    //     "Content-Type": "application/json",
    //   },
    //   data:{scheme_code:schemeCode},
    // };
    // try{
    //   const [data1, data2]= await Promise.all([
    //     httpInstance(`${GET_ANALYTICAL_DATA}`,httpConfig),
    //     httpInstance(`${GET_HOLDING_SECTOR}`,httpConfig)
    //   ]) 

    //   const analytical=data1?.data?.data
    //   const sector=data2?.data?.data

    const analytical = fundData;
    const sector = holdingDataOfSectors;

    let analyticArry = [
      // {name:"Equity", value:analytical?.asset_break_up?.equity},
      // {name:"Cash", value:analytical?.asset_break_up?.cash_equivalent},
      // {name:"Debit", value:analytical?.asset_break_up?.corporate_debt},
      // {name:"Other",value:Number((100-(analytical?.asset_break_up?.equity+analytical?.asset_break_up?.cash_equivalent+analytical?.asset_break_up?.corporate_debt)).toFixed(4))}
      { name: "Equity", value: analytical?.equity },
      { name: "Cash", value: analytical?.cash ? analytical?.cash : 0 },
      { name: "Debit", value: analytical?.debt },
      { name: "Other", value: analytical?.others }
    ]

    // const finalAnalytic=analyticArry?.filter((ele)=> ele.value>=0).sort(function(a,b){
    //   return b.value - a.value
    // })
    setAnalyticalData(analyticArry)

    //------------------set sector values----------------------------------------//
    setSectorData(
      sector?.length > 4 ? [
        { name: sector[0]?.sector, value: sector[0]?.percentage },
        { name: sector[1]?.sector, value: sector[1]?.percentage },
        { name: sector[2]?.sector, value: sector[2]?.percentage },
        { name: sector[3]?.sector, value: sector[3]?.percentage },
        { name: "Other", value: Number((100 - (sector[0].percentage + sector[1].percentage + sector[2].percentage + sector[3].percentage)).toFixed(2)) },
      ]
        :
        sector?.map((ele) => {
          return {
            name: ele.sector,
            value: ele.percentage
          }
        })
    )
    //setSectorData([{name:"x" ,value:10}])

    // }
    // catch{
    // }

  }

  useEffect(() => {
    if (fundData && holdingDataOfSectors) {
      get_data()
    }
  }, [fundData, holdingDataOfSectors])

  const colors = ["#0E76BC", "#6FDDB8", "#F3C04F", "#95FFDB", "#f59342"];
  return (
    <div style={{ borderBottom: "1px solid #E6E6E6" }}>
      <Box mt={10}>
        <Typography className={classes.title}>Holding Analysis</Typography>
        <Box px={{ xs: 2, md: 10 }} >
          <HoldingPieChart data={analyticalData} colors={colors} fundSize={fundSize} title="Equity/ Debt / Cash Split" />
          {sectorData.length > 0 && (
            <HoldingPieChart data={sectorData} colors={colors} fundSize={fundSize} title="Sector allocation" />
          )}
          <Box px={6}> <p>Exit Load Info: {exitLoad}</p></Box>
        </Box>
      </Box>
    </div>
  )
}

export default HoldingAnalysis