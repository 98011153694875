import React from 'react'
import Settings_Part from '../../containers/MyProfile/Settings/index'

const Settings = () => {
  return (
    <div>
        <Settings_Part/>
    </div>
  )
}

export default Settings