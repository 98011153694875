import React, { useState } from "react";
import { useStyles } from "../styles";
import { Box, Typography } from "@material-ui/core";
import TitleLayoult from "../TitleLayoult";
import TextInputField from "../Input/TextInputField";
import httpInstance from "../../../utils/instance";
import { GoogleLogin } from "react-google-login";
import { useForm } from "react-hook-form";
import ErrorMessage from "../../Validation/ErrorMessage";
import { useNavigate, createSearchParams } from "react-router-dom";
import { GOOGLE_LOGIN, GET_USER } from "../../../utils/endpoints";
import { namespace } from "../../../environment";

function Step1({ step, setData }) {
  const classes = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const navigate = useNavigate();

  const [google, setGoogle] = useState(false);

  const responseGoogle = async (response) => {
    setGoogle(true);
    sessionStorage.removeItem("mpin");
    // reset({
    //   email:response.profileObj. email
    // })

    const formData = {
      emailId: response.profileObj.email,
      loginType: "GOOGLE",
      secretKey: "A*C:-1/Lg-N!KK-gpwr-QY@z",
      accessToken: response.accessToken,
      //secretKey:process.env.REACT_APP_API_KEY
    };

    const httpConfig = {
      method: "POST",
      data: formData,
    };

    const httpConfig1 = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GOOGLE_LOGIN}`, httpConfig);
      localStorage.setItem("user_token", response.data?.data?.accessToken);
      localStorage.setItem("refresh_token", response.data?.data?.refreshToken);
      localStorage.setItem("user_id", response.data?.data?.user?.id);

      // navigate({
      //   pathname: '/signIn',
      //   search: `?${createSearchParams({stepcount:"2"})}`,
      // });
      // step(2)

      const status = await httpInstance(`${GET_USER}`, httpConfig1);
      if (status.data?.data?.mpin) {
        navigate({
          pathname: `${namespace}/signIn`,
          search: `?${createSearchParams({ stepcount: "mpin" })}`,
        });
      } else {
        if (status.data?.data?.isMobileNumberVerified) {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "mpin_generate" })}`,
          });
        } else {
          navigate({
            pathname: `${namespace}/signIn`,
            search: `?${createSearchParams({ stepcount: "2" })}`,
          });
        }
      }
    } catch (error) { }
  };

  const [error, setError] = useState("");

  const handleEmailSubmit = async (data) => {
    sessionStorage.removeItem("mpin");

    //   if(google){
    //   const   formData={emailId: data.email,loginType:"GOOGLE"}

    //   const httpConfig = {
    //     method: "POST",
    //     data: formData,
    //   };

    //     try{
    //       const response = await httpInstance(`${GOOGLE_LOGIN}`,httpConfig)
    //       localStorage.setItem("user_token",response.data?.data?.accessToken)
    //       localStorage.setItem("refresh_token",response.data?.data?.refreshToken)
    //       localStorage.setItem("user_id",response.data?.data?.user?.id)

    //       navigate({
    //         pathname: '/signIn',
    //         search: `?${createSearchParams({stepcount:"2"})}`,
    //       });
    //       step(2)
    //   }
    //   catch(error){
    //     console.log(error)
    //   }
    // }else{

    // Old code 
    // localStorage.setItem("email", data.email);

    // const formData = { emailId: data.email };

    // New code
    // console.log(data)


    localStorage.setItem("mobile", data.mobile);
    localStorage.setItem("full_name", data.full_name);
    localStorage.setItem("email", data.email);

    // const formData = { mobile: data.mobile };

    const formData = {
      full_name: data.full_name,
      mobile: data.mobile,
      email: data.email,
    };

    // localStorage.setItem("formData", JSON.stringify(formData));

    const httpConfig = {
      method: "POST",
      data: formData,
    };

    try {
      const response = await httpInstance(
        // "api/v1/usermgmt/user/login",
        "/api/users/",
        httpConfig
      );

      setError("");


      localStorage.setItem("sessionId", response?.data?.data?.sessionId);
      setData({
        Alldata: response?.data,
        mobile: data.mobile,
        email: data.email,
        sessionId: response?.data?.data?.sessionId,
        creationTime: response?.data?.data?.creationTime,
      });
      localStorage.setItem("creationTime", response?.data?.data?.creationTime);
      navigate({
        pathname: `${namespace}/onBoard`,
        search: `?${createSearchParams({ stepcount: "1" })}`,
      });
      step(2);
    } catch (error) {
      setError(error.response.data?.error);
    }
  };
  const [isLogin, setIsLogin] = useState(false);

  const handleToggle = () => {
    setIsLogin((prev) => !prev);
  };
  const handleLoginSubmit = async (data) => {
    sessionStorage.removeItem("mpin");

    //   if(google){
    //   const   formData={emailId: data.email,loginType:"GOOGLE"}

    //   const httpConfig = {
    //     method: "POST",
    //     data: formData,
    //   };

    //     try{
    //       const response = await httpInstance(`${GOOGLE_LOGIN}`,httpConfig)
    //       localStorage.setItem("user_token",response.data?.data?.accessToken)
    //       localStorage.setItem("refresh_token",response.data?.data?.refreshToken)
    //       localStorage.setItem("user_id",response.data?.data?.user?.id)

    //       navigate({
    //         pathname: '/signIn',
    //         search: `?${createSearchParams({stepcount:"2"})}`,
    //       });
    //       step(2)
    //   }
    //   catch(error){
    //     console.log(error)
    //   }
    // }else{

    // Old code 
    // localStorage.setItem("email", data.email);

    // const formData = { emailId: data.email };

    // New code
    console.log(data)


    localStorage.setItem("mobile", data.mobile);
    // localStorage.setItem("full_name", data.full_name);
    // localStorage.setItem("email", data.email);

    // const formData = { mobile: data.mobile };

    const formData = {
      // full_name: data.full_name,
      mobile: data.mobile,
      // email: data.email,
    };

    // localStorage.setItem("formData", JSON.stringify(formData));

    const httpConfig = {
      method: "POST",
      data: formData,
    };

    try {
      const response = await httpInstance(
        // "api/v1/usermgmt/user/login",
        "/api/auth/generate_otp",
        httpConfig
      );

      setError("");


      localStorage.setItem("sessionId", response?.data?.data?.sessionId);
      setData({
        Alldata: response?.data,
        mobile: data.mobile,
        email: data.email,
        sessionId: response?.data?.data?.sessionId,
        creationTime: response?.data?.data?.creationTime,
      });
      localStorage.setItem("creationTime", response?.data?.data?.creationTime);
      navigate({
        pathname: `${namespace}/onBoard`,
        search: `?${createSearchParams({ stepcount: "1" })}`,
      });
      step(2);
    } catch (error) {
      setError(error.response.data?.error);
    }
  }
  // return(
  // <React.Fragment>
  //     <Box display={'flex'} justifyContent="center">
  //       <Box display={'flex'} alignItems="center" flexDirection={'column'}>
  //         <TitleLayoult title={isLogin ? 'Login to MF Wealth' : 'Welcome to MF Wealth'} />
  //         <Box>
  //           {/* ... (your social login buttons) */}
  //         </Box>
  //         <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
  //           <Typography style={{ color: '#c7c7c7', fontSize: '0.8rem' }}>
  //             or
  //           </Typography>
  //         </Box>

  //         <form onSubmit={isLogin ? handleLoginSubmit : handleEmailSubmit}>
  //           {isLogin ? (
  //             <Box my={2}>
  //               <TextInputField
  //                 name="mobile"
  //                 label="Enter Phone Number"
  //                 placeholder=""
  //                 register={register}
  //                 inputPattern={/^[0-9-+]{10,10}$/}
  //                 handleChange={() => setError('')}
  //               />
  //               {errors.mobile && (
  //                 <ErrorMessage message="Enter valid Phone Number" />
  //               )}
  //             </Box>
  //           ) : (
  //             <React.Fragment>
  //               <Box my={2}>
  //                 <TextInputField
  //                   name="full_name"
  //                   label="Enter Full Name"
  //                   placeholder=""
  //                   register={register}
  //                   inputPattern={/^[A-Za-z\s]+$/}
  //                   handleChange={() => setError('')}
  //                 />
  //                 {errors.full_name && (
  //                   <ErrorMessage message="Only letters and spaces allowed in the name" />
  //                 )}
  //               </Box>
  //               <Box my={2}>
  //                 <TextInputField
  //                   name="mobile"
  //                   label="Enter Phone Number"
  //                   placeholder=""
  //                   register={register}
  //                   inputPattern={/^[0-9-+]{10,10}$/}
  //                   handleChange={() => setError('')}
  //                 />
  //                 {errors.email && (
  //                   <ErrorMessage message="Enter valid Phone Number" />
  //                 )}
  //               </Box>
  //               <Box my={3}>
  //                 <TextInputField
  //                   label="Enter Email ID"
  //                   name="email"
  //                   placeholder=""
  //                   register={register}
  //                   inputPattern={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
  //                 />
  //                 {errors.mobile && (
  //                   <ErrorMessage message="Please Enter Valid Email ID" />
  //                 )}
  //               </Box>
  //             </React.Fragment>
  //           )}

  //           {error && <ErrorMessage message={error} />}

  //           <Box mt={2}>
  //             <button className={classes.primaryButton} type="submit">
  //               {isLogin ? 'Login' : 'Continue'}
  //             </button>
  //           </Box>
  //         </form>

  //         <Box mt={2}>
  //           <button onClick={handleToggle}>
  //             {isLogin ? 'Switch to Signup' : 'Switch to Login'}
  //           </button>
  //         </Box>
  //       </Box>
  //     </Box>
  //   </React.Fragment>
  // )
  return (
    <React.Fragment>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="center" flexDirection={"column"}>
          <TitleLayoult title="Welcome to MF Wealth" />
          <Box>
            {/* <button className={classes.socialButton}>
          <img
            className={classes.socialButtonImage}
            src="/images/google-logo.svg"
          />
          <p >Continue with Google</p>
        </button> */}
            {/* <GoogleLogin
              clientId="901855431398-vo2vjitftml7v641unu1puorobh6u32r.apps.googleusercontent.com"
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  className={classes.socialButton}
                >
                  <img
                    className={classes.socialButtonImage}
                    src="/images/google-logo.svg"
                  />
                  <p>Continue with Google</p>
                </button>
              )}
              buttonText="Continue with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            /> */}
          </Box>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent="center"
            mt={4}
          >
            <Typography
              style={{
                color: "#c7c7c7",
                fontSize: "0.8rem",
              }}
            >
              or
            </Typography>
          </Box>

          {/* <form onSubmit={handleSubmit(handleEmailSubmit)}> */}
          <form onSubmit={isLogin ? handleSubmit(handleLoginSubmit) : handleSubmit(handleEmailSubmit)}>
            {isLogin ? (
              <Box my={2}>
                <TextInputField
                  name="mobile"
                  label="Enter Phone Number"
                  placeholder=""
                  register={register}
                  inputPattern={/^[0-9-+]{10,10}$/}
                  handleChange={() => setError('')}
                />
                {errors.mobile && (
                  <ErrorMessage message="Enter valid Phone Number" />
                )}
              </Box>
            ) : (
              <React.Fragment>

                <Box my={2}>
                  <TextInputField
                    name="full_name"
                    label="Enter Full Name"
                    placeholder=""
                    register={register}
                    inputPattern={/^[A-Za-z\s]+$/}
                    handleChange={() => setError("")}
                  />
                  {errors.full_name && (
                    <ErrorMessage message="Only letters and spaces allowed in the name" />
                  )}
                </Box>
                <Box my={2}>
                  <TextInputField
                    name="mobile"
                    label="Enter Phone Number"
                    placeholder=""
                    register={register}
                    inputPattern={/^[0-9-+]{10,10}$/}
                    handleChange={() => setError("")}
                  />
                  {errors.mobile && (
                    <ErrorMessage message="Enter valid Phone Number" />
                  )}
                </Box>
                <Box my={3}>
                  <TextInputField
                    label="Enter Email ID"
                    name="email"
                    placeholder=""
                    register={register}
                    inputPattern={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
                  />
                  {errors.email && (
                    <ErrorMessage message="Please Enter Valid Email ID" />
                  )}
                </Box>
              </React.Fragment>

            )}
            {error && <ErrorMessage message={error} />}

            <Box mt={2}>
              <button className={classes.primaryButton} type="submit">
                Continue
              </button>
            </Box>
          </form>
          <Box mt={2}>
            <button className={classes.LoginSignUpSwitchButton} onClick={handleToggle}>
              {isLogin ? 'Switch to Signup' : 'Switch to Login'}
            </button>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Step1;
