import { Box, Typography, CircularProgress } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useStyles } from "../styles";
import Webcam from "react-webcam";
import { UPLOAD_PAN, UPLOAD_PHOTO, POST_GEO_TAG, CREAT_KYC, GET_UPLOADED_PHOTO } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import { useNavigate, createSearchParams } from "react-router-dom";
import { namespace, BASE_URL } from "../../../environment";

function TakePicture({ step }) {
  const classes = useStyles();
  const [showcam, setShowcam] = React.useState(false);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [captureButton, setCaptureButton] = React.useState(false);
  const [file, setFile] = useState(null);
  const [Lat, setLat] = useState("");
  const [Lng, setLng] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const blob = await fetch(imageSrc).then((res) => res.blob());
    setImgSrc(imageSrc);
    setShowcam(false);
    setCaptureButton(false);

    setFile(blob);
  }, [webcamRef]);

  const handleCamera = () => {
    setShowcam(true);
    setCaptureButton(true);
  };

  const success = ({ coords }) => {
    setLat(coords.latitude);
    setLng(coords.longitude);
    handle_upload(coords.latitude, coords.longitude);
    // handle_upload_location(coords.latitude, coords.longitude);

  };

  const onError = (error) => {
    alert("Unable to retrieve your location");
  };

  const getCords = () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(success, onError);
    }
  };

  // const handle_upload_location = async (lat, lng) => {
  //   const kycForm = {
  //     isFinalSubmit: false,
  //     latitude: lat,
  //     longitude: lng,
  //   };
  //   const httpConfig1 = {
  //     method: "POST",
  //     tokenType: "user",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: kycForm,
  //   };
  //   await httpInstance(`${CREAT_KYC}`, httpConfig1);

  // }

  // useEffect(()=>{
  //     getCords()
  // },[navigator.geolocation.watchPosition(success, onError) ])

  // useEffect(() => {
  //   const geo = navigator.geolocation
  //   if (!geo) {
  //     alert('Geolocation is not supported')
  //     return;
  //   }
  //  let watcher = geo.watchPosition(onChange, onError);
  //   return () => geo.clearWatch(watcher);
  // }, []);



  const get_uploaded_photo = async () => {
    // const kycForm = {
    //   isFinalSubmit: false,
    //   latitude: lat,
    //   longitude: lng,
    // };
    const httpConfig1 = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      // data: kycForm,
    };
    try {
      const response = await httpInstance(`${GET_UPLOADED_PHOTO}`, httpConfig1);
      const blob = await fetch(`${BASE_URL}${response?.data?.data?.photo_url}`).then((res) => res.blob());
      setImgSrc(`${BASE_URL}${response?.data?.data?.photo_url}`);

      setShowcam(false);
      setCaptureButton(false);
  
      setFile(blob);
    }
    catch (error) {
    }
  }

  useEffect(() => {
    get_uploaded_photo()
  }, [])
  const handle_upload = async (lat, lng) => {
    //getCords()
    if (!lat || !lat) {
      alert("Unable to retrieve your location");
    } else {
      setLoading(true);
      const formData = new FormData();
      // formData.append("profile_file", file);
      formData.append("photo", file);

      const kycForm = {
        // isFinalSubmit: false,
        // latitude: lat,
        // longitude: lng,
        lat: lat,
        lng: lng,
      };

      const httpConfig = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      const httpConfig1 = {
        method: "POST",
        tokenType: "user",
        headers: {
          "Content-Type": "application/json",
        },
        data: kycForm,
      };

      try {
        // await httpInstance(`${UPLOAD_PAN}`, httpConfig);
        await httpInstance(`${UPLOAD_PHOTO}`, httpConfig);
        // await httpInstance(`${CREAT_KYC}`, httpConfig1);
        await httpInstance(`${POST_GEO_TAG}`, httpConfig1);

        navigate({
          pathname: `${namespace}/signIn`,
          search: `?${createSearchParams({ stepcount: "24" })}`,
        });
      } catch {
        setLoading(false);
      }
    }
  };

  const handleback = () => {
    navigate({
      pathname: `${namespace}/signIn`,
      search: `?${createSearchParams({ stepcount: "13" })}`,
    });
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-start"
        px={20}
        className={classes.backButton}
        onClick={handleback}
      >
        <img src="/images/backIcon.svg" />
      </Box>
      <Box display={"flex"} justifyContent="center">
        <Box display={"flex"} alignItems="center" flexDirection={"column"}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            flexDirection="column"
            py={4}
          >
            <Typography className={classes.title}>Take a Picture</Typography>
            <Box mt={1} style={{ width: "100%" }} textAlign={""}>
              <Typography
                className={classes.headingDescription}
                style={{ width: "22rem" }}
              >
                Please Click a Picture for KYC Documentation
              </Typography>
            </Box>
          </Box>
          {/* <TitleLayoult
          title={"Take a Picture"}
          description={"Please Click a Picture for KYC Documentation"}
          align={"flex-start"}
        /> */}

          <Box>
            {showcam && (
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                style={{ height: "16rem", width: "16rem" }}
              />
            )}
            {!showcam && !imgSrc ? (
              <img
                src="/images/takePicture.svg"
                style={{ height: "16rem", width: "16rem" }}
              />
            ) : (
              imgSrc && !captureButton && <img src={imgSrc} style={{ height: "16rem", width: "16rem" }}/>
            )}
          </Box>
          {captureButton ? (
            <Box mt={3}>
              <button
                className={classes.primaryButton}
                onClick={() => {
                  capture();
                }}
              >
                Capture
              </button>
            </Box>
          ) : (
            <Box mt={3}>
              <button className={classes.primaryButton} onClick={handleCamera}>
                {!imgSrc ? "Open Camera" : "Retake"}
              </button>
            </Box>
          )}

          {imgSrc && (
            <Box mt={3}>
              <button className={classes.primaryButton} onClick={getCords}>
                {loading ? (
                  <CircularProgress size="1rem" color="inherit" />
                ) : (
                  "Upload"
                )}
              </button>
            </Box>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default TakePicture;
