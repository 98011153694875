import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Subtab from "./subtab1";
import { Container } from "@material-ui/core";
import ContentSlider from "./ContentSlider";
import Kyc_status from "./KycStatusCheck/Kyc_status";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { namespace } from "../../../environment";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: "none",
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: "#0E76BC",
      textTransform: "none",
    },
  },
  tab_style: {
    //width: "100%",
    background: "white",
    color: "black",
    padding: "0px 1rem ",
    "& .MuiTab-root": {
      fontFamily: "inherit",
      fontSize: "1rem",
      color: "black",
      opacity: 1,
      textTransform: "none",
      background: "none",
      minWidth: "auto",
      marginRight: "2rem",
      marginLeft: "1rem",
      "& .MuiTouchRipple-root": {
        position: "unset",
      },
    },
    "& .MuiTab-textColorInheri": {
      opacity: 0.9,
    },
  },
  tabWidth: {
    //width:'25%',
    color: "#272727",
    fontSize: "25px",
    fontFamily: "Poppins",
    fontWeight: "400",
  },
  activeTab: {
    "& .MuiTab-wrapper": {
      borderBottom: "2px solid #0E76BC",
      color: "#0E76BC",
      paddingBottom: "5px",
    },
  },
  inActiveTab: {
    "& .MuiTab-wrapper": {
      paddingBottom: "5px",
    },
  },
  link: {
    textDecoration: "none",
  },
}));

export default function SimpleTabs({ kycStatus }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const location = useLocation();

  const { userStore } = useSelector((state) => state);

  useEffect(() => {
    if (location.pathname.includes("diy")) {
      setValue(0);
    } else {
      setValue(1);
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.tab_style}>
        <Container>
          {/* <Tabs
            className={classes.tab_style}
            classes={{ indicator: classes.indicator }}
            indicatorColor=""
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Link
              to={`${namespace}/dashboard/mutualfund/diy`}
              className={classes.link}
            >
              <Tab
                label="Do it yourself"
                {...a11yProps(0)}
                className={value == 0 ? classes.activeTab : classes.inActiveTab}
              />
            </Link> */}
            {/* <Link to={`${userStore?.user?.assistedServiceUserDetailsId?.is_expired ? '/dashboard/mutualfund/assisted-services' : userStore?.user?.isAssistedUser ? (userStore?.user?.riskProfileScore ? '/dashboard/mutualfund/assisted-services/invest-detail' : '/dashboard/mutualfund/assisted-services/questionaries') :'/dashboard/mutualfund/assisted-services'}`} 
                  className={classes.link}>  <Tab label="Assisted Services" {...a11yProps(1)} className={value==1 ? classes.activeTab :classes.inActiveTab} /></Link>  */}

            {/* <Link
              to={`${
                userStore?.user?.isAssistedUser
                  ? userStore?.user?.assistedServiceUserDetailsId?.is_expired
                    ? `${namespace}/dashboard/mutualfund/assisted-services`
                    : userStore?.user?.riskProfileScore
                    ? `${namespace}/dashboard/mutualfund/assisted-services/invest-detail`
                    : `${namespace}/dashboard/mutualfund/assisted-services/questionaries`
                  : `${namespace}/dashboard/mutualfund/assisted-services`
              }`}
              className={classes.link}
            >
              {" "}
              <Tab
                label="Assisted Services"
                {...a11yProps(1)}
                className={value == 1 ? classes.activeTab : classes.inActiveTab}
              />
            </Link>
          </Tabs> */}
        </Container>
      </div>

      <Box py={2} style={{ borderTop: "1px solid #E6E3E3", marginTop: "-6px" }}>
        <Kyc_status />

        <div>
          <Outlet />
        </div>
        {/* <TabPanel value={value} index={0}>
        <Subtab/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ContentSlider/>
      </TabPanel> */}
      </Box>
    </div>
  );
}
