import React, { useEffect, useState } from 'react'
import {
    Cell,
    PieChart,
    Pie,
    ResponsiveContainer,
    Label,
    Tooltip,
    Legend,
} from "recharts";
import { makeStyles, Grid, Typography, Container, Box } from "@material-ui/core";


const RiskProfileChart = ({ data, showQuestion}) => {
    const useStyles = makeStyles((theme) => ({
        LegendList: {
            //display: "grid",
            //gridTemplateColumns: "1fr 1fr",
            listStyle: "none" /* Remove default bullets */,
            "& li": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                margin: "5px",
            },
        },
        cirecleBullet: {
            borderRadius: "50%",
            minWidth: "15px",
        },
        bulletLabel: {
            display: "flex",
            alignItems: "center",
            //wordBreak: "break-all",
        },
        subTitle: {
            fontWeight: 600,
            padding: "0px 3rem",
        },
        mainText: {
            fontFamily: "Poppins",
            fontSize: "31px",
            fontWeight: "700",
            lineHeight: "44px",
            // color: "#0E76BC",
        },
        subText: {
            fontFamily: "Poppins",
            fontSize: "21px",
            fontWeight: "500",
            lineHeight: "26px",
            // width: "285px",
            // height: "48px",
            marginTop: "12px",
            marginBottom: "32px",

        },
        text1: {
            fontFamily: "Poppins",
            fontSize: "22px",
            fontWeight: "400",
            lineHeight: "39px",
            // width: "285px",
            // height: "48px",
            marginTop: "10px",
            marginBottom: "32px",
            color: "#333333",

        },
        text2: {
            fontFamily: "Poppins",
            fontSize: "23px",
            fontWeight: "500",
            lineHeight: "38px",
            // width: "285px",
            // height: "48px",
            marginTop: "10px",
            marginBottom: "32px",
            // color: "#333333",
        },
        primaryButton: {
            minWidth: "300px",
            padding: "0.6rem 1.5rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontFamily: "inherit",
            fontWeight: 600,
            borderRadius: "45px",
            border: "1px solid #0E76BC",
            // marginRight: "15px",
            outline: "none",
            cursor: "pointer",
            "& .MuiCircularProgress-root": {
                padding: "0px 20px"
            }
        },
        desktop: {
            display: "block",
        },
        mobileView: {
            display: "none",
        },

        "@media (max-width: 991px)": {
            desktop: {
                display: "none",
            },
            mobileView: {
                display: "block",
            },
        },
    }));

    const classes = useStyles();

    const Bullet = ({ backgroundColor, size }) => {
        return (
            <div
                className={classes.cirecleBullet}
                style={{
                    backgroundColor,
                    width: size,
                    height: size,
                }}
            ></div>
        );
    };

    const [riskProfileChartValues, setRiskProfileChartValues] = useState([])
    useEffect(() => {
        setRiskProfileChartValues([
            { name: ' Equity ', value: data?.risk_profile?.max_equity_allocation },
            { name: ' Debt ', value: data?.risk_profile?.max_debt_allocation },
            { name: ' Liquid ', value: data?.risk_profile?.max_liquid_allocation },
        ])
    }, [data])

    // const riskProfileChartValues = [
    //     { name: 'Max Equity Allocation', value: 50 },
    //     { name: 'Max Debt Allocation', value: 50 },
    //     { name: 'Max Liquid Allocation', value: 50 },
    // ];

    const CustomizedLegend = (props) => {
        const { payload } = props;

        return (
            <div>
                {/* <p className={classes.subTitle}>{"Recommendation"}</p> */}
                {/* <ul className={classes.LegendList}>
                    {payload.map((entry, index) => (
                        <li key={index}>
                            <Grid container>
                                <Grid item xs={8}>
                                    <div className={classes.bulletLabel}>
                                        <Bullet backgroundColor={entry?.color} size="15px" />

                                        <div style={{ marginLeft: "10px", marginRight: "20px" }}>
                                            {entry.payload.payload?.payload?.name}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    {entry.payload.payload?.payload?.value.toFixed(2)}%
                                </Grid>
                            </Grid>
                        </li>
                    ))}
                </ul> */}
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <Box style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
                            <div className={classes.bulletLabel}>
                                <Bullet backgroundColor={payload?.[0]?.color} size="15px" />

                                <div style={{ marginLeft: "10px", marginRight: "20px" }}>
                                    {payload?.[0]?.payload.payload?.payload?.name}
                                </div>
                            </div>
                            {payload?.[0]?.payload.payload?.payload?.value.toFixed(2)}%

                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
                            <div className={classes.bulletLabel}>
                                <Bullet backgroundColor={payload?.[1]?.color} size="15px" />

                                <div style={{ marginLeft: "10px", marginRight: "20px" }}>
                                    {payload?.[1]?.payload.payload?.payload?.name}
                                </div>
                            </div>
                            {payload?.[1]?.payload.payload?.payload?.value.toFixed(2)}%

                        </Box>
                        {/* <Box >
                    </Box> */}
                    </Grid>
                    <Grid item xs={4} >
                        <Box style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }} >
                            <div className={classes.bulletLabel}>
                                <Bullet backgroundColor={payload?.[2]?.color} size="15px" />

                                <div style={{ marginLeft: "10px", marginRight: "20px" }}>
                                    {payload?.[2]?.payload.payload?.payload?.name}
                                </div>
                            </div>
                            {payload?.[2]?.payload.payload?.payload?.value.toFixed(2)}%
                        </Box>
                        <Box >

                        </Box>
                    </Grid>
                </Grid>
            </div>
        );
    };

    const CustomLabel = ({ viewBox, labelText, value }) => {
        const { cx, cy } = viewBox;
        return (
            <g>
                <text
                    x={cx}
                    y={cy}
                    // className="recharts-text recharts-label"
                    textAnchor="middle"
                    dominantBaseline="central"
                    alignmentBaseline="middle"
                    fill="#272B41"
                    fontSize="13"
                    fontWeight={600}

                >
                    {/* {suggested ?
                        `₹${value}` : `₹${value}Cr.`} */}
                </text>
            </g>
        );
    };
    const COLORS = ['#0E76BC', '#C0C0C0', '#545353'];


    return (
        <div>
            <div style={{ width: '100%' }} >
                <Container maxWidth="lg" >
                    <Typography className={classes.subText} style={{ textAlign: 'center' }}>Risk Profile Details</Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={6} md={6} sm={12}  >
                            {/* <CustomizedLegend2 payload={data}/> */}

                            <Typography className={classes.subText}>
                                Your risk profile is.
                            </Typography>
                            <Typography className={classes.mainText}>
                                Moderately Aggressive
                            </Typography>
                            <Typography className={classes.text1}>
                                Create your risk profile and make informed investment decisions.
                            </Typography>
                            <Typography className={classes.text2}>
                                Our recommendation based on your goal timeframe and investment mind
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} sm={12}>
                            <ResponsiveContainer width={"100%"} height={"100%"} >

                                <PieChart>


                                    <Pie
                                        data={riskProfileChartValues}
                                        dataKey="value"
                                        outerRadius={100}
                                        innerRadius={60}
                                        cx="50%"
                                        cy="40%"
                                    >
                                        {riskProfileChartValues.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                        <Tooltip />
                                        {/* <Label
                                            content={<CustomLabel value={fundSize} />}
                                            position="center"
                                        /> */}

                                    </Pie>
                                    {/* <Legend
                                            content={<CustomizedLegend2 />}
                                            layout="vertical"
                                            verticalAlign="left"
                                            align="center"
                                            width="40%"
                                            wrapperStyle={{
                                                left: 0,
                                                top: 0,
                                                paddingBottom: 10,
                                                position: "absolute",
                                            }}
                                        /> */}
                                    <Legend
                                        content={<CustomizedLegend />}
                                        layout="vetical"
                                        verticalAlign="bottom"
                                        align="left"
                                        width="40%"
                                        wrapperStyle={{
                                            left: 190,
                                            top: 280,
                                            paddingBottom: 10,
                                            position: "absolute",
                                        }}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                            {/* <Box>
                                {riskProfileChartValues?.[0]?.name} */}
                            {/* <Grid container>
                                <Grid item xs={10}>
                                    <div className={classes.bulletLabel}>
                                         <Bullet backgroundColor={entry.color} size="15px" /> 
                                        <div style={{ marginLeft: "10px", marginRight: "20px" }}>
                                            {riskProfileChartValues?.name}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    {riskProfileChartValues?.value?.toFixed(2)}%
                                </Grid>
                            </Grid> */}
                            {/* </Box> */}
                        </Grid>
                    </Grid>
                    <Box display='flex' justifyContent='center'>
                    <button className={classes.primaryButton} type="button" onClick={() => showQuestion()}>
                        Upadte Risk Profile
                    </button>
                    </Box>
                </Container>
            </div>


        </div>
    )
}

export default RiskProfileChart