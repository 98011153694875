import React, { useState, useEffect } from "react";
import Question from "../../../components/Assisted/Question";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { GET_USER_QUESTIONS, SUBMIT_ANSWERS } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../../redux/User/userActions";
import { namespace } from "../../../environment";

const Questionareies = ({questionsAreDone}) => {
  const useStyles = makeStyles({
    slideBoxActive: {
      padding: "3px",
      background: "#0E76BC",
      width: "40%",
      margin: "0px 5px",
      cursor: "pointer",
    },
    slideBox: {
      padding: "3px",
      background: "#DDDDDD",
      width: "40%",
      margin: "0px 5px",
      cursor: "pointer",
    },

    primaryButton: {
      color: "white",
      backgroundColor: "#0E76BC",
      border: "none",
      borderRadius: "39px",
      fontSize: "15px",
      fontWeight: "500",
      padding: "10px 3rem",
      fontFamily: "inherit",
      cursor: "pointer",
    },
  });
  const classes = useStyles();

  const [index, setIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState("");
  const [riskProfileScore, setRiskProfileScore] = useState("");
  const navigate = useNavigate();

  const { userStore } = useSelector((state) => state);
  const dispatch = useDispatch();

  const handleIndex = (i) => {
    if (answers[i]) {
      setIndex(i);
    }
  };

  const get_questions = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${GET_USER_QUESTIONS}`, httpConfig);
      setQuestions(response.data?.data);
      // setQuestions([
      //   {
      //     "id": 1,
      //     "question": "If the performance of an investment you have recently made is below your expectations or in negative, how would you feel?",
      //     "description": "asdasd",
      //     "is_active": true,
      //     "created_at": "2023-04-20T12:41:46.000Z",
      //     "updated_at": "2023-05-03T12:23:07.000Z",
      //     "risk_answer_choices": [
      //       {
      //         "id": 1,
      //         "risk_profile_question_id": 1,
      //         "risk_answer_weightage_id": 1,
      //         "answer": "Very upset",
      //         "answer_image_url": null,
      //         "position": 1,
      //         "created_at": "2023-04-20T12:42:18.000Z",
      //         "updated_at": "2023-05-03T12:25:23.000Z"
      //       },
      //       {
      //         "id": 3,
      //         "risk_profile_question_id": 1,
      //         "risk_answer_weightage_id": 2,
      //         "answer": "Somewhat upset, but hope that it will improve in the future",
      //         "answer_image_url": null,
      //         "position": 2,
      //         "created_at": "2023-04-20T12:43:07.000Z",
      //         "updated_at": "2023-05-03T12:30:53.000Z"
      //       },
      //       {
      //         "id": 4,
      //         "risk_profile_question_id": 1,
      //         "risk_answer_weightage_id": 3,
      //         "answer": "Uneasy but willing to take it in my stride",
      //         "answer_image_url": null,
      //         "position": 3,
      //         "created_at": "2023-04-20T12:43:07.000Z",
      //         "updated_at": "2023-10-06T08:10:37.000Z"
      //       },
      //       {
      //         "id": 8,
      //         "risk_profile_question_id": 1,
      //         "risk_answer_weightage_id": 4,
      //         "answer": "Not upset because I know that all investments carry risk",
      //         "answer_image_url": "",
      //         "position": 4,
      //         "created_at": "2023-05-08T05:19:06.000Z",
      //         "updated_at": "2023-10-06T08:10:37.000Z"
      //       }
      //     ]
      //   },
      //   {
      //     "id": 2,
      //     "question": "If you had to choose between being a salaried employee and running your own business, which one would you prefer?",
      //     "description": "123123123",
      //     "is_active": true,
      //     "created_at": "2023-04-20T12:41:46.000Z",
      //     "updated_at": "2023-05-03T12:23:36.000Z",
      //     "risk_answer_choices": [
      //       {
      //         "id": 2,
      //         "risk_profile_question_id": 2,
      //         "risk_answer_weightage_id": 1,
      //         "answer": "Being a salaried employee",
      //         "answer_image_url": null,
      //         "position": 1,
      //         "created_at": "2023-04-20T12:42:18.000Z",
      //         "updated_at": "2023-10-06T08:10:38.000Z"
      //       },
      //       {
      //         "id": 5,
      //         "risk_profile_question_id": 2,
      //         "risk_answer_weightage_id": 2,
      //         "answer": "Doing a salaried job and may be run a part-time business",
      //         "answer_image_url": null,
      //         "position": 2,
      //         "created_at": "2023-05-03T12:41:35.000Z",
      //         "updated_at": "2023-05-03T12:41:35.000Z"
      //       },
      //       {
      //         "id": 6,
      //         "risk_profile_question_id": 2,
      //         "risk_answer_weightage_id": 3,
      //         "answer": "Running a partnership business",
      //         "answer_image_url": null,
      //         "position": 3,
      //         "created_at": "2023-05-08T05:11:29.000Z",
      //         "updated_at": "2023-10-06T08:10:38.000Z"
      //       },
      //       {
      //         "id": 7,
      //         "risk_profile_question_id": 2,
      //         "risk_answer_weightage_id": 4,
      //         "answer": "Running my own business",
      //         "answer_image_url": null,
      //         "position": 4,
      //         "created_at": "2023-05-08T05:12:51.000Z",
      //         "updated_at": "2023-10-06T08:10:38.000Z"
      //       }
      //     ]
      //   },
      //   {
      //     "id": 3,
      //     "question": "When you invest your money in any instrument, what thought comes to your mind first?",
      //     "description": "no description",
      //     "is_active": true,
      //     "created_at": "2023-05-08T05:14:05.000Z",
      //     "updated_at": "2023-05-08T06:05:38.000Z",
      //     "risk_answer_choices": [
      //       {
      //         "id": 9,
      //         "risk_profile_question_id": 3,
      //         "risk_answer_weightage_id": 1,
      //         "answer": "I should not lose my money",
      //         "answer_image_url": null,
      //         "position": 1,
      //         "created_at": "2023-05-08T05:21:18.000Z",
      //         "updated_at": "2023-05-08T05:21:18.000Z"
      //       },
      //       {
      //         "id": 10,
      //         "risk_profile_question_id": 3,
      //         "risk_answer_weightage_id": 2,
      //         "answer": "This should not turn out to be a bad investment",
      //         "answer_image_url": null,
      //         "position": 2,
      //         "created_at": "2023-05-08T05:21:18.000Z",
      //         "updated_at": "2023-10-06T08:10:38.000Z"
      //       },
      //       {
      //         "id": 13,
      //         "risk_profile_question_id": 3,
      //         "risk_answer_weightage_id": 3,
      //         "answer": "This should turn out to be a good investment",
      //         "answer_image_url": null,
      //         "position": 3,
      //         "created_at": "2023-05-08T05:23:11.000Z",
      //         "updated_at": "2023-10-06T08:10:39.000Z"
      //       },
      //       {
      //         "id": 14,
      //         "risk_profile_question_id": 3,
      //         "risk_answer_weightage_id": 4,
      //         "answer": "I know this is a good decision",
      //         "answer_image_url": null,
      //         "position": 4,
      //         "created_at": "2023-05-08T05:23:11.000Z",
      //         "updated_at": "2023-10-06T08:10:39.000Z"
      //       }
      //     ]
      //   },
      //   {
      //     "id": 4,
      //     "question": "After you have made an investment, how do you usually feel?",
      //     "description": "no description",
      //     "is_active": true,
      //     "created_at": "2023-05-08T05:14:53.000Z",
      //     "updated_at": "2023-05-08T06:05:43.000Z",
      //     "risk_answer_choices": [
      //       {
      //         "id": 15,
      //         "risk_profile_question_id": 4,
      //         "risk_answer_weightage_id": 1,
      //         "answer": "Very worried",
      //         "answer_image_url": null,
      //         "position": 1,
      //         "created_at": "2023-05-08T05:25:14.000Z",
      //         "updated_at": "2023-05-08T05:25:14.000Z"
      //       },
      //       {
      //         "id": 16,
      //         "risk_profile_question_id": 4,
      //         "risk_answer_weightage_id": 2,
      //         "answer": "Somewhat worried",
      //         "answer_image_url": null,
      //         "position": 2,
      //         "created_at": "2023-05-08T05:25:14.000Z",
      //         "updated_at": "2023-10-06T08:10:39.000Z"
      //       },
      //       {
      //         "id": 17,
      //         "risk_profile_question_id": 4,
      //         "risk_answer_weightage_id": 3,
      //         "answer": "Somewhat satisfied",
      //         "answer_image_url": null,
      //         "position": 3,
      //         "created_at": "2023-05-08T05:25:14.000Z",
      //         "updated_at": "2023-10-06T08:10:40.000Z"
      //       },
      //       {
      //         "id": 18,
      //         "risk_profile_question_id": 4,
      //         "risk_answer_weightage_id": 4,
      //         "answer": "Very satisfied",
      //         "answer_image_url": null,
      //         "position": 4,
      //         "created_at": "2023-05-08T05:25:14.000Z",
      //         "updated_at": "2023-10-06T08:10:40.000Z"
      //       }
      //     ]
      //   },
      //   {
      //     "id": 5,
      //     "question": "If you had the choice between a fixed salary and a partly variable one, depending on your performance and the profits of your company, which one would you prefer?",
      //     "description": "no description",
      //     "is_active": true,
      //     "created_at": "2023-05-08T05:14:53.000Z",
      //     "updated_at": "2023-10-06T09:06:08.000Z",
      //     "risk_answer_choices": [
      //       {
      //         "id": 19,
      //         "risk_profile_question_id": 5,
      //         "risk_answer_weightage_id": 1,
      //         "answer": "I would prefer a fixed salary, even if it is small",
      //         "answer_image_url": null,
      //         "position": 1,
      //         "created_at": "2023-05-08T05:27:22.000Z",
      //         "updated_at": "2023-05-08T05:27:22.000Z"
      //       },
      //       {
      //         "id": 20,
      //         "risk_profile_question_id": 5,
      //         "risk_answer_weightage_id": 2,
      //         "answer": "I would prefer most of my salary to be fixed, with only a small variable part",
      //         "answer_image_url": null,
      //         "position": 2,
      //         "created_at": "2023-05-08T05:27:22.000Z",
      //         "updated_at": "2023-10-06T08:10:40.000Z"
      //       },
      //       {
      //         "id": 21,
      //         "risk_profile_question_id": 5,
      //         "risk_answer_weightage_id": 3,
      //         "answer": "I would prefer half my salary to be fixed and the other half to be variable",
      //         "answer_image_url": null,
      //         "position": 3,
      //         "created_at": "2023-05-08T05:27:22.000Z",
      //         "updated_at": "2023-10-06T08:10:40.000Z"
      //       },
      //       {
      //         "id": 22,
      //         "risk_profile_question_id": 5,
      //         "risk_answer_weightage_id": 4,
      //         "answer": "I would prefer most of my earnings to be performance-linked",
      //         "answer_image_url": null,
      //         "position": 4,
      //         "created_at": "2023-05-08T05:27:22.000Z",
      //         "updated_at": "2023-10-06T08:10:41.000Z"
      //       }
      //     ]
      //   },
      //   {
      //     "id": 6,
      //     "question": "After you have made an investment, how do you usually feel?",
      //     "description": "no descriptions asdad",
      //     "is_active": true,
      //     "created_at": "2023-05-08T05:15:08.000Z",
      //     "updated_at": "2023-10-06T09:06:08.000Z",
      //     "risk_answer_choices": [
      //       {
      //         "id": 23,
      //         "risk_profile_question_id": 6,
      //         "risk_answer_weightage_id": 1,
      //         "answer": "Very unsure",
      //         "answer_image_url": null,
      //         "position": 1,
      //         "created_at": "2023-05-08T05:28:45.000Z",
      //         "updated_at": "2023-05-08T05:28:45.000Z"
      //       },
      //       {
      //         "id": 24,
      //         "risk_profile_question_id": 6,
      //         "risk_answer_weightage_id": 2,
      //         "answer": "Not very confident",
      //         "answer_image_url": null,
      //         "position": 2,
      //         "created_at": "2023-05-08T05:28:45.000Z",
      //         "updated_at": "2023-10-06T08:10:41.000Z"
      //       },
      //       {
      //         "id": 25,
      //         "risk_profile_question_id": 6,
      //         "risk_answer_weightage_id": 3,
      //         "answer": "Some what confident",
      //         "answer_image_url": null,
      //         "position": 3,
      //         "created_at": "2023-05-08T05:28:45.000Z",
      //         "updated_at": "2023-10-06T08:10:41.000Z"
      //       },
      //       {
      //         "id": 26,
      //         "risk_profile_question_id": 6,
      //         "risk_answer_weightage_id": 4,
      //         "answer": "Very confident",
      //         "answer_image_url": null,
      //         "position": 4,
      //         "created_at": "2023-05-08T05:28:45.000Z",
      //         "updated_at": "2023-10-06T08:10:41.000Z"
      //       }
      //     ]
      //   }
      // ])
    } catch { }
  };

  useEffect(() => {
    setRiskProfileScore(userStore?.user?.riskProfileScore);
  }, [userStore, riskProfileScore]);

  useEffect(() => {
    get_questions();
  }, []);

  const handle_next = () => {
    if (index === questions.length - 1) {
      if (answers.length == questions.length) {
        submit_answer(answers[index]);
      } else {
        setError("Please answer all the questions");
      }
    } else {
      if (answers[index]) {
        submit_answer(answers[index]);
        setError(false);
        setIndex(index + 1);
      } else {
        setError("Please answer the questions");
      }
    }
  };

  //-------------------------update or insert item----------------------------------------//
  const upsert = (item) => {
    let array = [...answers];
    const i = array.findIndex((_item) => _item.id === item.id);
    if (i > -1) array[i] = item; // (2)
    else array.push(item);

    setAnswers(array);
  };

  //-----------------------------set answers-----------------------------------//
  const store_answers = (data) => {
    upsert(data);
  };
  //---------------------------submit answers------------------------------------------//
  const submit_answer = async (ans) => {
    console.log(ans, 'ans')
    // let answerArry = answers.map((ele) => {
    //   return { quizeChoiceMapId: ele.quizeChoiceMapId };
    // });
    const formData = {
      user_id: localStorage.getItem("user_id"),
      risk_profile_question_id:ans?.id + 1,
      risk_answer_choice_id: ans?.quizeChoiceMapId

      // userQuizeChoices: answerArry,
    };
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    try {
      await httpInstance(`${SUBMIT_ANSWERS}`, httpConfig);
      if (index === questions.length - 1){
        console.log('done')
        questionsAreDone()
      }
      // dispatch(getUser());
      // navigate(
      //   `${namespace}/dashboard/mutualfund/assisted-services/invest-detail`
      // );
    } catch { }
  };
  return (
    <div style={{width:'100%',}}>
      {/* {typeof riskProfileScore != "undefined" && ( */}
      <>
         <Box px={{ md: 20 }} mt={6} >
          <Typography style={{ fontSize: "1.2rem", fontWeight: 500 }}>Risk Profile Questions</Typography>

          <Box display="flex" justifyContent="center" mt={5}>
            {[...Array(questions?.length)].map((ele, i) => {
              return (
                <Box
                  key={i}
                  className={
                    i == index ? classes.slideBoxActive : classes.slideBox
                  }
                  onClick={() => handleIndex(i)}
                ></Box>
              );
            })}
          </Box>
          <Box  mt={6} p={0}>
            <Question
              data={questions && questions[index]}
              index={index}
              store_answers={store_answers}
              answers={answers}
            />
          </Box>


          <Box display="flex" justifyContent="center" mt={3}>
            <button onClick={handle_next} className={classes.primaryButton}>
              Next
            </button>
          </Box>
          <Box display="flex" justifyContent="center" mt={1}>
            {error && <ErrorMessage message={error} />}
          </Box>
        </ Box>
      </>
      {/* )} */}
    </div>
  );
};

export default Questionareies;
