import React from 'react'
import About from '../../containers/About/Aboutus'
// import Header from '../../components/header/Header'
import Header from "../../components/Header";
import Footer from '../../components/Footer'
import { isMobile } from "react-device-detect";
import FooterStatic from '../../components/FooterStatic';
import Contactus from '../../containers/About/Contactus';

const ContactUs = () => {
  return (
    <div>
      {!isMobile && <Header />}
      <Contactus />
      {!isMobile && <FooterStatic />}
    </div>
  )
}

export default ContactUs