// import React, { useEffect, useState } from "react";
// import { Box, Typography, makeStyles, Grid, Slider, CircularProgress } from "@material-ui/core";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
// import { POST_GOALS_CALCULATE_MY_FIRST_GOAL } from "../../utils/endpoints";
// import httpInstance from "../../utils/instance";
// import { namespace, BASE_URL } from "../../environment";
// import {
//     BarChart,
//     Bar,
//     XAxis,
//     YAxis,
//     CartesianGrid,
//     Tooltip,
//     Legend,
//     ResponsiveContainer,
//     LabelList

// } from "recharts";






// const ShowRecommendedFunds = () => {
//     const useStyles = makeStyles((theme) => ({
//         root: {},
//         mainGrid: {
//             padding: "30px 4rem 0px 8rem",
//         },
//         subTabtext: {
//             fontSize: "0.7rem",
//             fontFamily: "inherit",
//             fontWeight: 500,
//         },
//         textUnderline: {
//             fontSize: "0.7rem",
//             textDecoration: "underline",
//             fontFamily: "inherit",
//         },
//         text: {
//             fontSize: "0.8rem",
//             fontWeight: 700,
//         },
//         subText: {
//             fontSize: "0.7rem",
//             fontFamily: "inherit",
//             fontWeight: 500,
//             paddingLeft: '5px',
//         },
//         text1: {
//             fontSize: "0.8rem",
//         },
//         text2: {
//             fontSize: "1.2rem",
//             fontFamily: "inherit",
//             fontWeight: 500,
//             // paddingLeft: '5px', 
//         },
//         text3: {
//             fontSize: "1.2rem",
//             fontFamily: "inherit",
//             fontWeight: 700,
//             // paddingLeft: '5px', 
//         },
//         textBold: {
//             fontSize: "0.8rem",
//             fontWeight: 700,
//             marginBottom: "1rem",
//         },

//         thumb: {
//             background: "#bdbdbd",
//             width: "20px",
//             height: "20px",
//             boxShadow: 'none !important',
//             ' &.Mui-focusVisible': {
//                 boxShadow: 'none !important',
//             },
//             '&.Mui-active': {
//                 boxShadow: 'none !important',
//             },
//             // boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
//         },
//         mark: {
//             background: "black"
//         },
//         rail: {
//             background: "#EBEBEB",
//             height: "10px",
//             borderRadius: '10px',
//         },
//         track: {
//             background: "#0E76BC",
//             height: "10px",
//             borderRadius: '10px',
//         },
//         borderBox: {
//             // border: "1px solid #E4E4E4",
//             // borderRadius: "5px",
//             // padding: "1.5rem",
//             // width: "75%",
//             '& .MuiSlider-valueLabel': {
//                 '& > *': {

//                     backgroundColor: 'white',
//                     color: "black"
//                 },
//             }
//         },
//         rupees: {
//             fontFamily: "Open Sans"
//         },
//         primaryButton: {
//             padding: "0.8rem 5rem",
//             background: "#0E76BC",
//             color: "#fff",
//             fontSize: "0.8rem",
//             fontWeight: 600,
//             borderRadius: "45px",
//             border: "none",
//             cursor: "pointer",
//             fontFamily: "inherit"

//         },
//         "@media (max-width: 767px)": {
//             mainGrid: {
//                 padding: "0px 10px 0px 10px",
//             },
//         },
//         tabcontent: {
//             display: 'none'
//         },

//         active: {
//             display: 'block'
//         },

//         tablink: {
//             backgroundColor: '#f2f2f2',
//             padding: '10px 20px',
//             border: 'none',
//             cursor: 'pointer',
//             position: 'relative',
//             "&:hover": {
//                 backgroundColor: '#ddd',
//             }
//         },


//         active: {

//             backgroundColor: ' #ccc',

//         },

//         indicator: {
//             position: 'absolute',
//             bottom: '0',
//             left: '0',
//             width: '33.33%', /* Adjust based on the number of tabs */
//             height: '2px',
//             backgroundColor: 'blue',
//             transition: 'left 0.3s ease-in-out',
//         }
//     }));
//     const classes = useStyles();
//     const location = useLocation();

//     const [goalsSelection, setGoalsSelection] = useState(null)
//     const [sliderValueOnChangeEnd, setSliderValueOnChangeEnd] = useState(5000)
//     const [loading, setLoading] = useState(false)
//     const [calculatedMyFirstCroreData, setCalculatedMyFirstCroreData] = useState(null);
//     const [showBarchart, setShowBarChart] = useState(false);
//     const [showRecommendedFundsPage, setShowRecommendedFundsPage] = useState(false)

//     const navigate = useNavigate();


//     // calculate my first corre post request 
//     const CalculateMyFirstCrore = async () => {
//         setLoading(true)
//         const formData = {
//             investment_per_month: sliderValueOnChangeEnd,
//             increase_investment_yearly_percent: 15,
//             expected_returns: 15,
//         }
//         const httpConfig1 = {
//             method: "POST",
//             tokenType: "user",
//             headers: {
//                 "Content-Type": "application/json",
//             },
//             // params: { isin: fundData?.isinCode },
//             data: formData
//         };

//         try {
//             const response = await httpInstance(`${POST_GOALS_CALCULATE_MY_FIRST_GOAL}`, httpConfig1);
//             console.log(response?.data, 'fadfadfadsfasdfasdfasdf')

//             setCalculatedMyFirstCroreData(response?.data);
//             setShowBarChart(true)
//         } catch {

//         } finally {
//             setLoading(false)

//         }
//     }

//     // CustomBar Label
//     const CustomBarLabel = ({ fill, ...props }) => (
//         <g transform={`translate(${props.x},${props.y})`}>
//             <text fill={fill} fontSize={10} textAnchor="middle" dominantBaseline="middle" dy={-10} dx={10} >
//                 ₹{props.value.toFixed(2)}
//             </text>
//         </g>
//     );

//     //As the slider change in the area 

//     useEffect(() => {
//         if (showBarchart) {
//             CalculateMyFirstCrore();
//         }
//     }, [sliderValueOnChangeEnd])

//     //See recomendation function call after graph 

//     const SeeRecommendedFunds = () => {
//         setShowRecommendedFundsPage(true)
//     }

//     // const [activeTab, setActiveTab] = useState(0);

//     // const handleTabClick = (index) => {
//     //     setActiveTab(index);
//     // };

//     // const renderTabs = () => {
//     //     const tabs = ['Tab 1', 'Tab 2', 'Tab 3', 'Tab 4'];

//     //     return tabs.map((tab, index) => (
//     //         <div
//     //             key={index}
//     //             className={`tab ${activeTab === index ? 'active' : ''}`}
//     //             onClick={() => handleTabClick(index)}
//     //         >
//     //             {tab}
//     //         </div>
//     //     ));
//     // };


//     const [activeTab, setActiveTab] = useState('tab1');
//     const [indicatorPosition, setIndicatorPosition] = useState(0);

//     const tabClickHandler = (tabName, index) => {
//         setActiveTab(tabName);
//         setIndicatorPosition(index * 33.33); // Adjust based on the number of tabs
//     };



//     const [activeTabs, setActiveTabs] = useState('tab1');

//     const tabClickHandlers = (tabName) => {
//         setActiveTabs(tabName);
//     };


//     return (
//         <div >
//             {/* <div className="tabs-container">
//                 <div className="tabs">{renderTabs()}</div>
//             </div> */}

// <div className={classes.tabs}>
//         <div className="tab-slider" style={{ left: activeTab === 'tab1' ? '0%' : activeTab === 'tab2' ? '33.33%' : '66.66%' }}></div>
//         <button
//           className={activeTab === 'tab1' ? 'tablink active' : 'tablink'}
//           onClick={() => tabClickHandler('tab1')}>
//           Tab 1
//         </button>
//         <button
//           className={activeTab === 'tab2' ? 'tablink active' : 'tablink'}
//           onClick={() => tabClickHandler('tab2')}>
//           Tab 2
//         </button>
//         <button
//           className={activeTab === 'tab3' ? 'tablink active' : 'tablink'}
//           onClick={() => tabClickHandler('tab3')}>
//           Tab 3
//         </button>
//       </div>



//             <div className={classes.tabs}>
//                 <button
//                     className={activeTab === 'tab1' ? `${classes.tablink} ${classes.active}` : `${classes.tablink}`}
//                     onClick={() => tabClickHandler('tab1', 0)}>
//                     Tab 1
//                 </button>
//                 <button
//                     className={activeTab === 'tab2' ? `${classes.tablink} ${classes.active}` : `${classes.tablink}`}
//                     onClick={() => tabClickHandler('tab2', 1)}>
//                     Tab 2
//                 </button>
//                 <button
//                     className={activeTab === 'tab3' ? `${classes.tablink} ${classes.active}` : `${classes.tablink}`}
//                     onClick={() => tabClickHandler('tab3', 2)}>
//                     Tab 3
//                 </button>
//                 <div className="indicator" style={{ left: `${indicatorPosition}%` }} />
//             </div>

//             <div id="tab1" className={activeTab === 'tab1' ?  `${classes.tabcontent} ${classes.active}` : `${classes.tabcontent}`}>
//                 <h3>Tab 1 Content</h3>
//                 <p>This is the content of Tab 1.</p>
//             </div>

//             <div id="tab2" className={activeTab === 'tab2' ?  `${classes.tabcontent} ${classes.active}` : `${classes.tabcontent}`}>
//                 <h3>Tab 2 Content</h3>
//                 <p>This is the content of Tab 2.</p>
//             </div>

//             <div id="tab3" className={activeTab === 'tab3' ?  `${classes.tabcontent} ${classes.active}` : `${classes.tabcontent}`}>
//                 <h3>Tab 3 Content</h3>
//                 <p>This is the content of Tab 3.</p>
//             </div>
//         </div>
//         // </div >
//     );
// };

// export default ShowRecommendedFunds;



import React, { useEffect, useState } from "react";
import { Box, Typography, makeStyles, Grid, Slider } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { GET_PORTFOLIO_FUND_DISTRIBUTE } from "../../utils/endpoints";
import httpInstance from "../../utils/instance";
import { namespace, BASE_URL } from "../../environment";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList

} from "recharts";
import ErrorMessage from "../../components/Validation/ErrorMessage";


const useStyles = makeStyles((theme) => ({
    // tabsFolder: {
    //     border: '1px solid #0E76BC',
    //     borderRadius: '10px',
    //     overflow: 'hidden',
    //     padding: `5px`
    // },
    tabs: {
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        border: '1px solid #0E76BC',
        borderRadius: '10px',

    },
    tablink: {
        borderRadius: '10px',
        flex: 1,
        padding: '10px 20px',
        borderRadius: 0,
        '&:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
        position: 'relative',
        zIndex: 1,
        color: '#0E76BC',
        textTransform: 'capitalize',
    },
    active: {
        borderRadius: '10px',
        backgroundColor: '#0E76BC',
        color: theme.palette.secondary.contrastText,
    },
    activeBox: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '50%',
        height: '100%',
        // backgroundColor: theme.palette.secondary.main,
        backgroundColor: '#0E76BC',
        borderRadius: '10px',

        transition: 'left 0.3s ease-in-out',
    },
}));

const ShowRecommendedFunds = () => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState('tab1');
    const [activeBoxPosition, setActiveBoxPosition] = useState(0);
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const tabClickHandler = (tabName, position) => {
        setActiveTab(tabName);
        setActiveBoxPosition(position);
    };
    // const tabHoverHandler = (tabName, position) => {
    //     // if (!activeTab === tabName && !activeBoxPosition === position){

    //     // }
    // }





    // calculate my first corre post request 
    const RecommendedFunds = async () => {
        setLoading(true)
        const httpConfig = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                modal_portfilio_id: 1,
                duration: 8
                ,
                amount: 39000,
                is_onetime: false
            },
            // data: formData
        };

        const httpConfig1 = {
            method: "GET",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                modal_portfilio_id: 1,
                duration: 8
                ,
                amount: 39000,
                is_onetime: false
            },
            // data: formData1
        };

        try {
            const [responseReturn1, responseReturn2] = await Promise.all([
                httpInstance(`${GET_PORTFOLIO_FUND_DISTRIBUTE}`, httpConfig),
                httpInstance(`${GET_PORTFOLIO_FUND_DISTRIBUTE}`, httpConfig1),
            ])
            console.log(responseReturn1, responseReturn2)
            // const response = await httpInstance(`${POST_GOALS_CALCULATE_MY_FIRST_GOAL}`, httpConfig1);
            // console.log(response?.data, 'fadfadfadsfasdfasdfasdf')


        } catch (error) {
            console.log(error.response.data?.error)
            setErrorMessage(error.response.data?.error)
        } finally {
            setLoading(false)

        }
    }

    //As the slider change in the area 

    useEffect(() => {

        RecommendedFunds();
    }, [])




    return (
        <div>
            <div className={classes.tabsFolder}>
                <div className={classes.tabs}>
                    <div className={classes.activeBox} style={{ left: `${activeBoxPosition}%` }}></div>
                    <Button
                        className={`${classes.tablink} ${activeTab === 'tab1' ? classes.active : ''}`}
                        onClick={() => tabClickHandler('tab1', 0)}
                    // onMouseEnter={() => tabHoverHandler('tab1', 0)}
                    >
                        Based on Risk Profile
                    </Button>
                    <Button
                        className={`${classes.tablink} ${activeTab === 'tab2' ? classes.active : ''}`}
                        onClick={() => tabClickHandler('tab2', 50)}>
                        MF Wealth Recommended
                    </Button>
                </div>
            </div>
            {activeTab === 'tab1' &&
                <>
                    {errorMessage ? (
                        <ErrorMessage message={errorMessage} />
                    ) : (
                        <Box>
                            dafadfa
                            <div id="tab1" className={activeTab === 'tab1' ? 'tabcontent active' : 'tabcontent'}>
                                <h3>Tab 1 Content</h3>
                                <p>This is the content of Tab 1.</p>
                            </div>
                        </Box>
                    )}
                </>

            }
            {activeTab === 'tab2' &&
                <>
                    {errorMessage ? (
                        <ErrorMessage message={errorMessage} />
                    ) : (
                        <Box>
                            dafadfa
                            <div id="tab1" className={activeTab === 'tab1' ? 'tabcontent active' : 'tabcontent'}>
                                <h3>Tab 1 Content</h3>
                                <p>This is the content of Tab 1.</p>
                            </div>
                        </Box>
                    )}
                </>
            }

        </div>
    );
};


export default ShowRecommendedFunds;
