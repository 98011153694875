import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { namespace } from "../../../environment";

const InsufficientBalanceModal = ({ dialog, closeDialog }) => {
  const useStyles = makeStyles((theme) => ({
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
    },

    title: {
      fontSize: "1.1rem",
      fontWeight: 400,
      textAlign: "center",
      fontFamily: "inherit",
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "center",
      alignItems: " center",
      textAlign: "center",
      padding: "5px",
    },
    dialogPaper: {
      maxWidth: "16.5rem",
      justifyContent: "center",
    },
    image: {
      textAlign: "center",
    },
    text: {
      fontSize: "0.8rem",
      textAlign: "center",
      fontFamily: "inherit",
    },
    primaryButton: {
      padding: "0.8rem 0.3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      width: "100%",
    },
    dialogContent: {
      padding: "8px 30px",
    },
  }));

  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={dialog}
        onClose={closeDialog}
        classes={{ paper: classes.dialogPaper }}
      >
        <Box display="flex" className={classes.closeIcon}>
          {" "}
          <CloseIcon onClick={closeDialog} style={{ cursor: "pointer" }} />
        </Box>

        <DialogTitle
          classes={{ paper: classes.dialogPaper, root: classes.dialogTitle }}
        >
          <Typography className={classes.title}>
            Insufficient Balance
          </Typography>
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <Box className={classes.image}>
            <img src="/images/goldcoins.svg" height="50px" width="50px" />
          </Box>
          <Typography className={classes.text}>
            Available quantity is not enough. Do you want to buy?
          </Typography>

          <Box my={3}>
            <Link to={`${namespace}/dashboard/digitalgold`}>
              <button
                className={classes.primaryButton}
                type="submit"
                onClick={() => closeDialog("checkout")}
              >
                Okay
              </button>
            </Link>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InsufficientBalanceModal;
