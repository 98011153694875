import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import {
  Box,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import ErrorMessage from "../../../../components/Validation/ErrorMessage";
import { RESEND_OTP, POST_TRANSACTION_BASKETS_GENERATE_CONSENT } from "../../../../utils/endpoints";
import httpInstance from "../../../../utils/instance";

const Index = ({
  verify_otp,
  id,
  transactionTypeId,
  otpError,
  handle_otp_error,
  loadVerify,
}) => {
  const useStyles = makeStyles({
    text: {
      fontSize: "0.9rem",
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
      fontFamily: "inherit",
    },

    otpInput: {
      border: "none",
      borderRadius: "8px",
      width: "40px !important",
      height: "40px",
      fontSize: "13px",
      color: "#000",
      fontWeight: "400",
      caretColor: "blue",
      background: "#E6E6E6",
    },
    resendOtp: {
      fontSize: "0.7rem",
      color: "#0E76BC",
      textAlign: "center",
      cursor: "pointer",
    },
  });

  const classes = useStyles();

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (code) => setOtp(code);

  const resend_OTP = async () => {
    const formData = {
      userId: localStorage.getItem("user_id"),
      id: id,
      transactionTypeId: 3,
    };
    const httpConfig = {
      method: "POST",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        // userId: localStorage.getItem("user_id"),
        transaction_basket_id: id,
        // transactionTypeId: transactionTypeId,
      },
    };
    try {
      const response = await httpInstance(`${POST_TRANSACTION_BASKETS_GENERATE_CONSENT}`, httpConfig);
    } catch (error) {
      handle_otp_error(error.response.data?.message);
    }
  };

  //-----------------submit otp--------------------------//
  const submit_otp=(e)=>{
    e.preventDefault()
    verify_otp(otp)
  }

  return (
    <div>
      <Box display="flex" justifyContent="center">
        <Typography className={classes.text}>
          Please enter the OTP sent to your registered Mobile number or Email
        </Typography>
      </Box>
      <form onSubmit={submit_otp}>
      <Box mt={3} px={5} display="flex" justifyContent="center">
        
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={4}
          separator={<span style={{ width: "8px" }}></span>}
          isInputNum={true}
          shouldAutoFocus={true}
          inputStyle={classes.otpInput}
          focusStyle={{
            border: "1px solid #CFD3DB",
            outline: "none",
          }}
        />
      </Box>
      <Box px={11} mt={1}>
        {" "}
        <Typography className={classes.resendOtp} onClick={resend_OTP}>
          Resend OTP
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center">
        {otpError && <ErrorMessage message={otpError} />}
      </Box>
      <Box display="flex" justifyContent="center" mt={5} mb={10}>
        <button
          className={classes.primaryButton}
          //onClick={() => verify_otp(otp)}
        >
          {loadVerify ? (
            <CircularProgress size="1rem" color="inherit" />
          ) : (
            "Verify"
          )}
        </button>
      </Box>
      </form>
    </div>
  );
};

export default Index;
