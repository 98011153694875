import React from 'react'
// import Header from '../../components/header/Header'
import Header from "../../components/Header";
import Footer from '../../components/Footer'
import Terms_Conditions from '../../containers/About/Terms_Conditions'
import { isMobile } from "react-device-detect";
import FooterStatic from '../../components/FooterStatic';

const Terms_conditions = () => {
  return (
    <div>
        {!isMobile && <Header/>}
        <Terms_Conditions/>
        { !isMobile &&  <FooterStatic/>}
    </div>
  )
}

export default Terms_conditions