import React, { useState, useEffect } from 'react'
import { Box, Typography, makeStyles, Grid, Table, TableBody, TableCell, TableHead, TableRow, Container, Tab, Tabs, LinearProgress } from "@material-ui/core";
import { GET_HOLDINGS } from '../../utils/endpoints'
import httpInstance from '../../utils/instance'
import { fontSize } from '@mui/system';
import PropTypes from 'prop-types';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={"span"}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const Holdings = ({ schemeCode, holdingDataOfSector }) => {
    const useStyles = makeStyles((theme) => ({
        root: {

        },
        tableCellsLists: {

            "& .MuiLinearProgress-colorPrimary": {
                backgroundColor: "#25AAE1",
            },
            "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#0E76BC",
            },
        },
        title: {
            fontWeight: 600,
            padding: "0px 7rem",
            fontSize: "1.2rem"
        },
        tableHead: {
            fontWeight: 600
        },
        tab_box: {
            padding: "0px 0rem"
        },
        tab_style: {
            width: "100%",
            background: "white",
            color: "black",
            borderBottom: '1px solid #E5E3E3',
            "& .MuiTab-root": {
                fontFamily: "inherit",
                fontSize: "1rem",
                color: "black",
                opacity: 1,
                textTransform: "none",
                background: "none",
                minWidth: "auto",
                padding: "0px"
            },
            "& .MuiTab-textColorInheri": {
                opacity: 0.9
            },
            "& .MuiTabs-root": {
                height: "42px",
                minHeight: "42px",
                padding: "0px"
            },
            "& .MuiTabs-flexContainer": {
                display: "flex",
                justifyContent: "space-between"
            },
            "& .MuiTabs-scrollable": {
                overflowY: "clip"
            }


        },
        tabWidth: {
            width: '25%',
            color: '#272727',
            fontSize: '15px',
            fontFamily: 'Poppins',
            fontWeight: '400'
        },
        activeTab: {
            "& .MuiTab-wrapper": {
                borderBottom: "2px solid #0E76BC",
                color: "#0E76BC",
                paddingBottom: "5px",

            }

        },

        inActiveTab: {
            "& .MuiTab-wrapper": {
                paddingBottom: "5px",

            }

        },
        "@media (max-width: 600px)": {
            tab_box: {
                padding: "20px"
            },
            activeTab: {
                "& .MuiTab-wrapper": {
                    padding: "10px"
                }
            },
            inActiveTab: {
                "& .MuiTab-wrapper": {
                    padding: "10px"

                }
            },
        },
        "@media (min-width: 992px)": {
            tablefirstCell: {
                fontWeight: 600,
                width: "45%",
                paddingLeft: "7rem",

            },
            tableBodyfirstCell: {
                paddingLeft: "7rem",
                width: "45%",

            },
            tablelastCell: {
                fontWeight: 600,
                paddingRight: "5rem",

            },
            tableBodylastCell: {
                paddingRight: "5rem",
            },
        },
        viewAll: {
            fontSize: "0.9rem",
            fontWeight: 600,
            color: '#0E76BC',
            textDecoration: "underline",
            cursor: "pointer"
        }


    }))
    const classes = useStyles();

    const [holdingData, setHoldingData] = useState([])
    const [viewAll, setViewAll] = useState(false)
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const get_holding = async () => {
        // console.log(schemeCode)
        // const formData={
        //     scheme_code:schemeCode,
        //     as_on:"",
        //     portfolio_type:''
        // }
        const httpConfig = {
            method: "GET",
            // tokenType: "user",
            test: 'test',
            // headers:{
            //   "Content-Type": "application/json",
            // },
            // data:formData,
        };
        try {
            const response = await httpInstance(`${GET_HOLDINGS}?fundPlansId=${schemeCode}`, httpConfig)
            setHoldingData(response.data?.data)
            holdingDataOfSector(response?.data?.data?.allocationBySector)
            // console.log(response?.data?.data?.allocationBySector)
        }
        catch {

        }
    }
        
    useEffect(() => {
        get_holding()
    }, [])

    const handle_viewAll = () => {
        setViewAll(!viewAll)
    }

    return (
        <div>
            <Box mt={5}>
                <Typography className={classes.title}>Fund Distribution</Typography>
                <Container className={classes.tab_box}>

                    <div className={classes.tab_style}>
                        <Tabs indicatorColor=" " value={value} onChange={handleChange} variant="scrollable" allowScrollButtonsMobil={true}>

                            <Tab label="Market Cap" {...a11yProps(0)} className={value == 0 ? classes.activeTab : classes.inActiveTab} />
                            <Tab label="Sector" {...a11yProps(1)} className={value == 1 ? classes.activeTab : classes.inActiveTab} />
                            <Tab label="Companies" {...a11yProps(2)} className={value == 2 ? classes.activeTab : classes.inActiveTab} />

                        </Tabs>
                    </div>

                </Container>
                <TabPanel value={value} index={0} >
                    <div style={{ width: 'auto', overflowX: 'auto' }}  className={classes.tableCellsLists}>


                        <Table style={{ marginTop: "0rem", }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tablefirstCell} >Name</TableCell>
                                    {/* <TableCell className={classes.tableHead} >Sector</TableCell> */}
                                    <TableCell className={classes.tablelastCell}>Assets</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {(!viewAll ? holdingData?.allocationByMarketCap?.slice(0, 10) : holdingData?.allocationByMarketCap)?.map((ele, i) => {
                                    return ( */}
                                {/* <TableRow key={i}>
                                            <TableCell className={classes.tableBodyfirstCell}>{ele[i]}</TableCell>
                                            <TableCell className={classes.tableBodylastCell}>{ele[i].assetAercentage}%</TableCell>

                                        </TableRow> */}
                                {/* )
                                })} */}
                                <TableRow >
                                    <TableCell className={classes.tableBodyfirstCell}>{`Giant Cap`}</TableCell>
                                    <TableCell className={classes.tableBodylastCell}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography variant="caption" color="textSecondary">{`${Math.round(holdingData?.allocationByMarketCap?.[0]?.giantPercentage)}%`}</Typography>
                                            <LinearProgress variant="determinate" value={holdingData?.allocationByMarketCap?.[0]?.giantPercentage ? holdingData?.allocationByMarketCap?.[0]?.giantPercentage : 0} style={{ width: '50%', }} color='primary' sx={{
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'red',
                                                },
                                            }} />
                                        </Box></TableCell>

                                </TableRow>
                                <TableRow >
                                    <TableCell className={classes.tableBodyfirstCell}>{`Mid Cap`}</TableCell>
                                    <TableCell className={classes.tableBodylastCell}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography variant="caption" color="textSecondary">{`${Math.round(holdingData?.allocationByMarketCap?.[0]?.midPercentage)}%`}</Typography>
                                            <LinearProgress variant="determinate" value={holdingData?.allocationByMarketCap?.[0]?.midPercentage ? holdingData?.allocationByMarketCap?.[0]?.midPercentage : 0} style={{ width: '50%', }} color='primary' sx={{
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'red',
                                                },
                                            }} />
                                        </Box>




                                    </TableCell>

                                </TableRow>
                                <TableRow >
                                    <TableCell className={classes.tableBodyfirstCell}>{`Large Cap`}</TableCell>
                                    <TableCell className={classes.tableBodylastCell}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography variant="caption" color="textSecondary">{`${Math.round(holdingData?.allocationByMarketCap?.[0]?.largePercentage)}%`}</Typography>
                                            <LinearProgress variant="determinate" value={holdingData?.allocationByMarketCap?.[0]?.largePercentage ? holdingData?.allocationByMarketCap?.[0]?.largePercentage : 0} style={{ width: '50%', }} color='primary' sx={{
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'red',
                                                },
                                            }} />
                                        </Box></TableCell>

                                </TableRow>
                                <TableRow >
                                    <TableCell className={classes.tableBodyfirstCell}>{`Small Cap`}</TableCell>
                                    <TableCell className={classes.tableBodylastCell}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography variant="caption" color="textSecondary">{`${Math.round(holdingData?.allocationByMarketCap?.[0]?.smallPercentage)}%`}</Typography>
                                            <LinearProgress variant="determinate" value={holdingData?.allocationByMarketCap?.[0]?.smallPercentage ? holdingData?.allocationByMarketCap?.[0]?.smallPercentage : 0} style={{ width: '50%', }} color='primary' sx={{
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'red',
                                                },
                                            }} />
                                        </Box>
                                    </TableCell>

                                </TableRow>
                                <TableRow >
                                    <TableCell className={classes.tableBodyfirstCell}>{`Tiny Cap`}</TableCell>
                                    <TableCell className={classes.tableBodylastCell}>

                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                            <Typography variant="caption" color="textSecondary">{`${Math.round(holdingData?.allocationByMarketCap?.[0]?.tinyPercentage)}%`}</Typography>
                                            <LinearProgress variant="determinate" value={holdingData?.allocationByMarketCap?.[0]?.tinyPercentage ? holdingData?.allocationByMarketCap?.[0]?.tinyPercentage : 0} style={{ width: '50%', }} color='primary' sx={{
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'red',
                                                },
                                            }} />
                                        </Box>
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                    {/* <Box display="flex" justifyContent="center" mt={2}><Typography className={classes.viewAll} onClick={handle_viewAll}>{!viewAll ? "View All Holdings" : "View less"}</Typography></Box> */}


                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div style={{ width: 'auto', overflowX: 'auto' }} className={classes.tableCellsLists}>


                        <Table style={{ marginTop: "0rem" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tablefirstCell} >Name</TableCell>
                                    {/* <TableCell className={classes.tableHead} >Sector</TableCell> */}
                                    <TableCell className={classes.tablelastCell}>Assets</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(!viewAll ? holdingData?.allocationBySector?.slice(0, 10) : holdingData?.allocationBySector)?.map((ele, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className={classes.tableBodyfirstCell}>{ele.sector}</TableCell>
                                            {/* <TableCell>{ele.sector}</TableCell> */}
                                            {/* <TableCell className={classes.tableBodylastCell}>{ele.holding_perc}%</TableCell> */}
                                            <TableCell className={classes.tableBodylastCell}>

                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                                    <Typography variant="caption" color="textSecondary">{`${Math.round(ele?.percentage)}%`}</Typography>
                                                    <LinearProgress variant="determinate" value={ele?.percentage} style={{ width: '50%', }} color='primary' sx={{
                                                        '& .MuiLinearProgress-bar': {
                                                            backgroundColor: 'red',
                                                        },
                                                    }} />
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    <Box display="flex" justifyContent="center" mt={2}><Typography className={classes.viewAll} onClick={handle_viewAll}>{!viewAll ? "View All Holdings" : "View less"}</Typography></Box>

                </TabPanel>
                <TabPanel value={value} index={2} >
                    {/* <All_funds type="Hybrid" /> */}
                    <div style={{ width: 'auto', overflowX: 'auto' }} className={classes.tableCellsLists}>


                        <Table style={{ marginTop: "0rem" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tablefirstCell} >Name</TableCell>
                                    {/* <TableCell className={classes.tableHead} >Sector</TableCell> */}
                                    <TableCell className={classes.tablelastCell}>Assets</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(!viewAll ? holdingData?.allocationByStockHoldings?.slice(0, 10) : holdingData?.allocationByStockHoldings)?.map((ele, i) => {
                                    return (
                                        <TableRow key={i}>
                                            <TableCell className={classes.tableBodyfirstCell}>{ele.fullName}</TableCell>
                                            {/* <TableCell>{ele.sector}</TableCell> */}
                                            {/* <TableCell className={classes.tableBodylastCell}>{ele?.assetAercentage.toFixed(2)}%</TableCell> */}
                                            <TableCell className={classes.tableBodylastCell}>

                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                                    <Typography variant="caption" color="textSecondary">{`${Math.round(ele?.assetAercentage)}%`}</Typography>
                                                    <LinearProgress variant="determinate" value={ele?.assetAercentage} style={{ width: '50%' }} sx={{ color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'), }} thickness={10} />
                                                </Box>
                                            </TableCell>

                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>
                    <Box display="flex" justifyContent="center" mt={2}><Typography className={classes.viewAll} onClick={handle_viewAll}>{!viewAll ? "View All Holdings" : "View less"}</Typography></Box>

                </TabPanel>

            </Box>
        </div>
    )
}

export default Holdings