import React, { useEffect, useState } from "react";
import { Box, makeStyles,Typography } from "@material-ui/core";
import { GET_USER_ADDRESS, DELETE_ADDRESS } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import AddAddress from "../../DigitalGold/Delivery/AddAddress";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const Index = () => {
    const useStyles = makeStyles((theme) => ({
        root: {
          border: "1px solid #C4C4C4",
          borderRadius: "10px",
          // padding:"5px 85px",
          marginTop: "1rem",
          fontSize: "0.8rem",
          fontWeight: 400,
          //width:"55%"
          
        },
        boxWidth: {
            width: "35%",
          },
          name: {
            fontSize: "0.8rem",
            fontWeight: 600,
          },
          deleteText: {
            fontSize: "0.7rem",
            color: "#F32424",
            cursor: "pointer",
            width:"fit-content"
          },
          secondaryButton: {
            padding: "0.7rem 2rem",
            background: "#fff",
            color: "#0E76BC",
            fontSize: "0.8rem",
            fontFamily:"inherit",
            fontWeight: 600,
            borderRadius: "45px",
            border: "1px solid #0E76BC",
            marginRight: "15px",
            cursor:"pointer"
          },
          subTabtext: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
            fontWeight: 500,
          },
          textUnderline: {
            fontSize: "0.7rem",
            fontFamily: "inherit",
          },
    }))
    const classes=useStyles()

    const [address, setAddress] = useState([]);
    const [addAddress, setAddAddress] = useState(false);

    const handleCloseAddAddress = () => {
        setAddAddress(false);
      };

      const get_address = async () => {
        const httpConfig = {
          method: "GET",
          tokenType: "user",
          headers: {
            "Content-Type": "application/json",
          },
        };
    
        try {
          const response = await httpInstance(`${GET_USER_ADDRESS}`, httpConfig);
          setAddress(response.data?.data);
        } catch {}
      };
    
      useEffect(() => {
        get_address();
      }, []);

      const handleDelete = async (userId) => {
        const httpConfig = {
          method: "POST",
          tokenType: "user",
          headers: {
            "Content-Type": "application/json",
          },
          data: { id: userId },
        };
    
        try {
          await httpInstance(`${DELETE_ADDRESS}`, httpConfig);
          get_address();
          
        } catch {}
      };
    
  return (
    <div>
         <Box
        display="flex"
        alignItems="start"
        px={{ xs: 1, md: 17 }}
        pt={2}
        style={{ borderBottom: "1px solid #E6E3E3",}}
      >
      
          <Typography className={classes.subTabtext}>
            My Profile
          </Typography>
       
        <Box display="flex">
          <ChevronRightIcon style={{ height: "1rem" }} />
          <Typography
            className={classes.textUnderline}
          >
            Edit Delivery Address
          </Typography>
        </Box>
      </Box>
        <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        mt={3}
      >
        <Typography style={{fontSize:"1.2rem", fontWeight:500}}>Delivery Address</Typography>
        {address?.map((ele) => {
          return (
            <Box key={ele.id} className={classes.boxWidth}>
              <Box
                key={ele.id}
                className={classes.root
                }
                px={3}
                
              >
                <Box style={{ borderBottom: "1px solid #C4C4C4",cursor:"pointer"}}
                 >
                  <p className={classes.name}>{ele.fullName}</p>
                  <p>
                    {ele.address}, {ele.city?.name}
                  </p>
                  <p>
                    {ele.state?.name} - {ele.pincode}
                  </p>
                  <p>Phone :{ele.mobileNumber}</p>
                </Box>
                <p
                  className={classes.deleteText}
                  onClick={() => handleDelete(ele.id)}
                >
                  Delete
                </p>
              </Box>
              
            </Box>
          );
        })}
       <Box mt={6} display="flex"
        justifyContent="center"
        alignItems="center">
          <button
            className={classes.secondaryButton}
            onClick={() => setAddAddress(true)}
          >
            Add New Address
          </button>
        </Box>
    </Box>
       
    <AddAddress
        dialog={addAddress}
        closeDialog={handleCloseAddAddress}
        get_address={get_address}
      />
    </div>
  )
}

export default Index