import React,{useState,useEffect} from 'react'
import { Box,makeStyles,Typography,Grid} from '@material-ui/core'
import StpDialog from'../STP/StpDialog'
import { BASE_URL } from '../../../../environment'


const StpCard = ({fundToData,schemeData,fundFromData,stpData}) => {
    const useStyles = makeStyles({
        root:{
            border:"1px solid #D0D0D0",
            borderRadius:"5px"
        },
        primaryButton: {
            padding: "0.8rem 3rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.8rem",
            fontFamily:"inherit",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
          },

          title:{
            fontSize:"1rem",
            fontWeight:500
          },
          textgrey:{
            fontSize:"0.7rem",
            color:"#626262"
          },

        "@media  (max-width:600px)":{
            primaryButton: {
                padding: "0.6rem 1rem",
                borderRadius:"10px"
                
              },
        }
    })

    const classes=useStyles()
    const [showStp,setShowStp]=useState(false)
  return (
    <div>
        <Box className={classes.root} p={3} px={{xs:1,md:4}} mt={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
                <img src={`${BASE_URL}${fundToData?.amcLogoUrl}`} height="30px" width="30px" style={{marginRight:"10px"}}/>
                <Box>
                <Typography className={classes.title}>{fundToData?.schemeName}</Typography>
                <Typography className={classes.textgrey}>Exit load and capital gain tax may be applicable</Typography>
                </Box>
            </Box>
            <button className={classes.primaryButton} onClick={()=>setShowStp(true)}>Transfer</button>
            </Box>      

        </Box>
        <StpDialog show={showStp} onClose={()=>setShowStp(false)} fundToData={fundToData} fundFromData={fundFromData} schemeData={schemeData} stpData={stpData}/>
        
    </div>
  )
}

export default StpCard