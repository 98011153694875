import React from 'react'
import {Box,Typography,makeStyles} from '@material-ui/core'

const AssistedExpired = ({handleResubscribe}) => {
    const useStyles=makeStyles({
        head:{
            color:"#0E76BC",
            fontWeight:500,
            marginTop:"15px"
        },
        text:{
            color:"#0E76BC",
        },
        primaryButton:{
            color: 'white',
            backgroundColor: "#0E76BC",
            border:"none",
            borderRadius:'39px',
            fontSize:'15px',
            fontWeight:'500',
            padding:"10px 3rem",
            fontFamily:"inherit",
            cursor:"pointer",
            outline:"none",
            '&:hover': {
              backgroundColor: "#0E76BC",
            },
        }

    })
    const classes=useStyles()
  return (
    <div>
        <Box display="flex" alignItems="center" flexDirection="column">
          <img src="/images/expiry.svg" height="250px" width="250px"/>
          
        <Typography className={classes.head}>Your Subscription has expired</Typography>
         <p className={classes.text}> Please resubscribe to avail benifits of
            Assisted Services</p>  
            
        <button className={classes.primaryButton} onClick={handleResubscribe}>Resubscribe</button>
        </Box>
    </div>
  )
}

export default AssistedExpired