import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Box, Container, Grid, styled } from "@material-ui/core";
import { Link } from "react-router-dom";
import { namespace } from "../../../environment";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  cardContent: {
    display: "flex",
    margin: "2rem 4rem",
  },
  mainText: {
    fontFamily: "Poppins",
    fontSize: "28px",
    fontWeight: "500",
    lineHeight: "48px",
    color: "#0E76BC",
  },
  subText: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "24px",
    width: "285px",
    height: "48px",
    marginTop: "32px",
  },
  learnMore: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    width: "150px",
    height: "26px",
    marginTop: "32px",
    padding: "0",
  },
  arrowColor: {
    color: "#0E76BC",
    marginLeft: "10px",
  },
  bellBG: {
    backgroundColor: "#F2AF1A",
    borderRadius: "50%",
    height: "36px",
    width: "37px",
    color: "white",
    fontSize: "21px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  mainCard: {
    height: "250px",
    width: "300px",
  },
  cardCenter: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  btnCenter: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    marginTop: "3rem",
  },
  unlockBtn: {
    color: "white",
    backgroundColor: "#0E76BC",
    border: "none",
    borderRadius: "39px",
    fontSize: "15px",
    fontWeight: "500",
    padding: "10px 3rem",
    fontFamily: "inherit",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0E76BC",
    },
  },
});

export default function SimpleCard() {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Container maxWidth="lg">
      <Box>
        <div className={`${classes.cardContent} p-5`}>
          <Grid item lg={6}>
            <Typography className={classes.mainText}>
              Your Perfect Investment Plan in under 3 Minutes
            </Typography>
            <Typography className={classes.subText}>
              Answer these questions to help us plan and analyze the best
              investment stratergy for you
            </Typography>
          </Grid>
          <Grid className={classes.cardCenter} item lg={6}>
            <img
              className={classes.arrowColor}
              src="/images/getstarted.png"
              height="200px"
              width="300px"
            />
          </Grid>
        </div>
        <div className={classes.btnCenter}>
          <Link
            to={`${namespace}/dashboard/mutualfund/assisted-services/questionaries`}
          >
            <button className={classes.unlockBtn} variant="contained">
              Get Started
            </button>
          </Link>
        </div>
      </Box>
    </Container>
  );
}
