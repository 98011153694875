import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Container,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import { KYC_DATA } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";
import Kyc_status from "../Funds/KycStatusCheck/Kyc_status";
import { useSelector } from "react-redux/es/exports";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import GoldKyc from "./GoldKyc";
import { namespace } from "../../../environment";

const Kyc_digitalgold = ({ dialog, closeDialog }) => {
  const useStyles = makeStyles({
    closeIcon: {
      padding: "5px",
      justifyContent: "flex-end",
    },

    title: {
      fontSize: "1.1rem",
      fontWeight: 500,
      textAlign: "center",
      fontFamily: "inherit",
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "center",
      alignItems: " center",
      textAlign: "center",
      padding: "5px",
    },
    dialogPaper: {
      // maxWidth: "25.5rem",
      justifyContent: "center",
    },
    image: {
      textAlign: "center",
    },
    text: {
      fontSize: "0.8rem",
      textAlign: "center",
      fontFamily: "inherit",
    },
    primaryButton: {
      padding: "0.8rem 3rem",
      background: "#0E76BC",
      color: "#fff",
      fontSize: "0.8rem",
      fontWeight: 600,
      borderRadius: "45px",
      border: "none",
      cursor: "pointer",
    },
    dialogContent: {
      padding: "8px 30px",
    },
  });

  const classes = useStyles();

  const [kycStatus, setKycStatus] = useState("");
  const [kycUpdate, setKycUpdate] = useState(false);

  const { userStore } = useSelector((state) => state);
  const status = userStore?.goldUser?.kycStatus;

  const get_kyc_data = async () => {
    const httpConfig = {
      method: "GET",
      tokenType: "user",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await httpInstance(`${KYC_DATA}`, httpConfig);
      setKycStatus(response.data?.data?.fpKycStatus);
    } catch {
      setKycStatus("empty");
    }
  };

  useEffect(() => {
    get_kyc_data();
  }, []);

  const handle_gold_kyc = () => {
    setKycUpdate(true);
  };

  const handleCloseModal = () => {
    closeDialog();
    setKycUpdate(false);
  };
  return (
    <Dialog
      open={dialog}
      onClose={handleCloseModal}
      classes={{ paper: classes.dialogPaper }}
    >
      <Box display="flex" className={classes.closeIcon}>
        {" "}
        <CloseIcon onClick={handleCloseModal} style={{ cursor: "pointer" }} />
      </Box>

      {!kycUpdate ? (
        <>
          <DialogTitle classes={{ root: classes.dialogTitle }}>
            <Typography className={classes.title}>Complete KYC</Typography>
          </DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <Box className={classes.image}>
              <img src="/images/Group 23150.svg" width="200px" height="200px" />
            </Box>
            <Typography className={classes.text}>
              Your Gold KYC has been rejected, click below to retake KYC
            </Typography>

            <Box my={3} display="flex" justifyContent="center">
              <Link to={`${namespace}/dashboard/digitalgold`}>
                <button
                  className={classes.primaryButton}
                  type="button"
                  onClick={handle_gold_kyc}
                >
                  Retake KYC
                </button>
              </Link>
            </Box>
          </DialogContent>
        </>
      ) : (
        <GoldKyc closeDialog={closeDialog} />
      )}
    </Dialog>

    // <Box px={7.5}  pt={1}>
    //   {status=="approved" && null}
    //   {(status=="SUBMITTED" || status=="pending") && kycStatus==="submitted" && <Kyc_status/> }
    //   {(status=="rejected" || status==null )&& (kycStatus==null || kycStatus=="empty" ||  kycStatus=="rejected") && <Kyc_status/> }
    //   {status==="rejected" && kycStatus=="successful" &&
    //     <Box className={classes.root}>
    //     <Grid container spacing={2}>
    //     <Grid item xs={5}>
    //       <Box p={3}>
    //         <img src="/images/Group 23171.svg" width="200px" height="150px" />
    //       </Box>
    //     </Grid>

    //     <Grid item xs={5}>
    //         <Box py={2} >
    //       <Typography className={classes.title}>KYC Rejected</Typography>
    //       <Typography className={classes.text}>Hey, </Typography>
    //       <Typography className={classes.text}>Your Gold KYC has been rejected, click below to complete your KYC</Typography>
    //       <button className={classes.primaryButton} onClick={handle_gold_kyc}>Complete KYC</button>
    //       </Box>
    //     </Grid>
    //   </Grid>
    //   </Box>
    //   }

    //   {(status==="SUBMITTED" || status=="pending") && kycStatus=="successful" &&
    //   <Box className={classes.root}>
    //     <Grid container spacing={2}>
    //     <Grid item xs={5}>
    //       <Box p={3}>
    //         <img src="/images/undraw_process_re_gws7 1.svg" width="250px" height="150px" />
    //       </Box>
    //     </Grid>

    //     <Grid item xs={5}>
    //         <Box py={2} >
    //       <Typography className={classes.title}>KYC Under review</Typography>
    //       <Typography className={classes.text}>Hey, </Typography>
    //       <Typography className={classes.text}>Congrats on successfully completing your KYC onboarding. We are currently reviewing your application & well get back with an update in next 2 - 3 days</Typography>
    //       </Box>
    //     </Grid>
    //   </Grid>
    //   </Box>
    //   }
    // </Box>
  );
};

export default Kyc_digitalgold;
