import React, { useState } from "react";
import {
    Box,
    makeStyles,
    Container,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { namespace, BASE_URL } from "../../../environment";
import ErrorMessage from "../../../components/Validation/ErrorMessage";
import { MANDATES_DETAILS_OF_BANK } from "../../../utils/endpoints";
import httpInstance from "../../../utils/instance";

const EMandate = ({ dialog, closeDialog, bankList }) => {
    const useStyles = makeStyles({
        closeIcon: {
            padding: "5px",
            justifyContent: "flex-end",
        },
        backButton: {
            marginTop: '10px',
            padding: "5px",
            justifyContent: "flex-start",
            cursor: 'pointer',
        },
        title: {
            fontSize: "1.1rem",
            fontWeight: 500,
            textAlign: "center",
            fontFamily: "inherit",
        },
        subTitle: {
            fontSize: "0.9rem",
            fontWeight: 500,
            textAlign: "center",
            fontFamily: "inherit",
        },
        dialogTitle: {
            display: "flex",
            justifyContent: "center",
            alignItems: " center",
            textAlign: "center",
            padding: "5px",
        },
        dialogPaper: {
            // maxWidth: "25.5rem",
            justifyContent: "center",
        },
        image: {
            textAlign: "center",
        },
        text: {
            fontSize: "0.8rem",
            textAlign: "center",
            fontFamily: "inherit",
        },
        primaryButton: {
            padding: "0.8rem 3rem",
            background: "#0E76BC",
            color: "#fff",
            fontSize: "0.9rem",
            fontWeight: 600,
            borderRadius: "45px",
            border: "none",
            cursor: "pointer",
        },
        dialogContent: {
            padding: "8px 30px 30px",
        },
        box2: {
            borderBottom: "1px solid #E4E4E4",
        },
        box3: {
            padding: "1rem 10px",
            cursor: "pointer",
        },
        bluebox3: {
            padding: "1rem 10px",
            color: "#0E76BC",
            borderBottom: "3px solid #0E76BC",
            cursor: "pointer",
        },
    });

    const classes = useStyles();
    const [selectedBank, setSelectedBank] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedMandateTypeTab, setSelectedMandateTypeTab] = useState("UPI");

    const selectionOfBank = (selectionOfBank) => {
        setError('')
        const updatedSelectionOfBank = { ...selectionOfBank };
        setSelectedBank(updatedSelectionOfBank);
    }
    const formatAccountNumber = (accountNumber) => {
        const length = accountNumber.length;

        // Show only the first 2 and last 2 characters, replace the rest with "x"
        return accountNumber.substring(0, 2) + 'x'.repeat(length - 4) + accountNumber.substring(length - 2);
    };

    // ---------------------------- e mandate type selection ------------------------------------ // 

    const handle_tab = (data) => {
        setSelectedMandateTypeTab(data);
    };

    // ---------------------------- create e mandate here ------------------------------------ // 
    const handleEMandate = async () => {
        console.log(selectedBank)
        if (!selectedBank) {
            setError('Please select a bank to proceed!')
            return
        }
        const formData = {
            bank_id: selectedBank?.id,
            mandate_limit: selectedBank?.mandates?.[0]?.mandate_limit ? selectedBank?.mandates?.[0]?.mandate_limit : (selectedMandateTypeTab === 'UPI' ? 15000 : 100000),
            // mandate_type: selectedBank?.mandates?.[0]?.mandate_type,
            mandate_type: selectedMandateTypeTab,

        };
        const httpConfig = {
            method: "POST",
            tokenType: "user",
            headers: {
                "Content-Type": "application/json",
            },
            data: formData,
        };
        // sessionStorage.removeItem("sip");
        // sessionStorage.setItem("lumsumId", orderData?.id);

        try {
            const response = await httpInstance(`${MANDATES_DETAILS_OF_BANK}`, httpConfig);
            console.log(response?.data?.data)
            window.location.replace(response.data?.data?.token_url);
        } catch (error) {
            setLoading(false);
            // console.log(error.response.data?.message)
            setError(error?.response?.data?.message ? error?.response?.data?.message : 'something went wrong')
        }
        // }
    }


    return (
        <div>
            {/* <Dialog
        open={dialog}
        onClose={closeDialog}
        classes={{ paper: classes.dialogPaper }}
      > */}
            <Box mx={5}>
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    px={20}
                    className={classes.backButton}
                    // onClick={handleButtonBack}
                    onClick={closeDialog}
                >
                    <img src="/images/backIcon.svg" width={15} height={15} />
                </Box>
                {/* <Box display="flex" className={classes.closeIcon}>
                    {" "}
                    <CloseIcon  style={{ cursor: "pointer" }} />
                </Box> */}

                {/* <DialogTitle classes={{ root: classes.dialogTitle }}> */}
                <Typography className={classes.subTitle}>No Mandate Found</Typography>
                {/* </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}> */}
                <Box className={classes.image}>
                    <img
                        src="/images/undraw_cancel_u-1-it 1.svg"
                        width="150px"
                        height="150px"
                    />
                </Box>
                <Typography className={classes.text}>
                    It seems like no mandate is created for the selected primary bank.
                </Typography>
                <Typography className={classes.text}>
                    You ned to create mandate before creating an SIP Order
                </Typography>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    px={6}
                    pb={2}
                    className={classes.box2}
                >
                    <Box
                    px={3}
                        className={selectedMandateTypeTab == "UPI" ? classes.bluebox3 : classes.box3}
                        onClick={() => handle_tab("UPI")}
                    >
                        <Typography  >UPI</Typography>
                    </Box>
                    <Box
                    px={3}
                        className={selectedMandateTypeTab == "E_Mandate" ? classes.bluebox3 : classes.box3}
                        onClick={() => handle_tab("E_Mandate")}
                    >
                        <Typography  >EMandate</Typography>
                    </Box>
                </Box>
                {bankList?.map((list, i) => {
                    return (
                        <Box
                            item xs={2} md={1}
                            display="flex"
                            key={i}
                            style={{
                                border: (selectedBank?.id === list?.id) ? '1px solid #007bff' : '1px solid #cccc',
                                cursor: 'pointer',
                                marginBottom: '10px', // Add margin to create spacing between items
                                position: 'relative',
                                padding: '10px', // Add padding for better aesthetics
                            }}
                            p={1}
                            onClick={() => selectionOfBank(list)}
                        >
                            <img src={`${BASE_URL}${list?.logo_url}`} padding="2px 10px" height="50px" width='auto' />

                            <Box width='100%' paddingLeft='10px' display='flex' justifyContent='center' alignItems={"center"} flexDirection={'column'}>
                                <Typography className={classes.text}>
                                    {/* {list.account_number} */}
                                    {formatAccountNumber(list?.account_number)}

                                </Typography>

                                <Typography className={classes.subTitle}>
                                    {list?.bank_name}
                                </Typography>
                            </Box>
                            <Box width={17} marginRight='10px' display='flex' justifyContent='center' alignItems={"center"} >
                                {(selectedBank?.id === list?.id) && (

                                    <img
                                        src="/images/checkMark.svg"
                                        height="16"
                                        width="16"
                                    // className={classes.closeBtn}
                                    />
                                )}
                            </Box>

                        </Box>
                    );
                })}
                {error && <ErrorMessage message={error} />}

                <Box my={3} display="flex" justifyContent="center">
                    {/* <Link to={`${namespace}/my-profile/bank-and-autoMandate`}> */}
                    <button
                        className={classes.primaryButton}
                        type="button"

                        onClick={handleEMandate}
                    >
                        {loading ? (
                            <CircularProgress size="1rem" color="inherit" />
                        ) : (
                            "Create Mandate"
                        )}
                    </button>
                    {/* </Link> */}
                </Box>
                {/* </DialogContent>
      </Dialog> */}
            </Box>
        </div>
    );
};

export default EMandate;
